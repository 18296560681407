import { RRBoat } from '../classes/RRBoat.class';
import {
  RiverRideGameplayConfig,
  RRBoatConfig,
  RREvent,
  RREvents,
  RRGameModes,
  RRGameParams,
  RRRiverSegment
} from '../river-ride.interfaces';
import { RiverRideGame } from '../classes/RiverRideGame.class';
import { RiverSegmentContainer } from '../classes/RiverSegment.class';
import { GameService } from '../../../game/services/game.service';
import { getObjectDef, provideSceneConfig, shuffle } from '../river-ride.helpers';
import {
  RR_ATLAS_KEY,
  RR_NORMAL_GAME_TIME, RR_PAUSE_USED_LS_KEY,
  RR_PLAY_SCENE_KEY,
  RR_PREMIUM_GAME_MIN_SPEED,
  RR_PREMIUM_GAME_TIME,
  RR_SEGMENT_HEIGHT
} from '../river-ride.constants';
import { Subscription } from 'rxjs';
import { GameObjectIds } from '../river-ride.config';
import * as R from 'ramda';
import Pointer = Phaser.Input.Pointer;

const fontStyles = {
  color: '#FFF',
  fontSize: '22px',
  fontFamily: 'MikadoPhaser',
  align: 'center',
  fontStyle: 'normal',
};

export class RiverRidePlayScene extends Phaser.Scene {
  currentSegmentIndex = 0;
  currentSegment: RiverSegmentContainer;
  nextSegment: RiverSegmentContainer;
  playerBoat: RRBoat;
  riverMap: RRRiverSegment[];
  targetBoatRotation = 0;
  isPlaying = false;
  editMode: boolean;
  sceneConfig: RiverRideGameplayConfig;
  gameService: GameService;

  keyLeft: Phaser.Input.Keyboard.Key;
  keyRight: Phaser.Input.Keyboard.Key;
  keyUp: Phaser.Input.Keyboard.Key;
  keyDown: Phaser.Input.Keyboard.Key;
  keySpace: Phaser.Input.Keyboard.Key;
  keyShift: Phaser.Input.Keyboard.Key;
  keyCtrl: Phaser.Input.Keyboard.Key;
  uiContainer: Phaser.GameObjects.Container;
  timeLeft = 150;
  timeLeftText: Phaser.GameObjects.Text;
  totalPointsText: Phaser.GameObjects.Text;
  distanceText: Phaser.GameObjects.Text;
  totalDistance = 0;
  totalPoints = 0;
  myGame: RiverRideGame;
  rrEvents$: Subscription;
  timePassed = 0;
  chestsCatched = 0;
  chestsCreated = 0;
  gameParams: RRGameParams;
  fishesCatched = 0;
  soundsEnabled = true;
  private woodHitSound: Phaser.Sound.BaseSound;
  private soundsMap: any;
  private collectSound: Phaser.Sound.BaseSound;
  bankHit: Phaser.Sound.BaseSound;
  music: Phaser.Sound.BaseSound;
  private bump: Phaser.Sound.BaseSound;
  chestsContainer: Phaser.GameObjects.Container;
  chestsIcons: Phaser.GameObjects.Image[];
  private touchLeftActive: boolean;
  private touchUpActive: boolean;
  private touchDownActive: boolean;
  private touchRightActive: boolean;
  private leftButton: Phaser.GameObjects.Sprite;
  private rightButton: Phaser.GameObjects.Sprite;
  private speedUpButton: Phaser.GameObjects.Sprite;
  private speedDownButton: Phaser.GameObjects.Sprite;

  constructor() {
    super({key: RR_PLAY_SCENE_KEY});
  }

  init(data: any) {
    this.sceneConfig = provideSceneConfig();
    this.myGame = this.game as RiverRideGame;
    this.gameParams = this.myGame.gameConfig.gameParams;
    this.timeLeft = this.isPremiumGame() ? RR_PREMIUM_GAME_TIME : RR_NORMAL_GAME_TIME;
    if (this.isPremiumGame()) {
      this.sceneConfig.minSpeed = RR_PREMIUM_GAME_MIN_SPEED;
    }
  }

  isPremiumGame() {
    return this.gameParams.gameMode === RRGameModes.PREMIUM;
  }

  preload() {
    this.editMode = (this.game as RiverRideGame).editMode;
    const preloadText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, '');
    this.gameService = (this.game as RiverRideGame).gameService;
    this.load.atlas('rr-atlas',
      this.gameService.assetsService.getAsset('minigames/riverride/atlas/rr-atlas.png').path,
      this.gameService.assetsService.getAsset('minigames/riverride/atlas/rr-atlas.json').path
    );
    this.sceneConfig.riverSegments.forEach(
      riverSegment => {
        this.load.image(
          riverSegment.imagePath, this.gameService.assetsService.getAsset(riverSegment.imagePath).path
        );
        this.load.image(
          `${riverSegment.imagePath}-m`,
          this.gameService.assetsService.getAsset(riverSegment.maskPath).path
        );
      }
    );

    this.load.on('progress', (progress) => {
      const progressRound = Math.round(100 * progress);
      preloadText.setText(progressRound + '%');
      this.emitRREvent(RREvents.LOAD_PROGRESS, progressRound);
    });

    this.load.on('complete', (progress) => {
      preloadText.destroy();
    });

    this.load.audio('woodHit', this.gameService.assetsService.getAsset('minigames/riverride/sfx/log.mp3').path);
    this.load.audio('collect', this.gameService.assetsService.getAsset('minigames/riverride/sfx/coin.mp3').path);
    this.load.audio('bump', this.gameService.assetsService.getAsset('minigames/riverride/sfx/bump.mp3').path);
    this.load.audio('bank-hit', this.gameService.assetsService.getAsset('minigames/riverride/sfx/bank-hit.mp3').path);
    this.load.audio('music', this.gameService.assetsService.getAsset('minigames/riverride/sfx/music.mp3').path);
  }

  generateRiverMap() {
    if (this.gameParams.state) {
      this.riverMap = this.gameParams.state.riverMap;

      if (this.myGame.gameService.globalService.isDevDomain) {
        this.riverMap
          .forEach((segment, segmentIndex) => {
            segment.objects.forEach((object, index) => {
              const isChest = object.objectId === GameObjectIds.CHEST;
              if (isChest) {
                console.log('Kontynuacja gry: Skrzynia na segmencie ', segmentIndex, segment);
              }
            });
          });
      }
    } else {
      const easySegments = R.clone(shuffle(this.sceneConfig.riverSegments.filter(segment => segment.difficulty === 1)));
      const mediumSegments = R.clone(shuffle(this.sceneConfig.riverSegments.filter(segment => segment.difficulty === 2)));
      const hardSegments = R.clone(shuffle(this.sceneConfig.riverSegments.filter(segment => segment.difficulty === 3)));
      const veryHardSegments = R.clone(shuffle(this.sceneConfig.riverSegments.filter(segment => segment.difficulty === 4)));
      const duplicatedVeryHardSegments = [...R.clone(veryHardSegments), ...R.clone(veryHardSegments)];

      const difficultyMap: Array<RRRiverSegment[]> = [
        null,
        easySegments,
        mediumSegments,
        hardSegments,
        veryHardSegments
      ];

      const segmentsDifficultyMap = [1, 1, 2, 1, 2, 2, 3, 1, 3, 4, 2, 3, 3, 4, 4, 4];
      this.riverMap = segmentsDifficultyMap.map(difficultyValue => difficultyMap[difficultyValue].shift());
      this.riverMap.push(...shuffle(duplicatedVeryHardSegments));

      const chestAddedMap = new Array(5).fill(0);
      const chestSegmentMap = [
        null,
        Phaser.Math.Between(1, 2),
        Phaser.Math.Between(1, 4),
        Phaser.Math.Between(1, 4),
        Phaser.Math.Between(1, 4),
      ];

      this.riverMap
        .forEach((segment, segmentIndex) => {
          segment.objects.forEach((object, index) => {
            const isChest = object.objectId === GameObjectIds.CHEST;
            if (isChest) {
              chestAddedMap[segment.difficulty]++;

              if (chestAddedMap[segment.difficulty] === chestSegmentMap[segment.difficulty]) {
                if (this.myGame.gameService.globalService.isDevDomain) {
                  console.log('Skrzynia na segmencie: ', segmentIndex, segment);
                }
              } else {
                segment.objects.splice(index, 1);
              }
            }
          });
        });
    }
  }

  create() {
    this.generateRiverMap();
    this.initializePlayerBoat();
    this.initializeRiver();
    this.keyboardKeysInit();
    if (this.gameService.deviceDetector.isMobile() || this.gameService.deviceDetector.isTablet()) {
      this.touchInputInit();
    }
    this.createUI();
    this.subscribeRREvents();
    this.emitRREvent(RREvents.LOAD_COMPLETE);
    if (this.soundsEnabled) {
      this.initializeSounds();
    }
  }

  subscribeRREvents() {
    this.rrEvents$ = this.myGame.gameConfig.riverRideService.rrEvents.subscribe((event: RREvent) => {
      if (!this.cameras.main) {
        this.rrEvents$.unsubscribe();
        return;
      }
      switch (event.name) {
        case RREvents.GAME_START:
          this.startGame();
          break;
        case RREvents.GAME_PAUSE:
          this.scene.pause();
          break;
        case RREvents.GAME_RESUME:
          this.scene.resume();
          break;
      }
    });
  }

  initializeRiver(fromSegment: number = 0) {
    const gameParams = this.gameParams;
    if (gameParams.state && gameParams.state.currentSegment) {
      fromSegment = gameParams.state.currentSegment.segmentIndex;
      this.currentSegmentIndex = fromSegment;
      this.chestsCatched = gameParams.state.chestsCatched;
      this.timeLeft = gameParams.state.timeLeft;
      this.fishesCatched = gameParams.state.score1;
      this.totalDistance = gameParams.state.score2;
      this.playerBoat.x = gameParams.state.boatPos.x;
    }
    this.currentSegment = this.createRiverSegmentObject(fromSegment);
    this.currentSegment.y = 0;
    if (gameParams.state && gameParams.state.currentSegment) {
      this.currentSegment.y = gameParams.state.currentSegment.segmentScrollY;
    }
    this.nextSegment = this.createRiverSegmentObject(fromSegment + 1);
    this.nextSegment.y = this.currentSegment.y - RR_SEGMENT_HEIGHT;
  }

  refreshSegments(currentSegmentIndex: number) {
    this.currentSegment.destroy();
    this.currentSegment = this.nextSegment;
    this.currentSegmentIndex = currentSegmentIndex + 1;
    this.nextSegment = this.createRiverSegmentObject(this.currentSegmentIndex + 1);
  }

  initializePlayerBoat() {
    const boatConfig: RRBoatConfig = {
      x: this.cameras.main.centerX,
      y: this.cameras.main.height - 100,
      key: 'rr-atlas',
      frame: `${this.gameParams.avatar}.png`
    };
    this.playerBoat = new RRBoat(this, boatConfig);
    this.playerBoat.setScrollFactor(0);
    this.playerBoat.setDepth(1);
  }

  updateTimeText(timeValue?: number) {
    console.log('Aktualizacja czasu (przed).', this.timeLeft, this.timeLeft.toString(), timeValue);
    if (timeValue) {
      this.timeLeft += timeValue;
    }
    if (this.timeLeft <= 0) {
      this.timeLeft = 0;
      this.timeLeftText.setText(`${this.timeLeft.toString()} s`);
      this.gameOver();
    }
    this.timeLeftText.setText(`${this.timeLeft.toString()} s`);

    console.log('Aktualizacja czasu (po).', this.timeLeft, this.timeLeft.toString());

    if (timeValue) {
      this.timeLeftText.setScale(2);
      this.timeLeftText.setTint(0xff0000);
      this.add.tween({
        targets: this.timeLeftText,
        scale: 1,
        duration: 300,
      });

      this.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 300,
        onUpdate: (tween) => {
          const value = Math.floor(tween.getValue());
          this.timeLeftText.setTint(Phaser.Display.Color.GetColor(timeValue < 0 ? 255 : value, timeValue > 0 ? 255 : value, value));
        }
      });
    }
  }

  updatePointsText(pointsValue: number) {
    if (pointsValue === 0) {
      return;
    }
    this.totalPoints += pointsValue;
    this.totalPointsText.setText(`${this.fishesCatched}`);
    this.totalPointsText.setScale(2);
    this.totalPointsText.setTint(0xff0000);
    this.add.tween({
      targets: this.totalPointsText,
      scale: 1,
      duration: 300,
    });

    this.tweens.addCounter({
      from: 0,
      to: 255,
      duration: 300,
      onUpdate: (tween) => {
        const value = Math.floor(tween.getValue());
        this.totalPointsText.setTint(Phaser.Display.Color.GetColor(value, 255, value));
      }
    });
  }

  update(time: number, delta: number) {
    this.moveSegment(delta);
    this.handlePlayerInput(delta);
    if (!this.editMode && this.isPlaying) {
      this.playerBoat.checkCollisions(this.currentSegment);
    }
    this.saveCurrentState(delta, 3000);
    if (this.playerBoat.speed > 0) {
      this.playerBoat.anims.msPerFrame = 100 / (this.playerBoat.speed / 2);
    }

    this.currentSegment.objects
      .filter(object => !object.collided)
      .forEach((object)=> {
        if (!this.isPlaying) {
          return;
        }
        // const collide = object.polygon.contains(this.playerBoat.x, this.playerBoat.y - this.currentSegment.y);

        const collide = Phaser.Math.Distance.Between(this.playerBoat.x, this.playerBoat.y - 10, object.x, object.y + this.currentSegment.y);
        if (collide < object.params.size + 30) {

          const soundEffect = this.getCollisionSoundByObjectId(object.params.objectId);
          if (soundEffect && this.soundsEnabled) {
            soundEffect.play();
          }

          object.collided = true;
          object.params.collided = true;

          if (object.params.objectId === GameObjectIds.PIRANHA) {
            this.fishesCatched++;
          }

          const objectParams = getObjectDef(object.params.objectId);
          this.updateTimeText(objectParams.timeValue);


          if (objectParams.objectId === GameObjectIds.CHEST) {
            this.chestsCatched++;
            this.refreshChestUI();
          } else {
            this.updatePointsText(objectParams.pointsValue);
          }

          if (!objectParams.destroyOnCollision) {
            this.add.tween(
              {
                targets: this.playerBoat,
                scale: 1.5,
                duration: 100,
                tint: 0xff0000,
                yoyo: true,
                ease: Phaser.Math.Easing.Quadratic.InOut,
              }
            );
            this.add.tween(
              {
                targets: object,
                scale: 0.7,
                duration: 100,
                yoyo: true,
                repeat: 3,
                ease: Phaser.Math.Easing.Quadratic.InOut,
              }
            );

          } else {
            object.destroyObject(true);
          }
        }
      });
  }

  handlePlayerInput(delta: number) {
    if (!this.playerBoat.canControl) {
      return;
    }

    const speedUp = this.keyUp.isDown || this.touchUpActive;
    const slowDown = this.keyDown.isDown || this.touchDownActive;

    if (speedUp && this.isPlaying) {
      this.playerBoat.increaseSpeedBy(0.005 * delta);
    }
    if (slowDown && this.playerBoat.speed > 0) {
      this.playerBoat.increaseSpeedBy(-0.005 * delta);
    }

    if (this.keySpace.isDown) {
      if (this.editMode) {
        this.playerBoat.speed = 0;
      }
    }

    const turnLeft = this.keyLeft.isDown || this.touchLeftActive;
    const turnRight = this.keyRight.isDown || this.touchRightActive;

    if (!turnLeft && !turnRight && Math.floor(this.targetBoatRotation) !== 0) {
      this.targetBoatRotation += this.targetBoatRotation > 0 ? -1.5 : 1.5;
    }

    if (this.playerBoat.speed > 0) {
      if (turnLeft) {
        this.playerBoat.x -= 0.25 * delta;
        this.targetBoatRotation -= 0.6 * this.targetBoatRotation > 0 ? 6 : 1;
        // this.playerBoat.setAngle(-20);
        // this.playerBoat.increaseSpeedBy(-0.005 * delta);
      }
      if (turnRight) {
        this.playerBoat.x += 0.25 * delta;
        this.targetBoatRotation += 0.6 * this.targetBoatRotation < 0 ? 6 : 1;
        // this.playerBoat.setAngle(20);
        // this.playerBoat.increaseSpeedBy(-0.005 * delta);
      }
    }
    this.targetBoatRotation = Phaser.Math.Clamp(this.targetBoatRotation, -30, 30);
    this.playerBoat.setAngle(this.targetBoatRotation);
  }

  moveSegment(delta: number) {
    if (this.playerBoat.speed > 0) {
      const movedValue = (delta / 10) * this.playerBoat.speed;
      this.currentSegment.y += movedValue;
      this.nextSegment.y = this.currentSegment.y - this.currentSegment.segmentImage.displayHeight;
      // this.totalDistance += movedValue / 100;
      if (this.currentSegment) {
        this.totalDistance = ((this.currentSegment.y + ((this.currentSegmentIndex) * RR_SEGMENT_HEIGHT)) / 100);
      }
      this.distanceText.setText(`${this.totalDistance.toFixed().toString().padStart(3, '0')} m`);
    }
    if (this.currentSegment.y >= this.currentSegment.segmentImage.displayHeight) {
      this.refreshSegments(this.currentSegmentIndex);
    }
  }

  createRiverSegmentObject(segmentIndex: number) {
    const segmentData = this.riverMap[segmentIndex];
    if (!segmentData) {
      this.gameOver();
      return;
    }
    return new RiverSegmentContainer(this, 0, -this.cameras.main.height * segmentIndex, segmentData);
  }

  keyboardKeysInit() {
    this.input.enabled = true;
    this.keyLeft = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
    this.keyRight = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
    this.keyUp = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP);
    this.keyDown = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN);
    this.keySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    this.keyShift = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SHIFT);
    this.keyCtrl = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.CTRL);
  }

  touchInputInit() {

    const {height: cameraHeight, width: cameraWidth} = this.cameras.main;

    this.leftButton = this.add.sprite(80, cameraHeight - 80, RR_ATLAS_KEY, 'control.png')
      .setInteractive()
      .on('pointerdown', () => this.touchLeftActive = true)
      .on('pointerover', () => this.touchLeftActive = true)
      .on('pointerup', () => this.touchLeftActive = false)
      .on('pointerupoutside', () => this.touchLeftActive = false)
      .on('pointerout', () => this.touchLeftActive = false)
      .setDepth(100000)
      .setOrigin(0.5)
      .setAngle(-90);

    this.rightButton = this.add.sprite(220, cameraHeight - 80, RR_ATLAS_KEY, 'control.png')
      .setInteractive()
      .on('pointerdown', () => this.touchRightActive = true)
      .on('pointerover', () => this.touchRightActive = true)
      .on('pointerup', () => this.touchRightActive = false)
      .on('pointerupoutside', () => this.touchRightActive = false)
      .on('pointerout', () => this.touchRightActive = false)
      .setDepth(100000)
      .setOrigin(0.5)
      .setAngle(90);



    this.speedUpButton = this.add.sprite(cameraWidth - 80, cameraHeight - 190, RR_ATLAS_KEY, 'control.png')
      .setInteractive()
      .on('pointerdown', () => this.touchUpActive = true)
      .on('pointerover', () => this.touchUpActive = true)
      .on('pointerup', () => this.touchUpActive = false)
      .on('pointerupoutside', () => this.touchUpActive = false)
      .on('pointerout', () => this.touchUpActive = false)
      .setDepth(100000);


    this.speedDownButton = this.add.sprite(cameraWidth - 80, cameraHeight - 80, RR_ATLAS_KEY, 'control.png')
      .setInteractive()
      .on('pointerdown', () => this.touchDownActive = true)
      .on('pointerover', () => this.touchDownActive = true)
      .on('pointerup', () => this.touchDownActive = false)
      .on('pointerupoutside', () => this.touchDownActive = false)
      .on('pointerout', () => this.touchDownActive = false)
      .setDepth(100000)
      .setOrigin(0.5)
      .setAngle(180);
  }

  saveCurrentState(delta: number, timeout: number) {
    if (!this.isPlaying) {
      return;
    }
    this.timePassed += delta;
    if (this.timePassed > timeout) {
      this.timePassed = 0;
    } else {
      return;
    }

    this.emitRREvent(RREvents.PROGRESS, this.getCurrentState());
  }

  createUI() {
    const {width: cameraWidth, height: cameraHeight, centerX} = this.cameras.main;
    this.uiContainer = this.add.container(centerX, cameraHeight - 30);
    this.uiContainer.setDepth(9999);
    const bar = this.add.image(0, 0, RR_ATLAS_KEY, 'bar.png');
    bar.setOrigin(0.5);
    this.uiContainer.add(bar);

    const timeImage = this.add.image(-180, 0, RR_ATLAS_KEY, 'clock.png');
    this.uiContainer.add(timeImage);
    this.timeLeftText = this.add.text(timeImage.x + 30, 0, `${this.timeLeft} s`, fontStyles);
    this.timeLeftText.setOrigin(0, 0.5);
    this.uiContainer.add(this.timeLeftText);

    // FISH
    const fishImage = this.add.image(-30, 0, RR_ATLAS_KEY, 'cartons-01.png');
    fishImage.setScale(0.8);
    this.uiContainer.add(fishImage);
    this.totalPointsText = this.add.text(fishImage.x + 40, 0, `${this.fishesCatched}`, fontStyles);
    this.totalPointsText.setOrigin(0, 0.5);
    this.uiContainer.add(this.totalPointsText);

    // DISTANCE
    const distanceImage = this.add.image(90, 0, RR_ATLAS_KEY, 'distance.png');
    this.uiContainer.add(distanceImage);
    this.distanceText = this.add.text(distanceImage.x + 30, 0, `${this.totalDistance.toFixed().toString().padStart(3, '0')} m`, fontStyles);
    this.distanceText.setOrigin(0, 0.5);
    this.uiContainer.add(this.distanceText);

    if (this.isPremiumGame()) {
      this.chestsContainer = this.add.container(cameraWidth - 50, 25);
      this.chestsContainer.setDepth(999);
      const chestsBg = this.add.image(0, 0, RR_ATLAS_KEY, 'box_bar.png');
      chestsBg.setOrigin(0.5, 0);
      this.chestsContainer.add(chestsBg);
      this.chestsIcons = [];
      for (let i = 0; i < 3; i++) {
        const chestIcon = this.add.image(0, 30 + i * 40, RR_ATLAS_KEY, 'box_d.png');
        this.chestsContainer.add(chestIcon);
        this.chestsIcons.push(chestIcon);
      }
      this.refreshChestUI();
    }
  }

  createTimeLeftTimer() {
    this.time.addEvent({
      delay: 1000,
      callback: () => {
        this.timeLeft--;
        this.updateTimeText();
        if (this.timeLeft <= 0) {
          this.gameOver();
        }
      },
      callbackScope: this,
      loop: true
    });
  }

  gameOver() {
    this.emitRREvent(RREvents.GAME_OVER, this.getCurrentState());
    console.log(this.getCurrentState())
    localStorage.removeItem(RR_PAUSE_USED_LS_KEY);
    this.scene.pause();
  }

  getCurrentState() {
    return {
      points1: this.totalPoints,
      points2: this.totalDistance,
    };
  }

  startGame(instant?: boolean) {
    this.emitRREvent(RREvents.PROGRESS, this.getCurrentState());

    this.music.play();
    this.music['loop'] = true;
    if (instant) {
      this.playerBoat.increaseSpeedBy(0.1);
      return;
    }
    const counter = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, `3`, fontStyles);
    counter.setFontSize(60);
    let timeToStart = 3;
    const startTimes = this.time.addEvent({
      delay: 1000,
      callback: () => {
        timeToStart--;
        counter.setText(`${timeToStart}`);
        if (timeToStart <= 0) {
          startTimes.remove();
          counter.destroy();
          this.isPlaying = true;
          this.playerBoat.increaseSpeedBy(0.1);
          this.createTimeLeftTimer();

          // fix for https://phaser.discourse.group/t/problem-with-setinteractive-function/3261/10
          const {width: cameraWidth, height: cameraHeight, centerX} = this.cameras.main;
          this.scale.setGameSize(cameraWidth, cameraHeight);

          document.body.onkeyup = (e) => {
            if (this.scene && e.keyCode === 32) {

              if (this.wasPauseUsed()) {
                return;
              }

              // this.scene.isPaused() ? this.scene.resume() : this.scene.pause();
              if (!this.scene.isPaused()) {
                this.input.keyboard.resetKeys();
                localStorage.setItem(RR_PAUSE_USED_LS_KEY, 'true');
                this.scene.pause();
                this.emitRREvent(RREvents.GAME_PAUSE);
              }
            }
          };
        }
      },
      callbackScope: this,
      loop: true
    });
  }

  wasPauseUsed() {
    return Boolean(localStorage.getItem(RR_PAUSE_USED_LS_KEY));
  }

  emitRREvent(name: RREvents, value?: any) {
    this.myGame.gameConfig.riverRideService.rrEvents.emit({
      name,
      value
    });
  }

  protected initializeSounds() {
    this.woodHitSound = this.sound.add('woodHit');
    (this.woodHitSound as any).setVolume(0.5);
    this.collectSound = this.sound.add('collect');
    this.collectSound['volume'] = 0.3;
    this.bankHit = this.sound.add('bank-hit');
    this.bankHit['volume'] = 0.3;
    this.bump = this.sound.add('bump');
    this.music = this.sound.add('music');
    this.music['volume'] = 0.4;

    this.soundsMap = {
      [GameObjectIds.WHIRLPOOL]: this.bump,
      [GameObjectIds.LOG]: this.woodHitSound,
      [GameObjectIds.CROCK]: this.bump,
      [GameObjectIds.CROCK2]: this.bump,
      [GameObjectIds.ARAPAIMA]: this.bump,
      [GameObjectIds.STONE_BIG]: this.bump,
      [GameObjectIds.STONE_SMALL]: this.bump,
      [GameObjectIds.PIRANHA]: this.collectSound,
      [GameObjectIds.CLOCK]: this.collectSound,
      [GameObjectIds.CHEST]: this.collectSound
    };
  }


  getCollisionSoundByObjectId(objectId: GameObjectIds) {
    return this.soundsMap[objectId];
  }

  refreshChestUI() {
    this.chestsIcons.slice(0, this.chestsCatched).forEach(icon => icon.setTexture(RR_ATLAS_KEY, 'box.png'));
  }
}
