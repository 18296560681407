import { Component, OnInit, ViewChild } from '@angular/core';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { ApiMissionGroupService } from '../../../../api/core/services/api-mission-group.service';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { generateEachPages } from '../../../../../../../shared/helpers/generate-pages.helper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EVENT_DIALOGS_NAMES_MISSION } from '../../../../consts/core/event-dialogs/event-names';

@Component({
  selector: 'app-mission-group-list',
  templateUrl: './mission-group-list.component.html',

})
export class MissionGroupListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MissionGroupListComponent>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @ViewChild('slider', {static: false}) slider: SwiperComponent;
  @OwInject(MAT_DIALOG_DATA) data: {
    index: number,
  };
  STOCK_VIEW = STOCK_VIEW;
  sliderActiveIndex;
  groups: {
    config: {
      itemPerPage: number;
    };
    pages: {
      mission_group_id: number;
      icon: string;
      name: string;
      reward_product_id: number;
      total_missions: number;
      completed_missions: number;
      is_reward_collected: boolean;
    }[][];
    items: any[];
  } = {
    config: {
      itemPerPage: 3
    },
    pages: [],
    items: [],
  };

  ngOnInit() {
    this.getMissionGroups();
  }

  getMissionGroups() {
    this.apiMissionGroupService.getMissionGroups().subscribe((resp) => {
      this.groups.items = resp;
      this.groups.pages = [];
      this.generatePages();
      this.setActivePage(this.data.index || 0);
    });
  }

  generatePages() {
    this.groups = generateEachPages(this.groups);
  }

  setActivePage(index: number) {
    this.sliderActiveIndex = Math.floor(index / 3);
  }

  prevSlide() {
    this.slider.directiveRef.prevSlide();
  }

  nextSlide() {
    this.slider.directiveRef.nextSlide();
  }

  openMissionGroupDetails(mission: any, index: number) {
    this.matDialogRef.close();
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_GROUP_DETAILS,
        config: {
          data: {
            missionGroups: this.groups.items,
            missionGroupId: mission.mission_group_id,
            index,
          }
        }
      });
    });
  }
}
