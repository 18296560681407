import { GameObjects } from 'phaser';
import { MyScene } from '../../core/MyScene';
import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, BOARD_TILE_BUYABLE, PHASER_CAMERA_ZOOM, TILE_HOVER_DEPTH } from '../../../../constants';
import { BoardTile } from '../../custom/BoardTile.class';
import { getTileMenuPositionOffset, getTileMenuPositionOffsetByBuildingIcon } from '../../../utils/board.helper';
import { GameService } from '../../../../services/game.service';
import { addTextShadow, keepScale1 } from '../../../utils/utils';
import { translate } from '../../../../../../core/helpers/translate.helper';
import * as R from 'ramda';
import { isNonInteractiveBuilding } from '../../../utils/game.helper';
import { TILE_MENU_ATLAS } from '../../../scenes-main/main.constants';
import { TileHoverConfig } from '../../../interfaces/tile-hover-config.interface';
import { ProductOrCurrencyCombinedData } from '../../../interfaces/shared';

export class TileHoverCore extends GameObjects.Container {

  myScene: MyScene;
  gameService: GameService;
  background: GameObjects.Sprite;
  mainTextObject: GameObjects.Text;
  mainTextString: string;
  belowTextObject: GameObjects.Text;
  belowTextString: string;
  additionalTextObject: GameObjects.Text;
  levelImage: GameObjects.Sprite;
  building;
  counter: HoverCounter;
  menuBgHoverBgHeightDifference = 153;

  autoProductionCombined: ProductOrCurrencyCombinedData;
  limitedResourceCombined: ProductOrCurrencyCombinedData;

  targetTile: BoardTile;

  autoproductionContainer: GameObjects.Container;
  resourcesLeftTextObject: GameObjects.Text;

  offset: { x: number; y: number };

  config: TileHoverConfig;
  autoprodContainer: Phaser.GameObjects.Container;
  autoprodTextObject: Phaser.GameObjects.Text;
  autoprodIcon: Phaser.GameObjects.Image;

  constructor(scene: MyScene) {
    super(scene, 0, 0);
    this.myScene = scene;
    this.gameService = this.myScene.gameService;
    this.autoproductionContainer = this.myScene.make.container({x: 0, y: 0});

    this.config = this.provideConfig();
    this.beforeGenerate();

    this.generateBackground();
    this.generateObjects();

    this.myScene.add.existing(this);
    this.visible = false;

    this.scalingListener();

    this.add(this.autoproductionContainer);
    this.afterGenerate();
  }

  /**
   * Override this method to provide custom logic for config providing.
   * Default config is passed when this method is executed on a class constructor.
   */
  provideConfig() {
    return this.myScene.sceneConfig.tileHoverConfig;
  }

  scalingListener() {
    keepScale1(this, this.myScene.cameras.main?.zoom, this.config.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.config.maxZoomScaleValue);
    });
  }

  beforeGenerate() {
  }

  afterGenerate() {
  }

  calculateOffset(target: BoardTile) {
    const positionOffsetByBuildingIcon = getTileMenuPositionOffsetByBuildingIcon(target.playerBuildingData.icon);
    return positionOffsetByBuildingIcon ? positionOffsetByBuildingIcon : getTileMenuPositionOffset(target.tileData.tile_type);
  }

  generateBackground() {
    this.background = this.myScene.add.sprite(0, 0, TILE_MENU_ATLAS, 'schadow_3.png');
    this.add(this.background);
  }

  calculateBackgroundOffset() {
    return {
      x: 0,
      y: -this.background.height / 2 - this.menuBgHoverBgHeightDifference / 2,
    };
  }

  /**
   * Generate objects for future use with show/hide method.
   */
  generateObjects() {
    const levelTextConfig = {
      x: 0,
      origin: 0.5,
      style: this.config.additionalTextStyles,
    };
    this.additionalTextObject = this.myScene.make.text(levelTextConfig);
    this.add(this.additionalTextObject);

    const textConfig = {
      x: 0,
      origin: 0.5,
      style: this.config.mainTextStyles,
    };
    this.mainTextObject = this.scene.make.text(textConfig);
    this.belowTextObject = this.scene.make.text(textConfig);

    this.add(this.mainTextObject);
    this.add(this.belowTextObject);

    this.autoprodContainer = this.myScene.add.container(0, 0);
    this.autoprodTextObject = this.myScene.add.text(25, -40, '', this.config.additionalTextStyles);
    this.autoprodTextObject.setOrigin(0, 0.5);
    addTextShadow(this.autoprodTextObject);
    this.add(this.autoprodContainer);
    this.autoprodContainer.add(this.autoprodTextObject);

    this.autoprodIcon = this.myScene.add.image(0, -40, null);
    this.autoprodContainer.alpha = 0;
    this.autoprodIcon.setOrigin(0.5);
    this.autoprodContainer.add(this.autoprodIcon);

    this.setTextsShadow();
  }

  /**
   * Prepare combined values from tile autoproduction data
   */
  processAutoProductionData() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.gameService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
    };
    if (this.autoProductionCombined.currency || this.autoProductionCombined.product) {
      this.autoProductionCombined.amount = R.path(['additional_board_data', AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME], this.building) || 0;
    }
  }

  /**
   * Prepare combined values from tile limited resource data
   */
  processLimitedResourceData() {
    this.limitedResourceCombined = {
      currency: this.targetTile.tileData.limited_resource_production_currency_prize
        ? this.gameService.currencyService.getCurrencyDefinition(this.targetTile.tileData.limited_resource_production_currency_prize)
        : null,
      product: this.targetTile.tileData.limited_resource_production_product_prize
        ? this.gameService.productionService.productsService.getProduct(this.targetTile.tileData.limited_resource_production_product_prize)
        : null,
      amount: null,
    };
    if (this.limitedResourceCombined.currency || this.limitedResourceCombined.product) {
      this.limitedResourceCombined.amount = this.targetTile.tileData.resource_left;
    }
  }

  /**
   * Show tile hover on target tile
   * @param target
   */
  show(target: BoardTile) {
    if (target.tileData.state === BOARD_TILE_BUYABLE) {
      this.showOnBuyable(target);
      return;
    }
    this.targetTile = target;
    this.myScene = target.myScene;
    this.gameService = this.myScene.gameService;
    this.offset = this.calculateOffset(target);
    const backgroundOffset = this.calculateBackgroundOffset();
    this.x = target.x + this.offset.x + backgroundOffset.x;
    this.y = target.y + this.offset.y + backgroundOffset.y;
    this.y = target.y - target.baseSprite.height / 2;
    this.building = target.playerBuildingData;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.hideAutoprodObjects();
    this.processAutoProductionData();
    this.processLimitedResourceData();

    this.setValues();
    this.additionalTextObject.setText(translate('tile-menu.level-text', [this.building.level]));
    this.visible = true;
    this.belowTextObject.alpha = 0;
    this.autoproductionContainer.alpha = 1;
    this.additionalTextObject.alpha = this.building && this.building.hide_level_information_on_front ? 0 : 1;
    this.additionalTextObject.visible = !isNonInteractiveBuilding(this.targetTile.playerBuildingData.group_type);
  }

  /**
   * Show specific tile when hovering buyable tile
   * @param target
   */
  showOnBuyable(target: BoardTile) {
    this.gameService = this.myScene.gameService;
    this.x = target.x;
    this.y = target.y - target.baseSprite.displayHeight / 2;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.mainTextString = `Dostępne od`;
    this.belowTextString = `${target.tileData.required_level} poziomu`;

    this.mainTextObject.setText(this.mainTextString);
    addTextShadow(this.mainTextObject);

    this.belowTextObject.setText(this.belowTextString);
    addTextShadow(this.belowTextObject);

    this.mainTextObject.y = this.config.basePositions.textObject.y - 2;
    this.belowTextObject.y = this.config.basePositions.textObjectBelow.y;

    this.autoproductionContainer.alpha = 0;
    this.additionalTextObject.alpha = 0;
    this.belowTextObject.alpha = 1;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    if (this.counter) {
      clearInterval(this.counter.intervalRef);
    }
    this.counter = null;
    this.clearTheVariableTexts();
    this.hideAutoprodObjects();
  }

  hideAutoprodObjects() {
    this.autoprodContainer.setAlpha(0);
  }

  /**
   * Set text and icon values on show
   */
  setValues() {
    this.background.setFrame('schadow_3.png');
    this.mainTextString = `${this.building.name}`;
    this.mainTextObject.setText(this.mainTextString);
    this.additionalTextObject.y = this.config.basePositions.levelText.y;
    this.mainTextObject.y = this.config.basePositions.textObject.y;
    this.belowTextObject.y = this.config.basePositions.textObjectBelow.y;
    this.setAutoproductionParams();
    this.setLimitedResourceParams();
  }

  /**
   * Set and show or hide autoproduction information based on autoproduction combined data
   */
  async setAutoproductionParams() {
    const autoprodCombinedValue = this.autoProductionCombined.currency || this.autoProductionCombined.product;
    if (autoprodCombinedValue) {
      this.autoprodTextObject.setText(`${this.autoProductionCombined.amount.toString()}/h`);
      await this.gameService.safeSetSpriteTexture(this.autoprodIcon, autoprodCombinedValue.iconUrl);
      this.autoprodIcon.setDisplaySize(50, 50);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      this.autoprodContainer.x = -(this.autoprodIcon.width / 2 + this.autoprodTextObject.width) / 2;
      this.autoprodContainer.setAlpha(1);
      this.background.setFrame('schadow_2.png');
    }
  }

  /**
   * Set and show or hide autoproduction information based on autoproduction combined data
   */
  async setLimitedResourceParams() {
    const limitedResourceValue = this.limitedResourceCombined.currency || this.limitedResourceCombined.product;
    if (limitedResourceValue) {
      this.autoprodTextObject.setText(`${this.limitedResourceCombined.amount.toString()}`);
      await this.gameService.safeSetSpriteTexture(this.autoprodIcon, limitedResourceValue.iconUrl);
      this.autoprodIcon.setDisplaySize(50, 50);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      this.autoprodContainer.x = -(this.autoprodIcon.width / 2 + this.autoprodTextObject.width) / 2;
      this.autoprodContainer.setAlpha(1);
    }
  }

  setTextsShadow() {
    addTextShadow(this.additionalTextObject);
    addTextShadow(this.mainTextObject);
    addTextShadow(this.autoprodTextObject);
  }

  clearTheVariableTexts() {
    this.additionalTextObject.setText('');
    this.mainTextObject.setText('');
    this.belowTextObject.setText('');
    this.autoprodTextObject.setText('');
    if (this.resourcesLeftTextObject) {
      this.resourcesLeftTextObject.setText('');
    }
  }
}

export interface HoverCounter {
  secondsLeft?: number;
  intervalRef: any;
  stringTime: string;
}
