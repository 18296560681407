import { TileType } from '../../../../store/game/interfaces/board-tile.state';
import {
  BoardViewMode,
  BUILDING_ANCHORS,
  DISCOVER_PLACES_DISPLAY_NAMES,
  DISCOVER_PLACES_NAMES,
  DISCOVER_PLACES_VERSIONS,
  DiscoverPlaceTypes,
  TILE_DEPTH_BY_BUILDING_ICON,
  TILE_HOVER_TYPE_ANCHORS,
  TILE_HOVER_TYPES,
  TILE_MENU_POSITION_OFFSETS,
  TILE_MENU_POSITION_OFFSETS_BY_BUILDING_ICON,
  TILE_TOOLTIP_OFFSET,
  TILE_TYPE_POLYGON
} from '../../constants';
import { ROADS_SIDES_VALUES } from '../scenes-main/main.constants';
import { RoadSide } from '../interfaces/board-tile.config';
import { BoardTile } from '../classes/custom/BoardTile.class';

export function getTileHoverTextureName(tileType: TileType) {
  const hoverTileType = TILE_HOVER_TYPES[`${tileType.width}x${tileType.height}`];
  return hoverTileType ? hoverTileType : `tile-hover-1.png`;
}

export function getTileTypePolygon(tileType: TileType) {
  const tilePolygon = TILE_TYPE_POLYGON[`${tileType.width}x${tileType.height}`];
  return tilePolygon ? tilePolygon : TILE_TYPE_POLYGON['1x1'];
}

export function getTileHoverAnchor(tileType: TileType) {
  const hoverTileAnchor = TILE_HOVER_TYPE_ANCHORS[`${tileType.width}x${tileType.height}`];
  return hoverTileAnchor ? hoverTileAnchor : {x: 0.5, y: 1};
}

export function getBuildingAnchor(icon: string, level: number) {
  const buildingAnchor = BUILDING_ANCHORS[`${icon}-${level}`];
  return buildingAnchor ? buildingAnchor : null;
}

export function getTileMenuPositionOffset(tileType: TileType) {
  const tileMenuOffset = TILE_MENU_POSITION_OFFSETS[`${tileType.width}x${tileType.height}`];
  return tileMenuOffset ? tileMenuOffset : {x: 0, y: 0};
}

export function getTileMenuPositionOffsetByBuildingIcon(buildingIcon: string) {
  const tileMenuOffset = TILE_MENU_POSITION_OFFSETS_BY_BUILDING_ICON[`${buildingIcon}`];
  return tileMenuOffset ? tileMenuOffset : null;
}

export function getTileTooltipPositionOffset(tileType: TileType) {
  const hoverTooltipOffset = TILE_TOOLTIP_OFFSET[`${tileType.width}x${tileType.height}`];
  return hoverTooltipOffset ? hoverTooltipOffset : {x: 0, y: 0};
}

export function getDepthByBuildingIcon(buildingIcon: string) {
  return TILE_DEPTH_BY_BUILDING_ICON[buildingIcon];
}

export function getIslandFromWorld(playerIslandId: number, worldData = []) {
  let targetIsland;
  worldData.forEach(regionData => {
    if (!targetIsland) {
      targetIsland = regionData.islands.find(island => island.player_island_id === playerIslandId);
    }
  });
  return targetIsland;
}

export function getDiscoverPlaceType(targetIsland): DiscoverPlaceType {
  const islandTypeAndVersion = getIslandTypeAndVersion(targetIsland.island_group_type);
  return {
    type: DISCOVER_PLACES_NAMES[islandTypeAndVersion.type],
    version: DISCOVER_PLACES_VERSIONS[islandTypeAndVersion.version],
    name: DISCOVER_PLACES_DISPLAY_NAMES[islandTypeAndVersion.type]
  };
}

/**
 * Get discover place type and version by splitting group_type_id value.
 * Tens are type, and unities are version.
 * @param groupTypeId
 */
export function getIslandTypeAndVersion(groupTypeId: number) {
  const typeAndVersion = groupTypeId.toString().split('').map(el => parseInt(el, 2));
  return {
    type: typeAndVersion[0],
    version: typeAndVersion[1]
  };
}

export interface DiscoverPlaceType {
  type: DiscoverPlaceTypes;
  name: string;
  version: string;
}

export function isIsometric(mode: BoardViewMode) {
  return mode === BoardViewMode.Isometric;
}

export function isOrto(mode: BoardViewMode) {
  return mode === BoardViewMode.Ortogonal;
}

export function filterRoadsSides(bitmask) {
  return ROADS_SIDES_VALUES.filter((value, index) => {
    // tslint:disable-next-line:no-bitwise
    return bitmask & (1 << index);
  });
}

export function hasRoadOnSide(side: RoadSide, filteredSides: RoadSide[]) {
  return filteredSides.includes(side);
}

export function firstOrLast(index: number, itemsCount: number) {
  return index === 0 || index === itemsCount;
}

export function getCenterTilePosition(boardTile: BoardTile) {
  const size = `${boardTile.tileData.tile_type.width}x${boardTile.tileData.tile_type.height}`;
  switch (size) {
    case '1x1':
      return {
        x: boardTile.x,
        y: boardTile.y - 164
      };
    case '1x2':
      return {
        x: boardTile.x + 144,
        y: boardTile.y - 212
      };
    case '2x2':
      return {
        x: boardTile.x,
        y: boardTile.y - 200
      };
    case '2x1':
      return {
        x: boardTile.x - 144,
        y: boardTile.y - 212
      };
    case '4x1':
      return {
        x: boardTile.x - 511,
        y: boardTile.y - 367
      };
    default: {
      return {
        x: boardTile.x,
        y: boardTile.y
      };
    }
  }
}

export function showOrHideByBuilding(property, buildingGroups) {
  let building_group = property;
  //if defined multiple objects comma separated
  if (building_group.indexOf(',') > -1) {
    return buildingGroups = building_group.replace(' ', '').split(',');
  } else {
    return buildingGroups = [building_group];
  }
}
