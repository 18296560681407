import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { CONFIG_NAVIGATION_CENTER } from '../config/core/navigation-center.config';

@Directive()
@Injectable()
export abstract class AbstractNavigationCenterComponent {
  @Input() hidePrev: boolean;
  @Input() hideNext: boolean;
  @Input() disabledPrev: boolean;
  @Input() disabledNext: boolean;
  @Input() buttonLayout = CONFIG_NAVIGATION_CENTER.BUTTON_LAYOUT;
  @Input() buttonSize = CONFIG_NAVIGATION_CENTER.BUTTON_SIZE;
  @Input() buttonColor = CONFIG_NAVIGATION_CENTER.BUTTON_COLOR;
  @Input() otherClass = CONFIG_NAVIGATION_CENTER.OTHER_CLASS;
  @Output() prevClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  prev(event) {
    event.stopPropagation();
    this.prevClicked.emit(true);
  }

  next(event) {
    event.stopPropagation();
    this.nextClicked.emit(true);
  }
}
