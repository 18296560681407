import { Injectable } from '@angular/core';
import { GuiService } from './gui.service';
import { ApiService } from '../../../core/providers/api.service';
import { AssetsService } from '../../../core/providers/assets.service';
import { CurrencyService } from '../../../core/providers/currency.service';
import {
  BuildingProductionData,
  FinalProductType,
  ProcessedProduction,
  ProcessedProductionIngredient
} from '../interfaces/production.interfaces';
import { PlayerService } from '../../player/providers/player.service';
import { SynchronizeTimeService } from '../../../core/providers/synchronize-time.service';
import { ProductPlayerService } from '../../player/providers/product-player.service';
import { MOCK_BUILDING_PRODUCTION_KEY } from '../game-ui/buildings/mock/consts/mock-building-details.const';
import { MOCK_BUILDING_PRODUCTION_CIRCLE_KEY } from '../game-ui/buildings/mock/consts/mock-building-production.const';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  blockCollect: boolean;

  constructor(
    public guiService: GuiService,
    private apiClient: ApiService,
    private assetsService: AssetsService,
    public currencyService: CurrencyService,
    public productsService: ProductPlayerService,
    private playerService: PlayerService,
    public synchronizeTimeService: SynchronizeTimeService,
  ) {

  }

  getBuildingProduction(id) {
    return this.apiClient.get(
      `buildings/${id}/production`,
      {},
      `${MOCK_BUILDING_PRODUCTION_KEY}_${id}`
    );
  }

  startPlayerBuildingProduction(player_building_id, production_recipe_id, fast_production?) {
    const options = {
      body: {
        fast_production
      }
    };
    return this.apiClient.request('POST', `player-buildings/${player_building_id}/production/${production_recipe_id}`, options);
  }

  productionCollect(player_building_production_id) {
    return this.apiClient.request('PATCH', `production/${player_building_production_id}/collect`);
  }

  autoProductionCollect(playerBuildingId) {
    return this.apiClient.request('PATCH', `player-buildings/${playerBuildingId}/collect`);
  }

  cancelProduction(player_building_production_id) {
    return this.apiClient.request('PATCH', `production/${player_building_production_id}/cancel`);
  }

  // ProductionCollectEmit(tileId) {
  //   this.guiService.boardAction.emit({
  //     action: 'production-collect-completed',
  //     value: {
  //       tile_id: tileId,
  //     }
  //   });
  // }

  /**
   * Return productions in uniform structure for rendering in template.
   * In Volt project can produce only one currency or one product. Priority is for currency.
   */
  prepareProductionForView(production: BuildingProductionData[]): ProcessedProduction[] {
    const processedProductions: ProcessedProduction[] = [];
    production
      .forEach(recipe => {
        let canStart = true;
        let canStartFast = true;

        const isCurrency = !!recipe.currency_prizes.length;
        if (!recipe.currency_prizes[0] && !recipe.product_prizes[0]) {
          return;
        }

        let currencyPrize;
        if (isCurrency) {
          currencyPrize = this.currencyService.getCurrencyDefinition(recipe.currency_prizes[0]);
        }

        let productPrize;
        if (recipe.product_prizes[0]) {
          productPrize = this.productsService.getProduct(recipe.product_prizes[0]);
        }

        const ingredients: ProcessedProductionIngredient[] = [];

        recipe.production_currency_prices.forEach(currencyPriceDef => {
          const currencyPrice = this.currencyService.getCurrencyBalance(currencyPriceDef, this.playerService.player.currency_balances);
          ingredients.push({
            icon: currencyPrice.iconUrlBig,
            amount: currencyPriceDef.amount,
            ingredientCurrency: currencyPrice,
            ingredientProduct: null,
            have: currencyPrice.have,
          });

          if (!currencyPrice.have) {
            canStart = canStartFast = false;
          }
        });

        recipe.production_product_prices.forEach(productPriceDef => {
          const productPrice = this.productsService.getProduct(productPriceDef);
          ingredients.push({
            icon: productPrice.iconUrl,
            amount: productPrice.amount,
            ingredientCurrency: null,
            ingredientProduct: productPrice,
            have: productPrice.have,
          });

          if (!productPrice.have) {
            canStart = canStartFast = false;
          }
        });

        let fastProductionCurrencyPrice;
        if (recipe.fast_production_currency_prices[0]) {
          fastProductionCurrencyPrice = this.currencyService.getCurrencyDefinition(recipe.fast_production_currency_prices[0]);
          if (fastProductionCurrencyPrice.have) {
            canStartFast = false;
          }
        } else {
          canStartFast = false;
        }

        processedProductions.push(
          {
            producingCurrency: currencyPrize,
            producingProduct: productPrize,
            producingTimeMinutes: recipe.time_in_minutes ? recipe.time_in_minutes : recipe.time_in_days * 60 * 24,
            finalProductType: isCurrency ? FinalProductType.Currency : FinalProductType.Product,
            finalProductName: isCurrency ? currencyPrize.name : productPrize.name,
            finalProductAmount: isCurrency ? recipe.currency_prizes[0].amount : recipe.product_prizes[0].amount,
            icon: isCurrency ? currencyPrize.iconUrlBig : productPrize.iconUrlBig,
            ingredients: ingredients,
            recipeId: recipe.production_recipe_id,
            fastProductionCurrencyPrice,
            canStart,
            canStartFast,
          }
        );
      });
    return processedProductions;
  }

  getPlayerBuildingsProductionCycles(player_building_id) {
    return this.apiClient.get(
      `player-buildings/${player_building_id}/production-cycles`,
      {},
      `${MOCK_BUILDING_PRODUCTION_CIRCLE_KEY}_${player_building_id}`
    );
  }
}

