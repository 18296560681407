import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { select, Store } from '@ngrx/store';
import { PlayerSelectors } from '../../../../../../../store/player';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../../store/state';
import { Player } from '../../../../../../player/interfaces/player';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { SynchronizeTimeService } from '../../../../../../../core/providers/synchronize-time.service';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { LoginActions } from '../../../../../../../store/auth/login';
import { AuthService } from '../../../../../../auth/providers/auth.service';
import { GuiService } from '../../../../../services/gui.service';
import { EventEmitterDialogsService } from '../../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from '../../../../rankings/consts/custom/event-dialogs/event-names.const';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
})
export class SummaryComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(Store) store: Store<AppState>;
  player: Player;
  subs = {
    player: null,
    nextGameTimer: null,
  }

  nextGameTimerValue: number;

  currentView: string;
  VIEW = {
    DEFAULT: 'default',
    SUMMARY: 'summary',
  }

  summary: string[];

  ngOnInit() {
    this.subscribePlayer();
    this.changeView(this.VIEW.DEFAULT);
  }

  subscribePlayer(){
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe((player) => {
        this.player = player;

        if(this.player['can_start_new_game_at']){
          this.countdownNextGameTimer();
        }
      });
  }

  setNextGameTimerValue(){
    this.nextGameTimerValue = moment(this.player['can_start_new_game_at'])
      .diff(
        moment(this.synchronizeTimeService.getActualLocalTimeWithOffset()),
        'seconds'
      );

    if(this.nextGameTimerValue <= 0){
      this.nextGameTimerValue = 0;
    }
  }

  countdownNextGameTimer(){
    this.countdownNextGameTImerUnsubscribe();

    this.subs.nextGameTimer = timer(0, 1000).subscribe(() => {
      this.setNextGameTimerValue();

      if(this.nextGameTimerValue <= 0){
        this.countdownNextGameTImerUnsubscribe();
      }
    })
  }

  countdownNextGameTImerUnsubscribe(){
    if(this.subs['nextGameTimer']){
      this.subs['nextGameTimer'].unsubscribe();
    }
  }

  postNextRound(){
    this.apiService.post('game/start-new').subscribe(() => {
      location.replace('/main/');
    })
  }

  openRanks(){
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: 1,
        }
      }
    });
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }

  changeView(view: string){
    this.currentView = view;
  }

  getSummaryDetails(){
    this.apiService.get('quiz-game/summary').subscribe((resp) => {
      this.summary = resp;
      this.changeView(this.VIEW.SUMMARY)
    })
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
