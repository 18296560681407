import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { Credentials } from '../../../modules/auth/interfaces/credentials';
import { AuthService } from '../../../modules/auth/providers/auth.service';
import { ActionTypes, AuthFail, AuthStart, AuthSuccess } from './actions';
import { ApiService } from '../../../core/providers/api.service';
import { TokenObject } from '../../../modules/auth/interfaces/token-object';
import { AppState } from '../../state';
import { DialogService } from '../../../modules/shared/providers/dialog.service';
import { UtilityActions } from '../../utility';
import { PlayerService } from '../../../modules/player/providers/player.service';
import { omitErrorResponseHelper } from '../../../core/helpers/omit-error-response.helper';

@Injectable()
export class LoginEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private playerService: PlayerService,
  ) {
  }

  @Effect()
  $authStart: Observable<void | Action> = this.actions$
    .pipe(
      ofType(ActionTypes.AUTH_START),
      mergeMap((action: AuthStart) => {
        return this.login(<Credentials>action.payload);
      })
    );

  @Effect({dispatch: false})
  $authLogout = this.actions$
    .pipe(
      ofType(ActionTypes.AUTH_LOGOUT),
      tap(() => {
        this.authService.logout();
        location.href = this.authService.logoutUrl || '/';
      })
    );

  login(credentials: Credentials): Observable<AuthSuccess | AuthFail> {
    return this.authService.doLogin(credentials)
      .pipe(
        tap((tokenObject: TokenObject) => {
          this.handleLogin(tokenObject);
        }),
        map(() => {
          return new AuthSuccess();
        }),
        catchError((error: any) => {
          return of(new AuthFail(omitErrorResponseHelper(error)));
        })
      );
  }

  handleLogin(tokenObject) {
    if (tokenObject) {
      this.store.dispatch(new UtilityActions.SetToken(tokenObject))
    }
  }
}
