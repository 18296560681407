<div
        class="ow-dialog window-a primary no-transparent"
>
    <div class="results-container">
        <div class="summary">
            <h2>Czas minął!</h2>
            <!-- <div class="result">
                <div class="icon">
                    <img [src]="'clock.png' | asset: 'minigames/riverride/ui'"/>
                </div>
                <span class="sum"> &nbsp;=&nbsp; {{ summary?.time }} pkt</span>
            </div> -->


            <div class="result">
                <div class="icon">
                    <img [src]="'distance.png' | asset: 'minigames/riverride/ui'"/>
                </div>
                <span class="sum"> &nbsp;=&nbsp; {{ summary.length }} pkt</span>
            </div>

            <div class="result">
                <div class="icon">
                    <img [src]="'cartons.png' | asset: 'minigames/riverride/ui'"/>
                </div>
                <span class="sum"> &nbsp;=&nbsp; {{ summary?.chests }} pkt</span>
            </div>
            <div class="separator"></div>

            <div class="total">
                Razem: {{ (summary.points) }} pkt
            </div>

            <button
                    class="short primary "
                    (click)="close()"
            >
                ok
            </button>
        </div>
    </div>
</div>
