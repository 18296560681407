const moduleName = 'buildings';

export const EVENT_DIALOGS_NAMES_BUILDINGS = {
  BUILDING_SPECIAL_UPGRADE: `${moduleName}-special-upgrade`,
  BUILDING_PROBABILITY_UPGRADE: `${moduleName}-probability-upgrade`,
  BUILDING_SPECIAL_INCREASE: `${moduleName}-special-increase`,
  BUILDING_UPGRADE: `${moduleName}-upgrade`,
  BUILDING_BUILD: `${moduleName}-build`,
  BUILDING_PRODUCTION: `${moduleName}-production`,
  BUILDING_LIST: `${moduleName}-list`,
  BUILDING_INFO: `${moduleName}-info`,
  BUILDING_AUTO_PRODUCTION: `${moduleName}-auto-production`,
  BUILDING_DAILY_PRODUCTION: `${moduleName}-daily-production`,
  BUILDING_DEMOLISH: `${moduleName}-demolish`,
  BUILDING_MOVE: `${moduleName}-move`,
  BUILDING_TILE_BUY: `${moduleName}-tile-buy`,
};
