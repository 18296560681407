import { LoginComponent } from '../../custom/basic/components/login/login.component';
import { OauthConfirmComponent } from '../../custom/basic/components/oauth-confirm/oauth-confirm.component';
import { AUTH_ROUTING_PATH } from '../core/routing-auth.const';

export const CUSTOM_ROUTING_AUTH_CHILDREN = [
  {
      path: AUTH_ROUTING_PATH.LOGIN,
      pathMatch: 'full',
      component: LoginComponent,
  },
  {
    path: 'oauth2/confirm/:token', component: OauthConfirmComponent,
  },
];
