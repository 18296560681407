import { Directive, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { CurrencyService } from '../../../../../../core/providers/currency.service';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { Mission, MissionDetails } from '../../interfaces/core';
import { MISSION_CONDITION_VALUE_TYPE } from '../../consts/core';
import { CurrencyDetails } from '../../../../../../core/interfaces/currency';
import { ApiMissionService } from '../../api/core/services/api-mission.service';
import { EVENTS } from '../../../../../../core/consts/core/events';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { AppState } from '../../../../../../store/state';
import { Store } from '@ngrx/store';
import { UtilityActions } from '../../../../../../store/utility';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { PlayerService } from '../../../../../player/providers/player.service';
import * as moment from 'moment';
import { CenterMap } from '../../../../game-engine/interfaces/shared';
import { EVENT_DIALOGS_NAMES_MISSION } from '../../consts/core/event-dialogs/event-names';

@Directive()
@Injectable()
export abstract class AbstractMissionDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMissionDetailsComponent>;
  @OwInject(MAT_DIALOG_DATA) public data: {
    mission: Mission;
    afterCloseOpenList: boolean,
    isMissionContract: boolean,
  };
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;

  MISSION_CONDITION_VALUE_TYPE = MISSION_CONDITION_VALUE_TYPE;
  mission: MissionDetails;
  playerMissionsReload: boolean;
  noConditionsComplete: boolean;
  STOCK_VIEW = STOCK_VIEW;
  timer;
  missionExpired = false;
  intervalTimer;

  getMissionDetails(id: number) {
    this.apiMissionService.getMissionDetails(id)
      .subscribe(
        (resp) => {
          this.mission = resp;
          this.afterMissionDetailsRequest();
        });
  }

  afterMissionDetailsRequest() {
    this.setCurrencyAndProducts();
    this.isMissionSeen();
    this.checkMissionCondition();
    if (this.mission.expires_at) {
      this.setTimer();
    }
  }

  setCurrencyAndProducts() {
    this.mission.currency_prizes = <CurrencyDetails[]>this.currencyService.getCurrencyDefinitions(this.mission.currency_prizes);
    this.mission.product_prizes = this.productPlayerService.getProducts(this.mission.product_prizes);
  }

  isMissionSeen() {
    if (!this.mission.seen) {
      this.playerMissionsReload = true;
      this.apiMissionService.missionSeen(this.mission.player_mission_id)
        .subscribe(() => {
        }, (errResp) => {
          if (errResp.status === 409) {
            errResp.defaultHandler.unsubscribe();
          }
        });
    }
  }

  checkMissionCondition() {
    this.noConditionsComplete = false;
    for (let i = 0; i < this.mission.conditions.length; i++) {
      if (!this.mission.conditions[i].completed_at) {
        this.noConditionsComplete = true;
        break;
      }
    }
  }

  close() {
    if (this.data.afterCloseOpenList) {
      this.matDialogRef.close({afterCloseOpenList: this.data.afterCloseOpenList});
    } else {
      this.matDialogRef.close({playerMissionsReload: this.playerMissionsReload});
    }
  }

  openMissionTransfer(condition) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSION_TRANSFER,
      config: {
        data: {
          condition,
        },
      },
      callback: ({reload}: { reload?: boolean } = {}) => {
        if (reload) {
          this.getMissionDetails(this.mission.player_mission_id);
          this.playerMissionsReload = true;
        }
      }
    });
  }

  collect() {
    this.playerMissionsReload = true;
    this.apiMissionService.missionCollect(this.mission.player_mission_id)
      .subscribe((resp) => {
        this.afterCollectRequest(resp);
      });
  }

  afterCollectRequest(resp) {
    this.close();
  }

  reject() {
    this.playerMissionsReload = true;
    this.apiMissionService.missionReject(this.mission.player_mission_id)
      .subscribe(() => {
        this.close();
      });
  }

  showOnMap(centerMap: CenterMap) {
    this.globalService.globalEvents.emit({
      name: EVENTS.GAME.CENTER_TILE,
      value: centerMap,
    });

    this.dialogService.closeAll();
  }

  dispatchRemoveMissionToOpen() {
    if (this.mission) {
      this.store
        .dispatch(new UtilityActions.RemoveMissionToOpen({
          player_mission_id: this.mission.player_mission_id
        }));
    }
  }

  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = this.playerService.getRealTime();
      const expiredAt = moment(this.mission.expires_at);
      this.timer = moment(expiredAt).diff(moment(actualDate), 'seconds');
      if (this.timer <= 0) {
        clearInterval(this.intervalTimer);
        this.missionExpired = true;
      }
    }, 1000);
  }

  clearInterval() {
    clearInterval(this.intervalTimer);
  }
}
