import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractLoginComponent } from '../../../../abstract/abstract-login.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ActivatedRoute } from '@angular/router';
import { getToken } from '../../../../../../core/utility/token';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { orientationModes } from '../../../../../../core/consts/core/orientation-modes.enum';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { STORAGE_CONFIG_CUSTOM } from '../../../../../../core/config/custom/data-storage.config';

@Component({
  selector: 'app-custom-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(GlobalService) globalService: GlobalService;

  error: string;
  template: TEMPLATES;
  TEMPLATES = TEMPLATES;
  allowedCookieSession: boolean = undefined;

  ngOnInit() {
    this.subscribeRoutingParams();
    if (getToken().token) {
      this.router.navigate(['/auth/player-choose']);
    } else {
      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
      this.checkAllowCookieTemplate();
    }
  }

  checkCookieLogin(){
    if(this.getAllowedCookie() === '1'){
      this.login();
    } else {
      this.template = TEMPLATES.COOKIE;
    }
  }

  login() {
    const rememberMe = this.form.value.remember_me;
    location.href = location.origin + `/auth/oauth2/login${rememberMe ? '?remember_me=1' : ''}`;
  }

  subscribeRoutingParams() {
    this.activatedRoute.queryParams.subscribe((data: { error: string }) => {
      if (data.hasOwnProperty('error')) {
        this.error = this.decodeBase64(data.error);
      }
    });
  }

  decodeBase64(string) {
    return decodeURIComponent(Array.prototype.map.call(atob(string), (c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  checkAllowCookieTemplate(){
    if(this.getAllowedCookie() === '1' || this.allowedCookieSession === false){
      this.template = TEMPLATES.LOGIN;
    } else {
      this.template = TEMPLATES.COOKIE;
    }
  }

  getAllowedCookie(){
    return STORAGE_CONFIG_CUSTOM.COOKIE.get('allowed_cookie');
  }

  setAllowedCookie(){
    STORAGE_CONFIG_CUSTOM.COOKIE.set('allowed_cookie', '1', {});

    if(this.allowedCookieSession === undefined){
      this.allowedCookieSession = true;
      this.checkAllowCookieTemplate();
    } else {
      this.checkCookieLogin()
    }
  }

  setDisallowedCookieInSession(){
    this.allowedCookieSession = false;
    this.checkAllowCookieTemplate();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}

enum TEMPLATES {
  LOGIN,
  COOKIE
}
