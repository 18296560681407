import { SceneConfig } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASIC_HEIGHT, BASIC_SCENE_BASIC_WIDTH } from '../scenes-basic/basic.constants';
import { TILE_MENU_CONFIG } from '../classes/tile-menu/tile-menu.config';
import { TILE_HOVER_CONFIG } from '../classes/tile-hover/tile-hover.config';
import { TILE_TOOLTIP_CONFIG } from '../classes/tile-tooltip/tile-tooltip.config';

export const WORLD_SCENE_CONFIG: SceneConfig = {
  backgroundImages: [
    {
      filename: 'map.jpg',
      key: 'map',
      origin: {
        x: 0,
        y: 0
      },
      displayWidth: BASIC_SCENE_BASIC_WIDTH,
      displayHeight: BASIC_SCENE_BASIC_HEIGHT,
      x: 0,
      y: 0
    }
  ],
  backgroundColor: '#71aee3',
  tiledJsonPrefix: 'island',
  mapDirectoryName: 'world',
  boardStartOffset: {
    x: 0,
    y: 0
  },
  tiledStartOffset: {
    x: 0,
    y: 0
  },
  mapAtlasDirectory: 'map',
  tileMenuAtlasDirectory: 'tile-menu',
  islandGroupIdForBoard: 'B',
  tiledMapFileName: 'map',
  yAxisSign: -1,
  cameraBounds: {
    x: -5000,
    y: 0,
    width: 10000,
    height: 7000
  },
  cameraStartPosition: {
    x: 5000,
    y: 3500
  },
  tileMenuConfig: {...TILE_MENU_CONFIG},
  tileHoverConfig: {...TILE_HOVER_CONFIG},
  tileTooltipConfig: {...TILE_TOOLTIP_CONFIG}
};
