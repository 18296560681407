import { Action } from '@ngrx/store';
import { TokenObject } from '../../modules/auth/interfaces/token-object';
import { Mission } from '../../modules/game/game-ui/mission/interfaces/core';

export enum ActionTypes {
  UPDATE_ACTIVE_PLAYER_ID = '[utility] update active player id',
  UPDATE_ME_PLAYER_ID = '[utility] update me player id',
  FETCH_NEW_TOKEN_START = '[utility] fetch new token start',
  FETCH_NEW_TOKEN_FINISH = '[utility] fetch new token finish',
  SET_TOKEN = '[utility] set token',
  REMOVE_TOKEN = '[utility] clear token',
  SET_HAS_MISSIONS_TO_COLLECT = '[utility] set has missions to collect',
  ADD_MISSION_TO_OPEN = '[utility] add mission to open',
  REMOVE_MISSION_TO_OPEN = '[utility] remove mission to open',
  OPEN_MISSION = '[utility] open mission',
  ADD_NEW_PRODUCTS_IN_STORAGE = '[utility] new products in storage',
  CLEAR_NEW_PRODUCTS_IN_STORAGE = '[utility] clear products in storage',
  SET_HAS_NEW_MESSAGES_TO_READ = '[utility] set has new messages to read',
  CLEAR_NEW_MESSAGES_TO_READ = '[utility] clear new messages to read',
  ERROR_PRELOAD_ASSETS_MAP = '[utility] error preload assets map',
  DIFFERENT_VERSION_GAME = '[utility] different version game',
}

export class UpdateActivePlayerId implements Action {
  readonly type = ActionTypes.UPDATE_ACTIVE_PLAYER_ID;

  constructor(public payload: { playerId: number }) {
  }
}

export class UpdateMePlayerId implements Action {
  readonly type = ActionTypes.UPDATE_ME_PLAYER_ID;

  constructor(public payload: { playerId: number }) {
  }
}

export class FetchNewTokenStart implements Action {
  readonly type = ActionTypes.FETCH_NEW_TOKEN_START;
}

export class FetchNewTokenFinish implements Action {
  readonly type = ActionTypes.FETCH_NEW_TOKEN_FINISH;
}

export class SetToken implements Action {
  readonly type = ActionTypes.SET_TOKEN;

  constructor(public payload: TokenObject) {
  }
}

export class RemoveToken implements Action {
  readonly type = ActionTypes.REMOVE_TOKEN;

  constructor() {
  }
}

export class SetHasMissionsToCollect implements Action {
  readonly type = ActionTypes.SET_HAS_MISSIONS_TO_COLLECT;

  constructor(public payload: boolean) {
  }
}

export class AddMissionToOpen implements Action {
  readonly type = ActionTypes.ADD_MISSION_TO_OPEN;

  constructor(public payload: Partial<Mission>) {
  }
}

export class RemoveMissionToOpen implements Action {
  readonly type = ActionTypes.REMOVE_MISSION_TO_OPEN;

  constructor(public payload: Partial<Mission>) {
  }
}

export class OpenMission implements Action {
  readonly type = ActionTypes.OPEN_MISSION;
}

export class AddNewProductsInStorage implements Action {
  readonly type = ActionTypes.ADD_NEW_PRODUCTS_IN_STORAGE;

  constructor(public payload: { product_category_id: number }[]) {
  }
}

export class ClearNewProductsInStorage implements Action {
  readonly type = ActionTypes.CLEAR_NEW_PRODUCTS_IN_STORAGE;
}

export class SetHasNewMessagesToRead implements Action {
  readonly type = ActionTypes.SET_HAS_NEW_MESSAGES_TO_READ;

  constructor(public payload: boolean) {
  }
}

export class ClearNewMessagesToRead implements Action {
  readonly type = ActionTypes.CLEAR_NEW_MESSAGES_TO_READ;
}

export class ErrorPreloadAssetsMap implements Action {
  readonly type = ActionTypes.ERROR_PRELOAD_ASSETS_MAP;
}

export class DifferentVersionGame implements Action {
  readonly type = ActionTypes.DIFFERENT_VERSION_GAME;
}

export type Actions =
  UpdateActivePlayerId
  | UpdateMePlayerId
  | FetchNewTokenStart
  | FetchNewTokenFinish
  | SetToken
  | RemoveToken
  | SetHasMissionsToCollect
  | AddMissionToOpen
  | RemoveMissionToOpen
  | OpenMission
  | AddNewProductsInStorage
  | ClearNewProductsInStorage
  | SetHasNewMessagesToRead
  | ClearNewMessagesToRead
  | ErrorPreloadAssetsMap
  | DifferentVersionGame
  ;
