import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinigameRiverRideComponent } from './riverride/components/minigame-river-ride/minigame-river-ride.component';
import { RRConfigEditorModule } from './riverride/rrconfig-editor/rrconfig-editor.module';
import { SharedUiMobileModule } from '../game/game-ui/shared-ui/mobile/shared-ui-mobile.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SharedModule } from '../shared/shared.module';
import { MinigameRiverRideGuiComponent } from './riverride/components/minigame-river-ride-gui/minigame-river-ride-gui.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MinigameSummaryComponent } from './riverride/components/minigame-summary/minigame-summary.component';


@NgModule({
  declarations: [
    MinigameRiverRideComponent,
    MinigameRiverRideGuiComponent,
    MinigameSummaryComponent,
  ],
  imports: [
    CommonModule,
    RRConfigEditorModule,
    SharedUiMobileModule,
    SwiperModule,
    SharedModule,
    FormsModule,
    BrowserModule,
  ],
  entryComponents: [
    MinigameRiverRideGuiComponent,
  ],
})
export class MinigamesModule { }

