import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsChestSuccessComponent } from '../../../../abstract/core/abstract-message-details-chest-success.component';

@Component({
  selector: 'app-message-details-chest-success',
  templateUrl: './message-details-chest-success.component.html',

})
export class MessageDetailsChestSuccessComponent extends AbstractMessageDetailsChestSuccessComponent implements OnInit {
  ngOnInit() {
    this.getProducts();
  }
}
