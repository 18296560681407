import { BoardCore } from "../core/BoardCore.class";
import { BoardTileState } from "../../../../../store/game/interfaces/board-tile.state";
import { BoardTile } from "./BoardTile.class";
import { CAMERA_START_TILE } from "../../../constants";

export class Board extends BoardCore {
  createTile(tileData: BoardTileState, isUpdate?: boolean) {
    if (
      tileData.player_building &&
      (tileData.player_building.group.includes("hidden") || tileData.player_building.group.includes("hiden"))
    ) {
      console.log("Hidden building", tileData);
      return true;
    }

    this.destroyTile(tileData.tile_id);

    const boardTile = new BoardTile({
      scene: this.myScene,
      x: tileData.x_pos,
      y: tileData.y_pos * this.yAxisSign,
      tileData: tileData,
      viewMode: this.viewMode,
      offset: this.offset,
    });

    if (this.myScene.buildingsLayer) {
      boardTile.depthIndex = this.myScene.buildingsLayer.depthIndex;
      boardTile.calculateDepth();
      this.myScene.buildingsLayer.add(boardTile);
    }

    if (tileData.x_pos === CAMERA_START_TILE.x && tileData.y_pos === CAMERA_START_TILE.y) {
      this.myScene.cameraStartPos = {
        x: boardTile.x,
        y: boardTile.y - 100,
      };
    }

    if (boardTile?.playerBuildingData?.question_info) {
      this.boardTiles.push(boardTile);
    } else {
      this.destroyTile(tileData.tile_id);
    }
  }

  updateTile(tileData: BoardTileState) {
    const currentTile = this.getTileByTileId(tileData.tile_id);
    if (currentTile && this.shouldUpdateSilent(currentTile.tileData, tileData, currentTile) && tileData?.player_building?.question_info) {
      this.silentUpdate(currentTile, tileData);
    } else if (tileData?.player_building?.question_info) {
      this.createTile(tileData);
      this.resetBoardTilesAlpha();
    } else {
      this.destroyTile(tileData.tile_id);
    }
  }
}
