import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FilterObjectPipe } from './directives/filter-object/filter-object.pipe';
import { FilterRankDetailsPipe } from './directives/filter-rank-details.pipe';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { ParameterPipe } from './pipes/parameter.pipe';
import { BuildingIconPipe } from './pipes/building-icon.pipe';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  slidesPerView: 1,
  threshold: 15,
};

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        SwiperModule,
        FormsModule,
    ],
    declarations: [
        FilterObjectPipe,
        FilterRankDetailsPipe,
        ParameterPipe,
    ],
    providers: [
        DecimalPipe,
        PercentPipe,
        {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    ],
    exports: [
        BuildingIconPipe,
    ]
})
export class GameGuiModule {
}
