import { IsoSprite } from '../classes/core/IsoSprite';
import { isoToScreen } from '../utils/utils';

const TRAIN_DURATION_FACTOR = 1;

export class Train extends IsoSprite {

  fromSouth: boolean;
  update() {
    const screenPos = isoToScreen(this.isoX, this.isoY);

    this.x = screenPos.x;
    this.y = screenPos.y;
  }

  start() {
    const shape = this.scene.add.image(5823, 3940, this.texture.key, 'station_mask.png').setVisible(false);
    this.setMask(shape.createBitmapMask());
    this.mask.invertAlpha = true;
    this.goToStation();
  }

  goToStation(fromSouth?: boolean) {
    this.fromSouth = fromSouth;
    this.scene.add.tween({
      targets: [this],
      isoY: 897,
      duration: 10000 / TRAIN_DURATION_FACTOR,
      ease: Phaser.Math.Easing.Quadratic.Out,
      onUpdate: () => this.update(),
      onComplete: () => setTimeout(() => {
        if (fromSouth) {
          this.goNorth();
        } else {
          this.goSouth();
        }
      }, 2000)
    });
  }

  goSouth() {
    this.scene.add.tween({
      targets: [this],
      isoY: 12000,
      duration: 10000 / TRAIN_DURATION_FACTOR,
      ease: Phaser.Math.Easing.Quadratic.In,
      onUpdate: () => this.update(),
      onComplete: () => setTimeout(() => {
        this.goToStation(true);
      }, 5000)
    });
  }


  goNorth() {
    this.scene.add.tween({
      targets: [this],
      isoY: -7500,
      duration: 10000 / TRAIN_DURATION_FACTOR,
      ease: Phaser.Math.Easing.Quadratic.In,
      onUpdate: () => this.update(),
      onComplete: () => setTimeout(() => {
        this.goToStation();
      }, 5000)
    });
  }
}
