import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from './actions';
import { WebSocketService } from '../../core/providers/websocket.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class SocketEffects {

  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
  ) {
  }

  @Effect({dispatch: false})
  socketConnect = this.actions$
    .pipe(
      ofType(ActionTypes.SOCKET_CONNECT),
      tap(() => {
        this.webSocketService.connect();
      })
    );

  @Effect({dispatch: false})
  socketDisconnect = this.actions$
    .pipe(
      ofType(ActionTypes.SOCKET_DISCONNECT),
      tap(() => {
        this.webSocketService.disconnect();
      })
    );
}
