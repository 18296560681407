import { Component, OnInit } from '@angular/core';
import { UtilityActions } from '../../../../../store/utility';
import { AbstractTempTokenExchangeComponent } from '../../../abstract/abstract-temp-token-exchange.component';

@Component({
  selector: 'app-temp-token-exchange',
  template: '',
})
export class TempTokenExchangeComponent extends AbstractTempTokenExchangeComponent implements OnInit {
  ngOnInit() {
    this.store.dispatch(new UtilityActions.RemoveToken());
    this.subscribeRoutingParams();
  }
}
