import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HudAbstractModule } from '../../abstract/core/hud-abstract.module';
import { HudResourcesComponent } from '../../basic/components/hud-resources/hud-resources.component';
import { HudComponent } from '../../basic/components/hud/hud.component';
import { HudOffsetTimeComponent } from '../../basic/components/hud-offset-time/hud-offset-time.component';
import { MatButtonModule } from '@angular/material/button';
import { HudParametersComponent } from '../../basic/components/hud-parameters/hud-parameters.component';
import { HudMenuComponent } from '../../basic/components/hud-menu/hud-menu.component';
import { HudPlayerComponent } from '../../basic/components/hud-player/hud-player.component';
import { PlayerDetailsComponent } from '../../basic/dialogs/player-details/player-details.component';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { UserSettingsComponent } from '../../basic/dialogs/user-settings/user-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildingsModule } from '../../../buildings/module/core/buildings.module';
import { CUSTOM_DECLARATIONS } from '../../const/custom/components.const';
import { CUSTOM_IMPORTS } from '../../const/custom/imports.const';
import { CUSTOM_PROVIDERS } from '../../const/custom/providers.const';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    HudAbstractModule,
    SharedUiMobileModule,
    ReactiveFormsModule,
    BuildingsModule,
    FormsModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    HudResourcesComponent,
    HudComponent,
    HudOffsetTimeComponent,
    HudMenuComponent,
    HudParametersComponent,
    HudPlayerComponent,
    PlayerDetailsComponent,
    UserSettingsComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  exports: [
    HudComponent,
  ],
  providers: [
    ...CUSTOM_PROVIDERS
  ]
})
export class HudModule {
}
