export const MOCK_BUILDING_DEMOLISH_KEY = 'mock-building-demolish-key';

export const MOCK_BUILDING_DEMOLISH = {
  api: {
    currency_percentage: 0.8,
    product_percentage: 0.8,
    currencies: [{currency_id: 1, amount: 2432}, {currency_id: 2, amount: 344}],
    base_currencies: [{currency_id: 1, amount: 3040}, {currency_id: 2, amount: 430}],
    products: [{product_id: 1, amount: 2}, {product_id: 3, amount: 2}, {product_id: 4, amount: 4}, {
      product_id: 9,
      amount: 1
    }, {product_id: 11, amount: 1}, {product_id: 14, amount: 1}, {product_id: 15, amount: 1}, {product_id: 16, amount: 1}, {
      product_id: 22,
      amount: 2
    }],
    base_products: [{product_id: 1, amount: 2}, {product_id: 3, amount: 2}, {product_id: 4, amount: 5}, {
      product_id: 9,
      amount: 1
    }, {product_id: 11, amount: 1}, {product_id: 14, amount: 1}, {product_id: 15, amount: 1}, {product_id: 16, amount: 1}, {
      product_id: 22,
      amount: 2
    }]
  },
  ref: {playerTileId: 55161}
};
