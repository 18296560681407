<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="groups.items.length">

    <div class="top-info">
      <p class="title">Główne wyzwania</p>
    </div>

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="sliderActiveIndex <= 0"
      [hideNext]="sliderActiveIndex >= groups.pages.length - 1"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>

    <swiper #slider [(index)]="sliderActiveIndex">
      <div class="collections" *ngFor="let page of groups.pages;">
        <div class="ow-dialog window-b primary loading-opacity"
             *ngFor="let group of page; let index = index"
             (click)="openMissionGroupDetails(group, index)"
        >
          <div
            class="icon"
            [style.background-image]="'url(' + (group.icon + '.png' | asset:'missions/big' ) + ')'"
          ></div>
          <div class="name">{{group.name}}</div>

          <div class="bottom">
            <div class="score-box">
              <img [src]="'checklist_small.png' | asset: 'missions'"/>
              <div class="score-container">
                <div class="score">{{ group.completed_missions }}/{{ group.total_missions }}</div>
              </div>
            </div>


            <div class="progressbar-container">
              <div class="box">
                <div class="border"></div>
                <ng-container *ngIf="group.completed_missions">
                  <!-- FILL -->
                  <div
                    class="fill"
                    [style.width.px]="group.completed_missions / group.total_missions * 100 * 0.5"
                  >
                  </div>
                </ng-container>
              </div>

              <img
                *ngIf="group.is_reward_collected"
                [src]="'icon-check.png' | asset: 'ui'"
                class="check"
              />

              <!-- BUTTON -->
              <div class="progress-icon">
                <img [src]="'chest_small.png' | asset: 'missions'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper>

    <!-- SLIDER DOTS -->
    <m-ui-slider-dots [sliderRef]="slider" [stroke]="true"></m-ui-slider-dots>

    <div class="bottom-info">
      <div class="icon"><i class="fas fa-exclamation"></i></div>
      Zrealizuj serie misji ukrytych w każdym<br/>
      wyzwaniu, aby odebrać skrzynie z nagrodami!
    </div>
  </ng-container>
</div>

