import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionsAbstractModule } from '../../abstract/core/missions-abstract.module';
import { MissionsComponent } from '../../base/core/dialogs/missions/missions.component';
import { MissionDetailsComponent } from '../../base/core/dialogs/mission-details/mission-details.component';
import { MissionTransferComponent } from '../../base/core/dialogs/mission-transfer/mission-transfer.component';
import { VirtualKeyboardMobileModule } from '../../../virtual-keyboard/mobile/virtual-keyboard-mobile.module';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CUSTOM_PROVIDERS } from '../../consts/custom/providers.const';
import { CUSTOM_DECLARATIONS } from '../../consts/custom/components.const';
import { MissionService } from '../../services/core/mission.service';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { MissionGroupListComponent } from '../../base/core/dialogs/mission-group-list/mission-group-list.component';
import { MissionGroupDetailsComponent } from '../../base/core/dialogs/mission-group-details/mission-group-details.component';

@NgModule({
    imports: [
        CommonModule,
        MissionsAbstractModule,
        VirtualKeyboardMobileModule,
        SharedUiMobileModule,
        SwiperModule,
        ...CUSTOM_IMPORTS,
    ],
    declarations: [
        MissionsComponent,
        MissionDetailsComponent,
        MissionTransferComponent,
        MissionGroupListComponent,
        MissionGroupDetailsComponent,
        ...CUSTOM_DECLARATIONS,
    ],
    providers: [
        ...CUSTOM_PROVIDERS,
    ]
})
export class MissionModule {
  constructor(
    private missionService: MissionService,
  ) {
  }
}
