import { Directive, Injectable, ViewChild } from '@angular/core';
import { AbstractMessageDetailsBaseComponent } from './abstract-message-details-base.component';
import { BuildingsService } from '../../../../services/buildings.service';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { generateEachPages } from '../../../../game-gui/utilities/slider.utility';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsLevelUpComponent extends AbstractMessageDetailsBaseComponent {
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  // END INJECTIONS

  loading: boolean;
  @ViewChild('slider') slider: SwiperComponent;
  sliderUnlockedBuildingsActiveIndex;
  sliderUnlockedProductsActiveIndex;

  unlockedBuildings = {
    config: {
      itemPerPage: 3,
    },
    pages: [],
    items: []
  };

  unlockedProducts = {
    config: {
      itemPerPage: 4,
    },
    pages: [],
    items: []
  };

  getUnlocks() {
    this.loading = true;

    forkJoin([
      this.buildingsService.getUnlockedBuildingsByLevel(this.message.parameters.level),
      this.productPlayerService.unlockedProductsAtLevel(this.message.parameters.level)
    ])
      .pipe(
        catchError(() => {
          return of(null);
        }),
      )
      .subscribe(([buildings, products]) => {
        this.unlockedBuildings.items = buildings;
        this.unlockedBuildings = generateEachPages(this.unlockedBuildings);

        this.unlockedProducts.items = products;
        this.unlockedProducts = generateEachPages(this.unlockedProducts);

        this.loading = false;
      });
  }

  prevSlide(slider: SwiperComponent) {
    slider.directiveRef.prevSlide();
  }

  nextSlide(slider: SwiperComponent) {
    slider.directiveRef.nextSlide();
  }
}
