import { Component, OnInit } from '@angular/core';
import { AbstractBuildingRowProductionComponent } from '../../../../abstract/core/abstract-building-row-production.component';

@Component({
  selector: 'm-building-row-production',
  templateUrl: './building-row-production.component.html',

})
export class MBuildingRowProductionComponent extends AbstractBuildingRowProductionComponent implements OnInit {
  ngOnInit() {
    this.setRequiredStatusCount();
    this.checkEnd();
  }
}
