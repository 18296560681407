import { Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '../../../core/providers/assets.service';

@Pipe({
  name: 'asset',
})
export class AssetPipe implements PipeTransform {
  constructor(
    private assetsService: AssetsService,
  ) {
  }

  transform(assetName: string, assetPath: string) {
    return this.assetsService.getAssetPath(`${assetPath}/${assetName}`);
  }
}
