import { Component, OnInit } from "@angular/core";
import { AbstractRankDetailsGameComponent } from "../../../../abstract/core/abstract-rank-game-details.component";
import { RANK_CONFIG } from "./config/rank.config";

@Component({
  selector: "app-ranking-details-game",
  templateUrl: "./ranking-details-game.component.html",
})
export class RankingDetailsGame extends AbstractRankDetailsGameComponent implements OnInit {
  RANK_CONFIG = RANK_CONFIG.TROPHY;
  getGameRanksResults() {
    this.apiCoreGameRanksService
      .getGameRanksResults({
        rank_edition_id: this.rank.rank_edition_id,
      })
      .subscribe(resp => {
        this.rankResults = resp;
        this.afterGetGameRankRequest();
      });
  }
}
