<button
  *ngIf="counterValue === null"
  class="base primary button-start loading-opacity"
  (click)="postStartTimer()"
>
  <span>START</span>
  <loading></loading>
</button>

<button
  *ngIf="counterValue >= 1"
  class="base primary button-counter"
>
  {{ counterValue }}
</button>
