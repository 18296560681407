import { STORAGE_LOCATION_NO_SUPPORTED } from '../../consts/core/storage/location-no-supported.const';

export class StorageData {
  storageDefinitions;

  constructor(storageDefinitions) {
    this.storageDefinitions = storageDefinitions;
  }

  set(key, value, parameters) {
    return this.method('set', key, value, parameters);
  }

  get(key) {
    return this.method('get', key);
  }

  remove(key) {
    return this.method('remove', key);
  }

  clear() {
    return this.method('clear');
  }

  method(method, ...args) {
    for (let storageDefinition of this.storageDefinitions) {
      const result = storageDefinition[method].apply(storageDefinition, args);

      if (result !== STORAGE_LOCATION_NO_SUPPORTED) {
        return result;
      }
    }
  }
}
