import { Component } from '@angular/core';
import { AbstractInfoMessageScreenComponent } from '../../../abstract/abstract-info-message-screen.component';

@Component({
  selector: 'app-m-info-message-screen',
  templateUrl: './info-message-screen.component.html',

})
export class MInfoMessageScreenComponent extends AbstractInfoMessageScreenComponent {

}
