import { Component, OnInit } from '@angular/core';
import { AbstractGameDateComponent } from '../../../abstract/abstract-game-date.component';

@Component({
  selector: 'm-ui-game-date',
  templateUrl: './ui-game-date.component.html',

})
export class MUiGameDateComponent extends AbstractGameDateComponent implements OnInit {

  ngOnInit() {
  }

}
