import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsHiddenRewardComponent } from '../../../../abstract/core/abstract-message-details-hidden-reward.component';

@Component({
  selector: 'app-message-details-hidden-reward',
  templateUrl: './message-details-hidden-reward.component.html',

})
export class MessageDetailsHiddenRewardComponent extends AbstractMessageDetailsHiddenRewardComponent implements OnInit {
  ngOnInit() {
    this.getProducts();
    this.setMessageProperty();
    this.afterInit();
  }
}
