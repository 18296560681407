import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractMessagesComponent } from '../../../../abstract/core/abstract-messages.component';
import { forkJoin } from 'rxjs';
import { MessageFilterValue } from '../../../../interfaces/core/message-filter-value.interface';
import { prepareFiltersValues } from '../../../../helpers/core/message.helper';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { MessageFilter } from '../../../../interfaces/core/message-filter.interface';
import { MessageService } from '../../../../services/core/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',

})
export class MessagesComponent extends AbstractMessagesComponent implements OnInit {
  @OwInject(MessageService) messagesService: MessageService;
  @ViewChild('slider', {static: true}) slider: SwiperComponent;

  ngOnInit() {
    this.dispatchClearNewMessagesToRead();
    this.getMessages();

    forkJoin(
      this.apiMessageService.getMessagesFilters({filter: 'categories'}),
      this.apiMessageService.getMessagesFilters({filter: 'sender-groups'}),
    )
      .subscribe(([categoryFilterValues, senderFilterValues]: [MessageFilterValue[], MessageFilterValue[]]) => {
        this.filterCategory.values = prepareFiltersValues(categoryFilterValues, 'category_name');
        this.filterSender.values = prepareFiltersValues(senderFilterValues, 'sender_name');
      });
  }

  config = {
    slidesPerView: 'auto',
    centeredSlides: true,
    freeMode: true,
  };

  slideTo(slide) {
    this.slider.directiveRef.setIndex(slide);
  }

  setFilterValue(filter: MessageFilter, value: MessageFilterValue) {
    this.setFilterValueCoreLogic(filter, value);
    if (this.slider && this.filterCategory.values.length) {
      this.slideTo(this.filterCategory.values.indexOf(value));
    }
  }

  toggleTrash() {
    this.toggleTrashCoreLogic();
    if (this.slider && this.filterCategory.values.length) {
      this.slideTo(this.filterCategory.values.length);
    }
  }
}
