import * as moment from 'moment';
import { DictionaryService } from '../providers/dictionary.service';
import { SynchronizeTimeService } from '../providers/synchronize-time.service';
import { InjectorVariable } from './injector-instance.helper';

export class OwDate implements OwDateI {
  year?: number;
  month?: number;
  day?: number;
  quarter?: number;
  half?: number;
  month_text_normal?: string;
  month_text_roman?: string;
  quarter_text_normal?: string;
  half_text_normal?: string;
  momentDate: moment.Moment;

  dictionaryService: DictionaryService;
  synchronizeTimeService: SynchronizeTimeService;

  constructor(input: OwDateI = {}) {
    this.dictionaryService = InjectorVariable.instance.get<DictionaryService>(DictionaryService);
    this.synchronizeTimeService = InjectorVariable.instance.get<SynchronizeTimeService>(SynchronizeTimeService);

    this.set(input);
  }

  parse() {
    this.year = this.momentDate.get('year');
    this.month = this.momentDate.get('month') + 1;
    this.day = this.momentDate.get('date');
    this.quarter = this.momentDate.quarter();
    this.half = this.momentDate.quarter() < 3 ? 1 : 2;

    this.setMonthText();
    this.setQuarterText();
    this.setHalfText();
  }

  set(input: { year?, month?, day? } = {}) {
    this.momentDate = moment(this.synchronizeTimeService.getActualLocalTime());

    if (input) {
      let {year, month, day} = input;

      this.momentDate.set({
        year,
        month: month ? (month - 1) : month, // 0 to 11
        date: day,
      });
    }

    this.parse();
  }

  setQuarter(input: { year?, quarter? } = {}) {
    this.momentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf('quarter');

    if (input) {
      let {year, quarter} = input;

      this.momentDate.set({
        year,
        quarter,
      }).startOf('quarter');
    }

    this.parse();
  }

  setHalf(input: { year?, half? } = {}) {
    if (input) {
      let {year, half} = input;

      let month = half == 2 ? 7 : 1;
      this.set({year, month});
    }

    this.parse();
  }

  add(input: OwDateI) {
    let {year, month, day, quarter} = input;

    this.momentDate.add({year, month, day, quarter});
    this.parse();
  }

  subtract(input: OwDateI) {
    let {year, month, day, quarter} = input;

    this.momentDate.subtract({year, month, day, quarter});
    this.parse();
  }

  setMonthText() {
    this.month_text_roman = this.dictionaryService.getMonth(this.month);
    this.month_text_normal = this.dictionaryService.getMonthText(this.month);
  }

  setQuarterText() {
    this.quarter_text_normal = this.dictionaryService.getQuarterName(this.quarter);
  }

  setHalfText() {
    this.half_text_normal = this.dictionaryService.getHalfName(this.half);
  }

  calcMonth(): number {
    return this.momentDate.month() + (this.momentDate.year() * 12);
  }

  calcQuarter(): number {
    return this.momentDate.quarter() + (this.momentDate.year() * 12);
  }

  calcHalf(): number {
    let half = this.momentDate.quarter() < 3 ? 1 : 2;
    return half + (this.momentDate.year() * 12);
  }
}

export interface OwDateI {
  year?: number;
  month?: number;
  day?: number;
  quarter?: number;
}
