<button
  (click)="logout()"
  class="base primary logout-button loading-opacity"
>
  Wyloguj
</button>

<div class="support">
  <a href="mailto:biuro.bhp@budimex.pl">Support: biuro.bhp@budimex.pl</a>
</div>

<div class="user-profile">
  <div class="avatar-background">
    <div class="avatar-inner-background">
      <img
        class="avatar"
        [src]="player['user_avatar_url'] || (player['user_avatar_number'] + '.png' | asset:'avatar')"
      />
    </div>
  </div>

  <div class="rank-name">
    {{ player['player_rank_details'].name }}
  </div>
</div>

<div
  class="container loading-opacity"
  [class.summary-details]="currentView === VIEW.SUMMARY"
>
  <loading></loading>

  <ng-container
    *ngIf="currentView === VIEW.DEFAULT"
    [ngTemplateOutlet]="defaultTpl"
  ></ng-container>

  <ng-container
    *ngIf="currentView === VIEW.SUMMARY"
    [ngTemplateOutlet]="summaryTpl"
  ></ng-container>
    
</div>

<ng-template #defaultTpl>
  <button
    class="base primary login"
    (click)="openRanks()"
  >
    Zobacz ranking
  </button>

  <div class="description text-center">
      Sprawdź swoją pozycję w rankingu i porównaj wyniki innych graczy. Pamiętaj, że ranking cały czas się aktualizuje i wyniki będą się w nim zmieniać aż do zakończenia zabawy.
  </div>

  <button
    class="base primary login"
    (click)="getSummaryDetails()"
  >
    Zobacz podsumowanie
  </button>

  <div class="description text-center">
      Przeanalizowaliśmy Twoje odpowiedzi i na tej podstawie wyciągnęliśmy kilka wniosków... przekonaj się, jakim typem pracownika jesteś.
  </div>

<!--  <div-->
<!--    *ngIf="player['can_start_new_game_at']"-->
<!--    class="timer text-center"-->
<!--  >-->
<!--    {{ nextGameTimerValue | owDuration2:'HH:mm:ss' }}-->
<!--  </div>-->

<!--  <div-->
<!--    class="display-grid"-->
<!--    matTooltip="Limit podejść wyczerpany"-->
<!--    matTooltipPosition="above"-->
<!--    [matTooltipDisabled]="player['can_start_new_game_at']"-->
<!--  >-->
<!--    REPEAT BUTTON-->
<!--    <button-->
<!--      class="base primary login"-->
<!--      [disabled]="(player['can_start_new_game_at'] === null || nextGameTimerValue > 0)"-->
<!--      (click)="postNextRound()"-->
<!--    >-->
<!--      Zagraj ponownie-->
<!--    </button>-->
<!--  </div>-->

</ng-template>

<ng-template #summaryTpl>
  <div class="summary-details relative">
    <button
      class="exit"
      (click)="changeView(VIEW.DEFAULT)"
    >
      <i class="fas fa-times"></i>
    </button>

    <div class="description text-center text-bold">
      Dziękujemy, że wziąłeś udział w tygodniu bezpieczeństwa BUDIMEX!<br/>
      W oparciu o to, jak grałeś stworzyliśmy opis profilu pracownika.<br/>
      Zapoznaj się z nim i zobacz, co poszło Ci dobrze, a co wymaga jeszcze Twojej uwagi.
    </div>

    <div
      *ngFor="let text of summary"
      class="description text-center inner-html-description"
      [innerHTML]="text | safe:'html'"
    ></div>
  </div>
</ng-template>
