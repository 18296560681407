import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QuestionInfo, QuestionInfoAnswer, QuestionInfoSliderImages } from "../../../interfaces/custom/question.info";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { QuizSliderComponent } from "../quiz-slider/quiz-slider.component";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { timer } from "rxjs";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { tap } from "rxjs/operators";
import { pinBonus } from "../../../mock/consts/mock-pin.mock";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { deepCopy } from "../../../../../../../helpers/common.helpers";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../../../store/state";
import { PlayerSelectors } from "../../../../../../../store/player";

@Component({
  selector: "app-quiz-bonus",
  templateUrl: "quiz-bonus.component.html",
})
export class QuizBonusComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QuizBonusComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    questionInfo: QuestionInfo;
  };
  questionInfo = null;
  currentSliderIndex = null;
  isLoadingAnswer: boolean;
  currentTime = null;

  subs = {
    timer: null,
    player: null,
  };

  getQuestionInfo() {
    this.questionInfo = deepCopy(this.data.questionInfo);
  }

  ngOnInit() {
    this.matDialogRef.disableClose = true;
    this.getQuestionInfo();
    this.checkPreSlider();
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      if (player?.quiz_game?.finished_at) {
        this.close();
      }
    });
  }

  unsubscribePlayer() {
    this.subs.player.unsubscribe();
  }

  startQuiz() {
    this.apiService
      .post(`/quiz-game/question-player-tile/${this.data.questionInfo.question_player_tile_id}/start`)
      .pipe(
        tap(() => (this.currentTime = this.data.questionInfo.time)),
        tap(() => this.startTimer())
      )
      .subscribe(
        () => {},
        error => {
          console.error(error);
          this.close();
        }
      );
  }

  checkPreSlider() {
    if (this.data.questionInfo.pre_slider_images?.length) {
      this.openPreSlider();
    } else {
      this.setSlider();
      this.startQuiz();
    }
  }

  setSlider() {
    if (!this.data.questionInfo.youtube_id && this.data.questionInfo.slider?.length) {
      this.setSlide(0);
    }
  }

  setSlide(index: number) {
    this.currentSliderIndex = index;
  }

  openPreSlider() {
    if (this.data.questionInfo.pre_slider_images?.length) {
      this.dialogService.open(
        QuizSliderComponent,
        {
          data: {
            questionInfoSliderImages: this.data.questionInfo.pre_slider_images,
          },
        },
        () => {
          this.startQuiz();
        }
      );
    }
  }

  startTimer() {
    this.subs.timer = timer(1000, 1000).subscribe(() => {
      this.currentTime--;

      if (this.currentTime <= 0) {
        this.unsubscribeTimer();
        this.close();
      }
    });
  }

  answerToggle(answer: QuestionInfoAnswer) {
    answer.is_checked = !answer.is_checked;
  }

  submitAnswer() {
    this.isLoadingAnswer = true;
    const allAnswers = [];
    this.questionInfo.answers.forEach(answer => {
      if (answer.is_checked) {
        allAnswers.push(answer.answer_id);
      }
    });

    this.apiService
      .post(`/quiz-game/question-player-tile/${this.data.questionInfo.question_player_tile_id}/answer`, {
        body: {
          answer_ids: allAnswers,
        },
      })
      .subscribe(
        () => {
          this.isLoadingAnswer = false;
          this.unsubscribeTimer();
          this.close();
        },
        () => {
          this.isLoadingAnswer = false;
        }
      );
  }

  unsubscribeTimer() {
    unsubscribeObject(this.subs);
  }

  close() {
    this.matDialogRef.close();
  }

  ngOnDestroy() {
    this.unsubscribeTimer();
    this.unsubscribePlayer();
  }
}
