import 'phaser';

import { filter, mergeMap, take } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { MyGame } from '../../classes/core/MyGame';
import { selectGame } from '../../../../../store/game/selectors';
import { GameState } from '../../../../../store/game';
import { MyScene } from '../../classes/core/MyScene';
import { ResetBoard } from '../../../../../store/game/actions';
import { of } from 'rxjs';
import { BASIC_BOOT_SCENE, BASIC_MAIN_SCENE, BASIC_PRELOAD_SCENE } from '../basic.constants';
import { CustomBasicPreloadScene } from '../custom/CustomBasic.preload.scene';
import { CustomBasicMainScene } from '../custom/CustomBasic.main.scene';
import { BASIC_SCENE_CONFIG } from '../basic-scene.config';

export class BasicBootScene extends MyScene {
  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : {key: BASIC_BOOT_SCENE};
    super(sceneClassConfig);
  }

  init(data?: any) {
    this.passedData = data;
  }

  provideAllScenes() {
    if (!this.scene.get(BASIC_PRELOAD_SCENE)) {
      this.scene.add(BASIC_PRELOAD_SCENE, CustomBasicPreloadScene, false);
      this.scene.add(BASIC_MAIN_SCENE, CustomBasicMainScene, false);
    }
  }

  provideSceneConfig() {
    if (this.passedData && this.passedData.sceneConfigKey) {
      return BASIC_SCENE_CONFIG[this.passedData.sceneConfigKey];
    }
    return BASIC_SCENE_CONFIG['default'];
  }

  preload(params): void {
    this.sceneConfig = this.provideSceneConfig();
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);

    this.gameService.store.dispatch(new ResetBoard());

    if (this.passedData && this.passedData.playerIslandId) {
      this.gameService.prepareGameData(this.gameService.playerService.player.id, this.passedData.playerIslandId);
    } else {
      this.gameService.boardService.getWorld(this.gameService.playerService.getActivePlayerId())
        .pipe(
          mergeMap(worldData => {
              let worldIsland;
              worldData.find(regionData => worldIsland = regionData.islands.find(island => island.group_id === this.sceneConfig.islandGroupIdForBoard));
              if (Boolean(worldIsland)) {
                this.gameService.prepareGameData(this.gameService.playerService.player.id, worldIsland.player_island_id);
              }
              return of(worldIsland);
            }
          ))
        .subscribe(console.log);
    }

    this.provideAllScenes();
  }

  create(): void {
    this.gameService.store
      .pipe(
        select(selectGame),
        filter(game => game.boardState.loaded),
        take(1)
      )
      .subscribe((game: GameState) => {
        const assetsToPreload = this.gameService.buildingsService.getBuildingsAssetsNamesFromBoard(game.boardState.board);
        this.scene.start(BASIC_PRELOAD_SCENE, {assetsToPreload, passedData: this.passedData});
      });
  }
}
