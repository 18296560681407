import { StorageLocationBase } from './location-base.class';

export class StorageLocationVariableJs extends StorageLocationBase {
  init() {
    window['StorageVariableJs'] = {};
    this.storage = window['StorageVariableJs'];
  }

  isSupported() {
    return true;
  }

  get(key) {
    return this.storage[key];
  }

  set(key, value, parameters = {}) {
    return this.storage[key] = value;
  }

  remove(key) {
    return delete this.storage[key];
  }

  clear() {
    return this.storage = {};
  }
}
