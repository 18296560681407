import { Component } from '@angular/core';
import { AbstractHudMenuComponent } from '../../../abstract/core/abstract-hud-menu.component';

@Component({
  selector: 'app-hud-menu',
  templateUrl: './hud-menu.component.html',

})
export class HudMenuComponent extends AbstractHudMenuComponent {
}
