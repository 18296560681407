import { GameObjects } from 'phaser';
import { MyScene } from '../../core/MyScene';
import { TileMenu } from '../custom/TileMenu';
import { PlayerBuilding } from '../../../interfaces/player-building.config';
import { GameService } from '../../../../services/game.service';
import { isPointerupOnCanvas } from '../../../utils/game.helper';
import { translate } from '../../../../../../core/helpers/translate.helper';
import { TILE_MENU_ATLAS } from '../../../scenes-main/main.constants';
import { TileMenuButtonConfig } from '../../../interfaces/tile-menu-config.interface';

export class TileMenuButtonCore extends GameObjects.Sprite {
  productTextObject: {
    text: Phaser.GameObjects.Text,
    icon: Phaser.GameObjects.Text
  };
  isActive: boolean;
  loadDelay: number;
  hasAutoProduction: boolean;
  hasProduction: boolean;
  activeCheckFn: Function;
  myScene: MyScene;
  gameService: GameService;
  eventName: string;
  tileMenu: TileMenu;
  playerTileId: number;
  basicFrame: string;
  hoverFrame: string;
  activeFrame: string;
  building: PlayerBuilding;
  additionalBoardData: any[];

  constructor(tileMenu: TileMenu, buttonConfig: TileMenuButtonConfig) {
    super(tileMenu.myScene, buttonConfig.x, buttonConfig.y, TILE_MENU_ATLAS, buttonConfig.frame);
    this.myScene = tileMenu.myScene;
    this.activeCheckFn = buttonConfig.activeCheckFn.bind(this);
    this.gameService = this.myScene.gameService;
    this.eventName = buttonConfig.eventName;
    this.tileMenu = tileMenu;
    this.playerTileId = this.tileMenu.targetTile.tileData.player_tile_id;
    this.name = buttonConfig.name;
    this.tileMenu.add(this);
    this.basicFrame = buttonConfig.frame;
    this.hoverFrame = buttonConfig.hoverFrame;
    this.activeFrame = buttonConfig.activeFrame;
    this.building = tileMenu.building;
    this.additionalBoardData = buttonConfig.additionalBoardData;
    this.loadDelay = 0;
    this.alpha = 0;
    this.setInteractive({cursor: 'pointer'});
    this.setButtonStatus();
    this.animateButton();
    this.scale = tileMenu.config.scaleFactor;
  }

  /**
   * Animates buttons with smooth alpha transition.
   * Parameters such as duration, delay, repeat and so on can be changed in the function.
   * When the animation is over, pointer events will be added to the button.
   */
  animateButton() {
    this.myScene.tweens.add({
      targets: [this, this.productTextObject],
      // x: {from: 0, to: this.x},
      // y: {from: 0, to: this.y},
      alpha: {
        from: 0,
        to: this.isActive ? 1 : 0,
      },
      ease: Phaser.Math.Easing.Expo.In,
      duration: 150,
      onComplete: () => {
        this.addPointerEvents();
      },
      delay: this.loadDelay,
      repeat: 0,
    });
  }

  addPointerEvents() {

    /**
     * Changes the tile menu text, by changing the main text, and hiding the additional text.
     * Changes the current frame to hover state.
     */
    this.on('pointerover', () => {
      if (!this.scene.input.isOver) {
        return true;
      }
      this.tileMenu.changeMenuTextAndHideSecondLine(translate(this.name));
      this.setFrame(this.hoverFrame);
    });

    /**
     * Changes the tile menu text, by changing the main text to initial state, and showing the additional text.
     * Changes the current frame to initial state.
     */
    this.on('pointerout', () => {
      if (!this.scene.input.isOver) {
        return true;
      }
      this.tileMenu.setBasicMenuText();
      this.setFrame(this.basicFrame);
    });

    /**
     * Changes the current frame to active state.
     */
    this.on('pointerdown', (pointer) => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.setFrame(this.activeFrame);
    });

    /**
     * Emits the event based on the current button configuration.
     * Destroys currently opened tile menu.
     */
    this.on('pointerup', (pointer) => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.gameService.emitGameEvent({
        name: this.eventName,
        value: this.playerTileId,
        data: this.tileMenu.targetTile.tileData,
      });
      this.tileMenu.targetTile.closeTileMenu();
    });
  }

  /**
   * Set the isActive property of the button executing activeCheckFn from passed button configuration.
   */
  setButtonStatus() {
    this.isActive = this.activeCheckFn();

    if (!this.isActive) {
      this.removeInteractive();
    }
  }

  /**
   * Method to override when custom logic needed to be executed after button is created
   */
  afterCreate() {}
}
