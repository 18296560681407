import { Component } from '@angular/core';
import { AbstractHudResourcesComponent } from '../../../abstract/core/abstract-hud-resources.component';

@Component({
  selector: 'app-hud-resources',
  templateUrl: './hud-resources.component.html',

})
export class HudResourcesComponent extends AbstractHudResourcesComponent {
}
