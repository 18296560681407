import { Component } from '@angular/core';
import { AbstractWarehouseComponent } from '../../../../abstract/core/abstract-warehouse.component';

@Component({
  selector: 'app-core-warehouse',
  templateUrl: './warehouse.component.html',

})
export class CoreWarehouseComponent extends AbstractWarehouseComponent {
}
