import { MyScene } from '../classes/core/MyScene';
import { CAR_ISO_Y, TRAIN_ISO_X } from './config';
import { Train } from './train.class';
import { Vehicle } from './vehicle.class';


export class Traffic {
  car: Vehicle;
  train: Train;
  scene: MyScene;

  constructor(scene: MyScene) {
    this.scene = scene;
    this.car = new Vehicle({scene, x: 23000, y: CAR_ISO_Y, key: 'map-atlas', frame: 'car_right.png'});
    this.car.start();
    this.car.setOrigin(0.5);
    this.car.trafficRef = this;
    this.car.setDepth(1);

    this.train = new Train({scene, x: TRAIN_ISO_X, y: -7500, key: 'map-atlas', frame: 'train.png'});
    this.train.setOrigin(0.5);
    this.train.start();
    this.train.setDepth(2);
  }
}
