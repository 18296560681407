import { Component } from '@angular/core';
import { AbstractHudParametersComponent } from '../../../abstract/core/abstract-hud-parameters.component';

@Component({
  selector: 'app-hud-parameters',
  templateUrl: './hud-parameters.component.html',

})
export class HudParametersComponent extends AbstractHudParametersComponent {
}
