import { select } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { GameState } from '../../../../../store/game';
import { ResetBoard } from '../../../../../store/game/actions';
import { selectGame } from '../../../../../store/game/selectors';
import { MyGame } from '../../classes/core/MyGame';
import { BASIC_PRELOAD_SCENE } from '../basic.constants';
import { BasicBootScene } from '../core/Basic.boot.scene';

export class CustomBasicBootScene extends BasicBootScene {
  preload(params): void {
    this.sceneConfig = this.provideSceneConfig();
    this.gameService = (this.game as MyGame).gameService;
    this.gameService.guiService.isSplashShow.next(true);

    this.gameService.store.dispatch(new ResetBoard());

    if (this.passedData && this.passedData.playerIslandId) {
      this.gameService.prepareGameData(this.gameService.playerService.player.id, this.passedData.playerIslandId);
    } else {
      this.gameService.boardService.getWorld(this.gameService.playerService.getActivePlayerId())
        .pipe(
          mergeMap(worldData => {
              let worldIsland;
              worldData.find(regionData => worldIsland = regionData.islands.find(island => island.group_id === this.sceneConfig.islandGroupIdForBoard));
              if (Boolean(worldIsland)) {
                this.gameService.prepareGameData(this.gameService.playerService.player.id, worldIsland.player_island_id);
              } else if (!this.gameService.playerService.player.title_id.playable) {
                this.gameService.prepareGameData(this.gameService.playerService.player.id);
              }

              return of(worldIsland);
            }
          ))
        .subscribe(console.log);
    }

    this.provideAllScenes();
  }

  create() {
    this.gameService.store
      .pipe(
        select(selectGame),
        filter(game => game.boardState.loaded),
        take(1)
      )
      .subscribe((game: GameState) => {
        const assetsToPreload = this.gameService.assetsService.getAssetsByFilenamePart('buildings');
        this.scene.start(BASIC_PRELOAD_SCENE, {assetsToPreload, passedData: this.passedData});
      });
  }
}
