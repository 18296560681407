import { RRBoatConfig } from '../river-ride.interfaces';
import { RiverSegmentContainer } from './RiverSegment.class';
import { generateAnimationFramesList } from '../river-ride.helpers';
import { RiverRidePlayScene } from '../scenes/RiverRidePlay.scene';
import { RR_ATLAS_KEY } from '../river-ride.constants';

export class RRBoat extends Phaser.GameObjects.Sprite {
  speed = 0;
  canControl = true;
  playerRepositionTween: Phaser.Tweens.Tween;
  rrScene: RiverRidePlayScene;

  constructor(scene: RiverRidePlayScene, params: RRBoatConfig) {
    super(scene, params.x, params.y, params.key, params.frame);
    this.rrScene = scene as RiverRidePlayScene;

    scene.add.existing(this);
    this.setOrigin(0.5);

    // this.scene.anims.create({
    //   key: 'boat-anim',
    //   frames: generateAnimationFramesList(RR_ATLAS_KEY, `truck`, 11),
    //   delay: 0,
    //   frameRate: 10,
    //   duration: 5000,
    //   repeat: -1,              // set to (-1) to repeat forever
    // });
    // this.scene.anims.play('boat-anim', this);
    // const frontCollisionPoint = this.scene.add.image(this.x, this.y, 'n');
    // frontCollisionPoint.setTint(0xff0000);
  }

  increaseSpeedBy(acceleration: number) {
    this.speed += acceleration;
    this.speed = Phaser.Math.Clamp(this.speed, this.rrScene.sceneConfig.minSpeed, this.rrScene.sceneConfig.maxSpeed);
  }

  checkCollisions(segment: RiverSegmentContainer) {
    const cameraHeight = this.scene.cameras.main.height;
    const segmentHeight = segment.segmentImage.displayHeight;

    const isFrontCollision = this.checkCollisionAt(this.x, segmentHeight - (cameraHeight - this.y) - segment.y, segment);
    const isLeftCollision = this.checkCollisionAt(this.x - 10, segmentHeight - (cameraHeight - this.y) - segment.y, segment);
    const isRightCollision = this.checkCollisionAt(this.x + 10, segmentHeight - (cameraHeight - this.y) - segment.y, segment);

    if (isFrontCollision || isLeftCollision || isRightCollision) {
      if (this.rrScene.soundsEnabled) {
        this.rrScene.bankHit.play();
      }
      // this.canControl = false;
      this.setTint(0xff0000);
      this.scene.cameras.main.shake(500, new Phaser.Math.Vector2(0.005, 0.005));
    } else {
      this.setTint(0xffffff);
    }

    let targetX = 0;
    if (isLeftCollision || isRightCollision) {
      const isSafeOnTheLeft = !this.checkCollisionAt(this.x - 50, segmentHeight - (cameraHeight - this.y) - segment.y, segment);
      const isSafeOnTheRight = !this.checkCollisionAt(this.x + 50, segmentHeight - (cameraHeight - this.y) - segment.y, segment);
      targetX = this.x + (isSafeOnTheRight ? 50 : -50);

      // if (!isSafeOnTheRight && !isSafeOnTheRight) {
      //   targetX = this.scene.cameras.main.width / 2;
      //   console.error('NO SAFE PLACE');
      // }

      this.playerRepositionTween = this.scene.add.tween({
        targets: [this],
        x: targetX,
        duration: 200 / this.speed,
        onComplete: () => {
          // this.canControl = true;
        }
      });
      this.speed = this.rrScene.sceneConfig.minSpeed;
    }

    if (this.x < 50 || this.x > this.scene.cameras.main.width - 50) {
      this.x = this.scene.cameras.main.centerX;
    }
  }

  checkCollisionAt(x: number, y: number, segment: RiverSegmentContainer) {
    const color = this.scene.textures.getPixel(x, y, `${segment.segmentData.imagePath}-m`);
    return (color && color.red < 100);
  }
}
