import { Directive, Injectable } from '@angular/core';
import { MESSAGE_DETAILS_TYPE } from '../../consts/core/message-details-type.const';

@Directive()
@Injectable()
export abstract class AbstractMessageService {
  abstract mappedTypesToEventDialog: { [key: string]: string };

  handleMessageDetailsComponent(messageType: string) {
    return this.mappedTypesToEventDialog[messageType] || this.mappedTypesToEventDialog[MESSAGE_DETAILS_TYPE.NORMAL];
  }
}
