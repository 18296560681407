import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { MOCK_MESSAGE_DETAILS_LEVEL_UP, MOCK_MESSAGE_DETAILS_LEVEL_UP_PRODUCTS } from '../consts/mock-message-details-level-up.const';
import { EVENT_DIALOGS_NAMES_MESSAGE } from '../../consts/core/event-dialogs/event-names.const';
import { MOCK_MESSAGES, MOCK_MESSAGES_FILTER } from '../consts/mock-messages.const';
import { MOCK_MESSAGE_DETAILS_NORMAL } from '../consts/mock-message-details-normal.const';
import { MOCK_MESSAGE_DETAILS_COMICS } from '../consts/mock-message-details-comics.const';
import { MOCK_MESSAGE_DETAILS_HIDDEN_REWARD } from '../consts/mock-message-details-hidden-reward.const';
import { MOCK_BUILDING_DETAILS_KEY } from '../../../buildings/mock/consts/mock-building-details.const';

@Injectable({providedIn: 'root'})
export class MockMessageService {
  constructor(private mockDialogService: MockDialogListService) {
    this.mockDialogService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_LEVEL_UP: {
          this.mockDialogService.setDialogRef(MOCK_MESSAGE_DETAILS_LEVEL_UP.ref);
          this.mockDialogService.setMockApiData(
            `${MOCK_BUILDING_DETAILS_KEY}_${MOCK_MESSAGE_DETAILS_LEVEL_UP.ref.message.parameters.level}`,
            MOCK_MESSAGE_DETAILS_LEVEL_UP.api.buildings
          );
          this.mockDialogService.setMockApiData(
            MOCK_MESSAGE_DETAILS_LEVEL_UP_PRODUCTS,
            MOCK_MESSAGE_DETAILS_LEVEL_UP.api.products
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES: {
          this.mockDialogService.setDialogRef(MOCK_MESSAGES.ref);
          this.mockDialogService.setMockApiData(
            EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
            MOCK_MESSAGES.api.messages
          );
          this.mockDialogService.setMockApiData(
            `${MOCK_MESSAGES_FILTER}_categories`,
            MOCK_MESSAGES.api.categories
          );
          this.mockDialogService.setMockApiData(
            `${MOCK_MESSAGES_FILTER}_sender-groups`,
            MOCK_MESSAGES.api.senderGroups
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_NORMAL: {
          this.mockDialogService.setDialogRef(MOCK_MESSAGE_DETAILS_NORMAL.ref);
          break;
        }
        case EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS: {
          this.mockDialogService.setDialogRef(MOCK_MESSAGE_DETAILS_COMICS.ref);
          this.mockDialogService.setMockApiData(EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS, MOCK_MESSAGE_DETAILS_COMICS.api);
          break;
        }
        case EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD: {
          this.mockDialogService.setDialogRef(MOCK_MESSAGE_DETAILS_HIDDEN_REWARD.ref);
          this.mockDialogService.setMockApiData(EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD, MOCK_MESSAGE_DETAILS_HIDDEN_REWARD.api);
          break;
        }
      }
    });
  }
}
