<div class="debug-container">
    <div class="debug-item" [class.expanded]="expandMap.game">
        <div (click)="expandMap.game = !expandMap.game" class="debug-item-header">
            Ustawienia sceny (domyślne)
        </div>
        <div class="debug-item-content">
            <label>Nazwa pliku mapy Tiled (puste pole wyłącza mapę):
                <input class="short" [(ngModel)]="sceneConfig.default.tiledMapFileName">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Rzut prostokątny?: {{sceneConfig.default.isOrhto | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="sceneConfig.default.isOrhto">
                <button (click)="restartScene()">Restart</button>
            </label>
        </div>
    </div>

    <div class="debug-item" [class.expanded]="expandMap.tileMenu">
        <div (click)="expandMap.tileMenu = !expandMap.tileMenu" class="debug-item-header">
            Ustawienia TileMenu/Tooltip/Hover (nie wymagają restartu)
        </div>
        <div class="debug-item-content" *ngIf="game && game.currentScene && game.currentScene.sceneConfig">
            <label>MENU: Maksymalny rozmiar przy oddaleniu:
                <input class="short"
                       [(ngModel)]="game.currentScene.sceneConfig.tileMenuConfig.maxZoomScaleValue"></label>
            <label>TOOLTIP: Maksymalny rozmiar przy oddaleniu:
                <input class="short"
                       [(ngModel)]="game.currentScene.sceneConfig.tileTooltipConfig.maxZoomScaleValue"></label>
            <label>HOVER: Maksymalny rozmiar przy oddaleniu:
                <input class="short"
                       [(ngModel)]="game.currentScene.sceneConfig.tileHoverConfig.maxZoomScaleValue"></label>
        </div>
    </div>

    <div class="debug-item" [class.expanded]="expandMap.worldInfo">
        <div (click)="expandMap.worldInfo = !expandMap.worldInfo" class="debug-item-header">
            Regiony i wyspy
        </div>
        <div class="debug-item-content">
            <app-world-info></app-world-info>
        </div>
    </div>

    <div class="debug-item" [class.expanded]="expandMap.isoToScreen">
        <div (click)="showScreenToIsoConverter()" class="debug-item-header">
            Screen to iso
        </div>
        <div class="debug-item-content">
            <app-to-iso></app-to-iso>
        </div>
    </div>


    <div class="debug-item" [class.expanded]="expandMap.tiles">
        <div (click)="expandMap.tiles = !expandMap.tiles" class="debug-item-header">
            Kafle
        </div>
        <div class="debug-item-content">
            <button class="color-primary mat-raised-button" (click)="showInputArea()">Pokaż/ukryj pole klikalne</button>
            <button class="ml" class="color-primary mat-raised-button" (click)="showTileInfo()">
                Pokaż/ukryj informacje o kaflach
            </button>
        </div>
    </div>

    <div class="debug-item" [class.expanded]="expandMap.features">
        <div (click)="expandMap.features = !expandMap.features" class="debug-item-header">
            Funkcjonalności
        </div>
        <div class="debug-item-content">
            <label>Board włączony: {{gameFeaturesMap.board | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.board">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Graficzne tło sceny: {{gameFeaturesMap.sceneBgImages | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.sceneBgImages">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Mapa kafli z Tiled: {{gameFeaturesMap.tiledMap | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.tiledMap">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Interaktywność kafli: {{gameFeaturesMap.boardTileInput | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.boardTileInput">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Tooltipy: {{gameFeaturesMap.tooltip | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.tooltip">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Sterowanie kamerą: {{gameFeaturesMap.cameraControls | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.cameraControls">
                <button (click)="restartScene()">Restart</button>
            </label>

            <label>Wymuś CANVAS renderer: {{gameFeaturesMap.forceCanvas | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.forceCanvas">
                <button [routerLink]="['main/auth']">Reload</button>
            </label>

            <label>Wymuś 30fps (zamiast 60): {{gameFeaturesMap.forceLowerFramerate | onOff}}
                <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.forceLowerFramerate">
                <button [routerLink]="['main/auth']">Reload</button>
            </label>
        </div>
    </div>

    <div class="debug-item" [class.expanded]="expandMap.other">
        <div (click)="expandMap.other = !expandMap.other" class="debug-item-header">
            Inne (z datGUI)
        </div>
        <div class="debug-item-content">
            <div class="other"></div>
        </div>
    </div>
</div>

<button class="button-toggle" (click)="toggle()">
    <i class="fas fa-arrow-alt-{{isOpen ? 'right' : 'left'}}"></i>
</button>
