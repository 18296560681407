import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { FEATURE_FLAG } from '../../../../../core/config/core/feature-flags.config';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { Router } from '@angular/router';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../auth/consts/core/routing-auth-absolute.const';

@Directive()
@Injectable()
export abstract class AbstractChangePasswordButtonComponent extends AbstractInjectBaseComponent {
  @OwInject(Router) router: Router;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  FEATURE_FLAG = FEATURE_FLAG;

  click() {
    this.clicked.emit(true);
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.CHANGE_PASSWORD], {
      queryParams: {
        isShowBackButton: 1,
      }
    });
  }
}
