import { Component, OnDestroy, OnInit } from '@angular/core';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { AbstractBuildingAutoProductionComponent } from '../../../../abstract/core/abstract-building-auto-production.component';

@Component({
  selector: 'app-m-building-auto-production',
  templateUrl: './building-auto-production.component.html',

})
export class MBuildingAutoProductionComponent extends AbstractBuildingAutoProductionComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
