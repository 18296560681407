import { Action } from '@ngrx/store';
import { AuthState } from './auth';
import { GameState } from './game';
import { UserState } from './user';
import { PlayerState } from './player';
import { UtilityState } from './utility';
import { PrimaryState } from './primary';
import { SocketState } from './socket';

export enum ApplicationActionTypes {
  CLEAR_STATE = '[application] clear state',
  MOCK_STATE = '[application] mock state'
}

export class ClearState implements Action {
  readonly type = ApplicationActionTypes.CLEAR_STATE;

  constructor() {
  }
}

export class MockState implements Action {
  readonly type = ApplicationActionTypes.MOCK_STATE;

  constructor(public payload: {
    auth: AuthState;
    game: GameState;
    user: UserState;
    player: PlayerState;
    utility: UtilityState;
    primary: PrimaryState;
    socket: SocketState;
  }) {
  }
}

export type ApplicationActions =
  ClearState
  ;
