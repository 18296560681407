import { EVENT_DIALOGS_NAMES_BUILDINGS } from '../../consts/core/event-dialogs/event-names.const';

export const MOCK_BUILDINGS_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE,
    name: 'Buildings Special Upgrade',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PROBABILITY_UPGRADE,
    name: 'Buildings Probability Upgrade',
    available: false
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_INCREASE,
    name: 'Buildings Special Increase',
    available: false
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE,
    name: 'Buildings Upgrade',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD,
    name: 'Buildings Build',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION,
    name: 'Buildings Production',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST,
    name: 'Buildings List',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO,
    name: 'Buildings Info',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_AUTO_PRODUCTION,
    name: 'Buildings Auto Production',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_DEMOLISH,
    name: 'Buildings Demolish',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE,
    name: 'Buildings Move',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_TILE_BUY,
    name: 'Buildings Tile Buy',
    available: true
  },
];
