import { Component } from '@angular/core';
import { AbstractNavigationComponent } from '../../../abstract/abstract-navigation.component';

@Component({
  selector: 'm-ui-navigation',
  templateUrl: './ui-navigation.component.html',

})
export class MUiNavigationComponent extends AbstractNavigationComponent {
}
