import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { CUSTOM_EXPORTS } from '../../consts/custom/exports.const';
import { CUSTOM_DECLARATIONS } from '../../consts/custom/components.const';
import { CoreTransactionHistory } from '../../base/core/dialogs/transaction-history/transaction-history.component';

@NgModule({
    declarations: [
        CoreTransactionHistory,
        ...CUSTOM_DECLARATIONS,
    ],
    imports: [
        CommonModule,
        SharedModule,
        SharedUiMobileModule,
        ...CUSTOM_IMPORTS,
    ],
    exports: [
        ...CUSTOM_EXPORTS,
    ]
})
export class TransactionHistoryModule {
}
