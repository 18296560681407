<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <div class="back-bar">
    <button (click)="backToList()" class="square normal primary">
      <i class="fas fa-angle-left"></i>
    </button>
  </div>

  <ng-container *ngIf="missionGroup">
    <!-- TOP BAR BASE -->
    <div class="top-bar">
      <div [innerHTML]="missionGroup.name" class="title"></div>
    </div>

    <div class="container loading-opacity">
      <div class="products-collected">
        <div
          *ngFor="let mission of missionGroup.missions"
          (click)="openMissionDetails(mission)"
          [class.disabled]="!mission.player_mission_id || mission.is_collected"
          [class.collected]="mission.is_collected"
          class="artefact"
        >
          <img
            [src]="'icon-check.png' | asset: 'ui'"
            class="check-image"
          />

          <div
            [class.empty]="!mission.player_mission_id"
            class="card"
          >
            <ng-container *ngIf="mission.player_mission_id; else emptyTpl">
              <!-- ITEM NAME -->
              <p class="name">
                {{ mission.title }}
              </p>

              <!-- ITEM ICON -->
              <div class="icon-production">
                <img [src]="mission.icon + '.png' | asset:'missions/big' "/>
              </div>
            </ng-container>

            <!-- ITEM WORTH -->
            <div [class.check]="mission.is_collected" class="worth">
              <m-ui-stock-other
                [item]="{icon: 'exp', amount: mission.exp_points}"
                [stockView]="STOCK_VIEW.H"
                [lack]="false"
                [other]="{value: '+ '}"
              ></m-ui-stock-other>
            </div>

            <!-- NO ITEM -->
            <ng-template #emptyTpl>
              <i class="fas fa-question"></i>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- ARROW -->
      <div class="arrow">
        <img [src]="'arow-blue-right.png' | asset: 'ui'"/>
      </div>

      <!-- PRIZE -->
      <div class="prize">
        <!-- TITLE -->
        <p class="prize-title">
          Nagroda <br/> za realizację wyzwania
        </p>

        <ng-container *ngIf="missionGroup.is_reward_collected">
          <!-- SPECIAL CHEST OPEN -->
          <img class="chest" [src]="'chest_open.png' | asset: 'missions'"/>

          <button
            (click)="openChestDetails(missionGroup.player_chest_id)"
            class="base secondary reward">
            Zobacz nagrody
          </button>
        </ng-container>

        <ng-container *ngIf="!missionGroup.is_reward_collected">
          <!-- SPECIAL CHEST CLOSED -->
          <img class="chest" [src]="'chest_closed.png' | asset: 'missions'"/>

          <!-- GET REWARD -->
          <button
            [disabled]="!(missionGroup.total_missions === missionGroup.completed_missions)"
            (click)="getMissionGroupReward()"
            class="base secondary reward">
            Odbierz
          </button>
        </ng-container>
      </div>
    </div>

    <!-- NAVIGATION -->
    <div class="navigation">
      <!-- PREV -->
      <div class="prev-coll">
        <ng-container *ngIf="activeMissionGroupIndex != 0">
          <button
            (click)="changePage(activeMissionGroupIndex - 1)"
            class="square small primary prev"
          >
            <i class="fas fa-angle-left"></i>
          </button>
          Poprzednie wyzwanie
        </ng-container>
      </div>
      <!-- NEXT -->
      <div class="next-coll">
        <ng-container *ngIf="activeMissionGroupIndex < missionGroups.length - 1">
          Następne wyzwanie
          <button
            (click)="changePage(activeMissionGroupIndex + 1)"
            class="square small primary next"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
