import { Component } from '@angular/core';
import { AbstractNavigationCenterComponent } from '../../../abstract/abstract-navigation-center.component';

@Component({
  selector: 'm-ui-navigation-center',
  templateUrl: './ui-navigation-center.component.html',

})
export class MUiNavigationCenterComponent extends AbstractNavigationCenterComponent {

}
