import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { RankingListComponent } from '../../base/custom/dialogs/ranking-list/ranking-list.component';
import { RankingDetailsGame } from '../../base/custom/dialogs/ranking-game-details/ranking-details-game.component';
import { RankingGameTableComponent } from '../../base/custom/dialogs/ranking-game-details/ranking-game-table/ranking-game-table.component';

@NgModule({
  declarations: [
    RankingListComponent,
    RankingDetailsGame,
    RankingGameTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedUiMobileModule,
    SwiperModule,
  ],
  exports: [
    RankingGameTableComponent
  ],
  entryComponents: [
    RankingListComponent,
    RankingDetailsGame,
  ]
})
export class RankingsModule {
}
