<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <ng-container *ngIf="buildingDetails">
    <div class="top-bar">
      <!-- BUILDING NAME AND LEVEL -->
      <div class="title">
        {{ buildingDetails.name }}
      </div>

      <!-- GROUP COUNT -->
      <div class="absolute-right">
        <m-ui-group-count
          *ngIf="upgradeBuildingDetails && differentGroup"
          [building]="upgradeBuildingDetails"
          #groupCount
        ></m-ui-group-count>
      </div>
    </div>

    <swiper #sliderUpgrade [(index)]="sliderActiveIndex" class="loading-opacity">
      <div class="building-details-container">
        <!-- LEFT -->
        <div class="building-icon">
          <!-- BUILDING ICON -->
          <img [src]="buildingDetails | buildingIcon:false">
          <!-- EXPERIENCE -->
          <m-ui-exp-up
            *ngIf="upgradeBuildingDetails"
            [experience]="upgradeBuildingDetails.exp_points"
          ></m-ui-exp-up>
        </div>

        <!-- RIGHT -->
        <div>
          <!-- BUILDING DESCRIPTION -->
          <div
            class="building-desc"
            [innerHTML]="buildingDetails.upgrade_building_id ? buildingDetails.additional_details : buildingDetails.group_description"
          ></div>

          <ng-container *ngIf="upgradeBuildingDetails">
            <!-- PARAMETERS -->
            <div class="parameters">
              <!-- RETURN CURRENCY -->
              <m-ui-currency
                *ngFor="let currency of upgradeBuildingDetails.return_currencies"
                [item]="currency"
                [other]="{value: '+'}"
                [stockView]="STOCK_VIEW.A"
                [lack]="false"
              ></m-ui-currency>

              <!-- RETURN PRODUCTS -->
              <m-ui-product
                *ngFor="let product of upgradeBuildingDetails.return_products"
                [item]="product"
                [other]="{value: '+'}"
                [stockView]="STOCK_VIEW.A"
                [lack]="false"
              ></m-ui-product>
            </div>

            <!-- PARAMETERS -->
            <div class="parameters" *ngIf="upgradeBuildingDetails.parameters.length > 0">
              <m-ui-parameter
                *ngFor="let parameter of upgradeBuildingDetails.parameters"
                [item]="parameter"
                [other]="{amount: parameter.income}"
                [stockView]="STOCK_VIEW.A"
                [lack]="false"
              ></m-ui-parameter>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- UNLOCKED BUILDINGS -->
      <div class="page unlocked-buildings" *ngIf="unlockedBuildings">
        <!-- TITLE UNLOCKED -->
        <p class="title-small">
          {{ (unlockedBuildings.length > 1 ? 'building-build.unlock-buildings' : 'building-build.unlock-build') | myTranslate }}
        </p>

        <!-- BUILDINGS THUMBNAIL LIST -->
        <div class="flex-center">
          <m-building-thumbnail
            *ngFor="let building of unlockedBuildings"
            [building]="building"
          ></m-building-thumbnail>
        </div>
      </div>
    </swiper>


    <div class="arrows-slider">
      <i class="fas fa-arrow-alt-left left"
         (click)="prevSlide()"
         [class.hide]="sliderActiveIndex <= 0"
      ></i>

      <m-ui-slider-dots [sliderRef]="sliderUpgrade"></m-ui-slider-dots>

      <i class="fas fa-arrow-alt-right right"
         (click)="nextSlide()"
         [class.hide]="sliderActiveIndex >= this.maxSlides - 1"
      ></i>
    </div>

    <!-- REQUIREMENTS -->
    <m-ui-requirements
      class="loading-opacity"
      *ngIf="upgradeBuildingDetails"
      [separator]="'global.requirements' | myTranslate"
      [groupLimit]="groupCount"
      [products]="upgradeBuildingDetails.build_product_prices"
      [currencies]="upgradeBuildingDetails.build_currency_prices"
      [playerLevel]="upgradeBuildingDetails.required_level"
      [population]="upgradeBuildingDetails.required_population"
      [requiredBuilding]="upgradeBuildingDetails.required_building"
      [star]="upgradeBuildingDetails.required_star"
      (requirementsStatus)="setRequirementsStatus($event)"
    ></m-ui-requirements>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar" *ngIf="upgradeBuildingDetails && requirementsStatus">
      <!-- BUILD -->
      <div class="bar-button base">
        <button
          class="base secondary"
          (click)="upgrade()"
          [disabled]="!requirementsStatus.valid"
        >
          {{ upgradeBuildingDetails.upgrade_button_name || ('building-special.button-upgrade' | myTranslate) }}
          <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div class="bar-button base"
           *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length">
        <button
          class="base secondary icon-left-side"
          (click)="openFastSpecialUpgradeConfirm()"
          [disabled]="!requirementsStatus.valid"
        >
          <i class="far fa-bolt"></i>
          {{'building-upgrade.button-fast-upgrade' | myTranslate}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
