import { Component } from '@angular/core';
import { AbstractChangePasswordButtonComponent } from '../../../abstract/abstract-change-password-button.component';

@Component({
  selector: 'm-ui-change-password-button',
  templateUrl: './ui-change-password-button.component.html',

})
export class MUiChangePasswordButtonComponent extends AbstractChangePasswordButtonComponent {
}
