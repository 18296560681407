export const MOCK_BUILDING_TILE_BUY = {
  api: {},
  ref: {
    tile: {
      player_tile_id: null,
      state: 'buyable',
      resource_left: null,
      player_building: null,
      tile_id: 145,
      x_pos: 3200,
      y_pos: -1280,
      z_pos: null,
      tile_type: {type_id: 1, width: 1, height: 1},
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [{currency_id: 1, amount: 600}, {currency_id: 2, amount: 800}],
      product_prices: [],
      resource_amount: null,
      production: null,
      open_player_island_id: null,
      player_island_id: 233,
      inner_island_player_production_building: null
    }
  }
};
