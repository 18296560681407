export const MOCK_WAREHOUSE_DETAILS = {
  api: {
    selling_price: 15,
    branch_selling_price: 15,
    buying_price: 45,
    product_id: 1,
    category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
    name: 'Drewno',
    description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.',
    icon: 'wood',
    reference_currency_price: 45,
    required_level: 1,
    buyable: false,
    sellable: false,
    buyable_from_level: null,
    sellable_from_level: null,
    original_reference_currency_price: 45,
    required_invention_for_trading: null
  },
  ref: {
    product_id: 1
  }
};
