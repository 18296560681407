<div class="global-dialog ow-dialog window-a primary">

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{ 'messages.level-up' | myTranslate }}
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <ng-container *ngIf="message">

    <!-- IF MESSAGE EXIST -->
    <div class="height-100 relative">

      <loading></loading>

      <div class="flex-container loading-opacity">
        <div class="level-up-container">

          <div class="level-up-top-wrapper">
            <!-- LVL UP RIBBON -->
            <div class="level-up-ribbon">
              <div class="level">
                <i class="fas fa-arrow-alt-up"></i>
                {{ message.parameters.level }}
              </div>
            </div>

            <!-- DESCRIPTION -->
            <div class="inner-html-description" [innerHTML]="message.description | safe:'html'"></div>
          </div>

          <!-- UNLOCKED BUILDING -->
          <ng-container *ngIf="unlockedBuildings.items.length > 0">

            <!-- SEPARATOR-->
            <m-ui-separator
              [title]="'messages.level-up-separator-unlocked-buildings-title' | myTranslate"></m-ui-separator>

            <!-- SLIDER -->
            <swiper
              #sliderUnlockedBuildings
              [config]="unlockedBuildings.config"
              [(index)]="sliderUnlockedBuildingsActiveIndex"
            >
              <div class="page" *ngFor="let page of unlockedBuildings.pages">
                <div *ngFor="let building of page">
                  <div
                    *ngIf="building"
                    class="building-thumbnail-container"
                  >
                    <img
                      [src]="building | buildingIcon: false"
                      placement="bottom"
                      tooltipClass="delayed"
                      container="body"
                    />
                    <p class="level">
                      {{ building.name }}
                    </p>
                    <p class="level">
                      lvl {{ building.level }}
                    </p>
                  </div>
                </div>
              </div>
            </swiper>

            <div class="arrows-slider loading-opacity">
              <i
                class="fas fa-arrow-alt-left left"
                (click)="prevSlide(sliderUnlockedBuildings)"
                [class.hide]="sliderUnlockedBuildingsActiveIndex === 0"
              ></i>

              <m-ui-slider-dots [sliderRef]="sliderUnlockedBuildings"></m-ui-slider-dots>

              <i
                class="fas fa-arrow-alt-right right"
                (click)="nextSlide(sliderUnlockedBuildings)"
                [class.hide]="sliderUnlockedBuildingsActiveIndex === unlockedBuildings.pages.length - 1"
              ></i>
            </div>
          </ng-container>

          <!-- UNLOCKED PRODUCTS -->
          <ng-container *ngIf="unlockedProducts.items.length > 0">

            <!-- SEPARATOR-->
            <m-ui-separator
              [title]="'messages.level-up-separator-unlocked-products-title' | myTranslate"></m-ui-separator>

            <!-- SLIDER -->
            <swiper
              #sliderUnlockedProducts
              [config]="unlockedProducts.config"
              [(index)]="sliderUnlockedProductsActiveIndex"
            >
              <div class="page" *ngFor="let page of unlockedProducts.pages">
                <div
                  *ngFor="let product of page"
                  class="product-container"
                >
                  <m-ui-product
                    [item]="product"
                    [lack]="false"
                    [hideValue]="true"
                  ></m-ui-product>
                  <span>{{ product.name }}</span>
                </div>
              </div>
            </swiper>

            <div class="arrows-slider loading-opacity">
              <i
                class="fas fa-arrow-alt-left left"
                (click)="prevSlide(sliderUnlockedProducts)"
                [class.hide]="sliderUnlockedProductsActiveIndex === 0"
              ></i>

              <m-ui-slider-dots [sliderRef]="sliderUnlockedProducts"></m-ui-slider-dots>

              <i
                class="fas fa-arrow-alt-right right"
                (click)="nextSlide(sliderUnlockedProducts)"
                [class.hide]="sliderUnlockedProductsActiveIndex === unlockedProducts.pages.length - 1"
              ></i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- MESSAGE TRASH -->
    <i
      class="fas fa-archive trash-icon"
      *ngIf="!message.deleted && message.deletable"
      (click)="moveToTrash()"
      [matTooltip]="'messages.archives' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300"
    ></i>
  </ng-container>
</div>
