import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { transformErrorApi } from '../../../core/utility/transform-error-api';
import { Alert, AlertConfirm, AlertImage } from '../../../core/interfaces/alert';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';
import { EventEmitterDialogsService } from '../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_CORE } from '../../../core/consts/core/event-dialogs/event-names.const';

@Injectable()
export class DialogService extends AbstractInjectBaseComponent {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MatDialog) dialog: MatDialog;
  // @TODO:gutyo [change this property from static to normal] (problem: DialogService is not singleton)
  static openDialogs: any[] = [];

  public open(component: any, config: MatDialogConfig = {data: {}}, onCloseCallback?) {
    const dialogRef = this.dialog.open(component, config);
    DialogService.openDialogs.push(dialogRef);

    this.prepareBackground();
    dialogRef.afterClosed().subscribe((data) => {
      if (onCloseCallback) {
        onCloseCallback(data);
      }

      this.removeDialog(dialogRef);
    });

    return dialogRef;
  }

  public closeAll() {
    DialogService.openDialogs.forEach((dialogRef) => {
      dialogRef.close();
      dialogRef = null;
    });
  }

  public openAlertErrorApi(
    data: { errResp: any } & Alert,
    callback?,
  ) {
    if (data.errResp.status !== 500 && data.errResp.status !== 502 && data.errResp.status !== 0) {
      const transformedError = transformErrorApi(data.errResp);

      this.openAlert({
        description: transformedError,
        ...data
      }, callback);
    }
  }

  public openAlert(
    data: Alert,
    callback?,
  ) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT,
      config: {
        data,
      },
      callback,
    });
  }

  public openConfirm(data: AlertConfirm, callback?) {
    const defaultAlertConfirm: AlertConfirm = {
      style: {
        maxWidth: 'calc(400px * var(--multiply))',
        minWidth: 'calc(400px * var(--multiply))',
      }
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT_CONFIRM,
      config: {
        data: {...defaultAlertConfirm, ...data}
      },
      callback,
    });
  }

  public openAlertImage(
    data: AlertImage,
    callback?,
  ) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT_IMAGE,
      config: {
        data,
      },
      callback,
    });
  }

  private removeDialog(dialogRef) {
    // REMOVE NULL/EMPTY ELEMENT START
    const idx = DialogService.openDialogs.indexOf(dialogRef);
    if (idx != -1) {
      delete DialogService.openDialogs[idx];
    }
    DialogService.openDialogs = DialogService.openDialogs.filter((dialog) => {
      return (dialog !== (undefined || '' || null));
    });
    // REMOVE NULL/EMPTY ELEMENT END

    this.prepareBackground({close: true});
  }

  public closeActive() {
    return DialogService.openDialogs.pop().close();
  }

  private prepareBackground({close}: { close?: boolean } = {}): void {
    let overlayClass = '.cdk-overlay-container .cdk-overlay-backdrop.cdk-overlay-dark-backdrop';

    if (close) {
      overlayClass += '.cdk-overlay-backdrop-showing';
    }

    const backdropArray = Array.from(document.querySelectorAll(overlayClass));
    backdropArray.forEach((node, index) => {
      node.classList.add('hide-background');

      if (index + 1 === backdropArray.length) {
        node.classList.remove('hide-background');
      }
    });

    this.hideDialog();
  }

  private hideDialog(): void {
    const dialogClass = '.cdk-overlay-container .cdk-global-overlay-wrapper .global-dialog';
    const dialogs = Array.from(document.querySelectorAll(dialogClass));

    dialogs.forEach((node, index) => {
      node.classList.add('hide-dialog');

      if (index + 1 === dialogs.length) {
        node.classList.remove('hide-dialog');
      }
    });
  }
}
