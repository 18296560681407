import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractMissionTransferComponent } from '../../../../abstract/core/abstract-mission-transfer.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-mission-transfer',
  templateUrl: './mission-transfer.component.html',

})
export class MissionTransferComponent extends AbstractMissionTransferComponent implements OnInit, OnDestroy {

  ngOnInit() {
    this.condition = this.data.condition;

    if (this.data.condition.product) {
      this.productOrCurrency = this.productPlayerService.getProduct(this.data.condition.product);
    }

    this.clearForm();
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
