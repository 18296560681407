import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../consts/core/event-dialogs/event-names.const';
import {
  MOCK_WAREHOUSE,
  MOCK_WAREHOUSE_CATEGORY,
  MOCK_WAREHOUSE_CATEGORY_PRODUCT,
  MOCK_WAREHOUSE_STORE_PRODUCT
} from '../consts/mock-warehouse.const';
import { MOCK_WAREHOUSE_DETAILS } from '../consts/mock-warehouse-details.const';

@Injectable({providedIn: 'root'})
export class MockWarehouseService {
  constructor(
    private mockDialogListService: MockDialogListService,
  ) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE: {
          this.mockDialogListService.setDialogRef(MOCK_WAREHOUSE.ref);
          this.mockDialogListService.setMockApiData(
            EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
            MOCK_WAREHOUSE.api.storages
          );
          MOCK_WAREHOUSE.api.storages.forEach((storage, i) => {
            this.mockDialogListService.setMockApiData(
              `${MOCK_WAREHOUSE_CATEGORY}_${storage.storage_id}`,
              MOCK_WAREHOUSE.api.categories[i]
            );
          });
          this.mockDialogListService.setMockApiData(
            MOCK_WAREHOUSE_CATEGORY_PRODUCT,
            MOCK_WAREHOUSE.api.productsPlayer
          );
          this.mockDialogListService.setMockApiData(
            MOCK_WAREHOUSE_STORE_PRODUCT,
            MOCK_WAREHOUSE.api.storePrimaryProducts
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS: {
          this.mockDialogListService.setDialogRef(
            MOCK_WAREHOUSE_DETAILS.ref
          );
          this.mockDialogListService.setMockApiData(
            EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS,
            MOCK_WAREHOUSE_DETAILS.api
          );
          break;
        }
      }
    });
  }
}
