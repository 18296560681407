import { Directive, Injectable, ViewChild } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../game-engine/interfaces/building-details-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { BuildingsService } from '../../../../services/buildings.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { catchError, filter, take } from 'rxjs/operators';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { PlayerBuilding } from '../../../../game-engine/interfaces/player-building.config';
import { forkJoin, of } from 'rxjs';
import { upgradeDifferenceParameters, upgradeDifferenceProduction } from '../../helpers/core/upgrade.helpers';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { ProductionService } from '../../../../services/production.service';
import { BUILDING_TYPES } from '../../consts/core/buidling-types.const';
import { translate } from '../../../../../../core/helpers/translate.helper';
import * as moment from 'moment';

@Directive()
@Injectable()
export abstract class AbstractBuildingUpgradeComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingUpgradeComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;

  BUILDING_TYPES = BUILDING_TYPES;
  playerBuilding: PlayerBuilding;
  buildingDetails: BuildingDetailsConfig;
  upgradeBuildingDetails: BuildingDetailsConfig;
  unlockedBuildings: BuildingDetailsConfig[];
  requirementsStatus: { valid: boolean, requirementsList: any[] };
  @ViewChild('sliderUpgrade') sliderUpgrade;

  subs = {
    board: null,
  };

  sliderActiveIndex = 0;
  maxSlides = 0;

  diffProductionsSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  diffParametersSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1),
      )
      .subscribe((tile) => {
        this.playerBuilding = tile.player_building;
        this.combineBuildDetails();
      });
  }

  afterGetDataRequests() {
  }

  combineBuildDetails() {
    forkJoin([
      this.buildingsService.getPlayerBuildingDetails(this.playerBuilding.player_building_id),
      this.buildingsService.getBuildingDetails(this.playerBuilding.upgrade_building_id),
    ])
      .subscribe(([
                    buildingDetails,
                    upgradeBuildingDetails,
                  ]) => {
        this.buildingDetails = buildingDetails;
        this.upgradeBuildingDetails = upgradeBuildingDetails;

        this.getUnlockedBuildings();
        this.getBuildingProductions();
        this.getUpgradeDifferenceParameters();
        this.checkSlideLength();
      });
  }

  getUnlockedBuildings() {
    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.NORMAL:
        this.buildingsService.getUnlockedBuildings(this.upgradeBuildingDetails.building_id)
          .subscribe((buildings: BuildingDetailsConfig[]) => {
            this.unlockedBuildings = buildings;
            if (this.unlockedBuildings.length === 0) {
              this.unlockedBuildings = null;
            }
            this.afterGetDataRequests();
          }, (errResp) => {
            if (errResp.status === 404) {
              errResp.defaultHandler.unsubscribe();
            }
          });
        break;
    }
  }

  getUpgradeDifferenceParameters() {
    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];
    this.diffParametersSlider.items = upgradeDifferenceParameters(this.buildingDetails.parameters, this.upgradeBuildingDetails.parameters);
    this.diffParametersSlider = generateEachPages(this.diffParametersSlider);
    this.afterGetDataRequests();
  }

  getBuildingProductions() {
    this.diffProductionsSlider.items = [];
    this.diffProductionsSlider.pages = [];

    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.PRODUCTION:
      case BUILDING_TYPES.CROP:
        if (this.buildingDetails.group_type !== BUILDING_TYPES.PRODUCTION && this.buildingDetails.group_type !== BUILDING_TYPES.CROP) {
          // ACTUAL BUILDING != PROD/CROP -> GET PRODUCTION ONLY FOR UPGRADE BUILDING
          this.productionService.getBuildingProduction(this.upgradeBuildingDetails.building_id)
            .subscribe((productionUpgrade) => {
              this.setDiffProductionSlider([], productionUpgrade);
            });
        } else {
          // COMPARE DIFF PRODUCTION BETWEEN BUILDINGS
          Promise.all([
            this.productionService.getBuildingProduction(this.buildingDetails.building_id)
              .pipe(
                catchError(() => of([]))
              )
              .toPromise(),
            this.productionService.getBuildingProduction(this.upgradeBuildingDetails.building_id)
              .pipe(
                catchError(() => of([]))
              )
              .toPromise(),
          ]).then((
            [
              productionNormal,
              productionUpgrade,
            ]: [
              any[],
              any[]
            ]
          ) => {
            this.setDiffProductionSlider(productionNormal, productionUpgrade);
          });
        }
        break;
    }
  }

  setDiffProductionSlider(productionNormal, productionUpgrade) {
    this.diffProductionsSlider.items = upgradeDifferenceProduction(productionNormal, productionUpgrade);
    this.diffProductionsSlider = generateEachPages(this.diffProductionsSlider);
    this.afterGetDataRequests();
  }

  upgrade({fastUpgrade}: { fastUpgrade?: boolean } = {}) {
    this.buildingsService.upgrade(this.playerBuilding.player_building_id, fastUpgrade)
      .subscribe(() => {
        this.matDialogRef.close();
      });
  }

  openFastUpgradeConfirm() {
    let description = translate('fast-action.description');
    if (this.upgradeBuildingDetails.fast_building_time_in_seconds) {
      const time = moment.utc(this.upgradeBuildingDetails.fast_building_time_in_seconds * 1000).format('HH[h] mm[m] ss[s]');
      const extendDescription = translate('building-upgrade.fast-action-extend-description', [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm({
      title: translate('fast-action.title'),
      description,
      costs: {
        separatorTitle: translate('fast-action.separator-title'),
        currencies: this.upgradeBuildingDetails.fast_build_currency_prices,
        products: this.upgradeBuildingDetails.fast_build_product_prices,
      }
    }, (confirm) => {
      if (confirm) {
        this.upgrade({fastUpgrade: true});
      }
    });
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  checkSlideLength() {
    setTimeout(() => {
      if (this.sliderUpgrade) {
        this.maxSlides = this.sliderUpgrade.swiperSlides.nativeElement.childElementCount;
      } else {
        this.maxSlides = 0;
      }

      this.checkSlideLength();
    }, 500);
  }

  prevSlide() {
    this.sliderUpgrade.directiveRef.prevSlide();
  }

  nextSlide() {
    this.sliderUpgrade.directiveRef.nextSlide();
  }
}
