export const CURRENT_STEP = {
  NULL: null,
  MINIGAME: 'minigame',
  MINIGAME_SUMMARY: 'minigame_summary',
  MINIGAME2: 'minigame2',
  MINIGAME2_SUMMARY: 'minigame2_summary',
  QUIZ_GAME: 'quiz-game',
  SUMMARY: 'summary',
}
// list in task #23142
