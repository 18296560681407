import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/providers/api.service';
import { tap } from 'rxjs/operators';
import { PlayerService } from '../../player/providers/player.service';
import { MOCK_BOARD_KEY } from '../mock/consts/mock-board.const';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
  ) {
  }

  getWorld(playerId: number) {
    return this.apiService.get(`player/${playerId}/region`)
      .pipe(
        tap((regions) => {
          this.playerService.playerRegions.next({
            playerId,
            regions,
          });
        })
      );
  }

  getBoard(playerId: number, islandId?: number) {
    return this.apiService.get(
      `player/${playerId}/board`,
      {
        params: {
          player_island_id: islandId
        },
      },
      MOCK_BOARD_KEY
    );
  }

  buyTile(tileId: number) {
    const options = {
      body: {
        tile_id: tileId,
      }
    };
    return this.apiService.post('board/tile', options);
  }
}
