import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { TokenObject } from '../interfaces/token-object';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../providers/auth.service';
import { DialogService } from '../../shared/providers/dialog.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { UtilityActions } from '../../../store/utility';
import { APP_ROUTING_ABSOLUTE_PATH } from '../../../consts/routing-app-absolute.const';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../consts/core/routing-auth-absolute.const';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';

@Directive()
@Injectable()
export abstract class AbstractTempTokenExchangeComponent extends AbstractInjectBaseComponent {
  @OwInject(Router) router: Router;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  tempToken: string;
  tokenData: TokenObject;

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.hasOwnProperty('temp-token')) {
        this.tempToken = params['temp-token'];
        this.exchangeToken();
      }
    });
  }

  exchangeToken() {
    this.authService.tempTokenExchange({temp_token: this.tempToken})
      .then((resp) => {
        this.tokenData = resp;
        this.store.dispatch(new UtilityActions.SetToken(resp));
        this.redirectPlayerChoose();
      }, () => {
        this.redirectLogin();
      });
  }

  redirectPlayerChoose() {
    this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
  }

  redirectLogin() {
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
  }
}
