export const MOCK_MISSION = {
  api: [{
    points_prize: 1000,
    status: 'active',
    locked_till: null,
    conditions_count: 5,
    conditions_completed: 1,
    seen: true,
    currency_prizes: [],
    product_prizes: [],
    player_mission_id: 109023,
    product_prizes_count: 0,
    center_map_on_tile_id: null,
    center_map: null,
    mission_id: 1,
    slot: {mission_slot_id: 1, type: 1},
    icon: '1',
    title: 'Przejdź do roku 1985',
    base_points_prize: 1000,
    star: {star_id: 2, star_name: 'Rok 1985', price_per_person: 100, cleaning_cost_multiplier: 1}
  }, {
    points_prize: 100,
    status: 'active',
    locked_till: null,
    conditions_count: 1,
    conditions_completed: 1,
    seen: true,
    currency_prizes: [],
    product_prizes: [],
    player_mission_id: 109038,
    product_prizes_count: 0,
    center_map_on_tile_id: null,
    center_map: null,
    mission_id: 22,
    slot: {mission_slot_id: 2, type: 1},
    icon: '1',
    title: 'Wybuduj ryneczek',
    base_points_prize: 100,
    star: null
  }, {
    points_prize: 100,
    status: 'active',
    locked_till: null,
    conditions_count: 3,
    conditions_completed: 0,
    seen: true,
    currency_prizes: [],
    product_prizes: [],
    player_mission_id: 109032,
    product_prizes_count: 0,
    center_map_on_tile_id: null,
    center_map: null,
    mission_id: 23,
    slot: {mission_slot_id: 3, type: 1},
    icon: '1',
    title: 'Odbierz produkcje drewna, rozbuduj tartak i uruchom kolejną produkcję drewna',
    base_points_prize: 100,
    star: null
  }],
  ref: {}
};
