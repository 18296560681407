import { Directive, Injectable, Input } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UtilitySelectors } from '../../../../../../store/utility';
import { AppState } from '../../../../../../store/state';
import { Player } from '../../../../../player/interfaces/player';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../../warehouse/consts/core/event-dialogs/event-names.const';
import { PlayerService } from '../../../../../player/providers/player.service';
import { PreviewPlayerService } from '../../../../game-gui/providers/preview-player.service';
import { EVENT_DIALOGS_NAMES_MESSAGE } from '../../../message/consts/core/event-dialogs/event-names.const';
import { EVENT_DIALOGS_NAMES_MISSION } from '../../../mission/consts/core/event-dialogs/event-names';
import { AssetsService } from '../../../../../../core/providers/assets.service';
@Directive()
@Injectable()
export abstract class AbstractHudMenuComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @Input() data: {
    player: Player
  };
  hasMissionsToCollect: Observable<boolean>;
  newProductsInStorage: Observable<any[]>;
  hasNewMessagesToRead: Observable<boolean>;
  storageId: number;

  afterConstructor() {
    this.hasMissionsToCollect = this.store.pipe(select(UtilitySelectors.selectHasMissionsToCollect));
    this.newProductsInStorage = this.store.pipe(select(UtilitySelectors.selectNewProductInStorage));
    this.hasNewMessagesToRead = this.store.pipe(select(UtilitySelectors.selectHasNewMessagesToRead));
  }

  openMissions() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
    });
  }

  openMessages() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
    });
  }

  openWarehouse() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
      config: {
        data: {
          storageId: this.storageId,
        },
      }
    });
  }
}
