export const MOCK_QA_DETAILS = {
  api: {},
  ref: {
    qa: {
      last_attempt: {
        finished_at: '2020-07-07T14:52:33+02:00',
        questions: [{
          qa_question_id: 50,
          content: 'Co wchodzi w zakres ubezpieczenia Life oferowanego przez Santander ?',
          answers: [{
            qa_answer_id: 146,
            content: 'Śmierć Ubezpieczonego',
            is_correct: true,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }, {
            qa_answer_id: 149,
            content: 'Poważne zachorowanie',
            is_correct: false,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }, {
            qa_answer_id: 152,
            content: 'Śmierć Ubezpieczonego i poważne zachorowanie',
            is_correct: false,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }],
          image_url: null,
          image: null,
          type: 'quiz',
          selected_answer: {
            qa_answer_id: 152,
            content: 'Śmierć Ubezpieczonego i poważne zachorowanie',
            is_correct: false,
            image_url: null,
            explanation: null,
            type: 'quiz'
          },
          qa_text_answer: null
        }, {
          qa_question_id: 53,
          content: 'Co wchodzi w zakres pakietu Bezpiecznego  „Pakietu Usług dodatkowych”  oferowanego przez Alior Bank ?',
          answers: [{
            qa_answer_id: 155,
            content: 'Assistance PZU',
            is_correct: false,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }, {
            qa_answer_id: 158,
            content: 'Alerty BIK',
            is_correct: true,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }, {qa_answer_id: 161, content: 'Wakacje kredytowe', is_correct: false, image_url: null, explanation: null, type: 'quiz'}],
          image_url: null,
          image: null,
          type: 'quiz',
          selected_answer: {qa_answer_id: 158, content: 'Alerty BIK', is_correct: true, image_url: null, explanation: null, type: 'quiz'},
          qa_text_answer: null
        }, {
          qa_question_id: 56,
          content: 'Jaki jest miesięczny koszt pakietu Bezpiecznego w „Pakiecie Usług dodatkowych” oferowanego przez Alior Bank ?',
          answers: [{
            qa_answer_id: 164,
            content: '10 zł',
            is_correct: false,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }, {qa_answer_id: 167, content: '3 zł', is_correct: false, image_url: null, explanation: null, type: 'quiz'}, {
            qa_answer_id: 170,
            content: '5 zł',
            is_correct: true,
            image_url: null,
            explanation: null,
            type: 'quiz'
          }],
          image_url: null,
          image: null,
          type: 'quiz',
          selected_answer: {qa_answer_id: 170, content: '5 zł', is_correct: true, image_url: null, explanation: null, type: 'quiz'},
          qa_text_answer: null
        }],
        started_at: '2020-07-07T14:48:39+02:00',
        player_points: 2,
        max_points: 3,
        currency_prizes_for_answers: [{currency_id: 1, amount: 3000}],
        product_prizes_for_answers: []
      },
      can_answer_from: '2020-07-07T10:39:00+02:00',
      can_answer_to: '2020-07-10T23:00:00+02:00',
      name: 'O dodatkowych produktach kredytowych',
      description: '<p>Produkty dodatkowe sprzedawane w kredycie to nie tylko bezpieczeństwo finansowe Klient&oacute;w i ich rodzin.<br />\r\nZ jednej strony, to dostęp do nowoczesnych usług bankowych podczas całego okresu kredytowania.<br />\r\nZ drugiej zaś, to r&oacute;wnież spos&oacute;b na zwiększenie swojej premii za sprzedaż kredytu.</p>\r\n\r\n<p>Przejrzyj materiał i zacznij zarabiać więcej!</p>\r\n\r\n<p></p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 14,
      content_at_finish: '<p>Dziękujemy za wypełnienie quizu!</p>',
      max_attempts: 1,
      done_attempts: 1,
      type: 'quiz',
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null
    }, prevState: {location: 0}
  }
};
