import { Component } from '@angular/core';
import { GameEngineP3Component } from '../../../components/game-engine-p3/game-engine-p3.component';

@Component({
  selector: 'app-mobile-game-engine-pce',
  templateUrl: './game-engine-pce.component.html',

})
export class MobileGameEnginePceComponent extends GameEngineP3Component {

}
