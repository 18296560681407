import { TokenObject } from '../../modules/auth/interfaces/token-object';
import { STORAGE_CONFIG } from '../config/core/data-storage.config';

export function getToken(): TokenObject {
  let token: TokenObject = {
    token: null,
    expired_at: null,
    download_token: null,
    is_impersonate: null,
    reauthorize_after: null,
    refresh_after: null,
  };

  const lsToken = STORAGE_CONFIG.TOKEN.get('token');

  if (lsToken) {
    try {
      token = JSON.parse(lsToken);
    } catch (e) {
    }
  }

  return token;
}

export function setToken(token: TokenObject) {
  let tokenString: string;

  if (token) {
    tokenString = JSON.stringify(token);
  }

  STORAGE_CONFIG.TOKEN.set('token', tokenString, {
    'expired_at': (token && token.reauthorize_after) ? token.expired_at : null,
  });
}

export function removeToken() {
  STORAGE_CONFIG.TOKEN.remove('token');
}
