<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="title">{{ 'auth.registration-confirm-a.title' | myTranslate }}:</p>

  <form [formGroup]="form" *ngIf="form">
    <!-- PASSWORD FORM -->
    <div class="control">
      <div class="label-flex">
        <label>{{ 'auth.registration-confirm-a.form.password' | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="password" type="password"/>
    </div>

    <!-- PASSWORD REPEAT -->
    <div class="control">
      <div class="label-flex">
        <label>{{ 'auth.registration-confirm-a.form.password-repeat' | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="passwordRepeat" type="password"/>
    </div>

    <p
      class="description invalid-form error"
      [class.opacity-1]="form.get('password').value && form.get('passwordRepeat').value && form.hasError('notSame')"
    >
      {{ 'auth.registration-confirm-a.form.invalid-password-the-same' | myTranslate }}
    </p>
  </form>

  <div class="buttons">
    <button
      class="base primary"
      (click)="registrationConfirm()"
      [disabled]="form.invalid"
    >
      {{ 'auth.registration-confirm-a.form.button-send' | myTranslate }}
    </button>
  </div>
</div>
