import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TokenObject } from '../../modules/auth/interfaces/token-object';
import { ApiOptions } from '../interfaces/api-options';
import { Observable } from 'rxjs';
import { getToken, removeToken, setToken } from '../utility/token';
import { MockDialogListService } from '../../modules/mock-dialogs/services/mock-dialog-list.service';

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private mockDialogService: MockDialogListService,
  ) {
  }

  private getOptionsDefault(key?) {
    const optionsDefault = {
      headers: {
        'x-token': this.getToken().token || 'owEmptyToken',
        'Content-Type': 'application/json'
      }
    };

    if (key) {
      return optionsDefault[key];
    }

    return optionsDefault;
  }

  private setHeaders(options: ApiOptions, method: string) {
    let optionsHeaders = options.headers || {};

    let httpHeaders = new HttpHeaders();

    optionsHeaders = {...this.getOptionsDefault('headers'), ...optionsHeaders};

    if (options.sendRequestWithoutToken) {
      delete optionsHeaders['x-token'];
    }

    if (options.contentTypeAuto || method === 'GET' || !options.body) {
      delete optionsHeaders['Content-Type'];
    }

    Object.keys(optionsHeaders).map((key) => {
      httpHeaders = httpHeaders.append(key, optionsHeaders[key]);
    });

    return httpHeaders;
  }

  private setParams(options: ApiOptions) {
    let optionsParams = {...options.params};

    let httpParams = new HttpParams({encoder: new CustomEncoder()});

    optionsParams = {...this.getOptionsDefault('params'), ...optionsParams};

    if (options.isNotShowLoading) {
      optionsParams['isNotShowLoading'] = true;
    }

    Object.keys(optionsParams).map((key) => {
      if (this.checkIsNotEmptyElement(optionsParams[key])) {
        httpParams = httpParams.append(key, optionsParams[key]);
      }
    });

    return httpParams;
  }

  private setBody(optionsBody) {
    if (!optionsBody) {
      return null;
    }

    if (optionsBody instanceof FormData) {
      return optionsBody;
    } else {
      const body = {...optionsBody};

      if (optionsBody) {
        Object.keys(optionsBody).forEach((key) => {
          let value = body[key];

          if (value === true) {
            value = 1;
          }

          if (value === false) {
            value = 0;
          }

          body[key] = value;
        });
      }

      return body;
    }
  }

  private prepareRequestOptions(options: ApiOptions = {}, method: string) {
    const finalOptions = {
      headers: this.setHeaders(options, method),
      params: this.setParams(options),
      body: this.setBody(options.body),
      observe: undefined,
      responseType: options.responseType || 'json',
    };

    if (options.responseWithHeaders) {
      finalOptions.observe = 'response';
    }

    return finalOptions;
  }

  private checkIsNotEmptyElement(value: any) {
    return (value || value === 0);
  }

  request(method: string, path: string, options: ApiOptions = {}) {
    let url = environment.apiUrl;
    if (options.useBaseUrl) {
      url = environment.base + '/';
    }

    /**
     * Images Service generates paths with a prefix '/'
     * appConfig.baseUrl and appConfig.apiUrl paths ends with '/'
     * Example: //api.exampledomain.com/<- apiUrl | path ->/assets/...
     * Output //api.exampledomain.com//assets/...
     * ErrorRequest //api.exampledomain.com/<- ERROR ->/assets/...
     *
     * If paths ends with '/' then remove first char from paths
     */
    if (path && path.charAt(0) == '/') {
      path = path.substr(1);
    }

    return <Observable<any>>this.httpClient.request(method, url + path, this.prepareRequestOptions(options, method));
  }

  post(path: string, options?: ApiOptions) {
    return this.request('POST', path, options);
  }

  get(path: string, options?: ApiOptions, mockDataKey?: string) {
    if (this.mockDialogService.isMocked && this.mockDialogService.findMockApiResponse(mockDataKey)) {
      return this.mockDialogService.createMockResponse(mockDataKey);
    } else {
      return this.request('GET', path, options);
    }
  }

  delete(path: string, options?: ApiOptions) {
    return this.request('DELETE', path, options);
  }

  put(path: string, options?: ApiOptions) {
    return this.request('PUT', path, options);
  }

  patch(path: string, options?: ApiOptions) {
    return this.request('PATCH', path, options);
  }

  setToken(tokenObject: TokenObject) {
    setToken(tokenObject);
  }

  getToken(): TokenObject {
    return getToken();
  }

  removeToken() {
    removeToken();
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
