import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { ImagesAtlas } from '../game-engine/interfaces';
import { Router } from '@angular/router';
import * as Actions from '../../../store/game/actions';
import { ResetBoard } from '../../../store/game/actions';
// import { MyGame } from '../game-engine/classes/MyGame.class';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AssetsService } from '../../../core/providers/assets.service';
import { DialogService } from '../../shared/providers/dialog.service';
import { BuildingsService } from './buildings.service';
import { from, Observable } from 'rxjs';
import { GlobalService } from '../../../core/providers/global.service';
import { GlobalEvent } from '../../../core/interfaces/shared.interfaces';
import { ToHourPipe } from '../game-gui/directives/to-hour.pipe';
import { CurrencyService } from '../../../core/providers/currency.service';
import { PlayerService } from '../../player/providers/player.service';
import { SynchronizeTimeService } from '../../../core/providers/synchronize-time.service';
import { ProductionService } from './production.service';
import { GAME_EVENTS } from '../constants';
import { BoardService } from './board.service';
// import { getDiscoverPlaceType } from '../game-engine/utils/board.helper';
import { PhaserGameService } from './phaser-game.service';
import { WaterAttractionsService } from './water-attractions.service';
import { MyGame } from '../game-engine/classes/core/MyGame';
import { MyScene } from '../game-engine/classes/core/MyScene';
import { ProductPlayerService } from '../../player/providers/product-player.service';
import { SectionItem } from '../../../core/interfaces/alert';
import { Currency } from '../../../core/interfaces/currency';
import { ProductDetailsBalance } from '../../player/interfaces/product.interface';
import { GuiService } from './gui.service';
import { MAIN_BOOT_SCENE } from '../game-engine/scenes-main/main.constants';
import { CenterMap } from '../game-engine/interfaces/shared';
import { isAssetLoadedToPhaserCache } from '../game-engine/utils/game.helper';

@Injectable({
  providedIn: 'root'
})

export class GameService {
  progressLoading: number;
  game: MyGame;
  openedMenu;
  isLowQuality = true; // !!localStorage.getItem('low-quality');

  debugUI;
  lastPlayerIslandId: number;
  centerOn: CenterMap;
  debugFolders: { [name: string]: any } = {};

  constructor(public assetsService: AssetsService,
              public buildingsService: BuildingsService,
              public dialogService: DialogService,
              public store: Store<AppState>,
              public router: Router,
              public deviceDetector: DeviceDetectorService,
              public globalService: GlobalService,
              public hoursPipe: ToHourPipe,
              public currencyService: CurrencyService,
              public playerService: PlayerService,
              public boardService: BoardService,
              public synchronizeTimeService: SynchronizeTimeService,
              public productionService: ProductionService,
              public productPlayerService: ProductPlayerService,
              public phaserGameService: PhaserGameService,
              public waterAttractionsService: WaterAttractionsService,
              public guiService: GuiService,
              public ngZone: NgZone,
  ) {
    this.isLowQuality = !!localStorage.getItem('low-quality');
  }

  addDebugFolder(folderName: string) {
    const existingFolder = this.getDebugFolder(folderName);
    if (existingFolder) {
      this.debugUI.removeFolder(existingFolder);
    }
    return this.debugFolders[folderName] = this.debugUI.addFolder(folderName);
  }

  getDebugFolder(folderName: string) {
    return this.debugFolders[folderName];
  }

  initGame(config: any, debug?: boolean) {
    debug = this.globalService.isDevDomain ? debug : false;
    // const datGUI = require('dat.gui');
    // this.debugUI = new datGUI.GUI({
    //   autoPlace: false,
    //   closed: false
    // });
    // const debugContainer = document.querySelector('app-game-debug .other');
    // debugContainer.appendChild(this.debugUI.domElement);

    this.game = new MyGame(config);

    if (debug) {
      this.game.debug = true;
      document.body.className = `${document.body.className} game-debug`;
    }

    return this.game;
  }

  prepareGameData(playerId: number, playerIslandId?: number) {
    this.fetchBoard(playerId, playerIslandId);
  }

  fetchBoard(playerId: number, playerIslandId: number) {
    this.store.dispatch(new Actions.FetchBoard({
      playerIslandId: playerIslandId,
      playerId: playerId
    }));
  }

  /**
   * Emit event on globalEmitter
   * @param event
   */
  emitGameEvent(event: GlobalEvent) {
    this.ngZone.run(() => {
      this.globalService.globalEvents.emit(event);
    });
  }

  /**
   * Load image and return promise with image key in game cache.
   * Promises are kept in object `key` property to avoid load two files with the same key name.
   * @param scene
   * @param assets[]
   * @returns {any}
   */
  loadGameImages(scene: MyScene, assets: string[]): Observable<any> {

    const promise = new Promise<void>((resolve, reject) => {
      if (assets.length > 0) {
        assets.forEach((assetPath: string) => {
          assetPath && scene.load.image(assetPath, assetPath);
        });
        const onLoaded = () => {
          resolve();
        };
        const onError = (err) => {
          reject(err);
        };
        scene.load.on('filecomplete', onLoaded, scene);
        scene.load.on('fileerror', onError, scene);
        scene.load.start();
      } else {
        resolve();
      }
    });
    return from(promise);
  }

  safeSetSpriteTexture(sprite: Phaser.GameObjects.Sprite | Phaser.GameObjects.Image, textureUrl: string) {
    return new Promise((resolve, reject) => {
      if (isAssetLoadedToPhaserCache(textureUrl, this.game.textures.getTextureKeys())) {
        sprite.setTexture(textureUrl);
        resolve(textureUrl);
      } else {
        sprite.visible = false;
        this.loadGameImages(sprite.scene as MyScene, [textureUrl])
          .subscribe(() => {
            try {
              sprite.setTexture(textureUrl);
              sprite.visible = true;
            } catch (error) {
              console.log(error);
              console.log(sprite);
            }
            resolve(textureUrl);
          });
      }
    });
  }

  /**
   * Get Phaser Cache JSON entries and search for a `textures` key.
   * If key exists it means that its texture atlas (can be multitexture if key container more than one element.
   * For now method handle only single texture atlas.
   * @return ImagesAtlas[]
   */
  getAtlasFromCache(): ImagesAtlas[] {
    return this.assetsService.getAtlasFromCache(this.game);
  }

  restartScene(playerId: number, scene: string = MAIN_BOOT_SCENE, playerIslandId?: number, customData?: any) {
    this.store.dispatch(new ResetBoard());
    this.prepareGameData(playerId, playerIslandId);
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: scene
    });
  }

  async getDiscoverPlaceType(playerIslandId: number) {
    // const result = await this.boardService.getWorld(this.playerService.getActivePlayerId()).toPromise();
    // let placeType = null;
    // result.forEach(regionData => {
    //   if (!placeType) {
    //     const targetIsland = regionData.islands.find(island => island.player_island_id === playerIslandId);
    //     placeType = getDiscoverPlaceType(targetIsland);
    //   }
    // });
    // return placeType;
  }

  setLowQuality(clear?: boolean) {
    if (clear) {
      localStorage.removeItem('low-quality');
    } else {
      localStorage.setItem('low-quality', 'on');
    }
    location.reload();
  }

  setDebugMode() {
    if (localStorage.getItem('debug-mode')) {
      this.clearDebugMode();
    } else {
      localStorage.setItem('debug-mode', 'on');
      location.reload();
    }
  }

  clearDebugMode() {
    localStorage.removeItem('debug-mode');
    location.reload();
  }

  /**
   * Prepare array of all combined currencies and products with balances and icons.
   * If requireToHave is false return undefined for have property, to allow separate not having (false) from not needed to have.
   * @param currencies
   * @param products
   * @param requireToHave
   */
  combineCurrenciesAndProducts(currencies = [], products = [], requireToHave = true) {
    const returnItems: SectionItem[] = [];
    const currenciesDefs = this.currencyService.getCurrencyDefinitions(currencies) as Currency[];
    currenciesDefs.forEach(currency => {
      const currencyBalance = this.currencyService.getCurrencyBalance(currency, this.playerService.player.currency_balances);
      returnItems.push({
        name: currencyBalance.name,
        icon: currencyBalance.iconUrlBig,
        amount: currencyBalance.amount,
        have: requireToHave ? currencyBalance.have : undefined
      });
    });

    products.forEach(productDef => {
      const product: ProductDetailsBalance = this.productionService.productsService.getProduct(productDef);
      returnItems.push({
        name: product.name,
        amount: product.amount,
        icon: product.icon_url,
        have: requireToHave ? product.have : undefined
      });
    });

    return returnItems;
  }

  pauseGame() {
    if (this.game && this.game.currentScene) {
      this.game.currentScene.scene.pause();
    }
  }

  resumeGame() {
    if (this.game && this.game.currentScene) {
      this.game.currentScene.scene.resume();
    }
  }
}
