import { Component, OnInit } from '@angular/core';
import { AbstractChestComponent } from '../../../../abstract/abstract-chest.component';

@Component({
  selector: 'app-chest',
  templateUrl: './chest.component.html',

})
export class ChestComponent extends AbstractChestComponent implements OnInit {
}
