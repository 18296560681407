export const MOCK_MISSION_DETAILS = {
  api: {
    points_prize: 1000,
    description: 'Wehikuł czasu profesora Kompetentnego tylko cześciowo spełnił swoje zadanie. Wylądowaliśmy w przeszłości, ale cofnęliśmy aż do 1980 roku - momentu, kiedy zalążki Komp Town dopiero zaczynały się pojawiać na mapie. Teraz dobre 25 lat dzieli nas od wydarzeń, kt&oacute;re rozegrały się w fabryce elektroniki w 2005 roku i kt&oacute;re finalnie doprowadzily do wielkiego blackoutu oraz upadku miasta. Musimy własnymi siłami odbudować miasto i ściągnąć do niego profesora Kompetentnego. To jedyny czowek, kt&oacute;ry może nas ocalić przed tragedią, kt&oacute;rej byliśmy świadkami w 2025 roku.',
    seen: true,
    currency_prizes: [],
    product_prizes: [],
    player_mission_id: 109023,
    product_prizes_count: 0,
    center_map_on_tile_id: null,
    center_map: null,
    slot: {mission_slot_id: 1, type: 1},
    icon: '1',
    title: 'Przejdź do roku 1985',
    rejectable: false,
    base_points_prize: 1000,
    star: {star_id: 2, star_name: 'Rok 1985', price_per_person: 100, cleaning_cost_multiplier: 1},
    created_at: '2021-02-03T09:41:45+01:00',
    expires_at: null,
    conditions: [{
      player_mission_condition_id: 165876,
      is_hand_over: false,
      can_hand_over: null,
      target_type: 'integer',
      center_map: null,
      name: 'Rozwiń populację do 700 mieszkańców',
      condition_type: 42,
      product: null,
      currency: null,
      target: 700,
      value: 130,
      completed_at: null
    }, {
      player_mission_condition_id: 165879,
      is_hand_over: false,
      can_hand_over: null,
      target_type: 'percentage',
      center_map: null,
      name: 'Rozwiń dostępność usług do 100%',
      condition_type: 46,
      product: null,
      currency: null,
      target: 1,
      value: 1.4615,
      completed_at: '2021-02-03T09:41:45+01:00'
    }, {
      player_mission_condition_id: 165882,
      is_hand_over: false,
      can_hand_over: null,
      target_type: 'integer',
      center_map: null,
      name: 'Wybuduj tartak i rozbuduj go do 3 poziomu',
      condition_type: 1,
      product: null,
      currency: null,
      target: 1,
      value: 0,
      completed_at: null
    }, {
      player_mission_condition_id: 165885,
      is_hand_over: false,
      can_hand_over: null,
      target_type: 'integer',
      center_map: null,
      name: 'Wybuduj i rozbuduj do 2 poziomu fabrykę mebli',
      condition_type: 1,
      product: null,
      currency: null,
      target: 1,
      value: 0,
      completed_at: null
    }, {
      player_mission_condition_id: 165888,
      is_hand_over: false,
      can_hand_over: null,
      target_type: 'integer',
      center_map: null,
      name: 'Wybuduj 10 domów jednorodzinncyh',
      condition_type: 1,
      product: null,
      currency: null,
      target: 10,
      value: 6,
      completed_at: null
    }]
  },
  ref: {
    mission: {
      points_prize: 1000,
      status: 'active',
      locked_till: null,
      conditions_count: 5,
      conditions_completed: 1,
      seen: true,
      currency_prizes: [],
      product_prizes: [],
      player_mission_id: 109023,
      product_prizes_count: 0,
      center_map_on_tile_id: null,
      center_map: null,
      mission_id: 1,
      slot: {mission_slot_id: 1, type: 1},
      icon: '1',
      title: 'Przejdź do roku 1985',
      base_points_prize: 1000,
      star: {star_id: 2, star_name: 'Rok 1985', price_per_person: 100, cleaning_cost_multiplier: 1},
      complete: false,
      iconDirectoryPath: 'character/basic'
    }
  }
};
