import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { clamp } from '../../../helpers/common.helpers';

@Pipe({
  name: 'owDuration2'
})
export class OWDuration2Pipe implements PipeTransform {

  transform(seconds: number, format: string = 'D[d] H[h] M[m] S[s]'): any {
    if (seconds < 0) {
      return null;
    }
    const duration = moment.duration(seconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format(format);
  }

}
