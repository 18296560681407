import { RiverRideGameConfig } from '../river-ride.interfaces';
import { Game } from 'phaser';
import { GameService } from '../../../game/services/game.service';

export class RiverRideGame extends Game {
  gameConfig: RiverRideGameConfig;
  gameService: GameService;
  editMode: boolean;

  constructor(gameConfig: RiverRideGameConfig) {
    super(gameConfig);
    this.gameConfig = gameConfig;
    this.gameService = gameConfig.gameService;
    this.editMode = gameConfig.editMode;
  }
}
