import { RRRiverSegmentObjectConfig } from '../river-ride.interfaces';
import { generateAnimationFramesList, getObjectDef } from '../river-ride.helpers';
import { RR_ATLAS_KEY } from '../river-ride.config';
import { RiverSegmentContainer } from './RiverSegment.class';
import { RiverRidePlayScene } from '../scenes/RiverRidePlay.scene';
import { RREditorScene } from '../scenes/RiverRideEditor.scene';

export class RRGameObject extends Phaser.GameObjects.Sprite {
  editorScene: RREditorScene;
  params: RRRiverSegmentObjectConfig;
  segmentInstance: RiverSegmentContainer;
  index: number;
  lastX: number;
  startX: number;
  startY: number;
  tweenRef: Phaser.Tweens.Tween;
  targetPosImage: Phaser.GameObjects.Sprite;
  polygon: Phaser.Geom.Polygon;
  collided = false;

  constructor(scene: RiverRidePlayScene, params: RRRiverSegmentObjectConfig, objectIndex: number, segmentInstance: RiverSegmentContainer) {

    super(scene, params.x, 0, params.key, params.frame);
    this.index = objectIndex;
    this.segmentInstance = segmentInstance;
    this.y = this.segmentYToWorldY(params.y);
    this.lastX = this.x;
    this.scene.add.existing(this);
    this.startX = this.x;
    this.startY = this.y;

    this.params = params;
    const objectDef = getObjectDef(params.objectId);

    this.scene.anims.create({
      key: `${params.objectId}-anim`,
      frames: generateAnimationFramesList(RR_ATLAS_KEY, objectDef.icon, objectDef.animationFrames as number),
      delay: 0,
      frameRate: 10,
      duration: 5000,
      repeat: -1,
    });
    this.scene.anims.play(`${objectDef.objectId}-anim`, this);
    this.createTween();

    if (scene.editMode) {
      this.enableEditMode();
    }

    const {x, y} = this;
    this.polygon = new Phaser.Geom.Polygon(`${x - 40} ${y + 40} ${x - 40} ${y - 40} ${x + 40} ${y - 40} ${x + 40} ${y + 40}` as any);
  }

  destroyObject(niceDestroy?: boolean) {
    if (this.editorScene) {
      this.editorScene.selectedObject$.next(null);
      if (this.targetPosImage) {
        this.targetPosImage.destroy();
      }
    }

    if (niceDestroy) {
      this.scene.add.tween({
        targets: this,
        scale: 3,
        y: this.y - 100,
        alpha: 0,
        duration: 500,
        ease: Phaser.Math.Easing.Quadratic.InOut,
        onComplete: () => {
          this.destroy();
        }
      });
    } else {
      this.destroy();
    }
  }

  createTween() {
    this.startX = this.x;
    this.startY = this.y;
    if (this.params.tweenDuration) {
      this.tweenRef = this.scene.add.tween({
        targets: this,
        x: this.params.targetX || this.x,
        y: this.segmentYToWorldY(this.params.targetY) || this.y,
        duration: 1000 * this.params.tweenDuration,
        repeat: -1,
        yoyo: true,
        onYoyo: () => this.lastX = this.x,
        onRepeat: () => this.lastX = this.x,
        onUpdate: () => {
          this.setScale(this.lastX < this.x ? 1 : -1, 1);
        },
      });
    }
  }

  enableEditMode() {
    this.editorScene = this.scene as RREditorScene;

    this.setInteractive({draggable: true});
    this.on('pointerdown', () => {
      this.editorScene.selectedObject$.next(this);
    });

    this.on('dragstart', (pointer, dragX, dragY) => {
      this.setTint(0xff0000);
    });
    this.on('drag', (pointer, dragX, dragY) => {
      this.x = dragX;
      this.y = dragY;

      this.params.x = this.x;
      this.params.y = this.worldYToSegmentY(this.y);
    });
    this.on('dragend', (pointer, dragX, dragY, dropped) => {
      this.clearTint();
      this.editorScene.selectedObject$.next(this);
    });

    if (this.params.targetX || this.params.targetY) {
      const targetX = this.params.targetX || this.x - 100;
      const targetY = this.params.targetY ? this.segmentYToWorldY(this.params.targetY) : this.y;
      const targetPosImage = this.scene.add.sprite(targetX, targetY, RR_ATLAS_KEY, `${this.params.icon}-01.png`);
      this.segmentInstance.add(targetPosImage);
      targetPosImage.setDepth(10);
      targetPosImage.setAlpha(0.7);

      targetPosImage.setInteractive({draggable: true});
      targetPosImage.on('pointerdown', () => {
        this.editorScene.selectedObject$.next(this);
      });

      targetPosImage.on('dragstart', (pointer, dragX, dragY) => {
        this.setTint(0xff0000);
        targetPosImage.setTint(0xff0000);
      });
      targetPosImage.on('drag', (pointer, dragX, dragY) => {
        targetPosImage.x = dragX;
        targetPosImage.y = dragY;
        this.params.targetX = targetPosImage.x;
        this.params.targetY = this.worldYToSegmentY(targetPosImage.y);
      });
      targetPosImage.on('dragend', (pointer, dragX, dragY, dropped) => {
        this.clearTint();
        this.editorScene.selectedObject$.next(this);
      });
      this.targetPosImage = targetPosImage;
    }
  }

  segmentYToWorldY(y: number) {
    if (!Boolean(y)) {
      return null;
    }
    const segmentHeight = this.segmentInstance.segmentImage.displayHeight;
    const cameraHeight = this.scene.cameras.main.height;
    return -(segmentHeight - y) + cameraHeight;
  }

  worldYToSegmentY(y: number) {
    const segmentHeight = this.segmentInstance.segmentImage.displayHeight;
    const cameraHeight = this.scene.cameras.main.height;
    return segmentHeight + y - cameraHeight;
  }

  stopTween() {
    if (this.tweenRef.isPlaying()) {
      this.setPosition(this.startX, this.startY);
      this.scene.tweens.getTweensOf(this).forEach(tween => tween.remove());
    }
  }
}
