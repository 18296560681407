<!--<button mat-button mat-raised-button (click)="pauseGame()"> Zatrzymaj / Wznów</button>-->
<div style="margin-top: 50px; padding: 20px;">
    <div class="info">
        <p>Strzałka w górę: płyń</p>
        <p>Spacja: stop</p>
        <br><br>
        <mat-card>
            <mat-card-content>
                <textarea readonly rows="10" cols="40" [value]="riverRideGameplayConfig| json"></textarea>
            </mat-card-content>
            <mat-card-actions>
                <button (click)="copyToClipboard()" mat-button mat-raised-button>Kopiuj do schowka</button>
                <button mat-button color="warn" mat-raised-button (click)="resetConfig()"> Reset</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <mat-card>
        <mat-card-title>Podstawowe parametry</mat-card-title>
        <mat-card-content>
            <mat-form-field>
                <input type="number" matInput placeholder="Prędkość minimalna"
                       [(ngModel)]="riverRideGameplayConfig.minSpeed">
            </mat-form-field>
            <mat-form-field>
                <input type="number" matInput placeholder="Prędkość maksymalna"
                       [(ngModel)]="riverRideGameplayConfig.maxSpeed">
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-title>Wybrany obiekt</mat-card-title>
        <mat-card-content>
            <div style="color: red" *ngIf="!selectedObject">
                Wybierz obiekt na planszy
            </div>
            <div *ngIf="selectedObject">
                <button mat-button mat-raised-button (click)="stopTween()"> Zatrzymaj animacje</button>
                <br><br>
                <button mat-button mat-raised-button (click)="playTweens()"> Wznów animacje</button>
                <br><br>
                <button mat-button color="warn" mat-raised-button (click)="removeObject()"> Usuń obiekt</button>

                <br><br>
                <button mat-button mat-raised-button (click)="saveAndRestart()"> Zapisz i restartuj</button>

                <p>Czas animacji: {{selectedObject.params.tweenDuration}}</p>

                <mat-slider
                        size="large"
                        thumbLabel
                        min="0"
                        max="5"
                        [(ngModel)]="selectedObject.params.tweenDuration"
                        step="0.5"
                        [value]="selectedObject.params.tweenDuration || 0"
                ></mat-slider>
            </div>
        </mat-card-content>
    </mat-card>

    <br><br><br>

    <mat-card>
        <mat-card-title>Dodaj obiekt</mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill">
                <mat-label>Wybierz obiekt</mat-label>
                <mat-select [(ngModel)]="objectToAdd">
                    <mat-option *ngFor="let go of gameObjectDefs" [value]="go">
                        {{go.objectId}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Wybierz segment docelowy</mat-label>
                <mat-select [(ngModel)]="segmentToAddObject">
                    <mat-option
                            *ngFor="let segment of riverRideGameplayConfig.riverSegments"
                            [value]="segment"
                    >
                        {{segment.imagePath.replace('minigames/riverride/', '')}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button (click)="addObject()">Dodaj i restartuj</button>
        </mat-card-content>
    </mat-card>

    <br><br>

    <mat-card>
        <mat-card-title>Kolejnośc segmentów (tylko w edytorze)</mat-card-title>
        <mat-card-content>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div style="padding: 10px; background: #fff; border: solid 1px #ccc;"
                     *ngFor="let segment of sceneRef.riverMap; let index = index"
                     cdkDrag>{{index + 1}} - {{segment.imagePath.replace('minigames/riverride/', '')}}</div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button mat-raised-button (click)="saveAndRestart()">Zapisz i restartuj</button>
        </mat-card-actions>
    </mat-card>
</div>
