import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',

})
export class LoadingComponent {
  @Input() classNames: string[] = [];
  @Input() size = 'small';
  @Input() position = '';
  @Input() @HostBinding('class.is-loading') showAlways: boolean;
  @Input() customLogicShow = true;
}
