import { IsoSprite } from '../classes/core/IsoSprite';
import { isoToScreen, screenToIso } from '../utils/utils';
import { Traffic } from './traffic.class';

const CAR_DURATION_FACTOR = 1.3;

export class Vehicle extends IsoSprite {
  trafficRef: Traffic;

  start() {
    const shape = this.scene.add.image(4402, 4107, this.texture.key, 'image.png').setVisible(false);
    this.setMask(shape.createBitmapMask());
    this.mask.invertAlpha = true;
    this.backToRC();
  }

  resetPos() {
    this.isoX = 23000;
    this.update();
  }

  // goToRC() {
  //   this.setDepth(1);
  //   this.setFrame('car_right.png');
  //   this.scene.add.tween({
  //     targets: [this],
  //     isoX: 12300,
  //     duration: 10000 / CAR_DURATION_FACTOR,
  //     onUpdate: () => this.update(),
  //     onComplete: () => this.slowDownToRC()
  //   });
  // }

  // slowDownToRC() {
  //   this.scene.add.tween({
  //     targets: [this],
  //     isoX: 12624,
  //     duration: 3000 / CAR_DURATION_FACTOR,
  //     ease: Phaser.Math.Easing.Sine.Out,
  //     onUpdate: () => this.update(),
  //     onComplete: () => this.checkCanGo(this.speedUpFromRC.bind(this))
  //   });
  // }

  checkCanGo(callback) {
    if (this.trafficRef.train.fromSouth && this.trafficRef.train.isoY > this.isoY) {
      setTimeout(this.checkCanGo.bind(this, callback), 2000);
      return;
    }

    if (!this.trafficRef.train.fromSouth && this.trafficRef.train.isoY < this.isoY) {
      setTimeout(this.checkCanGo.bind(this, callback), 2000);
      return;
    }
    callback();
  }

  // speedUpFromRC() {
  //   this.scene.add.tween({
  //     targets: [this],
  //     isoX: 13200,
  //     duration: 5000 / CAR_DURATION_FACTOR,
  //     onUpdate: () => this.update(),
  //     ease: Phaser.Math.Easing.Sine.In,
  //     onComplete: () => this.resetPos()
  //   });
  // }

  // goToEnd() {
  //   this.scene.add.tween({
  //     targets: [this],
  //     isoX: 23000,
  //     duration: 27000 / CAR_DURATION_FACTOR,
  //     onUpdate: () => this.update(),
  //     onComplete: () => {
  //       this.resetPos();
  //       this.backToRC();
  //     }
  //   });
  // }


  backToRC() {
    this.setDepth(3);
    this.setFrame('car_left.png');
    this.scene.add.tween({
      targets: [this],
      isoX: 13440,
      duration: 27000 / CAR_DURATION_FACTOR,
      onUpdate: () => this.update(),
      onComplete: () => this.slowDownBackToRC()
    });
  }

  slowDownBackToRC() {
    this.scene.add.tween({
      targets: [this],
      isoX: 13042,
      duration: 3000 / CAR_DURATION_FACTOR,
      ease: Phaser.Math.Easing.Sine.Out,
      onUpdate: () => this.update(),
      onComplete: () => this.checkCanGo(this.speedUpFromBackRC.bind(this))
    });
  }

  speedUpFromBackRC() {
    this.scene.add.tween({
      targets: [this],
      isoX: 11506,
      duration: 5000 / CAR_DURATION_FACTOR,
      onUpdate: () => this.update(),
      ease: Phaser.Math.Easing.Sine.In,
      onComplete: () => {
        this.resetPos();
        this.backToRC();
      }
    });
  }

  // goToStart() {
  //   this.setFrame('car_left.png');
  //   this.scene.add.tween({
  //     targets: [this],
  //     isoX: 9393,
  //     duration: 10000 / CAR_DURATION_FACTOR,
  //     onUpdate: () => this.update(),
  //     onComplete: () => this.goToRC()
  //   });
  // }

  update() {
    const screenPos = isoToScreen(this.isoX, this.isoY);

    this.x = screenPos.x;
    this.y = screenPos.y;
  }
}
