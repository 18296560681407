import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { PlayerService } from '../../../../../player/providers/player.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { PlayerSelectors } from '../../../../../../store/player';
import { Player } from '../../../../../player/interfaces/player';
import { AuthService } from '../../../../../auth/providers/auth.service';
import { GuiService } from '../../../../services/gui.service';
import { LoginActions } from '../../../../../../store/auth/login';
import { LINKS } from '../../../../../../core/consts/core/links.const';
import { FEATURE_FLAG } from '../../../../../../core/config/core/feature-flags.config';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_HUD } from '../../const/core/event-dialogs/event-names.const';
import { UserService } from '../../../../../user/providers/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { UserActions } from '../../../../../../store/user';

@Directive()
export abstract class AbstractPlayerDetailsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractPlayerDetailsComponent>;
  @OwInject(FormBuilder) fb: FormBuilder;
  player: Player;
  isOthersView: boolean;
  LINKS = LINKS;
  FEATURE_FLAG = FEATURE_FLAG;
  formPush: FormGroup;

  subs = {
    player: null,
  };

  ngOnInit(): void {
    this.subscribePlayer();
    this.clearForm();
  }

  clearForm() {
    this.formPush = this.fb.group({
      pushEnabled: this.fb.control(this.userService.me.push_enabled),
    });
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer)
      )
      .subscribe((player) => {
        this.player = player;
      });
  }

  toggleOthersView() {
    this.isOthersView = !this.isOthersView;
  }

  openSettings() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_HUD.USER_SETTINGS,
    });
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }

  close() {
    this.matDialogRef.close();
  }

  changePush() {
    const pushEnabled = this.formPush.value.pushEnabled;

    this.userService.changePush(pushEnabled)
      .subscribe(() => {
        this.store.dispatch(new UserActions.FetchUser());
      }, () => {
        this.clearForm();
      });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
