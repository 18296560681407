import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../core/providers/api.service';
import { PlayerService } from '../../../../player/providers/player.service';
import { DialogService } from '../../../../shared/providers/dialog.service';
import { MinigameSummary } from '../../interfaces/stats.interface';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-minigame-summary',
  templateUrl: './minigame-summary.component.html',
})
export class MinigameSummaryComponent implements OnInit, AfterViewInit {
  summary: MinigameSummary;

  constructor(
    private playerService: PlayerService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private matDialogRef: MatDialogRef<MinigameSummaryComponent>,
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    this.summary = (this.playerService.player.minigame2 || this.playerService.player.minigame) as MinigameSummary;
  }

  close() {
    this.apiService.post(`minigame/${this.summary.mini_game_id}/close-summary`)
      .subscribe(() => this.matDialogRef.close());
  }

  ngAfterViewInit(): void {
    console.log(this.summary);
  }
}
