import { Component } from '@angular/core';
import { AbstractExpUpComponent } from '../../../abstract/abstract-exp-up.component';

@Component({
  selector: 'm-ui-exp-up',
  templateUrl: './ui-exp-up.component.html',

})
export class MUiExpUpComponent extends AbstractExpUpComponent {
}
