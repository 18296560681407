import { Component } from '@angular/core';
import { AbstractReauthorizeComponent } from '../../../abstract/abstract-reauthorize.component';

@Component({
  selector: 'app-reauthorize',
  templateUrl: './reauthorize.component.html',

})
export class ReauthorizeComponent extends AbstractReauthorizeComponent {

}
