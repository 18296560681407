import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
import { GuiService } from '../../modules/game/services/gui.service';


@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {
  public constructor(
    public injector: Injector,
    public guiService: GuiService,
  ) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!(activatedRouteSnapshot.data && activatedRouteSnapshot.data.syncGuards)) {
      return of(false);
    }

    this.guiService.isSplashShow.next(true);
    return from(activatedRouteSnapshot.data.syncGuards)
      .pipe(
        concatMap((value) => {
          const guard = this.injector.get(value);
          const result = guard.canActivate(activatedRouteSnapshot, routerStateSnapshot);

          if (result instanceof Observable) {
            return result;
          } else if (result instanceof Promise) {
            return from(result);
          } else {
            return of(result);
          }
        }),
        first((x) => {
          return x === false || x instanceof UrlTree;
        }, true),
        tap((a) => {
          this.guiService.isSplashShow.next(false);
        })
      );
  }
}
