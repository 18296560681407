import { Directive, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Player } from '../../../../../player/interfaces/player';
import * as R from 'ramda';
import { PlayerParameterBalanceHud } from '../../interfaces/core/player-parameter-balance-hud.interface';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { PrimarySelectors } from '../../../../../../store/primary';
import { ParameterDefinition, PlayerParameterBalance } from '../../../../interfaces/parameters';
import { PlayerSelectors } from '../../../../../../store/player';
import { GlobalService } from '../../../../../../core/providers/global.service';
import { EVENTS } from '../../../../../../core/consts/core/events';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';

@Directive()
@Injectable()
export abstract class AbstractHudParametersComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  player: Player;
  parameterDefinitions: ParameterDefinition[] = [];
  parameterBalances: PlayerParameterBalanceHud[] = [];
  visible: boolean = true;

  subs = {
    parameters: null,
    player: null,
    global: null,
  };

  PLACEHOLDERS = [
    {
      placeholder: '%population_current',
      getValue: (parameter) => Math.floor(this.player.population_current * parameter.population_multiplier),
    },
    {
      placeholder: '%population_delta',
      getValue: () => this.player.population_delta,
    },
    {
      placeholder: '%population_max',
      getValue: () => this.player.population_max,
    },
    {
      placeholder: '%current_percentage_value',
    },
    {
      placeholder: '%current_income_value',
    },
    {
      placeholder: '%current_outcome_value',
    },
  ];

  ngOnInit() {
    this.subscribeGlobalEvents();
    this.subscribePlayer();
  }

  setHudParameters() {
    this.parameterBalances = [];

    this.parameterDefinitions.forEach((parameterDefinition) => {
      this.player.parameter_balances.forEach((parameterBalance) => {
        const parameter: PlayerParameterBalanceHud = <PlayerParameterBalanceHud>R.clone(parameterBalance);
        if (parameterDefinition.parameter_id === parameter.parameter_id) {

          switch (parameter.type) {
            case 'population_delta':
              parameter.displayHud = parameter.balance;
              break;

            default:
              parameter.displayHud = Math.floor(parameter.percentage * 100);
              if (parameter.displayHud > 199) {
                parameter.displayHud = 199;
              }
          }

          this.setTooltip(parameter);
          this.parameterBalances.push(parameter);
        }
      });
    });

    this.filterActiveParameter();
    this.filterHiddenParameter();
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe((player) => {
        this.player = player;
        this.afterPlayerSubscribe();
      });
  }

  subscribeParameters() {
    this.subs.parameters = this.store
      .pipe(
        select(PrimarySelectors.selectParameters),
        take(1),
      )
      .subscribe((parameterDefinitions) => {
        this.parameterDefinitions = parameterDefinitions;
        this.sortParameters();
        this.setHudParameters();
      });
  }

  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe((event) => {
      switch (event.name) {
        case EVENTS.GUI.HUD_PARAMETERS.SHOW:
          this.visible = true;
          break;

        case EVENTS.GUI.HUD_PARAMETERS.HIDE:
          this.visible = false;
          break;
      }
    });
  }

  sortParameters() {
    this.parameterDefinitions = R.sortBy(R.prop('position'), this.parameterDefinitions);
  }

  filterActiveParameter() {
    this.parameterBalances = this.parameterBalances.filter((parameter) => parameter.is_parameter_active);
  }

  filterHiddenParameter() {
    this.parameterBalances = this.parameterBalances.filter(parameter => parameter.position >= 0);
  }

  setTooltip(parameter: PlayerParameterBalanceHud) {
    if (parameter.tooltip) {
      Object.keys(this.PLACEHOLDERS).forEach((key) => {
        const placeholderObject: { placeholder: string, getValue: (parameter?: PlayerParameterBalance) => any } = this.PLACEHOLDERS[key];

        if (placeholderObject.placeholder === '%current_percentage_value') {
          placeholderObject.getValue = () => `${parameter.displayHud}%`;
        }

        if (placeholderObject.placeholder === '%current_income_value') {
          placeholderObject.getValue = () => `${parameter.income}`;
        }

        if (placeholderObject.placeholder === '%current_outcome_value') {
          placeholderObject.getValue = () => `${parameter.outcome}`;
        }

        parameter.tooltip = parameter.tooltip.replace(placeholderObject.placeholder, placeholderObject.getValue(parameter));
      });
    }
  }

  afterPlayerSubscribe() {
    this.subscribeParameters();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
