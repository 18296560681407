<div
  class="global-dialog ow-dialog no-transparent"
  [style.height]="isMobile ? (mobileSizes.height + 'px') : 'auto'"
>
  <!-- DIALOG EXIT -->
<!--  <m-ui-close-button-->
<!--    [otherClass]="'minigame-gui'"-->
<!--    [preventDefault]="true"-->
<!--    (click)="close()"-->
<!--  ></m-ui-close-button>-->

  <div
    class="minigame-container"
    [style.height]="isMobile ? (mobileSizes.height + 'px') : 'auto'"
  >
    <ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>

    <div class="game-container">
      <app-minigame-river-ride
        [parameters]="riverRideParams"
      ></app-minigame-river-ride>
    </div>
  </div>
</div>

<ng-template #riverRideGuiResult>
  <div class="background-container results-container">
    <div class="ow-dialog window-custom-minigame">
      <div class="logo results loading-opacity"></div>

      <div class="summary">
        <div class="result">
          <div class="icon">
            <img [src]="'cartons.png' | asset: 'minigames/riverride/ui'" />
          </div>
          <span class="sum">{{ summary.score1 }} x {{ summary.multiplier1 }} = {{ summary.points1 }} pkt</span>
        </div>

        <div class="result">
          <div class="icon">
            <img [src]="'distance.png' | asset: 'minigames/riverride/ui'" />
          </div>
          <span class="sum">{{ summary.score2 }} x {{ summary.multiplier2 }} = {{ summary.points2 }} pkt</span>
        </div>
      </div>

      <div class="separator"></div>

      <div class="total">
        Razem: {{ (summary.points1 + summary.points2) }} pkt
      </div>

      <button
        class="base primary"
        (click)="close()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>

<ng-template #riverRideGuiPause>
  <div class="background-container pause-container">
    <div class="ow-dialog window-custom-minigame">
      <div class="logo pause"></div>

      <div class="title">Pauza aktywna</div>

      <button
        class="base primary"
        (click)="resumeGame()"
      >
        KONTYNUUJ GRĘ
      </button>
    </div>
  </div>
</ng-template>

<ng-template #riverRideGuiLoad>
  <div class="background-container load-container">
    <div class="ow-dialog window-custom-minigame">
      <loading></loading>
      <div class="logo load loading-opacity"></div>

      <div class="loading">
        <i class="fa fa-refresh glyphicon-spin"></i>
      </div>
      <div class="title loading-opacity">Ładowanie: {{ gameState.progress }}%</div>

      <button
        [disabled]="!gameState.isLoaded"
        class="base primary loading-opacity"
        (click)="data.unfinishedStatus ? continueUnfinished() : start()"
      >
        {{ gameState.isLoaded ? (data.unfinishedStatus ? 'KONTYNUUJ' : 'GRAJ') : 'PROSZĘ CZEKAĆ...' }}
      </button>
    </div>
  </div>
</ng-template>
