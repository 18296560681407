import { Directive, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../store/state';
import { select, Store } from '@ngrx/store';
import { SocketActions, SocketSelectors } from '../../../store/socket';
import { GlobalService } from '../../../core/providers/global.service';
import { SOCKET_STATUS } from '../../../core/consts/core/socket.constants';
import { unsubscribeObject } from '../../../core/utility/unsubscribe-array';
import { Router } from '@angular/router';
import { TokenExchangeService } from '../../../core/providers/token-exchange.service';
import { DialogService } from '../../shared/providers/dialog.service';

@Directive()
@Injectable()
export abstract class AbstractSocketConnectionErrorComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(Router) router: Router;
  @OwInject(TokenExchangeService) tokenExchangeService: TokenExchangeService;
  @OwInject(DialogService) dialogService: DialogService;

  subs = {
    socket: null,
  };

  ngOnInit() {
    this.dialogService.closeAll();
    this.tokenExchangeService.exchangeTimeoutStop();
    this.subscribeSocketStore();
  }

  subscribeSocketStore() {
    this.subs.socket = this.store
      .pipe(
        select(SocketSelectors.selectSocketStatus),
      )
      .subscribe((status) => {
        switch (status) {
          case SOCKET_STATUS.DISCONNECTED:
            this.store.dispatch(new SocketActions.SocketClearStatus());
            break;

          case SOCKET_STATUS.AUTHENTICATED:
            this.router.navigate(['/']);
            this.tokenExchangeService.exchangeTimeoutStart();
            break;
        }
      });
  }

  reconnect() {
    this.store.dispatch(new SocketActions.SocketConnect());
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
