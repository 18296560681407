import { RiverRidePlayScene } from './RiverRidePlay.scene';
import { RRGameObject } from '../classes/RRGameObject.class';
import { Subject } from 'rxjs';
import { provideSceneConfig } from '../river-ride.helpers';
import { RREvents, RRGameModes, RRRiverSegment } from '../river-ride.interfaces';
import { LS_SEGMENT_ORDER_KEY } from '../rrconfig-editor/rrconfig-editor/rrconfig-editor.component';
import { RiverRideGame } from '../classes/RiverRideGame.class';

export class RREditorScene extends RiverRidePlayScene {
  private currentSegmentText: Phaser.GameObjects.Text;
  selectedObject$ = new Subject<RRGameObject>();

  init(data: any) {
    this.sceneConfig = provideSceneConfig();
    this.isPlaying = true;
    (this.game as RiverRideGame).gameConfig.gameParams = {
      gameMode: RRGameModes.PREMIUM
    };
  }

  generateRiverMap() {
    // temporary cloned array - will be random river segments generator
    this.riverMap = [...this.sceneConfig.riverSegments];
    const riverMapOrder = this.loadRiverMapConfig();
    if (riverMapOrder) {
      this.riverMap = riverMapOrder.map(segment => this.sceneConfig.riverSegments.find(_segment => _segment.id === segment.id));
    }
  }

  create() {
    this.generateRiverMap();
    this.initializePlayerBoat();
    this.initializeRiver();
    this.keyboardKeysInit();
    this.touchInputInit();
    this.createUI();
    this.initializeSounds();

    this.currentSegmentText = this.add.text(20, 20, ``);
    this.currentSegmentText.setDepth(100);
  }

  initializeRiver(fromSegment: number = 0) {
    this.currentSegment = this.createRiverSegmentObject(fromSegment);
    this.nextSegment = this.createRiverSegmentObject(fromSegment + 1);
  }

  saveRiverMapConfig() {
    localStorage.setItem(LS_SEGMENT_ORDER_KEY, JSON.stringify(this.riverMap));
  }

  loadRiverMapConfig() {
    return JSON.parse(localStorage.getItem(LS_SEGMENT_ORDER_KEY)) as RRRiverSegment[];
  }

  saveCurrentState(delta: number, timeout: number) {
    return null;
  }

  isPremiumGame(): boolean {
    return true;
  }

  update(time: number, delta: number) {
    super.update(time, delta);
    console.log(this.currentSegment.y)

    this.currentSegmentText.setText(`${this.currentSegmentIndex + 1} + ${this.currentSegment.segmentData.imagePath.replace('minigames/riverride/', '')}`);

    // if (this.keyShift.isDown) {
    //   this.cameras.main.scrollY -= 10;
    // }
    // if (this.keyCtrl.isDown) {
    //   this.cameras.main.scrollY += 10;
    // }
  }

  startWithSegment() {
    // this.
  }

  // startGame() {
  // }

  emitRREvent(name: RREvents, value?: any) {

  }

}
