import { Component, OnDestroy, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as R from 'ramda';
import { AbstractPlayerChooseComponent } from '../../../../abstract/abstract-player-choose.component';
import { UserSelectors } from '../../../../../../store/user';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'auth-player-choose',
  templateUrl: './player-choose.component.html',

})
export class PlayerChooseComponent extends AbstractPlayerChooseComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.guiService.isSplashShow.next(true);
    this.checkReload();
    this.subs.user = this.store
      .pipe(
        select(UserSelectors.selectUser),
        take(1)
      )
      .subscribe((user) => {
        this.enabledPlayers = R.clone(user.enabled_players);
        const sortedEnabledPlayers = R.sortWith([R.descend(R.prop('id'))], this.enabledPlayers);
        this.changePlayer(sortedEnabledPlayers[0]);
        this.setDefaultPlayer();
      });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
