import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@angular/common';
import { ApiCoreGameRanksService } from '../../../../api/custom/services/api-game-ranks.service';
import { RankGame } from '../../../../interfaces/custom/rank-game.interface';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from '../../../../consts/custom/event-dialogs/event-names.const';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { OwDate } from '../../../../../../../../core/utility/ow-date.class';
import { ApiBusinessRanksService } from '../../../../api/custom/services/api-business-ranks.service';
import { OwPaginationDate } from '../../../../../../../../core/utility/ow-pagination.class';
import { RANK_TYPE } from '../../../../consts/custom/rank-type.const';
import { RankType } from '../../../../interfaces/custom/rank-type.interface';

@Component({
  selector: 'app-ranking-list',
  templateUrl: './ranking-list.component.html',
})
export class RankingListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { location: number; };

  RANK_TYPE = RANK_TYPE;
  rankGameList: RankGame[] = [];
  activeRankType: RankType;
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate = new OwDate();
  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  RANKS_TYPES = {
    GAMES: {
      label: 'Świat gry',
      order: 1,
      type: RANK_TYPE.GAME,
      show: true,
    },
  };

  ngOnInit() {
    this.clearDate();
    this.changeActiveRankType(this.RANKS_TYPES.GAMES);
  }

  clearDate() {
    this.owDate = new OwDate();
  }

  clearRanks() {
    this.rankGameList = [];
  }

  changeActiveRankType(activeRankType: RankType) {
    this.activeRankType = activeRankType;
    this.getRanks();
  }

  getRanks() {
    this.clearRanks();

    switch (this.activeRankType) {
      case this.RANKS_TYPES.GAMES:
        this.gameRanks();
        break;
    }
  }

  gameRanks() {
    this.apiCoreGameRanksService.getGameRanks({location: this.data.location})
      .subscribe((resp: RankGame[]) => {
        this.rankGameList = resp;
      }, (errResp) => {
        this.rankGameList = [];
      });
  }

  openRankDetails(rank: RankGame) {
    switch (this.activeRankType.type) {
      case RANK_TYPE.GAME:
        this.openRankDetailsGame(rank);
        break;
    }
  }

  openRankDetailsGame(rank: RankGame) {
    if (!rank.last_calculated_at) {
      this.dialogService.openAlert({
        description: 'Ranking nie jest jeszcze gotowy. Spróbuj ponownie jutro!'
      });
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: rank.rank_edition_id,
        }
      }
    });
  }

  openRankDescription(rank) {
    this.dialogService.openAlert({
      title: rank.name,
      description: rank.description,
    });
  }

  keyAscOrder = (a: KeyValue<number, RankType>, b: KeyValue<number, RankType>): number => {
    return b.value.order > a.value.order ? -1 : (a.value.order > b.value.order ? 1 : 0);
  };

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate
    });
  }

  next() {
    this.owDate.add({month: 1});
    this.setPagination();
    this.getRanks();
  }

  prev() {
    this.owDate.subtract({month: 1});
    this.setPagination();
    this.getRanks();
  }
}
