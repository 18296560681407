import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../consts/core/event-dialogs/event-names.const';

export const MOCK_WAREHOUSE_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
    name: 'Warehouse',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS,
    name: 'Warehouse Details',
    available: true
  },
];
