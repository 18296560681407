import { Directive, Injectable, NgZone } from '@angular/core';
import { Message } from '../../interfaces/core/message.interface';
import { MessageFilter } from '../../interfaces/core/message-filter.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractMessageService } from './abstract-message.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiMessageService } from '../../api/core/services/api-message.service';
import { GetMessagesRequest } from '../../api/core/interfaces/get-messages-request.interface';
import { MESSAGE_TYPE_ID } from '../../consts/core/message-type.const';
import { MessageFilterValue } from '../../interfaces/core/message-filter-value.interface';
import { handleMessageDetailsType } from '../../helpers/core/message.helper';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MessageEventFromDetails } from '../../interfaces/core/message-callback-from-details.interface';
import { MESSAGE_EVENTS } from '../../consts/core/message-events.const';
import { NavigationUi } from '../../../shared-ui/interfaces/navigation-ui.interface';
import { MessageOpenDetailsData } from '../../interfaces/core/message-open-details-data.interface';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { UtilityActions } from '../../../../../../store/utility';
import { AppState } from '../../../../../../store/state';
import { Store } from '@ngrx/store';
import { translate } from '../../../../../../core/helpers/translate.helper';

@Directive()
@Injectable()
export abstract class AbstractMessagesComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMessagesComponent>;
  @OwInject(NgZone) ngZone: NgZone;
  abstract messagesService: AbstractMessageService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MAT_DIALOG_DATA) data: {
    type: number;
    title: string;
  };
  // END INJECTIONS
  type;
  title;

  messages: Message[];
  navigation: NavigationUi = {
    currentPage: 1,
    maxPage: null,
  };
  limit = 5;
  pagination = {
    allowNext: false,
    allowPrev: false,
  };
  trashView = false;

  filterSender: MessageFilter = {
    selectedId: 0,
    selectedName: '',
    name: 'Nadawca',
    values: [],
  };

  filterCategory: MessageFilter = {
    selectedId: 0,
    selectedName: '',
    name: 'Kategoria',
    values: [],
  };

  afterConstructor() {
    this.type = this.data.type || MESSAGE_TYPE_ID.NORMAL;
    this.title = this.data.title || translate('messages.messages');
  }

  dispatchClearNewMessagesToRead() {
    setTimeout(() => {
      this.store.dispatch(new UtilityActions.ClearNewMessagesToRead());
    });
  }

  getMessages(page?: number) {
    if (page) {
      this.navigation.currentPage = page;
    }

    const config: GetMessagesRequest = {
      page: this.navigation.currentPage,
      limit: this.limit,
      deleted: this.trashView,
      type: this.type,
    };

    if (this.filterSender.selectedId) {
      config['sender_group_id'] = this.filterSender.selectedId;
    }
    if (this.filterCategory.selectedId) {
      config['category_id'] = this.filterCategory.selectedId;
    }

    this.apiMessageService.getMessages(config)
      .subscribe((messagesResp: any) => {
        this.navigation.maxPage = messagesResp.headers.get('X-Pages-Count');
        this.handleMessages(messagesResp.body);
      });
  }

  handleMessages(messages: Message[]) {
    this.messages = messages;
  }

  readMessage(message: any) {
    this.apiMessageService.getMessageDetails({playerMessageId: message.player_message_id})
      .subscribe(messageDetails => {
          const messageType = handleMessageDetailsType(messageDetails);
          const eventDialog = this.messagesService.handleMessageDetailsComponent(messageType);
          const messageOpenDetailsData = this.getMessageOpenDetailsData(messageDetails);

          this.eventEmitterDialogsService.emitter.emit({
            name: eventDialog,
            config: {
              data: messageOpenDetailsData,
              disableClose: true,
            },
            callback: (event: MessageEventFromDetails) => {
              this.afterMessageDetailsClose(message, event);
            }
          });
        });
  }

  getMessageOpenDetailsData(messageDetails): MessageOpenDetailsData {
    return {
      message: messageDetails,
      fromList: true,
    };
  }

  afterMessageDetailsClose(message, event: MessageEventFromDetails) {
    if (!event) {
      return;
    }

    switch (event.action) {
      case MESSAGE_EVENTS.MOVED_TO_TRASH:
        this.checkPageAfterMoveToTrash();
        break;

      case MESSAGE_EVENTS.MARKED_READ:
        message.read = true;
        break;
    }
  }

  clearFilter(filter: MessageFilter, skipMessageRefresh?: boolean) {
    filter.selectedId = 0;
    !skipMessageRefresh && this.getMessages();
  }

  clearAllFilters() {
    this.clearFilter(this.filterCategory, true);
    this.clearFilter(this.filterSender, true);
  }

  setFilterValue(filter: MessageFilter, value: MessageFilterValue) {
    this.setFilterValueCoreLogic(filter, value);
  }

  setFilterValueCoreLogic(filter: MessageFilter, value: MessageFilterValue) {
    const isToggled = filter.selectedId === value.id;
    this.messages = [];
    this.navigation.currentPage = 1;
    if (isToggled) {
      filter.selectedId = 0;
    } else {
      filter.selectedId = value.id;
    }
    filter.selectedName = value.name;
    this.trashView = false;
    this.getMessages();
  }

  toggleTrash() {
    this.toggleTrashCoreLogic();
  }

  toggleTrashCoreLogic() {
    this.clearAllFilters();
    this.trashView = !this.trashView;
    this.messages = null;
    this.navigation.currentPage = 1;
    this.getMessages();
  }

  moveToTrash(messageId) {
    this.apiMessageService.deleteMessage({messageId})
      .subscribe(() => {
        this.checkPageAfterMoveToTrash();
      });
  }

  checkPageAfterMoveToTrash() {
    if (this.messages.length === 1 && this.navigation.currentPage > 1) {
      this.navigation.currentPage--;
    }
    this.getMessages(this.navigation.currentPage);
  }

  close() {
    this.matDialogRef.close();
  }
}
