import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PlayerService } from '../../modules/player/providers/player.service';
import { Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Player } from '../../modules/player/interfaces/player';
import { ActionTypes, FetchPlayer, FetchPlayerSuccess, NullAction, TryUpdatePlayer, UpdatePlayer, UpdateUnreadMessages } from './actions';
import { AppState } from '../state';
import { UtilityActions, UtilitySelectors } from '../utility';
import { UtilityPlayer } from '../utility/interfaces/utility-player';
import { CurrencyService } from '../../core/providers/currency.service';
import * as R from 'ramda';
import { CurrencyBalanceDetails } from '../../core/interfaces/currency';
import { ParametersService } from '../../core/providers/parameters.service';
import { UpdateUserCurrenciesBalances, UpdateUserDiscount } from '../user/actions';
import { SynchronizeTimeService } from '../../core/providers/synchronize-time.service';
import { customHandleUpdatePlayer } from './custom/helpers/handle-update-player.helper';
import { omitErrorResponseHelper } from '../../core/helpers/omit-error-response.helper';
import { customHandleHasMissionsToCollect } from './custom/helpers/handle-has-missions-to-collect.helper';

@Injectable()
export class PlayerEffects {
  isCheckPlayerPointsBalance: boolean;

  constructor(
    private actions$: Actions,
    private playerService: PlayerService,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
    private parametersService: ParametersService,
    private synchronizeTimeService: SynchronizeTimeService
  ) {
  }

  @Effect()
  $fetchPlayer: Observable<void | Action> = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_PLAYER),
      withLatestFrom(
        this.store.pipe(select(UtilitySelectors.selectUtilityPlayer))
      ),
      /**
       * If payload is empty, get active player id
       */
      map(([action, state]: [FetchPlayer, UtilityPlayer]) => {
        if (!action.payload) {
          return {
            payload: {
              playerId: state.activePlayerId
            }
          };
        } else {
          return action;
        }
      }),
      mergeMap((action: FetchPlayer) => {
        return this.fetchPlayer(action.payload);
      })
    );


  fetchPlayer(payload: { playerId: number }) {
    return this.playerService.getPlayer(payload.playerId)
      .pipe(
        concatMap((playerData: Player) => {
          playerData.currency_balances = <CurrencyBalanceDetails[]>this.currencyService.getCurrencyDefinitions(playerData.currency_balances);
          this.playerService.player = playerData;

          return [
            new FetchPlayerSuccess(),
            new UtilityActions.UpdateActivePlayerId({playerId: playerData.id}),
            new TryUpdatePlayer(playerData)
          ];
        }),
        tap(() => {
          this.playerService.playerChange();
        }),
        catchError((error: any) => {
          return of(new NullAction(omitErrorResponseHelper(error)));
        })
      );
  }

  @Effect()
  tryUpdatePlayer$: Observable<Action> = this.actions$
    .pipe(
      ofType(ActionTypes.TRY_UPDATE_PLAYER),
      withLatestFrom(
        this.store.pipe(select(UtilitySelectors.selectUtilityPlayer))
      ),
      tap(([action, state]: [TryUpdatePlayer, UtilityPlayer]) => {
        this.handleHasMissionsToCollect({action, state});
      }),
      map(([action, state]: [TryUpdatePlayer, UtilityPlayer]) => {
        return this.handleUpdatePlayer(R.clone(action.payload));
      }),
      tap((player) => {
        if (player.user_currency_balances) {
          this.store.dispatch(new UpdateUserCurrenciesBalances(player.user_currency_balances));
        }

        if (player.current_discount_value !== undefined) {
          this.store.dispatch(new UpdateUserDiscount(player.current_discount_value));
        }

        // if unread_messages is not falsy and there's some messages -> update it
        if (player.unread_messages != null && Object.keys(player?.unread_messages).length) {
          this.store.dispatch(new UpdateUnreadMessages(player.unread_messages));
        }
      }),
      map((player: Player) => {
        this.synchronizeTimeService.setTimeOffset(player.real_time);
        return new UpdatePlayer(player);
      })
    );

  handleHasMissionsToCollect({action, state}: { action: TryUpdatePlayer, state: UtilityPlayer }) {
    const coreValueHasMissionToCollect =
      state.hasMissionsToCollect ||
      action.payload.missions_to_collect.filter(mission => mission.mission_type === 1).length > 0;

    this.store.dispatch(
      new UtilityActions.SetHasMissionsToCollect(
        customHandleHasMissionsToCollect({action, state, coreValueHasMissionToCollect}) ||
        (!this.isCheckPlayerPointsBalance && action.payload.points_balance === 0)
      )
    );

    this.isCheckPlayerPointsBalance = true;
  }

  handleUpdatePlayer(player: Player) {
    customHandleUpdatePlayer(player);
    this.parametersService.setParametersBalances(player.parameter_balances);
    player.parameter_balances = this.parametersService.playerParametersBalances$.value;
    player.currency_balances = <CurrencyBalanceDetails[]>this.currencyService.getCurrencyDefinitions(player.currency_balances);
    this.playerService.player = player;
    window['storePlayer'] = player;
    return player;
  }

  @Effect({dispatch: false})
  $updateUnreadMessages: Observable<any> = this.actions$
    .pipe(
      ofType(ActionTypes.UPDATE_UNREAD_MESSAGES),
      tap((action) => {
        this.store.dispatch(
          new UtilityActions.SetHasNewMessagesToRead(action.payload[1] > 0)
        );
      })
    );
}
