import { Component } from '@angular/core';
import { AbstractGroupCountComponent } from '../../../abstract/abstract-group-count.component';

@Component({
  selector: 'm-ui-group-count',
  templateUrl: './ui-group-count.component.html',

})
export class MUiGroupCountComponent extends AbstractGroupCountComponent {
}
