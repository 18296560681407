import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractStockBase } from '../../../../abstract/abstract-stock-base.component';
import { STOCK_VIEW } from '../../../consts/stock-view.const';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { map } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { PlayerSelectors } from '../../../../../../../../store/player';
import { ProductPlayerService } from '../../../../../../../player/providers/product-player.service';
import { BLOCK_PRODUCT_STORAGE } from '../../../consts/block-product-storage.const';

@Component({
  selector: 'm-ui-product',
  templateUrl: '../stock.html',

})
export class MUiProductComponent extends AbstractStockBase implements OnInit, OnDestroy, OnChanges {
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  defaultStockView = STOCK_VIEW.A;
  BLOCK_PRODUCT_STORAGE = BLOCK_PRODUCT_STORAGE;

  subs = {
    productBalances: null
  };

  ngOnInit() {
    this.setDefaultStockView();

    if (this.observable) {
      this.subscribeProductsBalance();
    } else {
      this.parseItem();
    }
  }

  ngOnChanges() {
    this.parseItem();
  }

  parseItem() {
    this.item = this.productPlayerService.getProduct(this.item);
    this.checkBlockedProductsList();
    super.parseItem();
  }

  subscribeProductsBalance() {
    this.subs.productBalances = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        map((player) => {
          return player.product_balances;
        })
      )
      .subscribe(() => {
        this.parseItem();
      });
  }

  checkBlockedProductsList() {
    const isBlockStorage = BLOCK_PRODUCT_STORAGE.some(id => id === this.item.product_id);
    if (isBlockStorage) {
      this.lockOpenStorage = true;
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
