import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AssetsService } from './providers/assets.service';
import { DictionaryService } from './providers/dictionary.service';
import { ApiService } from './providers/api.service';
import { AuthGuard } from './guards/auth.guard';
import { MyMaterialModule } from '../modules/my-material/my-material.module';
import { AuthModule } from '../modules/auth/auth.module';
import { UserModule } from '../modules/user/user.module';
import { GlobalService } from './providers/global.service';
import { SynchronizeTimeService } from './providers/synchronize-time.service';
import { SharedModule } from '../modules/shared/shared.module';
import { PrimaryService } from './providers/primary.service';
import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';
import localePLExtra from '@angular/common/locales/extra/pl';
import { EffectsModule } from '@ngrx/effects';
import { SocketEffects } from '../store/socket';
import { ParametersService } from './providers/parameters.service';
import { MyTranslateService } from './providers/my-translate.service';
import { LoadingService } from './providers/loading.service';
import { BuildVersionService } from './providers/build-version.service';
import { SharedUiMobileModule } from '../modules/game/game-ui/shared-ui/mobile/shared-ui-mobile.module';
import { AlertComponent } from './basic/core/dialogs/alert/alert.component';
import { AlertConfirmComponent } from './basic/core/dialogs/alert-confirm/alert-confirm.component';
import { CUSTOM_DECLARATIONS } from './consts/custom/components.const';
import { CUSTOM_PROVIDERS } from './consts/custom/providers.const';
import { CUSTOM_IMPORTS } from './consts/custom/imports.const';
import { AlertImageComponent } from './basic/core/dialogs/alert-image/alert-image.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { InjectorVariable } from './utility/injector-instance.helper';

registerLocaleData(localePL, 'pl', localePLExtra);

declare global {
  export interface Console {
    ow(...message: any): void;
  }
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        MyMaterialModule,
        AuthModule,
        UserModule,
        SharedModule,
        EffectsModule.forFeature([
            SocketEffects
        ]),
        SharedUiMobileModule,
        PinchZoomModule,
        ...CUSTOM_IMPORTS,
    ],
    declarations: [
        AlertComponent,
        AlertConfirmComponent,
        AlertImageComponent,
        ...CUSTOM_DECLARATIONS,
    ],
    providers: [
        AssetsService,
        AuthGuard,
        ApiService,
        DictionaryService,
        GlobalService,
        SynchronizeTimeService,
        PrimaryService,
        ParametersService,
        {provide: LOCALE_ID, useValue: 'pl'},
        MyTranslateService,
        LoadingService,
        BuildVersionService,
        ...CUSTOM_PROVIDERS,
    ]
})
export class CoreModule {
  constructor(private injector: Injector) {
    InjectorVariable.instance = this.injector;
  }
}
