import { ErrorHandler, Injectable, isDevMode } from '@angular/core';

@Injectable()
export class AngularErrorHandler extends ErrorHandler {
  handleError(error: any) {
    if (window['owError'] && !isDevMode()) {
      window['owError'].pushError(error);
    }
    super.handleError(error);
  }
}
