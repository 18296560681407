import { Component } from '@angular/core';
import { AbstractBuildingThumbnailComponent } from '../../../../abstract/core/abstract-building-thumbnail.component';

@Component({
  selector: 'm-building-thumbnail',
  templateUrl: './building-thumbnail.component.html',

})
export class MBuildingThumbnailComponent extends AbstractBuildingThumbnailComponent {
}
