import { Directive, Injectable, ViewChild } from '@angular/core';
import { BuildingConfig } from '../../../../game-engine/interfaces/building-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuildingsService } from '../../../../services/buildings.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { selectPlayer } from '../../../../../../store/player/selectors';
import { Player } from '../../../../../player/interfaces/player';
import { CurrencyService } from '../../../../../../core/providers/currency.service';
import { CURRENCIES } from '../../../../../../core/consts/core/currencies';
import * as R from 'ramda';
import { CurrencyBalance } from '../../../../../../core/interfaces/currency';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { BuildingBuildData } from '../../interfaces/core/dialogs/building-build-data.interface';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_BUILDINGS } from '../../consts/core/event-dialogs/event-names.const';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter, take } from 'rxjs/operators';
import { BoardTileState } from '../../../../../../store/game/interfaces/board-tile.state';
import { translate } from '../../../../../../core/helpers/translate.helper';

@Directive()
@Injectable()
export abstract class AbstractBuildingsListComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingsListComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  @ViewChild('sliderBuildings') sliderBuildings: SwiperComponent;

  sliderActiveIndex: number;
  STOCK_VIEW = STOCK_VIEW;
  swiperConfig = {
    slidesPerView: 3,
    centeredSlides: true,
  };

  tile: BoardTileState;
  buildings: BuildingConfig[];
  player: Player;
  subs = {
    player: null,
    board: null,
  };

  ORDERS = {
    DEFAULT: 1,
    PLAYER_LEVEL: 2,
    GROUP_LIMIT: 4,
  };

  getBuildingsByPlayerTileId() {
    this.clearSliderActiveIndex();
    this.buildingsService.getBuildingsByPlayerTileId(this.data.playerTileId)
      .subscribe((buildings: BuildingConfig[]) => {
        this.buildings = buildings.map((building) => {
          building = this.setOrderGroup(building);
          building = this.getCurrenciesBalance(building);
          building = this.setBuildPricesDisplay(building);
          return building;
        });

        this.setOrders();
        this.setSecondItem();
        this.checkEmpty();
      });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(
      select(selectPlayer),
    ).subscribe(player => {
      this.player = player;
    });
  }

  openBuildingDetails(buildingId: number, index: number) {
    if (this.sliderActiveIndex !== index) {
      this.sliderBuildings.directiveRef.setIndex(index);
      return;
    }

    const buildingBuildData: BuildingBuildData = {
      buildingId,
      playerTileId: this.data.playerTileId,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD,
      config: {
        data: buildingBuildData,
      }
    });
  }

  getCurrenciesBalance(building: BuildingConfig): BuildingConfig {
    building.build_currency_prices = building.build_currency_prices.map((currency) => {
      return this.currencyService.getCurrencyBalance(currency, this.player.currency_balances);
    });

    return building;
  }

  setBuildPricesDisplay(building: BuildingConfig): BuildingConfig {
    const orders = [CURRENCIES.ONE, CURRENCIES.TWO];
    const buildPrices = <CurrencyBalance[]>R.clone(building.build_currency_prices);

    orders.reverse();
    building.buildPricesDisplay = buildPrices.sort((a, b) => {
      return orders.indexOf(b.key) - orders.indexOf(a.key);
    }).slice(0, 2);

    return building;
  }

  prevSlide() {
    this.sliderBuildings.directiveRef.prevSlide();
  }

  nextSlide() {
    this.sliderBuildings.directiveRef.nextSlide();
  }

  setSecondItem() {
    if (this.buildings.length >= 3) {
      this.sliderActiveIndex = 1;
    }
  }

  clearSliderActiveIndex() {
    this.sliderActiveIndex = 0;
  }

  setOrderGroup(building: BuildingConfig): BuildingConfig {
    building.orderInList = this.ORDERS.DEFAULT;

    if (building.required_level && building.required_level > this.player.level) {
      building.orderInList = this.ORDERS.PLAYER_LEVEL;
    }

    if (building.group_count >= building.group_limit) {
      building.orderInList = this.ORDERS.GROUP_LIMIT;
    }

    return building;
  }

  setOrders() {
    const sortedBuildings = [];

    Object.keys(this.ORDERS).forEach((key) => {
      const order = this.ORDERS[key];

      const tmpBuildings = this.buildings
        .filter((building) => building.orderInList === order)
        .sort((a, b) => a.name.localeCompare(b.name));


      switch (order) {
        case this.ORDERS.PLAYER_LEVEL:
          tmpBuildings
            .sort((a, b) => a.required_level > b.required_level ? 1 : -1);
          break;
      }

      sortedBuildings.push(...tmpBuildings);
    });

    this.buildings = sortedBuildings;
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1),
      )
      .subscribe((tile) => {
        this.tile = tile;
        this.getBuildingsByPlayerTileId();
      });
  }

  checkEmpty() {
    if (this.buildings.length === 0) {
      this.dialogService.openAlert({
        description: this.tile.empty_buildings_list_message || translate('building-list.empty-buildings-list-message')
      }, () => {
        this.matDialogRef.close();
      });
    }
  }
}
