import { BoardService } from '../../modules/game/services/board.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ActionTypes, FetchBoard, FetchBoardSuccess, NullAction } from './actions';
import { BoardTileState } from './interfaces/board-tile.state';
import { BuildingsService } from '../../modules/game/services/buildings.service';
import { GameService } from '../../modules/game/services/game.service';
import { GameActions } from './index';
import { AppState } from '../state';
import { omitErrorResponseHelper } from '../../core/helpers/omit-error-response.helper';


@Injectable()
export class GameEffects {

  constructor(
    private actions$: Actions,
    private boardService: BoardService,
    private buildingsService: BuildingsService,
    private gameService: GameService,
    private store: Store<AppState>,
  ) {
  }

  @Effect()
  $fetchBoard: Observable<void | Action> = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_BOARD),
      mergeMap((action: FetchBoard) => {
        return this.fetchBoard(action.payload.playerId, action.payload.playerIslandId);
      })
    );

  fetchBoard(playerId: number, playerIslandId?: number): Observable<FetchBoardSuccess | NullAction> {
    return this.boardService.getBoard(playerId, playerIslandId)
      .pipe(
        tap((boardData: BoardTileState[]) => {
          if (!playerIslandId && boardData && boardData.length > 0) {
            playerIslandId = boardData[0]['player_island_id'];
          }
          this.gameService.lastPlayerIslandId = playerIslandId;

          this.store.dispatch(
            new GameActions.SetCurrentPlayerIslandId(playerIslandId)
          );
        }),
        map((boardData: BoardTileState[]) => {
          return boardData ? new FetchBoardSuccess(boardData) : null;
        }),
        catchError((error: any) => {
          return of(new NullAction(<any>omitErrorResponseHelper(error)));
        })
      );
  }
}

