<div class="background-img">
  <loading></loading>

  <ng-container [ngSwitch]="template">
    <ng-container *ngSwitchCase="TEMPLATES.LOGIN" [ngTemplateOutlet]="loginTpl"></ng-container>
    <ng-container *ngSwitchCase="TEMPLATES.COOKIE" [ngTemplateOutlet]="cookieTpl"></ng-container>
  </ng-container>
</div>

<ng-template #loginTpl>
  <div class="container">
    <div class="logo-budimex"></div>

    <div class="description text-center">
        Wejdź do gry z okazji tygodnia BHP w Budimex, zobacz jakie atrakcje przygotowaliśmy i rywalizuj z innymi pracownikami.<br/><br/>
        Rozgrywka potrwa około 10 minut i będzie wymagała Twojego skupienia. Zapoznaj się dokładnie z instrukcjami, które będą pojawiały się w trakcie gry. Masz tylko jedno podejście.<br/><br/>
      Życzymy dobrej zabawy i powodzenia!<br/><br/>
      W razie problemów z logowaniem lub działaniem platformy prosimy o kontakt pod adresem: biuro.bhp@budimex.pl
    </div>

    <div [style]="'display: flex; flex-direction: column; width: 100%'">
      <button
        class="base primary login"
        (click)="checkCookieLogin()"
      >
        Logowanie przez Budimex
      </button>

      <div class="text-error text-center">
        {{ error }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cookieTpl>
  <div class="container">
    <div class="description text-center">
      <h3 class="mikado text-bold">Cenimy prywatność użytkowników</h3>

Używamy wyłącznie niezbędnych plików cookie, które umożliwiają działanie podstawowych funkcji, takich jak zapewnienie odpowiedniej funkcjonalności naszej witryny internetowej, czy bezpieczne logowanie się. Akceptacja wymaganych cookies jest niezbędna, aby wziąć udział w rozgrywce tj. do korzystania z witryny.
    </div>

    <div [style]="'display: flex; justify-content: space-evenly; width: 100%'">
      <button
        class="base primary login"
        (click)="setAllowedCookie()"
        [style]="'min-width: auto'"
      >
        &nbsp; Akceptuj &nbsp;
      </button>

      <!-- <button
        class="base primary login"
        (click)="setDisallowedCookieInSession()"
        [style]="'min-width: auto'"
      >
        &nbsp;&nbsp; Odrzuć &nbsp;&nbsp;
      </button> -->
    </div>
  </div>
</ng-template>
