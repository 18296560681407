import { Component, OnInit } from '@angular/core';
import { AbstractHudComponent } from '../../../abstract/core/abstract-hud.component';
import { GAME_EVENTS } from '../../../../../constants';
import { WORLD_BOOT_SCENE } from '../../../../../game-engine/scenes-world/world.constants';
import { MAIN_BOOT_SCENE } from '../../../../../game-engine/scenes-main/main.constants';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',

})
export class HudComponent extends AbstractHudComponent implements OnInit {

  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    this.setDynamicsComponents();
  }

  startWorldScene() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: WORLD_BOOT_SCENE
    });
  }

  startMainScene() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: MAIN_BOOT_SCENE
    });
  }
}

