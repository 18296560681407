import { MessageDetailsComicsComponent } from '../../base/custom/dialogs/message-details-comics/message-details-comics.component';

export const CUSTOM_IMPORTS = [];

export const CUSTOM_DECLARATIONS = [
  MessageDetailsComicsComponent,
];

export const CUSTOM_ENTRY_COMPONENTS = [];

export const CUSTOM_EVENT_DIALOGS = {};
