import { Component, OnInit } from '@angular/core';
import { BuildVersionService } from './core/providers/build-version.service';
import { RegisterEventDialogsService } from './core/services/core/register-event-dialogs.service';
import { RoutingService } from './core/services/core/routing.service';
import { ReauthorizeService } from './modules/auth/services/core/reauthorize.service';
import { AndroidService } from './core/providers/android.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent implements OnInit {
  constructor(
    private buildVersionService: BuildVersionService,
    private registerEventDialogsService: RegisterEventDialogsService,
    private routingService: RoutingService,
    private reauthorizeService: ReauthorizeService,
    private androidService: AndroidService,
  ) {
  }

  ngOnInit() {
  }
}
