import { Directive, Injectable, ViewChild } from '@angular/core';
import { BuildingDetailsConfig } from '../../../../game-engine/interfaces/building-details-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { BuildingsService } from '../../../../services/buildings.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter, take } from 'rxjs/operators';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { PlayerBuilding } from '../../../../game-engine/interfaces/player-building.config';
import { ProductionService } from '../../../../services/production.service';
import { upgradeDifferenceParameters } from '../../helpers/core/upgrade.helpers';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { BUILDING_TYPES } from '../../consts/core/buidling-types.const';
import { translate } from '../../../../../../core/helpers/translate.helper';
import * as moment from 'moment';

@Directive()
@Injectable()
export abstract class AbstractBuildingSpecialComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingSpecialComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(Store) store: Store<AppState>;

  @ViewChild('sliderUpgrade') sliderUpgrade;

  BUILDING_TYPES = BUILDING_TYPES;
  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: PlayerBuilding;
  upgradeBuildingDetails: BuildingDetailsConfig;
  unlockedBuildings: any[];
  differentGroup: boolean;
  requirementsStatus: { valid: boolean, requirementsList: any[] };

  subs = {
    board: null,
  };

  diffProductionsSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  diffParametersSlider = {
    config: {
      itemPerPage: 6
    },
    pages: [],
    items: []
  };

  sliderActiveIndex = 0;
  maxSlides = 0;

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1),
      )
      .subscribe((tile) => {
        this.buildingDetails = tile.player_building;
        if (this.buildingDetails.upgrade_building_id) {
          this.getBuildingDetails(this.buildingDetails.upgrade_building_id);
        }
      });
  }

  getBuildingDetails(buildingId: number) {
    this.buildingsService.getBuildingDetails(buildingId)
      .subscribe((buildingDetails) => {
        this.upgradeBuildingDetails = buildingDetails;
        this.setDifferentGroup();
        this.getUnlockedBuildings();
        this.getUpgradeDifferenceParameters();
        this.checkSlideLength();
      });
  }

  setDifferentGroup() {
    this.differentGroup = this.buildingDetails.group !== this.upgradeBuildingDetails.group;
  }

  getUnlockedBuildings() {
    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.NORMAL:
        this.buildingsService.getUnlockedBuildings(this.upgradeBuildingDetails.building_id)
          .subscribe((buildings: any[]) => {
            this.unlockedBuildings = buildings;
            if (this.unlockedBuildings.length === 0) {
              this.unlockedBuildings = null;
            }
            this.checkSlideLength();
          }, (errResp) => {
            if (errResp.status === 404) {
              errResp.defaultHandler.unsubscribe();
            }
          });
        break;
    }
  }

  getUpgradeDifferenceParameters() {
    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];

    this.diffParametersSlider.items = upgradeDifferenceParameters(this.buildingDetails.parameters, this.upgradeBuildingDetails.parameters);

    this.diffParametersSlider = generateEachPages(this.diffParametersSlider);
  }

  upgrade({fastUpgrade}: { fastUpgrade?: boolean } = {}) {
    this.buildingsService.upgrade(this.buildingDetails.player_building_id, fastUpgrade)
      .subscribe(() => {
        this.afterUpgradeRequest();
      });
  }

  afterUpgradeRequest() {
    this.matDialogRef.close(true);
  }

  openFastSpecialUpgradeConfirm() {
    let description = translate('fast-action.description');
    if (this.upgradeBuildingDetails.fast_building_time_in_seconds) {
      const time = moment.utc(this.upgradeBuildingDetails.fast_building_time_in_seconds * 1000).format('HH[h] mm[m] ss[s]');
      const extendDescription = translate('building-special.fast-action-extend-description', [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm({
      title: translate('fast-action.title'),
      description,
      costs: {
        separatorTitle: translate('fast-action.separator-title'),
        currencies: this.upgradeBuildingDetails.fast_build_currency_prices,
        products: this.upgradeBuildingDetails.fast_build_product_prices,
      }
    }, (confirm) => {
      if (confirm) {
        this.upgrade({fastUpgrade: true});
      }
    });
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  prevSlide() {
    this.sliderUpgrade.directiveRef.prevSlide();
  }

  nextSlide() {
    this.sliderUpgrade.directiveRef.nextSlide();
  }

  checkSlideLength() {
    setTimeout(() => {
      if (this.sliderUpgrade) {
        this.maxSlides = this.sliderUpgrade.swiperSlides.nativeElement.childElementCount;
      } else {
        this.maxSlides = 0;
      }

      this.checkSlideLength();
    }, 500);
  }
}
