<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{ 'transaction-history.title' | myTranslate }}
    </div>
  </div>

  <ng-container *ngIf="items">
    <!-- ITEMS -->
    <div class="table loading-opacity" *ngIf="items">
      <div class="tr" *ngFor="let item of items">
        <div class="td">
          {{ item.transaction_at | date:'dd-MM-y'}}
        </div>
        <div
          class="td td-reason"
        >
          <div
            [innerText]="item.reason"
            owEllipsis
          ></div>
        </div>
        <div class="td td-value">
          {{ item.value > 0 ? '+' : '' }}<!--
        -->{{ item.value | NumberWithSpaces }}
        </div>
        <div class="td">
          <m-ui-currency
            [item]="data.currency"
            [lack]="false"
            [stockView]="STOCK_VIEW.H"
            [hideValue]="true"
          ></m-ui-currency>
        </div>
      </div>
    </div>

    <!-- NO HISTORY -->
    <div class="no-item" *ngIf="items.length == 0">
      {{ 'transaction-history.no-transaction-history' | myTranslate }}
    </div>

    <!-- BOTTOM BAR -->
    <m-ui-navigation
      *ngIf="navigation.maxPage > 1"
      [navigation]="navigation"
      (pageChanged)="getCurrencyTransaction($event)"
    ></m-ui-navigation>
  </ng-container>
</div>


