export const MOCK_WAREHOUSE_CATEGORY = 'mock-warehouse-category';
export const MOCK_WAREHOUSE_CATEGORY_PRODUCT = 'mock-warehouse-category-product';
export const MOCK_WAREHOUSE_STORE_PRODUCT = 'mock-warehouse-store-product';

export const MOCK_WAREHOUSE = {
  api: {
    storages: [
      {
        storage_id: 1, capacity: 350, name: 'Magazyn produktów'
      },
      {
        storage_id: 2, capacity: 0, name: 'Magazyn specjalny'
      }
    ],
    categories: [
      [
        {
          storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'
        },
        {
          storage_id: 1,
          trading_currency_id: null,
          id: 3,
          name: 'Kadry',
          description: 'Kadry',
          icon: 'kadry'
        },
        {
          storage_id: 1,
          trading_currency_id: null,
          id: 5, name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        }
      ],
      []
    ],
    productsPlayer: [
      {
        selling_price: 15,
        branch_selling_price: 15,
        buying_price: 45,
        product_id: 1,
        category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
        name: 'Drewno',
        description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.',
        icon: 'wood',
        reference_currency_price: 45,
        required_level: 1,
        buyable: false,
        sellable: false,
        buyable_from_level: null,
        sellable_from_level: null,
        original_reference_currency_price: 45,
        required_invention_for_trading: null
      },
      {
        selling_price: 120,
        branch_selling_price: 120,
        buying_price: 360,
        product_id: 15,
        category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        reference_currency_price: 360,
        required_level: 4,
        buyable: false,
        sellable: false,
        buyable_from_level: null,
        sellable_from_level: null,
        original_reference_currency_price: 360,
        required_invention_for_trading: null
      },
      {
        selling_price: 160,
        branch_selling_price: 160,
        buying_price: 480,
        product_id: 14,
        category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
        name: 'Meble kuchenne',
        description: 'Podstawowy element wyposażenia kuchni, dzięki kt&oacute;remu rodziny mogą wsp&oacute;lnie przygotowywać i jeść posiłki. Miejsce produkcji: Fabryka Mebli lvl 2 i wyżej',
        icon: 'furniture_kitchen',
        reference_currency_price: 480,
        required_level: 5,
        buyable: false,
        sellable: false,
        buyable_from_level: null,
        sellable_from_level: null,
        original_reference_currency_price: 480,
        required_invention_for_trading: null
      }
    ],
    storePrimaryProducts: [
      {
        product_id: 1,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Drewnoooo',
        description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.',
        icon: 'wood',
        visible_from_level: 1
      },
      {
        product_id: 2,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Drzewooooo',
        description: 'Dzięki drzewom będziesz m&oacute;gł stworzyć meble dla mieszkańc&oacute;w Komp Town.',
        icon: 'tree',
        visible_from_level: 17
      },
      {
        product_id: 3,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Krzew',
        description: 'Krzew urozmaici wnętrza dom&oacute;w Twoich mieszkańc&oacute;w i wpłynie na ich relaks. Zapewni także zr&oacute;wnoważony rozw&oacute;j miasta. Miejsce produkcji: Centrum Ogrodnicze.',
        icon: 'bush',
        visible_from_level: 16
      },
      {
        product_id: 4,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Kwiat w doniczce',
        description: 'Kwiat w doniczce stanie się ozdobą niejednego parapetu. Miejsce produkcji: Centrum Ogrodnicze.',
        icon: 'flower_pot',
        visible_from_level: 20
      },
      {
        product_id: 5,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Matryca',
        description: 'Nowoczesna, OLED-owa matryca ekranu, kt&oacute;ra jest niezbędna do produkcji asortymentu RTV. Miejsce tworzenia: Fabryka podzespoł&oacute;w.',
        icon: 'matrix',
        visible_from_level: 18
      },
      {
        product_id: 6,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Płyta główna',
        description: 'Płyta gł&oacute;wna to serce komputer&oacute;w i laptop&oacute;w. Bez niej nie ma mowy o produkcji tych sprzęt&oacute;w. Miejsce opracowania: Fabryka podzespoł&oacute;w.',
        icon: 'motherboard',
        visible_from_level: 18
      },
      {
        product_id: 7,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Podzespoły elektroniczne',
        description: 'Podzespoły to elementy niezbędne do produkcji sprzętu elektronicznego. Miejsce produkcji : Fabryka podzepoł&oacute;w 2.0.',
        icon: 'electronic',
        visible_from_level: 22
      },
      {
        product_id: 8,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Procesor',
        description: 'Procesor jest m&oacute;zgiem każdego komputera, laptopa i smarfonu. Stw&oacute;rz go, aby m&oacute;c je produkować. Miejsce opracowania: Laboratorium.',
        icon: 'processor',
        visible_from_level: 17
      },
      {
        product_id: 9,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Laptop',
        description: 'Nowoczesny przenośny komputer, kt&oacute;ry umożliwia mobilną pracę i zabawę. Miejsce pozyskania: Port i fabryka elektroniki.',
        icon: 'laptop',
        visible_from_level: 18
      },
      {
        product_id: 10,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Komputer stacjonarny',
        description: 'Desktopowy komputer, kt&oacute;rego wysokie parametry umożliwiają nowoczesną rozrywkę oraz wykonywanie pracy biurowej. Miejsce produkcji:Fabryka Elektroniki.',
        icon: 'computer',
        visible_from_level: 99
      },
      {
        product_id: 11,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Smartfon',
        description: 'Smartfon to urządzenie o mocy zbliżonej do laptopa, kt&oacute;re oferuje nie mniej możliwości, a do tego jest bardzo poręczny i kompaktowy. Miejsce produkcji: Fabryka elektroniki lvl 3.',
        icon: 'smartphone',
        visible_from_level: 25
      },
      {
        product_id: 12,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Super TV',
        description: 'Telewizor umożliwia oglądanie kanał&oacute;w o r&oacute;żnej tematyce oraz serwis&oacute;w streamingowych na dużym ekranie. Miejsce produkcji: Fabryka super elektroniki.',
        icon: 'tv',
        visible_from_level: 25
      },
      {
        product_id: 13,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Meble biurowe',
        description: 'Solidnie wykonane biurowe meble, kt&oacute;re zapewnią odpowiednie warunki komfortu podczas pracy. Miejsce produkcji: Fabryka Mebli lvl 3 i wyżej',
        icon: 'furniture_office',
        visible_from_level: 8
      },
      {
        product_id: 14,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Meble kuchenne',
        description: 'Podstawowy element wyposażenia kuchni, dzięki kt&oacute;remu rodziny mogą wsp&oacute;lnie przygotowywać i jeść posiłki. Miejsce produkcji: Fabryka Mebli lvl 2 i wyżej',
        icon: 'furniture_kitchen',
        visible_from_level: 3
      },
      {
        product_id: 15,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        visible_from_level: 3
      },
      {
        product_id: 16,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Pierwszy komputer',
        description: 'Pierwszy komputer to sprzęt, kt&oacute;ry otworzył przed użytkownikami nowe możliwości w takich dziedzinach jak praca czy rozrywka. Miejsce pozyskania : port.',
        icon: 'computer_old',
        visible_from_level: 10
      },
      {
        product_id: 17,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Super procesor ',
        description: 'Doskonały wynalazek naukowc&oacute;w z Kompbridge, kt&oacute;ry zmienił oblicze świata elektroniki. Sprzęt wyposażony w super procesor jest wydajny, ekologiczny i gwarantujący najlepsze osiągi. Miejsce produkcji: fabryka podzespoł&oacute;w lvl 4',
        icon: 'processor_pro',
        visible_from_level: 34
      },
      {
        product_id: 18,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Monitor ',
        description: 'Monitor to Twoje okno na wirtualny świat, gdy zdecydujesz się na tradycyjny PC. Miejsce produkcji: Fabryka elektroniki.',
        icon: 'monitor',
        visible_from_level: 32
      },
      {
        product_id: 19,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Myszka',
        description: 'Ultraprecyzyjna, ergonomiczna mysz komputerowa, będzie przedłużeniem Twojej ręki w świecie wirtualnym. Miejsce produkcji: Fabryka elektroniki oraz port od lvl2.',
        icon: 'mouse',
        visible_from_level: 10
      },
      {
        product_id: 20,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Super komputer',
        description: 'Super komputer to sprzęt o najwyższej wydajności, jakości i komforcie użytkowania. Miejsce produkcji: Fabryka superelektroniki.',
        icon: 'computer_pro',
        visible_from_level: 38
      },
      {
        product_id: 21,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Super laptop',
        description: 'Super laptop jest odpowiedzią na potrzeby użytkownik&oacute;w, kt&oacute;rzy potrzebowali zaawansowanych rozwiązań w przenośnym komputerze osobistym. Miejsce produkcji: fabryka superelektroniki lvl 2',
        icon: 'laptop_pro',
        visible_from_level: 46
      },
      {
        product_id: 22,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Super smartfon',
        description: 'Super Smartfon to sprzęt dla fan&oacute;w niepozornych rozwiązań gabarytowych z ultrawydajnym wnętrzem. Miejsce produkcji: fabryka superelektroniki lvl 3.',
        icon: 'smartphone_pro',
        visible_from_level: 48
      },
      {
        product_id: 23,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Notatka',
        description: 'To Tw&oacute;j &bdquo;klucz&rdquo; z przyszłości, kt&oacute;ry pomoże Ci wybudować nowoczesne, bogate miasto .',
        icon: 'note',
        visible_from_level: 99
      },
      {
        product_id: 24,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 2,
          name: 'Produkty',
          description: 'Produkty',
          icon: 'produkty'
        },
        name: 'Plan budowy',
        description: 'Plan nowoczesnej architektury, kt&oacute;ry pozwoli na zr&oacute;wnoważony rozw&oacute;j Komp Town.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 25,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 3,
          name: 'Kadry',
          description: 'Kadry',
          icon: 'kadry'
        },
        name: 'Doktor',
        description: 'Doktor jest łącznikiem pomiędzy inżynierem i profesorem, jego doświadczenie i młodzieńczy zapał przyczynia się do ważnych odkryć naukowych. Miejsce kształcenia: Uczelnia.',
        icon: 'scientist_doctor',
        visible_from_level: 8
      },
      {
        product_id: 26,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 3,
          name: 'Kadry',
          description: 'Kadry',
          icon: 'kadry'
        },
        name: 'Inżynier',
        description: 'Nie ma fabryki, laboratorium, kt&oacute;re mogłoby funkcjonować bez inżynier&oacute;w &ndash; to właśnie oni przekładają akademicką teorię na praktykę. Miejsce pozyskiwania: Uczelnia lvl 1 i wyżej',
        icon: 'scientist_engineer',
        visible_from_level: 8
      },
      {
        product_id: 27,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 3,
          name: 'Kadry',
          description: 'Kadry',
          icon: 'kadry'
        },
        name: 'Profesor',
        description: 'Lata doświadczeń i nieograniczona wiedza &ndash; profesor to skromna postać, kt&oacute;ra stoi za wielkimi przełomami. Miejsce kształcenia: Uczelnia lvl 1 i wyżej',
        icon: 'scientist_professor',
        visible_from_level: 99
      },
      {
        product_id: 1000,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Zadowolony klient',
        description: 'Bezcenny uśmiech zadowolonego klienta, wdzięcznego za szybką dostawę sprzętu elektronicznego.',
        icon: 'smiley_face',
        visible_from_level: 99
      },
      {
        product_id: 1001,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Zespół badawczy',
        description: '',
        icon: 'research_team',
        visible_from_level: 99
      },
      {
        product_id: 1002,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Plan rozwoju miasta',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1003,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Nowy plan rozwoju miasta',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1004,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Plany prof. Kompetentnego',
        description: '',
        icon: 'note',
        visible_from_level: 99
      },
      {
        product_id: 1005,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Plany rozwoju komputeryzacji',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1006,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Nowy plan rozwoju miasta',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1007,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Projekt AI',
        description: '',
        icon: 'note',
        visible_from_level: 99
      },
      {
        product_id: 1008,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Plan rozwoju miasta 2010',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1009,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Plan rozwoju miasta 2015',
        description: 'Plan rozwoju miasta na lata 2015-2020 odblokowuje możliwość budowy nowych obiekt&oacute;w.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1010,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Plan rozwoju miasta 2020',
        description: 'Plan rozwoju miasta na lata 2020-2025 odblokowuje możliwość budowy nowych obiekt&oacute;w.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1011,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 1.0',
        description: 'Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje możliwość budowy fabryki podzespoł&oacute;w 2.0 oraz rozbudowy wielu innych budynk&oacute;w.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1012,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 2.0',
        description: 'Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje m.in. możliwość budowy fabryki elektroniki i rozbudowy apartamentowc&oacute;w do 5 poziomu.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1013,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 3.0',
        description: 'Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje m.in. możliwość rozbudowy muzeum do 4 poziomu.',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1014,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 4.0',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1015,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 5.0',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1016,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 6.0',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1017,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 7.0',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1018,
        category: {
          storage_id: 1,
          trading_currency_id: null,
          id: 5,
          name: 'Specjalne',
          description: 'Specjalne',
          icon: 'specjalne'
        },
        name: 'Certyfikat eko 8.0',
        description: '',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1100,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Salon Komputronika na 4 poziom',
        description: '',
        icon: '',
        visible_from_level: 99
      },
      {
        product_id: 1101,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Plan rozbudowy fabryki superelektroniki',
        description: 'Plan umożliwiający rozbudowę fabryki superelektroniki do 3 poziomu',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1102,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Plan rozbudowy fabryki superelektroniki',
        description: 'Plan umożliwiający rozbudowę fabryki superelektroniki do 4 poziomu',
        icon: 'blueprint',
        visible_from_level: 99
      },
      {
        product_id: 1103,
        category: {
          storage_id: 2,
          trading_currency_id: null,
          id: 4,
          name: 'Invisible',
          description: 'Invisible',
          icon: 'invisible'
        },
        name: 'Salon Komputronika na 5 poziom',
        description: '',
        icon: '',
        visible_from_level: 99
      }
    ]
  },
  ref: {
    storageId: undefined
  }
};
