import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QuestionInfo, QuestionInfoAnswer, QuestionInfoSliderImages } from "../../../interfaces/custom/question.info";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { QuizSliderComponent } from "../quiz-slider/quiz-slider.component";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { timer } from "rxjs";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../../../store/state";
import { PlayerSelectors } from "../../../../../../../store/player";

@Component({
  selector: "app-quiz-question",
  templateUrl: "quiz-question.component.html",
})
export class QuizQuestionComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QuizQuestionComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    questionInfo: QuestionInfo;
  };

  QUESTION_CHAR = ["A", "B", "C", "D", "E", "F", "G"];

  isLoadingAnswer: boolean;
  playerAnswer: QuestionInfoAnswer;
  currentTime = null;
  currentSliderIndex = null;

  subs = {
    timer: null,
    player: null,
  };

  afterConstructor() {
    this.matDialogRef.disableClose = true;
    super.afterConstructor();
  }

  ngOnInit() {
    this.checkPreSlider();
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      if (player?.quiz_game?.finished_at) {
        this.close();
      }
    });
  }

  unsubscribePlayer() {
    this.subs.player.unsubscribe();
  }

  checkPreSlider() {
    if (this.data?.questionInfo?.pre_slider_images?.length) {
      this.openPreSlider();
    } else {
      this.setSlider();
      this.startQuiz();
    }
  }

  openPreSlider() {
    if (this.data?.questionInfo?.pre_slider_images?.length) {
      this.dialogService.open(
        QuizSliderComponent,
        {
          data: {
            questionInfoSliderImages: this.data.questionInfo.pre_slider_images,
          },
        },
        () => {
          this.startQuiz();
        }
      );
    }
  }


  startQuiz() {
    this.apiService.post(`/quiz-game/question-player-tile/${this.data.questionInfo.question_player_tile_id}/start`).subscribe(
      () => {
        this.currentTime = this.data.questionInfo.time;
        this.startTimer();
      },
      error => {
        console.error(error);
        this.close();
      }
    );
  }

  startTimer() {
    this.subs.timer = timer(1000, 1000).subscribe(() => {
      this.currentTime--;

      if (this.currentTime <= 0) {
        this.unsubscribeTimer();
        this.close();
      }
    });
  }

  setSlider() {
    if (!this.data.questionInfo.youtube_id && this.data.questionInfo.slider?.length) {
      this.setSlide(0);
    }
  }

  setAnswer(answer: QuestionInfoAnswer) {
    this.playerAnswer = answer;
  }

  submitAnswer() {
    this.isLoadingAnswer = true;

    this.apiService
      .post(`/quiz-game/question-player-tile/${this.data.questionInfo.question_player_tile_id}/answer`, {
        body: {
          answer_id: this.playerAnswer.answer_id,
        },
      })
      .subscribe(
        () => {
          this.isLoadingAnswer = false;
          this.afterSubmitAnswer();
        },
        () => {
          this.isLoadingAnswer = false;
        }
      );
  }

  afterSubmitAnswer() {
    this.unsubscribeTimer();

    if (this.playerAnswer.post_image) {
      this.dialogService.open(
        QuizSliderComponent,
        {
          data: {
            manualClose: true,
            questionInfoSliderImages: <QuestionInfoSliderImages[]>[
              {
                url: this.playerAnswer.post_image,
                length: null,
              },
            ],
          },
        },
        () => {
          this.close();
        }
      );
    } else {
      this.close();
    }
  }

  unsubscribeTimer() {
    unsubscribeObject(this.subs);
  }

  close() {
    this.matDialogRef.close();
  }

  setSlide(index: number) {
    this.currentSliderIndex = index;
  }

  nextSlide() {
    if (this.currentSliderIndex < this.data.questionInfo.slider.length - 1) {
      this.setSlide(this.currentSliderIndex + 1);
    } else {
      this.setSlide(0);
    }
  }

  prevSlide() {
    if (this.currentSliderIndex > 0) {
      this.setSlide(this.currentSliderIndex - 1);
    } else {
      this.setSlide(this.data.questionInfo.slider.length - 1);
    }
  }

  ngOnDestroy() {
    this.unsubscribeTimer();
    this.unsubscribePlayer();
  }
}
