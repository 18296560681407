import { Component, OnInit } from '@angular/core';
import { AbstractMissionsComponent } from '../../../../abstract/core/abstract-missions.component';

@Component({
  selector: 'app-missions',
  templateUrl: './missions.component.html',

})
export class MissionsComponent extends AbstractMissionsComponent implements OnInit {
  ngOnInit() {
    this.baseInit();
  }
}
