import { StorageLocationBase } from './location-base.class';

export class StorageLocationCookie extends StorageLocationBase {
  isSupported() {
    const key = 'checkSupportedWebStorage';
    const value = (new Date()).toLocaleDateString();
    try {
      this.setCookie(key, value);
      if (this.getCookie(key) === value) {
        this.removeCookie(key);
        return true;
      } else {
        return this.noSupported();
      }
    } catch (e) {
      return this.noSupported();
    }
  }

  get(key) {
    return this.isSupported() !== this.noSupported() ? this.getCookie(key) : this.noSupported();
  }

  set(key, value, parameters = {}) {
    return this.isSupported() !== this.noSupported() ? this.setCookie(key, value, parameters) : this.noSupported();
  }

  remove(key) {
    return this.isSupported() !== this.noSupported() ? this.removeCookie(key) : this.noSupported();
  }

  getCookie(key) {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === key ? decodeURIComponent(parts[1]) : r;
    }, '');
  }

  setCookie(key, value, parameters = {}) {
    const expires = parameters['expired_at'] ? (new Date(parameters['expired_at'])).toUTCString() : 0;
    const secure = window.location.protocol === 'https:';
    const cookieValue = `${key}=${encodeURIComponent(value)}; expires=${expires}; path=${this.options.path} ${secure ? '; secure' : ''}`;

    return document.cookie = cookieValue;
  }

  removeCookie(key) {
    return this.setCookie(key, '');
  }
}
