import { Component } from '@angular/core';
import { AbstractAlertComponent } from '../../../../abstracts/abstract-alert.component';

@Component({
  selector: 'app-core-alert',
  templateUrl: './alert.component.html',

})
export class AlertComponent extends AbstractAlertComponent {
}

