import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import * as Fingerprint2 from 'fingerprintjs2/dist/fingerprint2.min.js';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
  fingerprint: string;

  getFingerprint() {
    return from(
      new Promise<void>((resolve) => {
        if (this.fingerprint) {
          resolve();
        } else {
          Fingerprint2.get((components) => {
            const values = components.map(component => component.value);
            this.fingerprint = Fingerprint2.x64hash128(values.join(''));
            resolve();
          });
        }
      })
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getFingerprint()
      .pipe(
        mergeMap(() => {
          const request = req.clone({
            setHeaders: {
              'X-FP': this.fingerprint || 'none-fingerprint',
            }
          });

          return next.handle(request);
        })
      );
  }
}
