import { Injectable } from '@angular/core';
import { Asset, AssetsService } from '../../../core/providers/assets.service';
import { PlayerBuilding } from '../game-engine/interfaces/player-building.config';
import { BoardTileState } from '../../../store/game/interfaces/board-tile.state';
import { ApiService } from '../../../core/providers/api.service';
import { BUILDINGS_EXTENSIONS_PATH, BUILDINGS_PATH, ICONS_PATH } from '../constants';
import { Observable } from 'rxjs';
import { BuildingDetailsConfig } from '../game-engine/interfaces/building-details-config';
import { BuildingConfig } from '../game-engine/interfaces/building-config';
import { constructBuildingFilenamePart, extractBuildingImageParametersFromAssetPath } from '../game-gui/helpers/buildings.helper';
import { BuildingImageParameters } from '../game-engine/interfaces/BuildingImageParameters';
import { environment } from '../../../../environments/environment';
import { DemolishBuildingInfoResponse } from '../interfaces/buildings.interfaces';
import { MOCK_BUILDING_DETAILS_KEY, MOCK_BUILDING_UNLOCKED_KEY } from '../game-ui/buildings/mock/consts/mock-building-details.const';
import { EVENT_DIALOGS_NAMES_BUILDINGS } from '../game-ui/buildings/consts/core/event-dialogs/event-names.const';
import { MOCK_BUILDING_DEMOLISH_KEY } from '../game-ui/buildings/mock/consts/mock-building-demolish.const';

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {

  constructor(
    private assetsService: AssetsService,
    private apiService: ApiService,
  ) {
  }

  getBuildingDetails(buildingId: number): Observable<BuildingDetailsConfig> {
    return this.apiService.get(
      `buildings/${buildingId}`,
      {},
      `${MOCK_BUILDING_DETAILS_KEY}_${buildingId}`
    );
  }

  getPlayerBuildingDetails(playerBuildingId: number): Observable<BuildingDetailsConfig> {
    return this.apiService.get(
      `player-buildings/${playerBuildingId}`,
      {},
      `${MOCK_BUILDING_DETAILS_KEY}_${playerBuildingId}`
    );
  }

  getUnlockedBuildingsByLevel(level) {
    return this.apiService.get(
      `buildings/at-level/${level}`,
      {},
      `${MOCK_BUILDING_DETAILS_KEY}_${level}`
    );
  }

  /**
   * Get buildings available to build no player tile.
   * @param playerTileId
   */
  getBuildingsByPlayerTileId(playerTileId: number) {
    const options = {
      params: {
        player_tile_id: playerTileId
      }
    };
    return this.apiService.get(
      'buildings',
      options,
      EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST
    );
  }

  /**
   * Get building image name
   * @param building
   * @param returnReplacementIfNotFound
   */
  getBuildingImage(building: PlayerBuilding, returnReplacementIfNotFound?: boolean): string {

    building = building.upgrading_from_building ? {...building, ...building.upgrading_from_building} : building;

    if (!building) {
      return null;
    }
    if (!building.level) {
      building.level = 1;
    }

    if (building.visual_variant > 1) {
      const buildingVariant = constructBuildingFilenamePart(building.icon, building.level, [building.visual_variant]);
      const assetPath = this.assetsService.getAssetByFilenamePart(buildingVariant, BUILDINGS_PATH);
      if (assetPath !== undefined) {
        return assetPath.path;
      }
    }

    let buildingIconWithLevel;

    for (let i = building.level; i >= 1; i--) {
      buildingIconWithLevel = constructBuildingFilenamePart(building.icon, i);

      /**
       * Explanations: if visual_variant is 1 that means filename for this building can be without any other parameter (it ends with .png)
       * or other parameters are passed (like anchor), then version param is skipped. In filename struct it looks like: icon-level--anchor.
       * @todo: rename all building files to have visual_variant in filename (event for `1`) or change the way building params are passed.
       */
      let assetPath = this.assetsService.getAssetByFilenamePart(`${buildingIconWithLevel}.`, BUILDINGS_PATH);
      if (assetPath === undefined) {
        assetPath = this.assetsService.getAssetByFilenamePart(`${buildingIconWithLevel}--`, BUILDINGS_PATH);
      }
      if (assetPath !== undefined) {
        return assetPath.path;
      }
    }

    if (returnReplacementIfNotFound) {
      return environment.base + '/assets/buildings/no-building.png';
    } else {
      return null;
    }
  }

  getBuildingImageAsset(building: PlayerBuilding): string {
    const buildingImageAssetObject = this.getBuildingImageAssetObject(building);
    return buildingImageAssetObject ? buildingImageAssetObject.path : undefined;
  }

  /**
   * Get building image name
   * @param building
   */
  getBuildingImageAssetObject(building: PlayerBuilding): Asset {

    // building = building.upgrading_from_building ? {...building, ...building.upgrading_from_building} : building;

    if (!building) {
      return null;
    }
    if (!building.level) {
      building.level = 1;
    }

    let buildingIconWithLevel;

    for (let i = building.level; i >= 1; i--) {
      buildingIconWithLevel = constructBuildingFilenamePart(building.icon, i);
      const assetPath = this.assetsService.getAssetByFilenamePart(buildingIconWithLevel, BUILDINGS_PATH);
      if (assetPath !== undefined) {
        return assetPath;
      }
    }
    return null;
  }

  getBuildingExtensions(building: PlayerBuilding) {
    const buildingExtensionFilename = constructBuildingFilenamePart(building.icon, building.level);
    const assetPath = this.assetsService.getAssetByFilenamePart(buildingExtensionFilename, BUILDINGS_EXTENSIONS_PATH);
    if (assetPath !== undefined) {
      return assetPath;
    }
  }

  /**
   * Get building image versions.
   * @param buildingImageParameters
   */
  getBuildingImageVersions(buildingImageParameters: BuildingImageParameters) {
    const buildingIconWithLevel = `${buildingImageParameters.icon}-${buildingImageParameters.level}`;
    const assets = this.assetsService.getAssetsByFilenamePart(buildingIconWithLevel, BUILDINGS_PATH);
    const versions = [];
    assets.forEach(buildingImageAasset => {
      const _buildingImageParameters = extractBuildingImageParametersFromAssetPath(buildingImageAasset);
      _buildingImageParameters.version && versions.push(buildingImageAasset);
    });
    return versions;
  }

  /**
   * Send build request.
   * @param playerTileId
   * @param buildingId
   * @param fastBuilding
   */
  build(playerTileId: number, buildingId: number, fastBuilding?: boolean) {
    const options = {
      body: {
        player_tile_id: playerTileId,
        building_id: buildingId,
        fast_building: fastBuilding,
      }
    };
    return this.apiService.request('POST', 'player-buildings', options);
  }

  /**
   * Send building upgrade request.
   * @param playerBuildingId
   * @param fast
   */
  upgrade(playerBuildingId, fast?: boolean) {
    let options = {
      body: {
        fast_building: fast,
      }
    };
    return this.apiService.request('PATCH', `player-buildings/${playerBuildingId}/upgrade`, options);
  }

  /**
   * Get building icon path
   * @param building
   */
  getBuildingIcon(building: PlayerBuilding) {

    const assetPath = this.assetsService.getAssetPath(`${ICONS_PATH}${building.icon}.png`);
    if (assetPath !== undefined) {
      return assetPath;
    }
    return null;
  }

  /**
   * Get unlocked buildings by building_id
   * @param building_id
   */
  getUnlockedBuildings(building_id): Observable<BuildingConfig[]> {
    return this.apiService.get(
      `buildings/${building_id}/unlocked-buildings`,
      {},
      `${MOCK_BUILDING_UNLOCKED_KEY}_${building_id}`
    );
  }

  getBuildingsAssetsNamesFromBoard(board: BoardTileState[]) {
    return board
      .filter((tileData: BoardTileState) => !!tileData.player_building)
      .map((tileData: BoardTileState) => {
        return this.getBuildingImage(tileData.player_building);
      });
  }

  demolishBuild(buildingId) {
    return this.apiService.request('DELETE', `player-buildings/${buildingId}`);
  }

  demolishBuildInfo(playerBuildingId: number): Observable<DemolishBuildingInfoResponse> {
    return this.apiService.get(
      `player-buildings/${playerBuildingId}/demolish-info`,
      {},
      `${MOCK_BUILDING_DEMOLISH_KEY}_${playerBuildingId}`
    );
  }

  /**
   * Execute move building request after moving building confirmed.
   * @param player_tile_id
   * @param player_building_id
   * @returns {Promise<any>}
   */
  movePlayerBuilding(player_tile_id: number, player_building_id: number) {
    let options = {
      body: {
        player_tile_id
      }
    };
    return this.apiService.request('PATCH', 'player-buildings/' + player_building_id, options);
  }

  collectGastronomyIncome(group: string) {
    return this.apiService.post(`gastronomy/${group}/receive`);
  }

  getGastroBuildingDetails(group: string) {
    return this.apiService.get(`gastronomy/${group}/details`);
  }
}
