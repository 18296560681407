import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { translate } from '../../../../../../core/helpers/translate.helper';
import { PlayerService } from '../../../../../player/providers/player.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { BoardService } from '../../../../services/board.service';
import { TileBuyData } from '../../interfaces/core/dialogs/tile-buy-data.interface';

@Directive()
@Injectable()
export abstract class AbstractTileBuyComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: TileBuyData;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractTileBuyComponent>;

  allowRequiredLevel: boolean;

  checkTileBuy() {
    this.checkAllowRequireLevel();

    if (this.allowRequiredLevel) {
      this.openDialogConfirmBuy();
    } else {
      this.openDialogDisallowBuy();
    }
  }

  checkAllowRequireLevel() {
    this.allowRequiredLevel = this.playerService.allowRequiredLevel(this.data.tile.required_level);
  }

  openDialogConfirmBuy() {
    this.dialogService.openConfirm({
      title: translate('tile-buy.title'),
      description: translate('tile-buy.allow-buy-alert.description'),
      buttonClose: {
        label: translate('global.cancel')
      },
      buttonSuccess: {
        label: translate('global.buy'),
      },
      costs: {
        currencies: this.data.tile.currency_prices,
        products: this.data.tile.product_prices,
        separatorTitle: translate('tile-buy.allow-buy-alert.separator-title')
      }
    }, (confirm) => {
      if (confirm) {
        this.buyTile();
      } else {
        this.close();
      }
    });
  }

  openDialogDisallowBuy() {
    this.dialogService.openAlert({
      title: translate('tile-buy.title'),
      description: translate('tile-buy.disallow-level-alert.description', [this.data.tile.required_level]),
      buttonClose: {
        label: translate('global.cancel')
      },
    }, () => {
      this.close();
    });
  }

  buyTile() {
    this.boardService.buyTile(this.data.tile.tile_id)
      .subscribe(() => {
          this.close();
        }, () => {
          this.close();
        }
      );
  }

  close() {
    this.matDialogRef.close();
  }
}
