import { TryUpdatePlayer } from '../../actions';
import { UtilityPlayer } from '../../../utility/interfaces/utility-player';

export function customHandleHasMissionsToCollect({
                                                   action,
                                                   state,
                                                   coreValueHasMissionToCollect
                                                 }: { action: TryUpdatePlayer, state: UtilityPlayer, coreValueHasMissionToCollect: boolean }) {
  return coreValueHasMissionToCollect;
}
