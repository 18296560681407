import { Component, OnInit } from '@angular/core';
import { AbstractQaListComponent } from '../../../../abstract/core/abstract-qa-list.component';

@Component({
  selector: 'app-m-qa-list',
  templateUrl: './qa-list.component.html',

})
export class MQaListComponent extends AbstractQaListComponent implements OnInit {

  ngOnInit() {
    this.baseInit();
  }
}
