<div class="top-left-container">
  <div class="avatar-background">
    <div class="avatar-inner-background">
      <img
        *ngIf="player"
        class="avatar"
        [src]="player['user_avatar_url'] || (player['user_avatar_number'] + '.png' | asset:'avatar')"
      />
    </div>

    <div class="rank-name" *ngIf="player">
      {{ player['player_rank_details'].name }}
    </div>
  </div>
</div>

<div class="top-center-container">
  <div class="item-box-container timer">
    <div class="icon"></div>
    {{ quizGameTimerValue | owDuration2:'mm:ss' }}
  </div>

  <div class="item-box-container currency">
    <div class="icon"></div>
    {{ (player.currency_balances | currencyGameBalance:'points').balance | NumberWithSpaces }}
  </div>
</div>

<app-summary *ngIf="currentStepService.currentStep.value === CURRENT_STEP.SUMMARY"></app-summary>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>
