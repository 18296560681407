import { Component, OnDestroy, OnInit } from '@angular/core';
import { MockDialog } from '../../interfaces/mock-dialog.interface';
import { OwInject } from '../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../../../../core/services/core/event-emitter-dialogs.service';
import { MockDialogListService } from '../../services/mock-dialog-list.service';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { take } from 'rxjs/operators';
import { MockState } from '../../../../store/actions';
import { PrimaryActions, PrimarySelectors } from '../../../../store/primary';
import { UserActions, UserSelectors } from '../../../../store/user';
import { MockMessageService } from '../../../game/game-ui/message/mock/services/mock-message.service';
import { MockBuildingsService } from '../../../game/game-ui/buildings/mock/services/mock-buildings.service';
import { MOCK_DIALOG_LIST } from '../../const/mock-dialog-list.const';
import { MOCK_STATE_APPLICATION } from '../../const/mock-state-application.const';
import { MockMissionsService } from '../../../game/game-ui/mission/mock/services/mock-missions.service';
import { MockWarehouseService } from '../../../game/game-ui/warehouse/mock/services/mock-warehouse.service';
import { PlayerActions, PlayerSelectors } from '../../../../store/player';
import { GameActions, GameSelectors } from '../../../../store/game';
import { UtilityActions } from '../../../../store/utility';
import { MOCK_WAREHOUSE_STORE_PRODUCT } from '../../../game/game-ui/warehouse/mock/consts/mock-warehouse.const';
import { Product } from '../../../player/interfaces/product.interface';
import { ProductPlayerService } from '../../../player/providers/product-player.service';
import { MockQaService } from '../../../game/game-ui/qa/mock/services/mock-qa.service';
import { MockTransactionHistoryService } from '../../../game/game-ui/transaction-history/mock/services/mock-transaction-history.service';
import { MockBoardService } from '../../../game/mock/services/mock-board.service';
import { MOCK_BOARD_KEY } from '../../../game/mock/consts/mock-board.const';
import { MockCurrencyExchangeService } from '../../../game/game-ui/currency-exchange/mock/services/mock-currency-exchange.service';
import { MockHudService } from '../../../game/game-ui/hud/mock/services/mock-hud.service';

@Component({
  selector: 'mock-dialogs-list',
  templateUrl: './mock-dialog-list.component.html',

})
export class MockDialogListComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MockDialogListService) mockDialogService: MockDialogListService;
  @OwInject(FormBuilder) formBuilder: FormBuilder;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MockMessageService) mockMessageService: MockMessageService;
  @OwInject(MockBuildingsService) mockBuildingsService: MockBuildingsService;
  @OwInject(MockMissionsService) mockMissionsService: MockMissionsService;
  @OwInject(MockWarehouseService) mockWarehouseService: MockWarehouseService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(MockQaService) mockQaService: MockQaService;
  @OwInject(MockTransactionHistoryService) mockTransactionHistoryService: MockTransactionHistoryService;
  @OwInject(MockBoardService) mockBoardService: MockBoardService;
  @OwInject(MockCurrencyExchangeService) mockCurrencyExchangeService: MockCurrencyExchangeService;
  @OwInject(MockHudService) mockHudService: MockHudService;

  showMockStore: boolean;
  dialogs: MockDialog[] = MOCK_DIALOG_LIST;
  selectedDialog: MockDialog;
  MOCK_BOARD_KEY = MOCK_BOARD_KEY;

  ngOnInit() {
    this.mockDialogService.isMocked = true;
    this.mockDialogService.keySubscription.emit(MOCK_BOARD_KEY);
    this.store.dispatch(new MockState({
      ...MOCK_STATE_APPLICATION
    }));

    this.store
      .pipe(
        select(UserSelectors.selectUser),
        take(1),
      )
      .subscribe((user) => {
        this.store.dispatch(new UserActions.UpdateUser(user));
      });
    this.store
      .pipe(
        select(PrimarySelectors.selectPrimary),
        take(1),
      )
      .subscribe((primary) => {
        this.store.dispatch(new PrimaryActions.FetchSuccess(primary));
      });
    this.store.pipe(
      select(PlayerSelectors.selectPlayer),
      take(1),
    ).subscribe(player => {
      this.store.dispatch(new PlayerActions.TryUpdatePlayer(player));
      this.store.dispatch(new GameActions.FetchBoard({
        playerId: player.id,
        playerIslandId: player.main_player_island.player_island_id
      }));
      this.store.dispatch(new UtilityActions.UpdateActivePlayerId({
        playerId: player.id
      }));
    });

    this.store.pipe(
      select(GameSelectors.selectCurrentScene),
      take(1),
    ).subscribe(scene => {
      this.store.dispatch(new GameActions.SetCurrentScene(
        scene
      ));
    });
  }

  selectDialog(mockDialog: MockDialog) {
    this.selectedDialog = mockDialog;
    this.mockDialogService.getMockDataByKey(mockDialog.key);
  }

  openDialog(key: string, data: any) {
    this.eventEmitterDialogsService.emitter.emit({
      name: key,
      config: {
        data: JSON.parse(data)
      }
    });
    const mock = this.mockDialogService.findMockApiResponse(MOCK_WAREHOUSE_STORE_PRODUCT);
    if (mock) {
      const products = JSON.parse(mock.data) as Product[];
      this.productPlayerService.setProductsDefinitions(products);
    }
  }

  ngOnDestroy() {
    this.mockDialogService.mockDialogRefJSON = null;
    this.mockDialogService.mockDataJSON = null;
    this.mockDialogService.isMocked = false;
  }
}

