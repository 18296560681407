import { Component, OnInit } from "@angular/core";
import { MinigameRiverRideGuiComponent } from "../../../../../../minigames/riverride/components/minigame-river-ride-gui/minigame-river-ride-gui.component";
import { MinigameSummaryComponent } from "../../../../../../minigames/riverride/components/minigame-summary/minigame-summary.component";
import { AbstractHudComponent } from "../../../abstract/core/abstract-hud.component";
import { CurrentStepService } from "../../../../../../shared/providers/current-step.service";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { CURRENT_STEP } from "../../../../../../shared/consts/custom/current-step.const";
import { CurrentStepQuizGameComponent } from "../../dialogs/current-step-quiz-game/current-step-quiz-game.component";
import { select } from "@ngrx/store";
import { PlayerSelectors } from "../../../../../../../store/player";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import * as moment from "moment/moment";
import { SynchronizeTimeService } from "../../../../../../../core/providers/synchronize-time.service";
import { timer } from "rxjs";
import { GlobalEvent } from "../../../../../../../core/interfaces/shared.interfaces";
import { GAME_EVENTS } from "../../../../../constants";
import { QuizQuestionComponent } from "../../dialogs/quiz-question/quiz-question.component";
import * as R from "ramda";
import { TryUpdatePlayer } from "../../../../../../../store/player/actions";
import { QuizBonusComponent } from "../../dialogs/quiz-bonus/quiz-bonus.component";

@Component({
  selector: "app-hud-custom",
  templateUrl: "./hud.component.html",
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  @OwInject(CurrentStepService) currentStepService: CurrentStepService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  CURRENT_STEP = CURRENT_STEP;
  quizGameTimerValue: number = null;

  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subscribeCurrentStep();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    this.subscribeQuizGameTimer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
      this.initQuizGameTimerValue();
    });
  }

  initQuizGameTimerValue() {
    if (this.quizGameTimerValue === null) {
      this.quizGameTimerValue = this.player["quiz_game"]?.["length_in_seconds"];
    }
  }

  setQuizGameTimerValue() {
    const quizGame = this.player["quiz_game"];
    this.quizGameTimerValue = moment(quizGame.started_at)
      .add("seconds", quizGame.length_in_seconds)
      .diff(moment(this.synchronizeTimeService.getActualLocalTimeWithOffset()), "seconds");

    this.quizGameTimerValue = R.min(quizGame.length_in_seconds, this.quizGameTimerValue);

    if (this.quizGameTimerValue <= 0) {
      this.quizGameTimerValue = 0;
    }
  }

  countdownQuizGameTimer() {
    this.subs["quizGameTimer"] = timer(0, 1000).subscribe(() => {
      this.setQuizGameTimerValue();

      if (this.quizGameTimerValue <= 0) {
        this.subs["quizGameTimer"].unsubscribe();
        this.dialogService.closeAll();

        setTimeout(() => {
          this.store.dispatch(new TryUpdatePlayer(this.player));
        });
      }
    });
  }

  subscribeCurrentStep() {
    this.subs["currentStep"] = this.currentStepService.currentStep.subscribe(currentStep => {
      this.triggerCurrentStep(currentStep);
    });
  }

  subscribeQuizGameTimer() {
    this.subs["quizGameTimer"] = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        map(player => player["quiz_game"]),
        filter(quizGame => quizGame),
        map(quizGame => quizGame["started_at"]),
        filter(quizGameStartedAt => quizGameStartedAt),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.setQuizGameTimerValue();

        if (this.quizGameTimerValue) {
          this.countdownQuizGameTimer();
        }
      });
  }

  triggerCurrentStep(currentStep) {
    switch (currentStep) {
      case CURRENT_STEP.NULL:
        break;

      case CURRENT_STEP.MINIGAME:
        this.dialogService.open(MinigameRiverRideGuiComponent, {
          data: {
            game: 1,
          },
          disableClose: true,
        });
        break;

      case CURRENT_STEP.MINIGAME_SUMMARY:
        this.dialogService.closeAll();
        this.dialogService.open(MinigameSummaryComponent, {
          disableClose: true,
        });
        break;

      case CURRENT_STEP.QUIZ_GAME:
        this.dialogService.open(CurrentStepQuizGameComponent, {
          disableClose: true,
        });
        break;

      case CURRENT_STEP.MINIGAME2:
        this.dialogService.open(MinigameRiverRideGuiComponent, {
          data: {
            game: 2,
          },
          disableClose: true,
        });
        break;

      case CURRENT_STEP.MINIGAME2_SUMMARY:
        this.dialogService.closeAll();
        this.dialogService.open(MinigameSummaryComponent, {
          disableClose: true,
        });
        break;

      case CURRENT_STEP.SUMMARY:
        // obsługa w hud component
        break;

      default:
        alert("nieobsługiwany current step przez FE");
    }
  }

  async handleGameEvents(event: GlobalEvent) {
    switch (event.name) {
      case GAME_EVENTS.OPEN_TILE_MENU:
        switch (event.value.playerBuildingData.group) {
          case "bonus":
            this.dialogService.open(QuizBonusComponent, {
              data: {
                questionInfo: event.value.playerBuildingData["question_info"],
              },
            });
            break;
          default:
            this.dialogService.open(QuizQuestionComponent, {
              data: {
                questionInfo: event.value.playerBuildingData["question_info"],
              },
            });
        }
        break;
      default:
        await super.handleGameEvents(event);
    }
  }
  openBonus() {
    this.dialogService.open(QuizBonusComponent, {
      data: {},
    });
  }
}
