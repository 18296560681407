import { Component } from '@angular/core';
import { AbstractBuildingUpgradeDiffParameterComponent } from '../../../../abstract/core/abstract-building-upgrade-diff-parameter.component';

@Component({
  selector: 'm-building-upgrade-diff-parameter',
  templateUrl: './building-upgrade-diff-parameter.component.html',

})
export class MBuildingUpgradeDiffParameterComponent extends AbstractBuildingUpgradeDiffParameterComponent {
}
