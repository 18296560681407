import { Component } from '@angular/core';
import { AbstractSeparatorComponent } from '../../../abstract/abstract-separator.component';

@Component({
  selector: 'm-ui-separator',
  templateUrl: './ui-separator.component.html',

})
export class MUiSeparatorComponent extends AbstractSeparatorComponent {
}
