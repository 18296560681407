import { RiverRidePlayScene } from '../scenes/RiverRidePlay.scene';
import { RRGameModes, RRRiverSegment } from '../river-ride.interfaces';
import { RRGameObject } from './RRGameObject.class';
import { RiverRideGame } from './RiverRideGame.class';
import { getObjectDef } from '../river-ride.helpers';

export class RiverSegmentContainer extends Phaser.GameObjects.Container {
  segmentImage: Phaser.GameObjects.Image;
  segmentData: RRRiverSegment;
  objects: RRGameObject[] = [];

  constructor(scene: RiverRidePlayScene, x: number, y: number, segmentData: RRRiverSegment) {
    super(scene, x, y);
    this.createSegmentImage(segmentData);
    this.createSegmentObjects();
    scene.add.existing(this);
  }

  createSegmentImage(segmentData: RRRiverSegment) {
    this.segmentData = segmentData;
    // this.segmentImage = this.scene.add.image(0, this.scene.cameras.main.height, segmentData.imagePath+'-mask');
    this.segmentImage = this.scene.add.image(0, this.scene.cameras.main.height, segmentData.imagePath);
    this.segmentImage.setOrigin(0, 1);
    this.add(this.segmentImage);
  }

  createSegmentObjects() {
    this.segmentData.objects.forEach((objectData, index) => {
      const isGamePremiumMode = (this.scene.game as RiverRideGame).gameConfig.gameParams.gameMode === RRGameModes.PREMIUM;

      const objectParams = getObjectDef(objectData.objectId);

      if (objectParams.isPremium && !isGamePremiumMode) {
        return;
      }

      const object = new RRGameObject((this.scene as RiverRidePlayScene), objectData, index, this);
      this.add(object);
      this.objects.push(object);
      //
      // const g = this.scene.add.graphics({fillStyle: {color: 0xff0000}});
      // g.setAlpha(0.4);
      // this.add(g);
      // g.fillCircle(object.x, object.y, 30);
    });
  }
}
