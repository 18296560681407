import { NgModule } from '@angular/core';
import { MobileGameEnginePceComponent } from './components/game-engine-pce/game-engine-pce.component';
import { GameDebugComponent } from './components/game-debug/game-debug.component';
import { HudModule } from '../../game-ui/hud/module/core/hud.module';
import { ScreenToIsoComponent } from './components/game-debug/components/screen-to-iso/screen-to-iso.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WorldInfoComponent } from './components/game-debug/components/world-info/world-info.component';
import { OnOffPipe } from './components/game-debug/pipes/on-off.pipe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MobileGameEnginePceComponent,
    GameDebugComponent,
    ScreenToIsoComponent,
    WorldInfoComponent,
    OnOffPipe,
  ],
  imports: [
    CommonModule,
    HudModule,
    FormsModule,
    RouterModule,
  ],
  exports: [
    MobileGameEnginePceComponent
  ]
})
export class GameEngineMobileModule {
}
