import { IsoSprite } from './IsoSprite';
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from '../../../constants/iso.constants';
import { GameService } from '../../../services/game.service';
import { MyScene } from './MyScene';
import { getMainSceneConfig } from '../../scenes-main/helpers/MainScene.helper';

export class MapObject extends IsoSprite {

  game: Phaser.Game;
  myScene;
  building;
  menu;
  tile;

  gameService: GameService;

  constructor(params) {
    super(params);
    this.myScene = params.scene as MyScene;
    this.game = this.myScene.sys.game;
    this.gameService = this.myScene.gameService;
    this.tile = params.tile;

    let sceneConfig = getMainSceneConfig();

    if (this.myScene.sceneConfig) {
      sceneConfig = this.myScene.sceneConfig;
    }

    this.x += sceneConfig.tiledStartOffset.x;
    this.y += sceneConfig.tiledStartOffset.y;

    this.depth = (this.x + this.y) * 10000;
  }

  calculateDepth() {
    this.setDepth(this.depthIndex + (this.y - this.width / 3) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
  }
}

