import { Traffic } from '../../budimex-classes/traffic.class';
import { calculateMinZoomValue } from '../../utils/game.helper';
import { BasicMainScene } from '../core/Basic.main.scene';

export class CustomBasicMainScene extends BasicMainScene {
  private traffic: Traffic;

  afterCreate() {
    this.traffic = new Traffic(this);
    this.cameras.main.zoomTo(Math.max(0.1, calculateMinZoomValue(this.cameras.main)), 0);
  }
}

