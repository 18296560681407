import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { SynchronizeTimeService } from './synchronize-time.service';
import { AppState } from '../../store/state';
import { Store } from '@ngrx/store';
import { FetchNewTokenStart } from '../../store/utility/actions';
import { PlayerService } from '../../modules/player/providers/player.service';
import { getToken } from '../utility/token';

@Injectable({
  providedIn: 'root'
})
export class TokenExchangeService {
  static timeout;
  intervalCheckTimeoutSeconds: number = 60; // 60 sec -> 1 min

  constructor(
    private apiService: ApiService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>,
    private playerService: PlayerService,
  ) {
  }

  exchangeTimeoutStart() {
    const refreshAfterDate = getToken().refresh_after;
    if (!refreshAfterDate) {
      return;
    }

    const currentMomentDate = moment(this.synchronizeTimeService.getActualLocalTime());
    const isAfterRefresh = moment(currentMomentDate).isAfter(moment(refreshAfterDate));

    if (isAfterRefresh && this.playerService.getMePlayerId()) {
      this.tokenExpiredAction();
    } else {
      TokenExchangeService.exchangeTimeoutStop();
      TokenExchangeService.timeout = setTimeout(() => {
        this.exchangeTimeoutStart();
      }, this.intervalCheckTimeoutSeconds * 1000);
    }
  }

  exchangeTimeoutStop() {
    clearTimeout(TokenExchangeService.timeout);
  }

  tokenExpiredAction() {
    this.store.dispatch(new FetchNewTokenStart());
  }

  exchangeToken() {
    return this.apiService.post('api-token/exchange');
  }

  static exchangeTimeoutStop() {
    clearTimeout(TokenExchangeService.timeout);
  }
}
