import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../core/abstracts/abstract-inject-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../providers/auth.service';
import { DialogService } from '../../shared/providers/dialog.service';
import { ResetPasswordRequest } from '../interfaces/reset-password';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { OwInject } from '../../../core/decorators/ow-inject.decorator';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../consts/core/routing-auth-absolute.const';

@Directive()
@Injectable()
export abstract class AbstractForgotPasswordComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  formSendSuccess: boolean;
  form: FormGroup;

  afterConstructor() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.email, Validators.required]),
    });
  }

  onFormSubmit() {
    const data: ResetPasswordRequest = {
      email: this.form.value.email
    };

    this.authService.resetPasswordRequest(data)
      .pipe(
        map(() => {
          this.formSendSuccess = true;
        }),
        catchError((errResp) => {
          return of(errResp);
        }),
      )
      .subscribe();
  }
}
