export const MOCK_MESSAGE_DETAILS_COMICS = {
  api: {
    read: true,
    title: 'Powrót do przeszłości',
    tip: '',
    teaser: '',
    description: '',
    player_message_id: 129888,
    generic_type: null,
    type: 1,
    category_name: 'Świat gry',
    sender_group_id: 3,
    sender_name: 'Komiks',
    sender_name_legacy: 'Komiks',
    sender_icon: 'comics',
    gallery: ['https://komptown.pl/api/files/download/3a6ad94b-5a43-485a-bdbe-2262c9931682/1a.png', 'https://komptown.pl/api/files/download/ecfcae24-489d-4097-9230-54f44989043d/1b.png', 'https://komptown.pl/api/files/download/8c97ec38-e522-4af5-91f8-bd6844d28148/1c.png', 'https://komptown.pl/api/files/download/7b8735f5-389c-49f3-84db-33aa2955b223/1d.png', 'https://komptown.pl/api/files/download/1190db59-d7e7-4fbc-a279-8d8a05494291/1e.png'],
    mobile_gallery: [],
    parameters: [],
    deleted: true,
    created_at: '2021-02-03T09:42:00+01:00'
  },
  ref: {
    message: {
      read: true,
      title: 'Powrót do przeszłości',
      tip: '',
      teaser: '',
      description: '',
      player_message_id: 129888,
      generic_type: null,
      type: 1,
      category_name: 'Świat gry',
      sender_group_id: 3,
      sender_name: 'Komiks',
      sender_name_legacy: 'Komiks',
      sender_icon: 'comics',
      gallery: ['https://komptown.pl/api/files/download/3a6ad94b-5a43-485a-bdbe-2262c9931682/1a.png', 'https://komptown.pl/api/files/download/ecfcae24-489d-4097-9230-54f44989043d/1b.png', 'https://komptown.pl/api/files/download/8c97ec38-e522-4af5-91f8-bd6844d28148/1c.png', 'https://komptown.pl/api/files/download/7b8735f5-389c-49f3-84db-33aa2955b223/1d.png', 'https://komptown.pl/api/files/download/1190db59-d7e7-4fbc-a279-8d8a05494291/1e.png'],
      mobile_gallery: [],
      parameters: [],
      deleted: true,
      created_at: '2021-02-03T09:42:00+01:00'
    },
    fromList: true
  }
};
