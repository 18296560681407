import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from '../../consts/core/event-dialogs/event-names.const';
import { MOCK_CURRENCY_EXCHANGE } from '../consts/mock-currency-exchange.const';

@Injectable({providedIn: 'root'})
export class MockCurrencyExchangeService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE: {
          this.mockDialogListService.setDialogRef(MOCK_CURRENCY_EXCHANGE.ref);
          break;
        }
      }
    });
  }
}
