import { GameObjectIds, GameObjectIdsMapForEC2, riverRideGameplayConfig, RR_GAME_OBJECT_DEFS } from './river-ride.config';
import { RRRiverSegmentGameObjectDef, RRRiverSegmentObjectConfig } from './river-ride.interfaces';
import { LS_CONFIG_KEY } from './rrconfig-editor/rrconfig-editor/rrconfig-editor.component';

export function generateAnimationFramesList(key: string, baseFrameName: string, framesCount: number) {
  return new Array(framesCount).fill(1).map((el, i) => ({key, frame: `${baseFrameName}-${(i + 1).toString().padStart(2, '0')}.png`}));
}

export function generateSegmentGameObjectConfig(
  x: number,
  y: number,
  key: GameObjectIds,
  gameObjectDefs: RRRiverSegmentGameObjectDef[],
  tweenDuration?: number,
  targetX?: number,
  targetY?: number,
): RRRiverSegmentObjectConfig {
  const gameObjetDef = gameObjectDefs.find(gameObjectDef => gameObjectDef.objectId === key);
  return {
    ...gameObjetDef,
    x,
    y,
    targetX,
    targetY,
    tweenDuration
  };
}

export function provideSceneConfig() {
  const savedLocalConfig = JSON.parse(localStorage.getItem(LS_CONFIG_KEY));
  console.log('config exists', savedLocalConfig);
  return savedLocalConfig ? savedLocalConfig : riverRideGameplayConfig;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}


export function getObjectDef(objectId: GameObjectIds) {
  return RR_GAME_OBJECT_DEFS.find(objectDef => objectDef.objectId === objectId);
}
