export const MOCK_BUILDING_BUILD = {
  api: {
    details: {
      upgrade_building_id: 128,
      required_building: {exists: false, name: 'Tartak', level: 2, icon: 'sawmill', group: 'sawmill'},
      required_building_on_island: null,
      required_star: null,
      building_time_in_seconds: 28800,
      fast_building_time_in_seconds: 0,
      build_currency_prizes: [],
      build_product_prizes: [{
        product_id: 15,
        category: {storage_id: 1, trading_currency_id: null, id: 2},
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        amount: 4
      }],
      parameters: [],
      building_id: 127,
      group_type: 'production',
      group_limit: 1,
      group_count: 0,
      category_id: 1,
      player_concurrence_builds_reached_error_message: null,
      group: 'furniture',
      group_description: 'W fabryce mebli wyprodukujesz meble dla Twoich mieszkańc&oacute;w.',
      icon: 'furniture',
      automatic_product: null,
      automatic_currency: null,
      level: 1,
      name: 'Fabryka Mebli',
      additional_details: '',
      required_level: 4,
      required_population: 140,
      building_time: 28800,
      building_time_in_days: null,
      fast_building_time: 0,
      repair_building_time: 0,
      build_product_prices: [],
      exp_points: 346,
      attributes: [],
      probability_parameters: [],
      repair_prices: [],
      build_currency_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
      fast_build_currency_prices: [],
      fast_build_product_prices: [],
      daily_product_productions: [],
      upgrade_button_name: null,
      auto_delete: false,
      variants: [],
      original_building_time_in_seconds: 28800,
      original_build_currency_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
      build_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
      original_build_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
      fast_build_prices: [],
      required_products: [],
      probability_parameters_percentage: 0
    },
    unlockedBuildings: [{
      upgrade_building_id: 222,
      required_building: {exists: false, name: 'Fabryka Mebli', level: 1, icon: 'furniture', group: 'furniture'},
      required_building_on_island: null,
      required_star: null,
      building_time_in_seconds: 300,
      fast_building_time_in_seconds: 0,
      build_currency_prizes: [],
      build_product_prizes: [],
      parameters: [{parameter_id: 3, income: 150, outcome: null}],
      building_id: 221,
      group_type: 'normal',
      group_limit: 1,
      group_count: 0,
      category_id: 1,
      player_concurrence_builds_reached_error_message: null,
      group: 'restaurant',
      group_description: 'Restauracja oferuje r&oacute;żnorodne dania, kt&oacute;re odpowiedzą na wymagania smakowe mieszkańc&oacute;w. Wpływa na poziom usług.',
      icon: 'restaurant',
      automatic_product: null,
      automatic_currency: null,
      level: 1,
      name: 'Restauracja',
      additional_details: '',
      required_level: 4,
      required_population: null,
      building_time: 300,
      building_time_in_days: null,
      fast_building_time: 0,
      repair_building_time: 0,
      build_product_prices: [{
        product_id: 15,
        category: {storage_id: 1, id: 2},
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        amount: 2
      }],
      exp_points: 212,
      attributes: [],
      probability_parameters: [],
      repair_prices: [],
      build_currency_prices: [{currency_id: 1, amount: 830}, {currency_id: 2, amount: 150}],
      fast_build_currency_prices: [],
      fast_build_product_prices: [],
      daily_product_productions: [],
      upgrade_button_name: null,
      auto_delete: false,
      variants: [],
      original_building_time_in_seconds: 300,
      original_build_currency_prices: [{currency_id: 1, amount: 830}, {currency_id: 2, amount: 150}],
      build_prices: [{currency_id: 1, amount: 830}, {currency_id: 2, amount: 150}],
      original_build_prices: [{currency_id: 1, amount: 830}, {currency_id: 2, amount: 150}],
      fast_build_prices: [],
      required_products: [{
        product_id: 15,
        category: {storage_id: 1, id: 2},
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        amount: 2
      }],
      probability_parameters_percentage: 0
    }],
    production: [{
      has_required_invention: false,
      currency_prizes: [],
      product_prizes: [{
        product_id: 15,
        category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
        name: 'Stół i krzesło',
        description: 'Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli',
        icon: 'furniture_table',
        required_level: 4,
        amount: 4
      }],
      production_recipe_id: 16,
      time_in_days: 2,
      time_in_minutes: null,
      invention: null,
      alternative_icon: null,
      alternative_name: null,
      production_product_prices: [{
        product_id: 1,
        category: {storage_id: 1, trading_currency_id: null, id: 2, name: 'Produkty', description: 'Produkty', icon: 'produkty'},
        name: 'Drewno',
        description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.',
        icon: 'wood',
        required_level: 1,
        amount: 12
      }],
      production_currency_prices: [{currency_id: 2, amount: 60}],
      fast_production_currency_prices: [],
      order: 16
    }]
  },
  ref: {
    buildingId: 127,
    playerTileId: 55170
  }
};
