import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../mock-dialogs/services/mock-dialog-list.service';
import { MOCK_BOARD, MOCK_BOARD_KEY } from '../consts/mock-board.const';

@Injectable({providedIn: 'root'})
export class MockBoardService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      if (key === MOCK_BOARD_KEY) {
        this.mockDialogListService.setMockApiData(
          MOCK_BOARD_KEY,
          MOCK_BOARD.api
        );
      }
    });
  }
}
