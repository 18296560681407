import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { Asset, AssetsService } from '../../core/providers/assets.service';

@Injectable({
  providedIn: 'root'
})
export class BudimexAssetsService extends AssetsService {
  getAssetsByFilenamePart(filenamePart: string, path: string = '') {
    const assetPath = `/${path}${filenamePart}`;
    const assets = Object.keys(this.assetsData).filter(_assetKey => {
      return this.assetsData[_assetKey].path.includes(assetPath);
    });
    return assets.map(assetKey => handleAssetPathBase(this.assetsData[assetKey]).path);
  }
}

function handleAssetPathBase(asset: Asset) {
  if (!asset) {
    return asset;
  }
  const returnAsset = R.clone(asset);
  returnAsset.path = asset.path;
  return returnAsset;
}
