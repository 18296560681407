import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { QUIZ_LOCATION } from '../../consts/core/qa-location.const';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiQaService } from '../../api/core/services/api-qa.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetQaListRequest } from '../../api/core/interfaces/get-qa-list-request.interface';
import { Qa } from '../../interfaces/core/qa.interface';
import { QA_TYPE } from '../../consts/core/qa-type.const';
import { GetQaEachRequest } from '../../api/core/interfaces/get-qa-each-request.interface';
import * as moment from 'moment';
import * as R from 'ramda';
import { PlayerService } from '../../../../../player/providers/player.service';
import { SynchronizeTimeService } from '../../../../../../core/providers/synchronize-time.service';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_QA } from '../../consts/core/event-dialogs/event-names.const';
@Directive()
@Injectable()
export abstract class AbstractQaListComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiQaService) apiQaService: ApiQaService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractQaListComponent>;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: {
    location?: number,
    qa?: Qa,
    type?: string,
    eachQaId?: number,
    isShowBackButton?: boolean;
  };
  STOCK_VIEW = STOCK_VIEW;
  QUIZ_LOCATION = QUIZ_LOCATION;
  QA_TYPE = QA_TYPE;
  allQa: Qa[];
  activeQa: Qa;
  currentPage = 0;
  canAnswerFrom;
  canAnswerTo;
  isExpiredQa: boolean;

  baseInit() {
    if (this.data.eachQaId) {
      this.getQaEach();
    } else {
      this.getQaList();
    }
  }

  openQaDetails() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_DETAILS,
      config: {
        disableClose: true,
        data: {
          qa: this.activeQa,
          prevState: {
            location: this.data.location,
            type: this.data.type,
            eachQaId: this.data.eachQaId,
          },
        }
      }
    });
  }

  startQa() {
    const startQa = this.apiQaService.startQa({
      qa_id: this.activeQa.qa_id,
      type: this.activeQa.type
    });
    startQa
      .subscribe(
        (resp) => {
          this.activeQa.last_attempt = resp;
          this.close();
          setTimeout(() => {
            this.openQaDetails();
          });
        });
  }

  changePage(page) {
    this.currentPage = page;
    const qa = this.searchQaByIndex(page);
    this.changeActiveQa(qa);
  }

  getQaList() {
    const getQaListRequest: GetQaListRequest = {
      location: this.data.location,
      type: this.data.type,
    };

    this.apiQaService.getQaList(getQaListRequest)
      .subscribe((resp) => {
          this.allQa = resp;
          this.afterQaList();
        });
  }

  getQaEach() {
    const getQaEachRequest: GetQaEachRequest = {
      qa_id: this.data.eachQaId,
    };

    this.apiQaService.getQaEach(getQaEachRequest)
      .subscribe((resp) => {
          this.allQa = [resp];
          this.afterQaList();
        });
  }

  checkWhenExpired() {
    const actualTime = moment(this.synchronizeTimeService.getActualLocalTime());
    const expiredTime = moment(this.activeQa.can_answer_to);
    const diff = moment(expiredTime).diff(actualTime);
    this.isExpiredQa = diff <= 0;
  }

  setCorrectlyFormatDates() {
    const canAnswerFromMoment = (() => moment(this.activeQa.can_answer_from))();
    const canAnswerToMoment = (() => moment(this.activeQa.can_answer_to))();

    if (this.isTheSameStart(canAnswerFromMoment) && this.isTheSameStart(canAnswerToMoment)) {
      const format = 'YYYY-MM-DD';
      this.canAnswerFrom = canAnswerFromMoment.format(format);
      this.canAnswerTo = canAnswerToMoment.subtract(1, 'days').format(format);
    } else {
      const format = 'YYYY-MM-DD HH:mm';
      this.canAnswerFrom = canAnswerFromMoment.format(format);
      this.canAnswerTo = canAnswerToMoment.format(format);
    }
  }

  isTheSameStart(date): boolean {
    return R.clone(date).unix() === R.clone(date).startOf('day').unix();
  }

  changeActiveQa(qa) {
    this.activeQa = qa;
    this.setCorrectlyFormatDates();
    this.checkWhenExpired();
  }

  searchQaByIndex(index: number) {
    return this.allQa[index];
  }

  setQaByIndex(index: number) {
    const qa = this.searchQaByIndex(index);
    if (qa) {
      this.changeActiveQa(qa);
    }
  }

  afterQaList() {
    let dataQa;

    if (this.data.qa) {
      dataQa = this.searchQaById(this.data.qa.qa_id);
    }

    if (dataQa) {
      this.changePage(this.allQa.indexOf(dataQa));
    } else {
      this.setQaByIndex(0);
    }
  }

  searchQaById(id: number) {
    return this.allQa.find((qa) => {
      return id === qa.qa_id;
    });
  }

  close() {
    this.matDialogRef.close();
  }
}
