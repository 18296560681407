import { SynchronizeTimeService } from '../providers/synchronize-time.service';
import * as moment from 'moment';
import { getToken } from './token';
import { InjectorVariable } from './injector-instance.helper';

export function isTokenExpired(): boolean {
  const synchronizeTimeService = InjectorVariable.instance.get<SynchronizeTimeService>(SynchronizeTimeService);

  if (!getToken().token) {
    return true;
  }

  const currentDate = moment(synchronizeTimeService.getActualLocalTime());
  const tokenExpiredAtDate = moment(getToken().expired_at);

  return currentDate.isAfter(tokenExpiredAtDate);
}
