import { EVENT_DIALOGS_NAMES_MISSION } from '../../consts/core/event-dialogs/event-names';

export const MOCK_MISSION_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
    name: 'Missions',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
    name: 'Mission Details',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_MISSION.MISSION_TRANSFER,
    name: 'Mission Transfer',
    available: true
  },
];
