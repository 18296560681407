import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { timer } from 'rxjs';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-current-step-quiz-game',
  templateUrl: './current-step-quiz-game.component.html',
})
export class CurrentStepQuizGameComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<CurrentStepQuizGameComponent>;

  subs = {
    counter: null,
  };
  counterValue = null;

  postStartTimer(){
    this.apiService.post('quiz-game/start').subscribe(() => {
      this.startCounter();
    })
  }


  startCounter(){
    this.counterValue = 3;
    this.subs.counter = timer(1000, 1000).subscribe(() => {
      this.counterValue -= 1;

      if(this.counterValue === 0){
        this.unsubscribeTimer();
        this.matDialogRef.close();
      }
    })
  }

  unsubscribeTimer(){
    unsubscribeObject(this.subs);
  }

  ngOnDestroy(){
    this.unsubscribeTimer();
  }
}
