import { Injectable } from '@angular/core';
import { getToken } from '../../../../core/utility/token';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../consts/core/routing-auth-absolute.const';
import { AuthService } from '../../providers/auth.service';
import { SynchronizeTimeService } from '../../../../core/providers/synchronize-time.service';
import { SocketActions, SocketSelectors, SocketState } from '../../../../store/socket';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { SOCKET_STATUS } from '../../../../core/consts/core/socket.constants';

@Injectable({
  providedIn: 'root'
})
export class ReauthorizeService {
  socketState: SocketState;

  constructor(
    private router: Router,
    private authService: AuthService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>,
  ) {
    this.subscribeSocketState();
  }

  subscribeSocketState() {
    this.store
      .pipe(
        select(SocketSelectors.selectState)
      )
      .subscribe((socketState) => {
        this.socketState = socketState;
      });
  }

  isAllowReauthorize() {
    let isAllowReauthorize = false;

    const reauthorizeAfterDate = this.getReauthorizeAfter();

    if (reauthorizeAfterDate) {
      const currentMomentDate = moment(this.synchronizeTimeService.getActualLocalTime());
      isAllowReauthorize = currentMomentDate.isAfter(moment(reauthorizeAfterDate));
    }

    return isAllowReauthorize;
  }

  getReauthorizeAfter() {
    return getToken().reauthorize_after;
  }

  isTokenRememberMe() {
    return !!getToken().reauthorize_after;
  }

  isSocketClearStatus() {
    return this.socketState.socketStatus === SOCKET_STATUS.CLEAR_STATUS;
  }

  checkReauthorizeAfter() {
    return this.checkLogicReauthorize();
  }

  checkLogicReauthorize() {
    if (this.isAllowReauthorize()) {
      if (this.isSocketClearStatus()) {
        return this.redirectToReauthorizeAndDisconnect();
      } else {
        return this.reauthorize();
      }
    }

    return true;
  }

  redirectToReauthorizeAndDisconnect() {
    this.store.dispatch(new SocketActions.SocketDisconnect());
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REAUTHORIZE]);
    return false;
  }

  reauthorize(): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.reauthorize().subscribe(() => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }
}
