<div class="global-dialog">
  <div *ngIf="currentTime !== null" class="timer-container">
    <div class="icon"></div>
    {{ currentTime | owDuration2: "mm:ss" }}
  </div>

  <div class="content" *ngIf="currentTime !== null" [ngStyle]="{ 'background-image': 'url(\'' +(questionInfo['background_image'] | asset:'ui')+'\')' }">
    <div
      class="quiz-answer"
      *ngFor="let answer of questionInfo.answers"
      [style.width]="answer.width+'px'"
      [style.height]="answer.height+'px'"
      [style.top]="answer.top+'px'"
      [style.left]="answer.left+'px'"
      [class.show-checked]="answer?.is_checked"
      (click)="answerToggle(answer)"
    ></div>
  </div>
  <button (click)="submitAnswer()" class="short primary bottom-button loading-opacity" [disabled]="isLoadingAnswer">Odpowiedz</button>
</div>
