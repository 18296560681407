import { EVENT_DIALOGS_NAMES_HUD } from '../../const/core/event-dialogs/event-names.const';

export const MOCK_HUD_DIALOGS = [
  {
    key: EVENT_DIALOGS_NAMES_HUD.PLAYER_DETAILS,
    name: 'Player Details',
    available: true
  },
  {
    key: EVENT_DIALOGS_NAMES_HUD.USER_SETTINGS,
    name: 'User Settings',
    available: false
  }
];
