import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../../../../core/providers/api.service';
import { ApiOptions } from '../../../../core/interfaces/api-options';
import { Observable } from 'rxjs';
import { RRProgressResponse } from '../interfaces/progress.interface';
import { RRStatus } from '../interfaces/status.interface';
import { MinigameStats } from '../interfaces/stats.interface';

@Injectable({
  providedIn: 'root'
})
export class RiverRideService {
  rrEvents = new EventEmitter();

  constructor(private apiService: ApiService) {
  }

  stats(): Observable<MinigameStats> {
    return this.apiService.get('minigame/stats');
  }

  unfinished(): Observable<RRStatus> {
    return this.apiService.get('minigame/unfinished');
  }

  start({game}: { game: number}): Observable<RRStatus> {
    return this.apiService.post(`minigame/${game}/start`);
  }

  finish({mini_game_id, progress}: { mini_game_id: number, progress: string }): Observable<RRProgressResponse> {
    const options: ApiOptions = {
      body: {
        progress,
      }
    };

    return this.apiService.post(`minigame/${mini_game_id}/finish`, options);
  }

  progress({mini_game_id, progress}: { mini_game_id: number, progress: string }) {
    const options: ApiOptions = {
      body: {
        progress,
      }
    };

    return this.apiService.post(`minigame/${mini_game_id}/progress`, options);
  }
}
