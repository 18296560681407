import { Component, HostBinding, OnInit } from '@angular/core';
import { BASIC_SCENE_CONFIG } from '../../../scenes-basic/basic-scene.config';
import { GameService } from '../../../../services/game.service';
import { BASIC_BOOT_SCENE } from '../../../scenes-basic/basic.constants';
import { MyScene } from '../../../classes/core/MyScene';
import { MyGame } from '../../../classes/core/MyGame';
import { GameFeaturesMap } from '../../../../constants/game-features';

@Component({
  selector: 'app-game-debug',
  templateUrl: './game-debug.component.html',
})
export class GameDebugComponent implements OnInit {
  expandMap: { [name: string]: boolean } = {};
  @HostBinding('class.isOpen')
  isOpen: Boolean = false;

  gameFeaturesMap = GameFeaturesMap;

  sceneConfig = BASIC_SCENE_CONFIG;
  currentScene: MyScene;
  game: MyGame;

  constructor(private gameService: GameService) { }

  showScreenToIsoConverter() {
    this.expandMap.isoToScreen = !this.expandMap.isoToScreen;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  restartScene() {
    this.gameService.game.currentScene.scene.start(BASIC_BOOT_SCENE);
  }

  ngOnInit(): void {
    this.game = this.gameService.game;
  }

  showInputArea() {
    this.game.currentScene.board.debugTilesInput();
  }

  showTileInfo() {
    this.game.currentScene.board.debugTiles();
  }

  reloadGame() {
    location.reload()
  }
}
