import { StorageLocationBase } from './location-base.class';

export class StorageLocationWeb extends StorageLocationBase {
  isSupported() {
    try {
      const key = 'checkSupportedWebStorage';
      sessionStorage.setItem(key, key);
      sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      return this.noSupported();
    }
  }

  get(key) {
    return this.isSupported() !== this.noSupported() ? localStorage.getItem(key) || sessionStorage.getItem(key) : this.noSupported();
  }

  set(key, value, parameters = {}) {
    const storage = parameters['expired_at'] ? localStorage : sessionStorage;
    return this.isSupported() !== this.noSupported() ? storage.setItem(key, value) : this.noSupported();
  }

  remove(key) {
    return this.isSupported() !== this.noSupported() ? (() => {
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
    })() : this.noSupported();
  }

  clear() {
    return this.isSupported() !== this.noSupported() ? (() => {
      sessionStorage.clear();
      localStorage.clear();
    })() : this.noSupported();
  }
}
