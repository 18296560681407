import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RiverRideGame } from '../../classes/RiverRideGame.class';
import { GameService } from '../../../../game/services/game.service';
import { RiverRidePlayScene } from '../../scenes/RiverRidePlay.scene';
import { RiverRideService } from '../../services/river-ride.service';
import { RREditorScene } from '../../scenes/RiverRideEditor.scene';
import { RRGameParams } from '../../river-ride.interfaces';
import ScaleModes = Phaser.Scale.ScaleModes;

@Component({
  selector: 'app-minigame-river-ride',
  templateUrl: './minigame-river-ride.component.html',
})
export class MinigameRiverRideComponent implements AfterViewInit, OnDestroy {
  game: RiverRideGame;

  constructor(private gameService: GameService,
              private riverRideService: RiverRideService,
              private cd: ChangeDetectorRef,
  ) {
  }

  @Input() parameters: RRGameParams;
  @Output() fromGameEvent = new EventEmitter<any>();
  editMode = Boolean(location.search.includes('edit-mode'));

  ngAfterViewInit() {
    // this.gameService.pauseGame();

    console.log(this.parameters);
    this.game = new RiverRideGame({
      parent: 'minigame-river-ride',
      scene: [this.editMode ? RREditorScene : RiverRidePlayScene],
      gameService: this.gameService,
      riverRideService: this.riverRideService,
      width: 1000,
      height: 570,
      editMode: this.editMode,
      gameParams: this.parameters,
      input: {
        activePointers: 3
      },
      // fps: {
      //   target: 10,
      //   forceSetTimeOut: true
      // },
      scale: {
        mode: ScaleModes.FIT
      }
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.game.destroy(true);
    this.gameService.resumeGame();
  }
}
