import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingsListComponent } from '../../../../abstract/core/abstract-buildings-list.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-m-buildings-list',
  templateUrl: './m-buildings-list.component.html',

})
export class MBuildingsListComponent extends AbstractBuildingsListComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribePlayer();
    this.subscribeBoardTile();
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
