import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { Observable } from 'rxjs';
import { GroupMissionDetailsInterface, GroupMissionInterface } from '../../../interfaces/core';

@Injectable({
  providedIn: 'root'
})
export class ApiMissionGroupService {

  constructor(private apiService: ApiService) {
  }

  getMissionGroups(): Observable<GroupMissionInterface[]> {
    return this.apiService.get('mission-groups');
  }

  getMissionGroupsDetails(mission_group_id: number): Observable<GroupMissionDetailsInterface> {
    return this.apiService.get(`mission-groups/${mission_group_id}`);
  }

  getMissionGroupReward(mission_group_id: number) {
    return this.apiService.post(`mission-groups/${mission_group_id}/reward`);
  }
}
