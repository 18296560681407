import { Component } from '@angular/core';
import { CoreAbstractHistoryTransactionBaseComponent } from '../../../../abstract/core/abstract-history-transaction-base.component';

@Component({
  selector: 'app-core-transaction-history',
  templateUrl: './transaction-history.component.html',

})
export class CoreTransactionHistory extends CoreAbstractHistoryTransactionBaseComponent {

}
