export const RANK_CONFIG = {
  DEFAULT: {
    columns: [
      { header: null, value: "position" },
      { header: null, custom: true, template: "avatar" },
      { header: null, custom: true, template: "fullName" },
      { columnFromApi: 1, textAlign: "center" },
      { columnFromApi: 2, textAlign: "center" },
      { columnFromApi: 3, textAlign: "center" },
      { header: null, custom: true, template: "playerRank" },
    ],
  },
  TROPHY: {
    columns: [
      { header: null, value: "position" },
      { header: null, custom: true, template: "avatar" },
      { header: null, custom: true, template: "fullName" },
      { columnFromApi: 1, textAlign: "center" },
      { header: null, custom: true, template: "trophy" },
    ],
  },
};
