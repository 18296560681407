<div class="global-dialog">
  <div *ngIf="currentTime !== null" class="timer-container">
    <div class="icon"></div>
    {{ currentTime | owDuration2: "mm:ss" }}
  </div>

  <ng-container *ngIf="currentTime !== null">
    <div class="question">
      <!-- YOUTUBE -->
      <div *ngIf="data.questionInfo.youtube_id as youtubeId" class="slider">
        <div class="youtube-container">
          <iframe
            [src]="
              'https://www.youtube.com/embed/' +
                youtubeId +
                '?ecver=1&amp;autoplay=1&amp;cc_load_policy=1&amp;iv_load_policy=3&amp;rel=0&amp;showinfo=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560'
                | safe: 'resourceUrl'
            "
            allowtransparency="true"
            frameborder="0"></iframe>
        </div>
      </div>

      <!-- SLIDER -->
      <div *ngIf="!data.questionInfo.youtube_id && data.questionInfo.slider as slider" class="slider">
        <div *ngIf="slider.length > 1" class="controls">
          <button class="control prev" (click)="prevSlide()">
            <i class="fas fa-chevron-left"></i>
          </button>

          <button class="control right" (click)="nextSlide()">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>

        <img *ngFor="let slide of slider; let index = index" [src]="slide" [class.active]="index === currentSliderIndex" />
      </div>

      <!-- CONTENT -->
      <div *ngIf="data.questionInfo.content" class="content">
        {{ data.questionInfo.content }}
      </div>
    </div>
    <perfect-scrollbar scrollGradient>
      <div class="answers loading-opacity">
        <loading></loading>

        <div *ngFor="let answer of data.questionInfo.answers; let index = index" class="answer">
          <div class="answer-char">
            {{ QUESTION_CHAR[index] }}
          </div>

          <button class="square small checkbox no-before-shadow" (click)="setAnswer(answer)">
            <i *ngIf="answer.answer_id === playerAnswer?.answer_id" class="fas fa-circle"></i>
          </button>

          <div class="content">
            {{ answer.content }}
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <button *ngIf="playerAnswer" (click)="submitAnswer()" class="short primary bottom-button loading-opacity" [disabled]="isLoadingAnswer">
      Odpowiedz
    </button>
  </ng-container>
</div>
