import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingMoveComponent } from '../../../../abstract/core/abstract-building-move.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-m-building-move',
  templateUrl: './building-move.component.html',

})
export class MBuildingMoveComponent extends AbstractBuildingMoveComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribePrimary();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
