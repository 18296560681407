export const MOCK_MESSAGE_DETAILS_LEVEL_UP_BUILDINGS = 'message-details-level-up-buildings';
export const MOCK_MESSAGE_DETAILS_LEVEL_UP_PRODUCTS = 'message-details-level-up-products';

export const MOCK_MESSAGE_DETAILS_LEVEL_UP = {
  api: {
    buildings: [
      {
        required_building: {exists: true, name: 'Tartak', level: 1, icon: 'sawmill', group: 'sawmill'},
        build_currency_prizes: [],
        build_product_prizes: [],
        parameters: [{parameter_id: 3, income: 80, outcome: null}],
        building_id: 37,
        group_limit: 1,
        group_count: 1,
        category_id: 1,
        player_concurrence_builds_reached_error_message: null,
        group: 'market',
        building_type: 1,
        icon: 'market',
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: 'Ryneczek',
        additional_details: '',
        required_level: 3,
        required_population: null,
        build_product_prices: [],
        exp_points: 112,
        probability_parameters: [],
        repair_prices: [],
        build_currency_prices: [{currency_id: 1, amount: 440}, {currency_id: 2, amount: 150}],
        fast_build_currency_prices: [],
        fast_build_product_prices: [],
        upgrade_button_name: null,
        auto_delete: false,
        variants: [],
        original_build_currency_prices: [{currency_id: 1, amount: 440}, {currency_id: 2, amount: 150}],
        build_prices: [{currency_id: 1, amount: 440}, {currency_id: 2, amount: 150}],
        original_build_prices: [{currency_id: 1, amount: 440}, {currency_id: 2, amount: 150}],
        fast_build_prices: [],
        required_products: []
      }, {
        required_building: null,
        build_currency_prizes: [],
        build_product_prizes: [],
        parameters: [],
        building_id: 152,
        group_limit: 1,
        group_count: 1,
        category_id: 1,
        player_concurrence_builds_reached_error_message: null,
        group: 'sawmill',
        building_type: 3,
        icon: 'sawmill',
        automatic_product: null,
        automatic_currency: null,
        level: 2,
        name: 'Tartak',
        additional_details: '',
        required_level: 3,
        required_population: 120,
        build_product_prices: [],
        exp_points: 196,
        probability_parameters: [],
        repair_prices: [],
        build_currency_prices: [{currency_id: 1, amount: 760}, {currency_id: 2, amount: 500}],
        fast_build_currency_prices: [],
        fast_build_product_prices: [],
        upgrade_button_name: null,
        auto_delete: false,
        variants: [],
        original_build_currency_prices: [{currency_id: 1, amount: 760}, {currency_id: 2, amount: 500}],
        build_prices: [{currency_id: 1, amount: 760}, {currency_id: 2, amount: 500}],
        original_build_prices: [{currency_id: 1, amount: 760}, {currency_id: 2, amount: 500}],
        fast_build_prices: [],
        required_products: []
      }],
    products: []
  },
  ref: {
    message: {
      read: true,
      title: 'Awans',
      tip: '',
      teaser: '',
      description: 'Twoje miasto awansowało na kolejny poziom rozwoju.',
      player_message_id: 129948,
      generic_type: 'level_up',
      type: 1,
      category_name: 'Świat gry',
      sender_group_id: 1,
      sender_name: 'Awans',
      sender_name_legacy: 'Awans',
      sender_icon: 'lvlup',
      gallery: null,
      mobile_gallery: [],
      parameters: {level: 3},
      deleted: true,
      created_at: '2021-02-03T09:46:41+01:00'
    }, fromList: true
  }
};
