import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppState } from '../../../store/state';
import { Store } from '@ngrx/store';
import { LoginActions } from '../../../store/auth/login';
import { SynchronizeTimeService } from '../../../core/providers/synchronize-time.service';
import { isOnWhitelist } from '../helpers/is-on-whitelist.helpers';
import { isTokenExpired } from '../../../core/utility/is-expired-token.helper';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  WHITE_LIST = [
    'user/change-password'
  ];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private synchronizeTimeService: SynchronizeTimeService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const cloned = req.clone();

    const isUrlOnWhitelist = this.WHITE_LIST.find(url => req.url.indexOf(url) !== -1);

    if (req.headers.has('X-Token') && !isUrlOnWhitelist) {
      const tokenHeader = req.headers.get('X-Token');

      if (!tokenHeader || tokenHeader === 'owEmptyToken') {
        this.tokenExpired();
      }

      if (isTokenExpired()) {
        this.tokenExpired();
      }
    }

    return next.handle(cloned).pipe(
      catchError(error => {
        if (!isOnWhitelist(cloned.url)) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              this.store.dispatch(new LoginActions.AuthLogout());
              break;

          }
        }

        return throwError(error);
      }));
  }

  tokenExpired() {
    this.store.dispatch(new LoginActions.AuthLogout());
    return throwError({message: 'tokenExpired'});
  }
}

