import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { EVENT_DIALOGS_NAMES_HUD } from '../../const/core/event-dialogs/event-names.const';
import { MOCK_PLAYER_DETAILS } from '../consts/mock-player-details.const';

@Injectable({providedIn: 'root'})
export class MockHudService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_HUD.PLAYER_DETAILS: {
          this.mockDialogListService.setDialogRef(MOCK_PLAYER_DETAILS.ref);
          break;
        }
      }
    });
  }
}
