export const MOCK_BUILDING_LIST = {
  api: [{
    required_building: {exists: false, name: 'Tartak', level: 2, icon: 'sawmill', group: 'sawmill'},
    build_currency_prizes: [],
    build_product_prizes: [{product_id: 15, amount: 4}],
    parameters: [],
    building_id: 127,
    group_limit: 1,
    group_count: 0,
    category_id: 1,
    player_concurrence_builds_reached_error_message: null,
    group: 'furniture',
    building_type: 3,
    icon: 'furniture',
    automatic_product: null,
    automatic_currency: null,
    level: 1,
    name: 'Fabryka Mebli',
    additional_details: '',
    required_level: 4,
    required_population: 140,
    build_product_prices: [],
    exp_points: 346,
    probability_parameters: [],
    repair_prices: [],
    build_currency_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
    fast_build_currency_prices: [],
    fast_build_product_prices: [],
    upgrade_button_name: null,
    auto_delete: false,
    variants: [],
    original_build_currency_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
    build_prices: [{
      currency_id: 1,
      amount: 1360,
      balance: 143410,
      type: 'player',
      key: 'brick',
      name: 'Cegły',
      description: 'Cegły',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: '<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>',
      position: 1,
      iconUrlBig: '/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900',
      iconUrl: '/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f',
      have: true
    }, {
      currency_id: 2,
      amount: 500,
      balance: 5351,
      type: 'player',
      key: 'coinsilver',
      name: 'Srebrne talary',
      description: 'Srebrne talary',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: true,
      tooltip: '<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>',
      position: 2,
      iconUrlBig: '/main/assets/currency/big/coinsilver.png?3ead6c13d3872b8ba40845025dd57aa2',
      iconUrl: '/main/assets/currency/basic/coinsilver.png?7e7a5e31fbb8aa1bfe2f25661f44fa12',
      have: true
    }],
    original_build_prices: [{currency_id: 1, amount: 1360}, {currency_id: 2, amount: 500}],
    fast_build_prices: [],
    required_products: [],
    orderInList: 2,
    buildPricesDisplay: [{
      currency_id: 1,
      amount: 1360,
      balance: 143410,
      type: 'player',
      key: 'brick',
      name: 'Cegły',
      description: 'Cegły',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: '<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>',
      position: 1,
      iconUrlBig: '/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900',
      iconUrl: '/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f',
      have: true
    }, {
      currency_id: 2,
      amount: 500,
      balance: 5351,
      type: 'player',
      key: 'coinsilver',
      name: 'Srebrne talary',
      description: 'Srebrne talary',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: true,
      tooltip: '<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>',
      position: 2,
      iconUrlBig: '/main/assets/currency/big/coinsilver.png?3ead6c13d3872b8ba40845025dd57aa2',
      iconUrl: '/main/assets/currency/basic/coinsilver.png?7e7a5e31fbb8aa1bfe2f25661f44fa12',
      have: true
    }]
  }, {
    required_building: null,
    build_currency_prizes: [],
    build_product_prizes: [],
    parameters: [{parameter_id: 1, income: 20, outcome: null}],
    building_id: 1,
    group_limit: 6,
    group_count: 6,
    category_id: 1,
    player_concurrence_builds_reached_error_message: null,
    group: 'house',
    building_type: 1,
    icon: 'house',
    automatic_product: null,
    automatic_currency: null,
    level: 1,
    name: 'Dom',
    additional_details: '',
    required_level: 1,
    required_population: null,
    build_product_prices: [{
      product_id: 1,
      category: {storage_id: 1, id: 2},
      name: 'Drewno',
      description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.',
      icon: 'wood',
      amount: 2
    }],
    exp_points: 69,
    probability_parameters: [],
    repair_prices: [],
    build_currency_prices: [{currency_id: 1, amount: 270}, {currency_id: 2, amount: 100}],
    fast_build_currency_prices: [],
    fast_build_product_prices: [],
    upgrade_button_name: null,
    auto_delete: false,
    variants: [],
    original_build_currency_prices: [{currency_id: 1, amount: 270}, {currency_id: 2, amount: 100}],
    build_prices: [{
      currency_id: 1,
      amount: 270,
      balance: 143410,
      type: 'player',
      key: 'brick',
      name: 'Cegły',
      description: 'Cegły',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: '<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>',
      position: 1,
      iconUrlBig: '/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900',
      iconUrl: '/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f',
      have: true
    }, {
      currency_id: 2,
      amount: 100,
      balance: 5351,
      type: 'player',
      key: 'coinsilver',
      name: 'Srebrne talary',
      description: 'Srebrne talary',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: true,
      tooltip: '<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>',
      position: 2,
      iconUrlBig: '/main/assets/currency/big/coinsilver.png?3ead6c13d3872b8ba40845025dd57aa2',
      iconUrl: '/main/assets/currency/basic/coinsilver.png?7e7a5e31fbb8aa1bfe2f25661f44fa12',
      have: true
    }],
    original_build_prices: [{currency_id: 1, amount: 270}, {currency_id: 2, amount: 100}],
    fast_build_prices: [],
    required_products: [{
      product_id: 1,
      category: {storage_id: 1, id: 2},
      name: 'Drewno',
      description: 'Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynków. Miejsce obróbki: Tartak.',
      icon: 'wood',
      amount: 2
    }],
    orderInList: 4,
    buildPricesDisplay: [{
      currency_id: 1,
      amount: 270,
      balance: 143410,
      type: 'player',
      key: 'brick',
      name: 'Cegły',
      description: 'Cegły',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: false,
      tooltip: '<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>',
      position: 1,
      iconUrlBig: '/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900',
      iconUrl: '/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f',
      have: true
    }, {
      currency_id: 2,
      amount: 100,
      balance: 5351,
      type: 'player',
      key: 'coinsilver',
      name: 'Srebrne talary',
      description: 'Srebrne talary',
      show_currency_history: true,
      is_gross: false,
      is_default_trading_currency: true,
      tooltip: '<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>',
      position: 2,
      iconUrlBig: '/main/assets/currency/big/coinsilver.png?3ead6c13d3872b8ba40845025dd57aa2',
      iconUrl: '/main/assets/currency/basic/coinsilver.png?7e7a5e31fbb8aa1bfe2f25661f44fa12',
      have: true
    }]
  }],
  ref: {
    playerTileId: 55170
  }
};
