import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '../../../../shared/providers/dialog.service';
import { ApiService } from '../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../core/interfaces/api-options';
import { translate } from '../../../../../core/helpers/translate.helper';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../consts/core/routing-auth-absolute.const';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';


@Directive()
@Injectable()
export abstract class AbstractRegistrationAComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  form: FormGroup;

  clearForm() {
    this.form = this.fb.group({
      firstName: this.fb.control(null, [Validators.required]),
      lastName: this.fb.control(null, [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      phone: this.fb.control(null, [Validators.required]),
    });
  }

  registration() {
    const data: RegistrationRequestData = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email,
      phone: this.form.value.phone,
    };

    this.registrationRequest(data)
      .subscribe(() => {
        this.openAlertSuccess();
        this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
      });
  }

  registrationRequest(data: RegistrationRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/wrong-data', options);
  }

  openAlertSuccess() {
    this.dialogService.openAlert({
      title: translate('auth.registration-a.alert-success.title'),
      description: translate('auth.registration-a.alert-success.description'),
    });
  }
}

interface RegistrationRequestData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}
