import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionInfoSliderImages } from '../../../interfaces/custom/question.info';
import { timer } from 'rxjs';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-quiz-slider',
  templateUrl: './quiz-slider.component.html',
})
export class QuizSliderComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QuizSliderComponent>
  @OwInject(MAT_DIALOG_DATA) data: {
    questionInfoSliderImages: Array<QuestionInfoSliderImages>;
    manualClose: boolean;
  };

  currentIndex = 0;
  currentTime = null;

  subs = {
    timer: null,
  }

  afterConstructor(){
    this.matDialogRef.disableClose = true;
    super.afterConstructor();
  }

  ngOnInit(){
    this.setSlide(0);
  }

  setSlide(index: number){
    this.currentIndex = index;
    if(this.currentIndex <= this.data.questionInfoSliderImages.length - 1){
      this.currentTime = this.data.questionInfoSliderImages[this.currentIndex].length;

      if(this.currentTime){
        this.startSliderTimer();
      }
    }
  }

  nextSlide(){
    if(this.currentIndex < this.data.questionInfoSliderImages.length - 1){
      this.setSlide(this.currentIndex + 1);
    } else {
      this.close();
    }
  }

  startSliderTimer(){
    this.unsubscribeTimer();

    this.subs.timer = timer(1000, 1000).subscribe(() => {
      this.currentTime--;

      if(this.currentTime <= 0){
        this.unsubscribeTimer();
        this.nextSlide();
      }
    })
  }

  unsubscribeTimer(){
    unsubscribeObject(this.subs);
  }

  close(){
    this.matDialogRef.close();
  }

  ngOnDestroy(){
    this.unsubscribeTimer();
  }
}
