import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { EVENT_DIALOGS_NAMES_QA } from '../../consts/core/event-dialogs/event-names.const';
import { MOCK_QA_LIST } from '../conts/mock-qa-list.const';
import { MOCK_QA_DETAILS } from '../conts/mock-qa-details.const';
import { MOCK_QA_FINISH } from '../conts/mock-qa-finish.const';

@Injectable({providedIn: 'root'})
export class MockQaService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_QA.QA_LIST: {
          this.mockDialogListService.setDialogRef(MOCK_QA_LIST.ref);
          this.mockDialogListService.setMockApiData(
            EVENT_DIALOGS_NAMES_QA.QA_LIST,
            MOCK_QA_LIST.api
          );
          break;
        }
        case EVENT_DIALOGS_NAMES_QA.QA_DETAILS: {
          this.mockDialogListService.setDialogRef(MOCK_QA_DETAILS.ref);
          break;
        }
        case EVENT_DIALOGS_NAMES_QA.QA_FINISH: {
          this.mockDialogListService.setDialogRef(MOCK_QA_FINISH.ref);
          break;
        }
      }
    });
  }
}
