import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { NavigationUi } from '../interfaces/navigation-ui.interface';

@Directive()
@Injectable()
export abstract class AbstractNavigationComponent {
  @Input() navigation: NavigationUi;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  pageChange(page: number) {
    this.pageChanged.emit(page);
  }
}
