import { MapObject } from './MapObject';
import { TILE_HEIGHT } from '../../../constants';

export class IsoMapLayer extends Phaser.GameObjects.Group {

  x: number;
  y: number;
  depthIndex: number;
  name: string;

  constructor(params: IsoLayerConfig) {

    super(params.scene);
    this.name = params.name;
    this.depthIndex = params.depthIndex ? params.depthIndex : 0;

    if (params.objects) {
      params.objects.forEach((objectData) => {
        let object;
        if (objectData.atlas) {
          object = new MapObject({
            scene: params.scene,
            x: objectData.x,
            y: objectData.y,
            key: objectData.atlas,
            frame: objectData.name,
          });
          object.setData('properties', objectData.properties);
        } else {
          object = new MapObject({scene: params.scene, x: objectData.x, y: objectData.y, key: objectData.name});
        }

        object.depthIndex = this.depthIndex;
        object.calculateDepth();

        this.add(object);
        object.setAlpha(1);
        object.setOrigin(0.5, 1);

        /**
         * Change sprite settings if layer is tilelayer.
         */
        if (params.isTileLayer) {
          // this.enableDebug(object);
          object.y += TILE_HEIGHT + objectData.offset.y;
          // object.setOrigin(0.5, 0);
          object.customData.tileData = objectData;
        }
      });
    }
    params.scene.add.existing(this);
  }

  private enableDebug(object) {
    object.setInteractive();
    object.on('pointerup', (pointer) => {
      console.log(object);
      console.log(object.customData.tileData);
    });

    object.on('pointerover', (pointer) => {
      object.setTint(0xff0000);
    });

    object.on('pointerout', (pointer) => {
      object.setTint(0xffffff);
    });
  }
}

export interface IsoLayerConfig {
  scene;
  name?: string;
  objects?: any[];
  textureKey?: string;
  depthIndex?: number;
  isTileLayer?: boolean;
}

function rgbToHex(rgb) {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
}
