import { STORAGE_LOCATION_TYPE } from './location-type.const';
import { StorageLocationCookie } from '../../../storage/core/location/location-cookie.class';
import { StorageLocationWeb } from '../../../storage/core/location/location-web.class';
import { StorageLocationVariableJs } from '../../../storage/core/location/location-variable-js.class';
import { APP_CONFIG } from '../../../../../config';

export const STORAGE_DEFINITIONS = {
  WEB_STORAGE: new StorageLocationWeb({
    type: STORAGE_LOCATION_TYPE.WEB_STORAGE
  }),

  COOKIE: new StorageLocationCookie({
    type: STORAGE_LOCATION_TYPE.COOKIE,
    options: {
      path: APP_CONFIG.baseHref,
    }
  }),

  VARIABLE_JS: new StorageLocationVariableJs({
    type: STORAGE_LOCATION_TYPE.VARIABLE_JS
  })
};
