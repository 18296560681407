<div class="global-dialog ow-dialog no-transparent" [class.padding-bottom]="rank && !rankResults?.my_position">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="rank">
    <!-- RANK NAME -->
    <div class="rank-title">
      {{ rank.name }}
    </div>

    <!-- RANK DETAILS -->
    <div
      class="rank-details-container loading-opacity"
      *ngIf="rankResults"
    >
      <ng-container *ngIf="rankResults.results.length; else noItemsTpl">
        <div class="header">
          <perfect-scrollbar class="height-auto">
            <ranking-game-table
              [columns]="RANK_CONFIG.columns"
              [rankResults]="rankResults"
              [rank]="rank"
              [show]="'header'"
            ></ranking-game-table>
          </perfect-scrollbar>
        </div>

        <!-- RANK RESULTS -->
        <perfect-scrollbar>
          <ranking-game-table
            [columns]="RANK_CONFIG.columns"
            [rankResults]="rankResults"
            [rank]="rank"
            [show]="'results'"
          ></ranking-game-table>
        </perfect-scrollbar>

        <!-- MY POSITION -->
<!--        <div class="my-position-container" *ngIf="rankResults.my_position">-->
          <div class="my-position-container">

          <!-- LABEL -->
          <label>{{ 'ranks.rank-details-game.my-position' | myTranslate }}</label>

          <perfect-scrollbar class="height-auto my-position">
            <ranking-game-table
              [rankResults]="rankResults"
              [rank]="rank"
              [columns]="RANK_CONFIG.columns"
              [show]="'myPosition'"
            ></ranking-game-table>
          </perfect-scrollbar>
        </div>
      </ng-container>
      <ng-template #noItemsTpl>
        <div class="flex-center no-items">
          Aktualnie brak graczy spełniających założenia tego rankingu.
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
