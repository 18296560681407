import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CURRENT_STEP } from '../consts/custom/current-step.const';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { PlayerSelectors } from '../../../store/player';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentStepService {
  currentStep = new BehaviorSubject(CURRENT_STEP.NULL);

  constructor(
    private store: Store<AppState>
  ) {
    this.subscribePlayer();
  }

  subscribePlayer(){
    this.store.pipe(
      select(PlayerSelectors.selectPlayer),
      map(player => player['current_step']),
      distinctUntilChanged(),
    )
    .subscribe((currentStep) => {
      console.log(new Date(), 'Receiver new step: ' + currentStep);
      this.currentStep.next(currentStep);
    });
  }
}
