<div class="time-test" [class.active]="isActive" *ngIf="globalService.isDevDomain">
  <button
    class="button-toggle" (click)="toggle()"
    [class.text-error]="errorAssetsLength > 0"
  >
    <i class="fas fa-arrow-alt-up"></i>
  </button>

  <p>{{timer$ | async}}
    <span class="zoom"><b>zoom:</b> {{ window['cameraObject']?.zoom | number:'0.2-2' }}</span>
  </p>

  <p *ngIf="currentTimeToNextClick">Allow next click: {{ currentTimeToNextClick }}s</p>

  <div class="flex-center">
    <button
      *ngFor="let button of buttons"
      (click)="testSpeedUp(button)"
      [disabled]="currentTimeToNextClick"
    >
      {{ button.label }}
    </button>

    <button (click)="toggleLogger()">LOG</button>

    <button (click)="toggleShortkeys()">SHORTKEYS</button>

    <button
      (click)="logErrorAssets()"
      [class.text-error]="errorAssetsLength > 0"
    >
      Error Assets ({{ errorAssetsLength }})
    </button>
  </div>

  <div class="shortkeys-container" *ngIf="isActiveShortKeys">
    <ng-container *ngFor="let shortkey of shortkeys">
      <br/>
      {{ shortkey.label }}{{ shortkey.code }}:
      <select
        (ngModelChange)="selectButton($event, shortkey)"
        [ngModel]="shortkey.button?.time"
      >
        <option
          *ngFor="let button of buttons"
          [value]="button.time"
        >
          {{ button.label }}
        </option>
      </select>
    </ng-container>

    <button (click)="loadShortKeysFromLS()">LOAD FROM LS</button>
  </div>
</div>

<div id="ow-logger" [class.show]="isActive && isActiveLogger" *ngIf="globalService.isDevDomain"></div>
