import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { ApiOptions } from '../interfaces/api-options';
import * as moment from 'moment';
import { GlobalService } from './global.service';
import { map } from 'rxjs/operators';
import { DialogService } from '../../modules/shared/providers/dialog.service';
import { SocketActions } from '../../store/socket';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state';
import { UtilityActions } from '../../store/utility';

@Injectable()
export class BuildVersionService {
  socketActions = SocketActions;
  timeout: number;
  timeInSecondsToCheckBuild = 60;
  isVersionGameChanged: boolean;

  constructor(
    private apiService: ApiService,
    private globalService: GlobalService,
    private store: Store<AppState>,
  ) {
    setTimeout(() => {
      if (environment.production) {
        this.setGameVersion();
      }
    }, 0);
  }

  private setGameVersion() {
    this.getLatestGameVersion()
      .subscribe((resp) => {
        document['GAME_VERSION_TIMESTAMP'] = resp;
        this.checkGameVersion();
        this.checkVersionGameChangedAndSendEvent();
      });
  }

  private checkGameVersion() {
    this.clearTimeout();

    this.timeout = setTimeout(() => {
      this.getLatestGameVersion()
        .subscribe((resp) => {
          if (resp !== document['GAME_VERSION_TIMESTAMP']) {
            this.isVersionGameChanged = true;
          } else {
            this.checkGameVersion();
          }
        });
    }, this.timeInSecondsToCheckBuild * 1000);
  };

  private clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private getLatestGameVersion() {
    const options: ApiOptions = {
      params: {
        timestamp: moment().format()
      },
      contentTypeAuto: true,
      responseType: 'text',
      sendRequestWithoutToken: true,
      useBaseUrl: true,
      isNotShowLoading: true,
    };

    return this.apiService.get('build.txt', options)
      .pipe(
        map((resp) => {
          return resp.trim();
        })
      );
  }

  checkVersionGameChangedAndSendEvent() {
    setTimeout(() => {
      if (this.isVersionGameChanged && DialogService.openDialogs.length === 0) {
        this.store.dispatch(new UtilityActions.DifferentVersionGame());
      } else {
        this.checkVersionGameChangedAndSendEvent();
      }
    }, 3000);
  }
}
