import { GAME_EVENTS, PLAYER_BUILDING_STATUS_BUILT } from '../../../constants';
import { TileMenuButtonConfig } from '../../interfaces/tile-menu-config.interface';
import { getAdditionalBoardData, isAutoProduction, isProduction } from '../../../game-gui/helpers/buildings.helper';
import { ADDITIONAL_BOARD_DATA } from '../../config/additional-board-data.config';
import { CUSTOM_BUTTONS_CONFIG } from './custom-buttons-config';

export const BUTTONS_CONFIG: { [buttonName: string]: TileMenuButtonConfig } = {
  info: {
    x: -100,
    y: -100,
    name: 'tile-menu.info',
    frame: 'info.png',
    hoverFrame: 'info_h.png',
    activeFrame: 'info_a.png',
    eventName: GAME_EVENTS.BUILDING_INFO,
    activeCheckFn: function () {
      return true;
    }
  },
  production: {
    x: 0,
    y: -135,
    name: 'tile-menu.production',
    frame: 'production.png',
    hoverFrame: 'production_h.png',
    activeFrame: 'production_a.png',
    eventName: GAME_EVENTS.BUILDING_PRODUCTION,
    activeCheckFn: function () {
      const isActive = isProduction(this.building.group_type);
      this.hasProduction = this.isActive;
      return isActive;
    }
  },
  autoProduction: {
    x: 0,
    y: -135,
    name: 'tile-menu.auto-production',
    frame: 'auto.png',
    hoverFrame: 'auto_h.png',
    activeFrame: 'auto_a.png',
    eventName: GAME_EVENTS.BUILDING_AUTO_PRODUCTION,
    activeCheckFn: function () {
      const isActive = isAutoProduction(this.building.group_type);
      this.hasAutoProduction = isActive;
      return isActive;
    }
  },
  upgrade: {
    x: 0,
    y: 130,
    name: 'tile-menu.upgrade',
    frame: 'upgrade.png',
    hoverFrame: 'upgrade_h.png',
    activeFrame: 'upgrade_a.png',
    eventName: GAME_EVENTS.BUILDING_UPGRADE,
    activeCheckFn: function () {
      return this.building.upgrade_building_id && this.building.status === PLAYER_BUILDING_STATUS_BUILT;
    }
  },
  move: {
    x: -90,
    y: 110,
    name: 'tile-menu.move',
    frame: 'move.png',
    hoverFrame: 'move_h.png',
    activeFrame: 'move_a.png',
    eventName: GAME_EVENTS.BUILDING_MOVE,
    activeCheckFn: function () {
      const playerLevel = this.gameService.playerService.player.level;
      let movableFromLevelChecked = true;
      if (this.building.moveable_from_level) {
        movableFromLevelChecked = playerLevel >= this.building.moveable_from_level;
      }
      return this.building.is_moveable && movableFromLevelChecked;
    }
  },
  delete: {
    x: 90,
    y: 110,
    name: 'tile-menu.delete',
    frame: 'delete.png',
    hoverFrame: 'delete_h.png',
    activeFrame: 'delete_a.png',
    eventName: GAME_EVENTS.BUILDING_DELETE,
    activeCheckFn: function () {
      const playerLevel = this.gameService.playerService.player.level;
      let deletableFromLevelChecked = true;
      if (this.building.deletable_from_level) {
        deletableFromLevelChecked = playerLevel >= this.building.deletable_from_level;
      }
      return this.building.is_deletable && deletableFromLevelChecked;
    }
  },
  qa: {
    x: 0,
    y: -135,
    name: 'tile-menu.qa',
    frame: 'quiz.png',
    hoverFrame: 'quiz_h.png',
    activeFrame: 'quiz_a.png',
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_QA_LOCATION_ID, this.building);
    }
  },
  storage: {
    x: 0,
    y: -135,
    name: 'tile-menu.storage',
    frame: 'warehouse.png',
    hoverFrame: 'warehouse_h.png',
    activeFrame: 'warehouse_a.png',
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_STORAGE_ID, this.building);
    }
  },
  exchange: {
    x: 0,
    y: -135,
    name: 'tile-menu.exchange',
    frame: 'exchange.png',
    hoverFrame: 'exchange_h.png',
    activeFrame: 'exchange_a.png',
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_EXCHANGE, this.building);
    }
  },
  ...CUSTOM_BUTTONS_CONFIG
};
