<div class="background-img">
  <loading></loading>

  <div class="container loading-opacity">
    <form [formGroup]="form" *ngIf="form">
      <ng-container *ngIf="currentStep === STEPS['1']">
        <div [style]="'display: flex; flex-direction: column; justify-content: space-between; align-items: center; height: 100%'">
          <div>
            <div class="description text-center">
              Zapoznaj się z regulaminem i zaakceptuj wymaganą zgodę, aby przejść dalej
            </div>

            <div class="description text-center" [style]="'margin: 15px 0 40px 0;'">
              <a [href]="LINKS.TERMS" target="_blank">Pobierz regulamin</a>
            </div>

            <div class="control control-checkbox flex-center">
              <div class="custom-checkbox">
                <input type="checkbox" formControlName="terms" id="terms" required/>
                <span class="custom-checkbox-fill">
                <i class="fas fa-check"></i>
            </span>
              </div>

              <label for="terms">
                Akceptuję regulamin
              </label>
            </div>
          </div>

          <button
            class="base primary"
            (click)="changeStep(STEPS['2'])"
            [disabled]="form.get('terms').invalid"
          >
            Dalej
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === STEPS['2']">
        <div [style]="'display: flex; flex-direction: column; justify-content: space-between; align-items: center; height: 100%'">
          <div class="description text-center">
            Wybierz swojego awatara. Będzie on widoczny również dla innych użytkowników w rankingu.
          </div>

          <div class="avatars">
            <div
              *ngIf="avatarUrl"
              class="avatar"
              [class.active]="form.get('avatar').value === null"
              [style.background-image]="'url('+(avatarUrl) +')'"
              (click)="setFormAvatar(null)"
            ></div>

            <div
              *ngFor="let avatar of AVATARS"
              class="avatar"
              [class.active]="form.get('avatar').value === avatar"
              [style.background-image]="'url('+(avatar + '.png' | asset:'avatar') +')'"
              (click)="setFormAvatar(avatar)"
            ></div>
          </div>

          <button
            class="base primary"
            (click)="registrationConfirm()"
            [disabled]="form.invalid"
          >
            Dalej
          </button>
        </div>
      </ng-container>
    </form>
  </div>
</div>
