import { MyScene } from '../../classes/core/MyScene';
import { MyGame } from '../../classes/core/MyGame';
import { MAIN_MAIN_SCENE, MAIN_PRELOAD_SCENE, TILE_MENU_ATLAS } from '../main.constants';
import { environment } from '../../../../../../environments/environment';
import * as R from 'ramda';
import { MAIN_SCENE_CONFIG } from '../main-scene.config';
import { MAIN_SCENE_CUSTOM_CONFIG } from '../main-scene.custom.config';
import { SceneConfig } from '../../interfaces/scene-config.interface';
import { MAP_ATLAS_NAME } from '../../../constants';

export class PreloadScene extends MyScene {

  constructor() {
    super({
      key: MAIN_PRELOAD_SCENE,
    });
  }

  assetsToPreload: string[];

  init(data?) {
    this.assetsToPreload = data.assetsToPreload;
    this.passedData = data;
  }

  customPreload() {
  }

  preload(): void {
    this.gameService = (this.game as MyGame).gameService;

    this.customPreload();

    const preloadValue = this.add.text(-10000, -10000, `test`, {
      fontSize: '30px',
      fontFamily: 'MyriadPro',
      align: 'center',
      fontStyle: 'bold',
      color: '#6afbfc'
    });
    preloadValue.setOrigin(0.5);

    this.gameService = (this.sys.game as MyGame).gameService;

    this.load.on('progress', (progress) => {
      const progressRound = Math.round(100 * progress);
      this.gameService.progressLoading = progressRound;
      // preloadValue.setText(progressRound + '%');
    });

    this.load.on('complete', (progress) => {
      preloadValue.destroy();
      this.gameService.guiService.isSplashShow.next(false);
    });

    this.assetsToPreload.forEach(asset => {
      if (asset) {
        this.load.image(asset, asset);
      }
    });

    const gl = this.game.renderer['gl'];
    if (gl) {
      console.log('MAX TEXTURE SIZE', gl.getParameter(gl.MAX_TEXTURE_SIZE));
    }

    const sceneConfig: SceneConfig = R.mergeDeepRight(MAIN_SCENE_CONFIG, MAIN_SCENE_CUSTOM_CONFIG);
    sceneConfig.backgroundImages.forEach(image => {
      this.load.image(image.key, this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/${image.filename}`));
    });

    this.load.image(
      'construction',
      this.gameService.assetsService.getAssetPath('buildings/construction.png')
    );
    this.load.image(
      environment.base + '/assets/buildings/no-building.png',
      this.gameService.assetsService.getAssetPath('buildings/no-building.png')
    );

    let mapFilenameSuffix = 'main';
    if (this.passedData && this.passedData.islandGroupType) {
      mapFilenameSuffix = this.passedData.islandGroupType;
    }

    const mapAsset = this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/island-${mapFilenameSuffix}.json`);
    if (!mapAsset) {
      console.warn('[GAME]', `No tiled file for ${mapFilenameSuffix} island grop type. Fallback to 'main'`);
      mapFilenameSuffix = 'main';
    }
    this.tiledMapKey = `island-${mapFilenameSuffix}`;
    this.load.json(
      this.tiledMapKey,
      this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/${sceneConfig.tiledJsonPrefix}-${mapFilenameSuffix}.json`)
    );

    if (sceneConfig.mapAtlasDirectory) {
      this.load.atlas(
        MAP_ATLAS_NAME,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.png`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.json`),
      );
    }

    if (sceneConfig.tileMenuAtlasDirectory) {
      this.load.atlas(
        TILE_MENU_ATLAS,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.png`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.json`),
      );
    }

    this.load.atlas(
      'icons-atlas',
      this.gameService.assetsService.getAssetPath('icons-atlas.png'),
      this.gameService.assetsService.getAssetPath('icons-atlas.json'),
    );

    this.cameras.main.setBackgroundColor('#12212E');
  }

  create(): void {
    this.gameService.guiService.isSplashShow.next(false);
    this.scene.start(MAIN_MAIN_SCENE, {tiledMapKey: this.tiledMapKey});
  }
}
