import { Component, OnChanges, OnInit } from '@angular/core';
import { AbstractHudPlayerComponent } from '../../../abstract/core/abstract-hud-player.component';

@Component({
  selector: 'app-hud-player',
  templateUrl: './hud-player.component.html',

})
export class HudPlayerComponent extends AbstractHudPlayerComponent implements OnInit, OnChanges {

  ngOnInit() {
    this.calcPlayerLevelProgress();
  }

  ngOnChanges() {
    this.calcPlayerLevelProgress();
  }
}
