import { Injectable } from '@angular/core';
import { MockDialogListService } from '../../../../../mock-dialogs/services/mock-dialog-list.service';
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from '../../consts/core/event-dialogs/event-names.const';
import { MOCK_TRANSACTION_HISTORY } from '../consts/mock-transaction-history.const';

@Injectable({providedIn: 'root'})
export class MockTransactionHistoryService {
  constructor(private mockDialogListService: MockDialogListService) {
    this.mockDialogListService.keySubscription.subscribe(key => {
      switch (key) {
        case EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY: {
          this.mockDialogListService.setDialogRef(MOCK_TRANSACTION_HISTORY.ref);
          this.mockDialogListService.setMockApiData(
            EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
            MOCK_TRANSACTION_HISTORY.api
          );
          break;
        }
      }
    });
  }
}
