import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { BuildingProductionData } from '../../../../interfaces/production.interfaces';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';

@Directive()
@Injectable()
export abstract class AbstractBuildingRowProductionComponent {
  @Input() production: BuildingProductionData;
  @Output() requirementsStatus = new EventEmitter();
  STOCK_VIEW = STOCK_VIEW;
  isActiveMultiView: boolean;

  requiredStatusList = [];
  requiredStatusCount: number;

  setRequiredStatusCount() {
    this.requiredStatusCount = [
      ...this.production.production_currency_prices,
      ...this.production.production_product_prices
    ].length;
  }

  emitStatus() {
    const valid = this.requiredStatusList.filter((item) => {
      return item.have === false;
    }).length === 0;

    this.requirementsStatus.emit({
      valid: valid,
      requirementsList: this.requiredStatusList,
    });
  }

  checkCompleted(event) {
    const itemExistIndex = this.requiredStatusList.findIndex((item) => {
      return (
        item.currency_id && item.currency_id === event.currency_id ||
        item.product_id && item.product_id === event.product_id
      );
    });

    if (itemExistIndex === -1) {
      this.requiredStatusList.push(event);
    } else {
      this.requiredStatusList[itemExistIndex] = event;
    }

    this.checkEnd();
  }

  checkEnd() {
    if (this.requiredStatusList.length === this.requiredStatusCount) {
      setTimeout(() => {
        this.emitStatus();
      });
    }
  }

  restart() {
    this.isActiveMultiView = false;
    this.setRequiredStatusCount();
  }
}
