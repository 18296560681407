import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { GAME_CONFIG } from "../../../../../../../../../core/config/custom/_parsed-game.config";

@Component({
  selector: "ranking-game-table",
  templateUrl: "./ranking-game-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingGameTableComponent {
  @Input() rankResults;
  @Input() rank;
  @Input() columns;
  @Input() show;
  @Input() activeFilter;

  STOCK_VIEW = STOCK_VIEW;
  GAME_CONFIG = GAME_CONFIG;
  TROPHIES = [
    {
      name: "trophy_gold",
      positions: [1, 2],
    },
    {
      name: "trophy_silver",
      positions: [3, 4],
    },
    {
      name: "trophy_bronze",
      positions: [5, 6],
    },
  ];
}
