export const MOCK_MESSAGE_DETAILS_HIDDEN_REWARD = {
  api: {
    read: true,
    title: 'Sprawdź, co odkryliśmy podczas budowy!',
    tip: '',
    teaser: '',
    description: 'Podczas rozbudowy trafiliśmy na ciekawe znalezisko, sprawdź co odkryliśmy.',
    player_message_id: 129945,
    generic_type: 'hidden_reward',
    type: 1,
    category_name: 'Świat gry',
    sender_group_id: 2,
    sender_name: 'Nowość',
    sender_name_legacy: 'Nowość',
    sender_icon: 'new',
    gallery: null,
    mobile_gallery: [],
    parameters: {
      currencies: [],
      prizes: [{name: 'Drewno', icon: 'wood', amount: 2, product_category_id: 2, storage_id: 1}],
      player_building_id: 187254
    },
    deleted: true,
    created_at: '2021-02-03T09:46:26+01:00'
  },
  ref: {
    message: {
      read: true,
      title: 'Sprawdź, co odkryliśmy podczas budowy!',
      tip: '',
      teaser: '',
      description: 'Podczas rozbudowy trafiliśmy na ciekawe znalezisko, sprawdź co odkryliśmy.',
      player_message_id: 129945,
      generic_type: 'hidden_reward',
      type: 1,
      category_name: 'Świat gry',
      sender_group_id: 2,
      sender_name: 'Nowość',
      sender_name_legacy: 'Nowość',
      sender_icon: 'new',
      gallery: null,
      mobile_gallery: [],
      parameters: {
        currencies: [],
        prizes: [{name: 'Drewno', icon: 'wood', amount: 2, product_category_id: 2, storage_id: 1}],
        player_building_id: 187254
      },
      deleted: true,
      created_at: '2021-02-03T09:46:26+01:00'
    },
    fromList: true
  }
};
