import { EventEmitter, Injectable } from '@angular/core';
import { MockApiResponseDialog } from '../interfaces/mock-dialog.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { LoadingService } from '../../../core/providers/loading.service';
import { MOCK_STATE_APPLICATION } from '../const/mock-state-application.const';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class MockDialogListService {

  mockDataJSON: MockApiResponseDialog[] = [];
  mockDialogRefJSON: string;
  mockStoreJSON = JSON.stringify(MOCK_STATE_APPLICATION, undefined, 2);

  isMocked: boolean;
  isMockDialogRefDisabled: boolean;
  keySubscription = new EventEmitter<string>();

  constructor(
    private store: Store<AppState>,
    private loadingService: LoadingService
  ) {
  }

  findMockApiResponse(key: string): MockApiResponseDialog {
    this.loadingService.hide();
    return key ? this.mockDataJSON.find(data => data.key === key) : null;
  }

  getMockDataByKey(mockDialogKey: string) {
    this.isMockDialogRefDisabled = false;
    this.mockDataJSON = [];
    this.mockDialogRefJSON = null;
    this.keySubscription.emit(mockDialogKey);
  }

  setMockApiData(key: string, data: any) {
    this.mockDataJSON.push({
      key,
      data: JSON.stringify(data, undefined, 1)
    });
  }

  setDialogRef(data: any) {
    this.mockDialogRefJSON = JSON.stringify(data, undefined, 1);
  }

  createMockResponse(mockDataKey: string): Observable<any> {
    const res = JSON.parse(this.findMockApiResponse(mockDataKey).data);
    if (res.headers) {
      let httpHeaders = new HttpHeaders();
      res.headers.forEach(header => {
        httpHeaders = httpHeaders.append(header.key, header.value);
      });
      res.headers = httpHeaders;
    }
    return of(res);
  }
}

