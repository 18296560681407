import { Component, EventEmitter, Output } from '@angular/core';
import { OwInject } from '../../../../../core/decorators/ow-inject.decorator';
import { MockDialogListService } from '../../../services/mock-dialog-list.service';
import { AbstractInjectBaseComponent } from '../../../../../core/abstracts/abstract-inject-base.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/state';
import { MockState } from '../../../../../store/actions';

@Component({
  selector: 'app-mock-store-data',
  templateUrl: './mock-store-data.component.html',

})
export class MockStoreDataComponent extends AbstractInjectBaseComponent {
  @OwInject(MockDialogListService) mockDialogService: MockDialogListService;
  @OwInject(Store) store: Store<AppState>;

  @Output() accept = new EventEmitter<string>();
  @Output() close = new EventEmitter();

  save() {
    this.store.dispatch(new MockState({
      ...JSON.parse(this.mockDialogService.mockStoreJSON)
    }));
    this.close.emit();
  }
}
