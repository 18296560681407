import { Component, HostBinding } from '@angular/core';
import { GuiService } from '../../../game/services/gui.service';
import { GameService } from '../../../game/services/game.service';
import { debounce } from 'rxjs/operators';
import { EMPTY, timer } from 'rxjs';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',

})
export class SplashScreenComponent {
  @HostBinding('class.active') isShow: boolean;

  constructor(
    public guiService: GuiService,
    public gameService: GameService,
  ) {
    this.guiService.isSplashShow
      .pipe(
        debounce((value) => {
          return value ? EMPTY : timer(250);
        })
      )
      .subscribe(value => {
        this.isShow = value;
      });
  }
}
