import { Injectable } from '@angular/core';
import * as gameInterfaces from '../../modules/game/game-engine/interfaces';
import { environment } from '../../../environments/environment';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  get assetsData(): Assets {
    return window['assets'];
  }

  constructor() {
  }

  /**
   * Get asset path by asset path. (Sounds like shit, but it is like that) @todo: rethink function name
   *
   * Return file asset path for passed asset path.
   * @param {string} assetPath
   * @returns {string}
   */
  getAssetPath(assetPath: string): string {
    const assetObject = this.getAsset(assetPath);
    if (!assetObject) {
      if (!assetObject) {
        this.logNoAssets(assetPath);
      }
    }
    return assetObject ? assetObject.path : undefined;
  }

  logNoAssets(assetPath: string) {
    console.error('No asset:', assetPath);

    if (!window['errorAssets']) {
      window['errorAssets'] = new Set();
    }

    setTimeout(() => window['errorAssets'].add(assetPath));
  }

  /**
   * Get asset object by asset path.
   *
   * Return Asset object for passed asset path.
   * @param {string} assetPath
   * @returns {Asset}
   */
  getAsset(assetPath: string): Asset {
    return handleAssetPathBase(this.assetsData[`/${assetPath}`]);
  }

  /**
   * Find assets by directory name.
   *
   * Return assets from passed directory name. Search only first level of directory tree.
   * @param {string} directory
   * @returns {object}
   */
  getAssetsByDirectory(directory: string): object {
    const assetsObject = {};
    Object.entries(this.assetsData).forEach(asset => {
      if (asset[0].split('/').indexOf(directory) === 2) {
        assetsObject[asset[0]] = asset[1];
      }
    });
    return assetsObject;
  }

  getAssetFilename(assetPath: string) {
    return assetPath.split('/').pop();
  }

  /**
   * Get asset by filename part.
   * Primary used for searching building image knowing only group and level that is first part of file name.
   * @param filenamePart
   * @param path
   */
  getAssetByFilenamePart(filenamePart: string, path: string = ''): Asset {
    const assetPath = `/${path}${filenamePart}`;
    const assetKey = Object.keys(this.assetsData).find(_assetKey => {
      return this.assetsData[_assetKey].path.includes(assetPath);
    });
    return handleAssetPathBase(this.assetsData[assetKey]);
  }

  /**
   * Get assets by filename part.
   * Primary used for searching building image versions.
   * @param filenamePart
   * @param path
   */
  getAssetsByFilenamePart(filenamePart: string, path: string = '') {
    const assetPath = `/${path}${filenamePart}`;
    const assets = [];
    Object.keys(this.assetsData).forEach(assetKey => {
      if (this.assetsData[assetKey].path.includes(assetPath)) {
        assets.push(environment.base + this.assetsData[assetKey].path);
      }
    });
    return assets.length ? assets : null;
  }

  /**
   * Find all texture atlases and return .
   *
   * Get Phaser textures list and return ImageAtlas object for textures which contain 'frames' property.
   * @return ImagesAtlas[]
   */
  getAtlasFromCache(game: Phaser.Game): gameInterfaces.ImagesAtlas[] {
    const atlases: gameInterfaces.ImagesAtlas[] = [];
    const textureKeys = game.textures.getTextureKeys();

    textureKeys.forEach((key) => {
      const texture = game.textures.get(key);
      if (texture.hasOwnProperty('frames')) {
        atlases.push({
          name: key,
          images: Object.keys(texture.frames)
        });
      }
    });
    return atlases;
  }
}

export interface Asset {
  path: string;
  width: number;
  height: number;
}

export interface Assets {
  [asset: string]: Asset;
}

function handleAssetPathBase(asset: Asset) {
  if (!asset) {
    return asset;
  }
  const returnAsset = R.clone(asset);
  returnAsset.path = asset.path;
  return returnAsset;
}
