import { Directive, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Player } from '../../../../../player/interfaces/player';
import { User } from '../../../../../user/interfaces/user';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { CurrencyBalance, CurrencyDefinition } from '../../../../../../core/interfaces/currency';
import { PrimarySelectors } from '../../../../../../store/primary';
import { PlayerService } from '../../../../../player/providers/player.service';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from '../../../transaction-history/consts/core/event-dialogs/event-names.const';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { selectPlayer } from '../../../../../../store/player/selectors';
import { UserSelectors } from '../../../../../../store/user';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { combineLatest } from 'rxjs';
import { GuiItem } from '../../interfaces/core/gui-item.interface';
import * as R from 'ramda';
import { GameService } from '../../../../services/game.service';
import { CurrencyService } from '../../../../../../core/providers/currency.service';

@Directive()
@Injectable()
export abstract class AbstractHudResourcesComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(CurrencyService) currencyService: CurrencyService;

  currencyDefinitions: CurrencyDefinition[] = [];
  player: Player;
  user: User;
  guiItems: GuiItem[] = [];

  subs = {
    user: null,
    combine: null,
  };

  PLACEHOLDERS = [
    {
      placeholder: '%balance%',
      getValue: (item) => item ? item.balance : 0,
    },
    {
      placeholder: '%storage_capacity%',
      getValue: (product) => product ? product.storage_capacity : 0,
    },
    {
      placeholder: '%daily_production%',
      getValue: (product) => product ? product.daily_production : 0,
    },
  ];

  ngOnInit() {
    this.subscribeUser();
    this.setParameters();
  }

  setParameters() {
    const currency$ = this.store
      .pipe(
        select(PrimarySelectors.selectCurrencies),
        take(1),
      );
    const player$ = this.store
      .pipe(
        select(selectPlayer),
      );

    this.subs.combine = combineLatest([currency$, player$]).subscribe(([currencies, player]) => {
      this.player = player as Player;
      this.currencyDefinitions = currencies as CurrencyDefinition[];
      this.guiItems = player['gui_items'].map(guiItem => {
        let resourceBalance;

        // CURRENCY
        if (guiItem.currency_id) {
          const currency = this.currencyDefinitions.find(curr => curr.currency_id === guiItem.currency_id);
          resourceBalance = this.currencyService.getCurrencyBalance(guiItem, [...player['currency_balances'], ...this.user['currency_balances']]);

          if (currency) {
            guiItem = {
              ...guiItem,
              type: currency.type,
              show_currency_history: currency.show_currency_history,
              currency: R.mergeDeepRight(resourceBalance, currency)
            };
          }
        }

        // PRODUCT
        if (guiItem.product_id) {
          resourceBalance = this.findProductBalance(guiItem, player['product_balances']);
        }

        let balance = 0;
        if (resourceBalance) {
          balance = resourceBalance.balance;
        }
        guiItem = {...guiItem, resourceBalance: balance};
        this.setTooltip(guiItem);

        return guiItem;
      });

      this.orderGuiItems();
    });
  }

  openHistoryTransaction(currency: CurrencyBalance, tooltip?: NgbTooltip) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: {currency}
        },
        callback: () => {
          if (tooltip) {
            tooltip.close();
          }
        }
      });
    }
  }

  subscribeUser() {
    this.subs.user = this.store
      .pipe(
        select(UserSelectors.selectUser),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  setTooltip(guiItem: GuiItem) {
    const productBalance = this.findProductBalance(guiItem, this.player['product_balances']);
    if (guiItem.tooltip && guiItem.product_id) {
      Object.keys(this.PLACEHOLDERS).forEach((key) => {
        const placeholderObject: { placeholder: string, getValue: (product?: any) => any } = this.PLACEHOLDERS[key];
        if (guiItem.tooltip.indexOf(placeholderObject.placeholder) > -1) {
          guiItem.tooltip = guiItem.tooltip.replace(placeholderObject.placeholder, placeholderObject.getValue(productBalance));
        }
      });
    }
  }

  findProductBalance(guiItem: GuiItem, balances: any) {
    return balances.find(balance => balance.product_id === guiItem.product_id);
  }

  findCurrencyBalance(guiItem: GuiItem, balances: CurrencyBalance[]) {
    return balances.find(balance => balance.currency_id === guiItem.currency_id);
  }

  orderGuiItems() {
    this.guiItems = R.sortBy(R.prop('sequence'), this.guiItems);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
