import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualKeyboardMobileModule } from '../game/game-ui/virtual-keyboard/mobile/virtual-keyboard-mobile.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MockDialogListComponent } from './components/mock-dialog-list/mock-dialog-list.component';
import { MockStoreDataComponent } from './components/dialogs/mock-store-data/mock-store-data.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        VirtualKeyboardMobileModule,
        SwiperModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        SharedModule
    ],
    declarations: [
        MockDialogListComponent,
        MockStoreDataComponent
    ],
    exports: [
        MockDialogListComponent,
        MockStoreDataComponent
    ]
})
export class MockDialogModule {
}
