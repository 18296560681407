import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RRConfigEditorComponent } from './rrconfig-editor/rrconfig-editor.component';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [RRConfigEditorComponent],
  exports: [
    RRConfigEditorComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatSliderModule,
    MatSelectModule,
    DragDropModule,
    MatCardModule,
  ]
})
export class RRConfigEditorModule {
}
