import { Injectable, OnInit } from '@angular/core';
import { ApiCoreGameRanksService } from '../../api/custom/services/api-game-ranks.service';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { RankGame } from '../../interfaces/custom/rank-game.interface';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { GetGameRankResultsResponse } from '../../api/custom/interfaces/response/get-game-rank-results.response.interface';
import { RANK_CONFIG } from '../../base/custom/dialogs/ranking-game-details/config/rank.config';
import { PlayerService } from '../../../../../player/providers/player.service';

@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsGameComponent>;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    rankId: number;
  };
  limit = 100;
  STOCK_VIEW = STOCK_VIEW;
  rank: RankGame;
  rankResults: GetGameRankResultsResponse;
  RANK_CONFIG = RANK_CONFIG.DEFAULT;

  ngOnInit() {
    this.getRank();
  }

  getRank() {
    this.getGameRank();
  }

  getGameRank() {
    this.apiCoreGameRanksService.getGameRank({
      rank_edition_id: this.data.rankId,
    })
      .subscribe((resp) => {
        this.rank = resp;
        this.parseTitle();
        this.getGameRanksResults();
      });
  }

  parseTitle() {
    const regex = new RegExp(`<br\\/?>`, 'g');
    this.rank.name = this.rank.name.replace(regex, ' ');
  }

  getGameRanksResults() {
    this.apiCoreGameRanksService.getGameRanksResults({
      rank_edition_id: this.rank.rank_edition_id,
      limit: this.limit,
    })
      .subscribe((resp) => {
        this.rankResults = resp;
        this.afterGetGameRankRequest();
      });
  }

  afterGetGameRankRequest() {
    this.replacePlaceholders();
  }

  replacePlaceholders() {
    const regex = new RegExp(`%([a-zA-Z0-9_]+)`, 'g');

    Object.keys(this.rank.parameters || {}).forEach((key) => {
      this.rankResults.results.forEach((item) => {
        let value = this.rank.parameters[key];

        if (typeof value === 'string') {
          let result = this.getRegexValue(regex, value);

          while (result) {
            value = value.replace(`%${result}`, item[result]);
            result = this.getRegexValue(regex, value);
          }

          item[key] = value;
        }
      });
    });
  }

  getRegexValue(regex: RegExp, string: string) {
    const results = regex.exec(string);
    return results && results[1];
  }

  close() {
    this.matDialogRef.close();
  }
}
