import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';
import { TokenObject } from '../../../../interfaces/token-object';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../consts/core/routing-auth-absolute.const';
import { LINKS } from '../../../../../../core/consts/core/links.const';
import {
  AbstractRegistrationConfirmAComponent
} from '../../../../abstract/registration-confirm/variant-a/abstract-registration-confirm-a.component';

@Component({
  selector: 'app-oauth-confirm',
  templateUrl: './oauth-confirm.component.html',
})
export class OauthConfirmComponent extends AbstractRegistrationConfirmAComponent implements OnInit {
  LINKS = LINKS;
  avatarUrl: number | null;
  STEPS = {
    1: 1,
    2: 2,
  }
  currentStep: number = 1;
  AVATARS = [1,2,3,4,5];

  ngOnInit() {
    this.setAvatarUrl();
    this.subscribeRoutingParams();
  }

  setAvatarUrl(){
    this.avatarUrl = this.activatedRoute.snapshot.queryParams?.avatar;
  }

  setFormAvatar(avatar: null | number){
    this.form.get('avatar').setValue(avatar);
  }

  changeStep(step: number){
    this.currentStep = step;
  }

  clearForm() {
    this.form = this.fb.group({
      token: this.fb.control(this.token, [Validators.required]),
      avatar: this.fb.control(this.avatarUrl ? null : 1),
      terms: this.fb.control(null, [Validators.required]),
    });
  }

  registrationConfirm() {
    const data: RegistrationConfirmDataRequest = {
      token: this.form.value.token,
      avatar: this.form.value.avatar,
    };

    this.confirmRegistrationRequest(data)
        .subscribe((resp: TokenObject) => {
          this.dispatchToken(resp);
          this.routeToPlayerChoose();
        }, (errResp) => {
          if (errResp.status === 404) {
            errResp.defaultHandler.unsubscribe();
            this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
            this.dialogService.openAlert({title: 'Token wygasł, spróbuj ponownie.'});
          }
        });
  }

  confirmRegistrationRequest(data: RegistrationConfirmDataRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post('auth/oauth2/confirm', options);
  }
}

interface RegistrationConfirmDataRequest {
  token: string;
  avatar: number | null;
}
