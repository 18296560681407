import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaAbstractModule } from '../../abstract/core/qa-abstract.module';
import { MQaListComponent } from '../../base/core/dialogs/qa-list/qa-list.component';
import { SharedUiMobileModule } from '../../../shared-ui/mobile/shared-ui-mobile.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { MQaDetailsComponent } from '../../base/core/dialogs/qa-details/qa-details.component';
import { MQaSummaryComponent } from '../../base/core/components/qa-summary/qa-summary.component';
import { MQaFinishComponent } from '../../base/core/dialogs/qa-finish/qa-finish.component';
import { FormsModule } from '@angular/forms';
import { CUSTOM_DECLARATIONS } from '../../consts/custom/components.const';
import { CUSTOM_IMPORTS } from '../../consts/custom/imports.const';
import { CUSTOM_PROVIDERS } from '../../consts/custom/providers.const';

@NgModule({
    declarations: [
        MQaListComponent,
        MQaDetailsComponent,
        MQaSummaryComponent,
        MQaFinishComponent,
        ...CUSTOM_DECLARATIONS,
    ],
    imports: [
        CommonModule,
        QaAbstractModule,
        SharedUiMobileModule,
        SharedModule,
        FormsModule,
        ...CUSTOM_IMPORTS,
    ],
    providers: [
        ...CUSTOM_PROVIDERS,
    ]
})
export class QaMobileModule {
}
