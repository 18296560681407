<div
  class="ow-dialog window-d quaternary no-transparent"
  [style.min-width]="300"
  [style.max-height]="400"
  [style.min-height]="200"
>
  <!-- TITLE -->
  <p class="title">
    MOCK STORE DATA
  </p>

  <div class="json-editor">
    <textarea
      [(ngModel)]="mockDialogService.mockStoreJSON"
    ></textarea>
  </div>

  <div class="buttons">
    <!-- CANCEL -->
    <button
      class="base secondary"
      type="button"
      (click)="save()"
    >
      Zapisz
    </button>
  </div>

</div>
