import { Directive, Injectable, Input } from '@angular/core';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';

@Directive()
@Injectable()
export abstract class AbstractBuildingUpgradeDiffParameterComponent extends AbstractInjectBaseComponent {
  @Input() normal: any;
  @Input() upgrade: any;
  @Input() parameters: any;
  STOCK_VIEW = STOCK_VIEW;
}
