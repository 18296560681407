import { Action } from '@ngrx/store';

export enum ActionTypes {
  SOCKET_CLEAR_STATUS = '[socket] clear status',
  SOCKET_CONNECT = '[socket] connect socket',
  SOCKET_CONNECTING = '[socket] connecting socket',
  SOCKET_CONNECTED = '[socket] socket connected',
  SOCKET_AUTHENTICATE = '[socket] socket authenticate',
  SOCKET_AUTHENTICATING = '[socket] socket authenticating',
  SOCKET_AUTHENTICATED = '[socket] socket authenticated',
  SOCKET_DISCONNECT = '[socket] socket disconnect',
  SOCKET_DISCONNECTING = '[socket] socket disconnecting',
  SOCKET_DISCONNECTED = '[socket] socket disconnected',
  SOCKET_RECONNECT_ATTEMPT = '[socket] socket reconnect attempt',
  SOCKET_OTHER_SESSION = '[socket] socket other session',
  SOCKET_CONNECTION_ERROR = '[socket] socket connection error',
}

export class SocketClearStatus implements Action {
  readonly type = ActionTypes.SOCKET_CLEAR_STATUS;
}

export class SocketConnect implements Action {
  readonly type = ActionTypes.SOCKET_CONNECT;
}

export class SocketConnecting implements Action {
  readonly type = ActionTypes.SOCKET_CONNECTING;
}

export class SocketConnected implements Action {
  readonly type = ActionTypes.SOCKET_CONNECTED;
}

export class SocketAuthenticate implements Action {
  readonly type = ActionTypes.SOCKET_AUTHENTICATE;
}

export class SocketAuthenticating implements Action {
  readonly type = ActionTypes.SOCKET_AUTHENTICATING;
}

export class SocketAuthenticated implements Action {
  readonly type = ActionTypes.SOCKET_AUTHENTICATED;
}

export class SocketDisconnect implements Action {
  readonly type = ActionTypes.SOCKET_DISCONNECT;
}

export class SocketDisconnecting implements Action {
  readonly type = ActionTypes.SOCKET_DISCONNECTING;
}

export class SocketDisconnected implements Action {
  readonly type = ActionTypes.SOCKET_DISCONNECTED;
}

export class SocketReconnectAttempt implements Action {
  readonly type = ActionTypes.SOCKET_RECONNECT_ATTEMPT;

  constructor(public payload: { attempt: number }) {
  }
}

export class SocketOtherSession implements Action {
  readonly type = ActionTypes.SOCKET_OTHER_SESSION;
}

export class SocketConnectionError implements Action {
  readonly type = ActionTypes.SOCKET_CONNECTION_ERROR;
}

export type Actions =
  SocketClearStatus
  | SocketConnect
  | SocketConnecting
  | SocketConnected
  | SocketAuthenticate
  | SocketAuthenticating
  | SocketAuthenticated
  | SocketDisconnect
  | SocketDisconnecting
  | SocketDisconnected
  | SocketOtherSession
  | SocketReconnectAttempt
  | SocketConnectionError
  ;
