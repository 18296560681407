import { Component, OnInit } from '@angular/core';
import { GameService } from '../../../../../../services/game.service';
import { mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GAME_EVENTS } from '../../../../../../constants';
import { BASIC_BOOT_SCENE } from '../../../../../scenes-basic/basic.constants';

@Component({
  selector: 'app-world-info',
  templateUrl: './world-info.component.html',
})
export class WorldInfoComponent implements OnInit {

  worldData$;

  constructor(private gameService: GameService) {}

  ngOnInit(): void {
    this.worldData$ = this.gameService.playerService.playerRegions
      .pipe(
        tap(console.log),
        mergeMap(worldData => {
            return of(worldData);
          }
        ));
  }

  startScene(playerIslandId: number, islandConfigName: string) {
    this.gameService.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: islandConfigName,
        playerIslandId: playerIslandId
      }
    });
  }
}


