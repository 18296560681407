import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { GetCurrencyTransactionRequest } from '../interfaces/get-currency-transaction-request.interface';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from '../../../consts/core/event-dialogs/event-names.const';

@Injectable({
  providedIn: 'root'
})
export class ApiTransactionHistoryService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
  ) {
  }

  currencyTransaction({page, currency, limit, player_id}: GetCurrencyTransactionRequest) {
    player_id = player_id || this.playerService.getActivePlayerId();

    const options: ApiOptions = {
      responseWithHeaders: true,
      params: {
        page,
        currency,
        limit,
      }
    };

    return this.apiService.get(
      `player/${player_id}/transactions`,
      options,
      EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY
    );
  }
}
