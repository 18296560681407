import { Component } from '@angular/core';
import { AbstractBackButtonComponent } from '../../../abstract/abstract-back-button.component';

@Component({
  selector: 'm-ui-back-button',
  templateUrl: './ui-back-button.component.html',

})
export class MUiBackButtonComponent extends AbstractBackButtonComponent {
}
