import { Component, ViewChild } from '@angular/core';
import { AbstractBuildingInfoComponent } from './abstract-building-info.component';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { select, Store } from '@ngrx/store';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter } from 'rxjs/operators';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { BuildingsService } from '../../../../services/buildings.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { AppState } from '../../../../../../store/state';
import { PlayerBuilding } from '../../../../game-engine/interfaces/player-building.config';
import { ProductionService } from '../../../../services/production.service';
import { SynchronizeTimeService } from '../../../../../../core/providers/synchronize-time.service';
import { BoardTileProduction, BoardTileState } from '../../../../../../store/game/interfaces/board-tile.state';
import { translate } from '../../../../../../core/helpers/translate.helper';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { BuildingProductionData } from '../../../../interfaces/production.interfaces';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';

@Component({
  template: '',
})
export class AbstractBuildingProductionComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingInfoComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;

  STOCK_VIEW = STOCK_VIEW;
  building: PlayerBuilding;
  actualProduction: BoardTileProduction;
  diffTime: {
    actualSeconds: number;
    percentage: number;
    observableTime: number;
    actualTime: number;
    fullTime: number;
  };
  cyclesRequirements: any;

  subs = {
    board: null,
  };

  @ViewChild('sliderProductions') sliderProductions: SwiperComponent;
  config = {
    direction: 'vertical',
  };
  productions = {
    config: {
      itemPerPage: 4,
    },
    pages: [],
    items: []
  };
  activeSliderIndex = 0;

  baseInit() {
    this.subscribeBoardTile();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
      )
      .subscribe((tile) => {
        this.afterBoardTileSubscribe(tile);
      });
  }

  afterBoardTileSubscribe(tile: BoardTileState) {
    this.setBuilding(tile);
    this.setActualProduction(tile);
    this.startActualProduction();
    this.getBuildingProduction();
  }

  setBuilding(tile: BoardTileState) {
    this.building = tile.player_building;
  }

  setActualProduction(tile: BoardTileState) {
    this.actualProduction = tile.production;
  }

  setCyclesRequirements(cyclesRequiurements) {
    this.cyclesRequirements = cyclesRequiurements;
  }

  getBuildingProduction() {
    this.productionService.getBuildingProduction(this.building.building_id)
      .subscribe((resp) => {
        this.productions.pages = [];
        this.productions.items = resp;
        this.generatePages();
      });
  }

  generatePages() {
    this.productions = generateEachPages(this.productions);
  }

  setRequirementsStatus(status, production) {
    production.requirementsStatus = status;
    this.checkActualProduction(production);
    this.checkCycles(production);
  }

  checkActualProduction(production) {
    if (this.actualProduction) {
      production.requirementsStatus.valid = false;
    }
  }

  checkCycles(production) {
    if (!this.cyclesRequirements.valid) {
      production.requirementsStatus.valid = false;
    }
  }

  startActualProduction() {
    if (this.actualProduction) {
      this.diffTimer();
    } else {
      this.clearDiffTimer();
    }
  }

  clearDiffTimer() {
    if (this.diffTime) {
      clearTimeout(this.diffTime.observableTime);
    }

    this.diffTime = {
      actualSeconds: -1,
      percentage: 0,
      observableTime: null,
      actualTime: 0,
      fullTime: 0,
    };
  }

  diffTimer() {
    const created_at = new Date(this.actualProduction.created_at);
    const finishes_at = new Date(this.actualProduction.finishes_at);

    this.clearDiffTimer();
    this.diffTime.fullTime = Math.floor((finishes_at.getTime() - created_at.getTime()) / 1000);

    this.calcTickDiff(created_at, finishes_at);
  }

  calcTickDiff(created_at, finishes_at) {
    this.diffTime.actualTime = Math.floor((this.synchronizeTimeService.getActualLocalTimeWithOffset().getTime() - created_at.getTime()) / 1000);
    this.diffTime.actualSeconds = Math.floor((finishes_at.getTime() - this.synchronizeTimeService.getActualLocalTimeWithOffset().getTime()) / 1000);

    this.diffTime.percentage = ~~(this.diffTime.actualTime / this.diffTime.fullTime * 100);

    if (this.diffTime.actualSeconds <= 0) {
      this.diffTime.actualSeconds = 0;
      this.diffTime.percentage = 100;

      this.afterEndActualProduction();
    } else {
      this.diffTime.observableTime = setTimeout(() => {
        this.calcTickDiff(created_at, finishes_at);
      }, 950);
    }
  }

  afterEndActualProduction() {
    this.matDialogRef.close();
  }

  startProduction({recipeId, fastProduction}: { recipeId: number, fastProduction?: boolean }) {
    this.productionService.startPlayerBuildingProduction(this.building.player_building_id, recipeId, fastProduction)
      .subscribe(() => {
      });
  }

  cancelPlayerProductionConfirm() {
    this.dialogService.openConfirm(
      {
        title: translate('building-production.alert-cancel.title'),
        description: translate('building-production.alert-cancel.description'),
        buttonSuccess: {
          label: translate('building-production.alert-cancel.button-success')
        },
        buttonClose: {
          label: translate('building-production.alert-cancel.button-close')
        }
      }, (confirm) => {
        if (confirm) {
          this.cancelPlayerProduction();
        }
      }
    );
  }

  cancelPlayerProduction() {
    this.productionService.cancelProduction(this.actualProduction.player_building_production_id)
      .subscribe(() => {
      });
  }

  changePage(index) {
    this.activeSliderIndex = index;
  }

  openFastProductionConfirm(production: BuildingProductionData) {
    this.dialogService.openConfirm({
      title: translate('fast-action.title'),
      description: translate('fast-action.description'),
      costs: {
        separatorTitle: translate('fast-action.separator-title'),
        currencies: production.fast_production_currency_prices,
      }
    }, (confirm) => {
      if (confirm) {
        this.startProduction({
          recipeId: production.production_recipe_id,
          fastProduction: true,
        });
      }
    });
  }

  baseDestroy() {
    this.clearDiffTimer();
    unsubscribeObject(this.subs);
  }
}
