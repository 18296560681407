export const MOCK_MESSAGE_DETAILS_NORMAL = {
  api: {},
  ref: {
    message: {
      read: true,
      title: 'Rankingi ze świata gry',
      tip: '',
      teaser: '',
      description: 'W oknie ranking&oacute;w pojawiła się <b>zakładka "Świat gry".</b><br><br>Na dobry początek, udostępniamy <b>ranking aktualnych poziom&oacute;w graczy</b> oraz <b>NOWOŚĆ - ranking Zdobytego doświadczenia globtroterskiego</b>. <b>W nowym rankingu</b> uzyskasz tym lepszą pozycję, im więcej miejsc i tras odkryjesz. Przekonaj się, że eksplorowanie dżungli przynosi same korzyści!<br><br>Powodzenia!',
      player_message_id: 65933,
      generic_type: null,
      type: 1,
      category_name: 'Świat biznesu',
      sender_group_id: 2,
      sender_name: 'Informacja',
      sender_name_legacy: 'Informacja',
      sender_icon: 'sender-info',
      gallery: null,
      mobile_gallery: [],
      parameters: [],
      deleted: false,
      created_at: '2021-05-07T07:14:11+02:00'
    }, fromList: true
  }
};
