import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ProductionService } from '../../../../services/production.service';
import { BuildingProductionCycles } from '../../interfaces/core/building-production-cycles.interfaces';
import { translate } from '../../../../../../core/helpers/translate.helper';

@Directive()
@Injectable()
export abstract class AbstractBuildingProductionCyclesComponent extends AbstractInjectBaseComponent {
  @OwInject(ProductionService) productionService: ProductionService;
  event: {
    valid: boolean,
    message: string,
  } = {
    valid: false,
    message: '',
  };

  building: { player_building_id: number };
  cycles: BuildingProductionCycles;
  @Output() requirementsValid = new EventEmitter();

  @Input('building') set Building(building) {
    this.building = building;
    this.playerBuildingsProductionCycles();
  }

  playerBuildingsProductionCycles() {
    this.productionService.getPlayerBuildingsProductionCycles(this.building.player_building_id)
      .subscribe((resp) => {
        this.cycles = resp;
        this.checkValid();
        this.sendEvent();
      });
  }

  checkValid() {
    this.event.valid = this.cycles.done < this.cycles.total;
    this.event.message = translate('building-production-cycles.max-description', [this.cycles.total]);
  }

  sendEvent() {
    this.requirementsValid.emit(this.event);
  }
}
