<table *ngIf="rankResults">
  <!--    <thead>-->
  <!--    <tr [ngClass]="{'hide-element': show != 'header'}">-->
  <!--      <ng-container *ngFor="let column of columns">-->
  <!--        &lt;!&ndash; FE COLUMN HEADER &ndash;&gt;-->
  <!--        <th-->
  <!--          *ngIf="!column.columnFromApi"-->
  <!--          [style.text-align]="column.textAlign"-->
  <!--        >-->
  <!--          {{ column.header }}-->
  <!--        </th>-->

  <!--        &lt;!&ndash; BE COLUMN HEADER &ndash;&gt;-->
  <!--        <ng-container *ngIf="column.columnFromApi">-->
  <!--          <th-->
  <!--            *ngIf="rank['value' + column.columnFromApi + '_label'] as label"-->
  <!--            [style.text-align]="column.textAlign"-->
  <!--          >-->
  <!--            {{ label }}-->
  <!--          </th>-->
  <!--        </ng-container>-->
  <!--      </ng-container>-->
  <!--    </tr>-->
  <!--    </thead>-->
  <tbody>
    <!-- MY POSITION -->
    <ng-container
      *ngIf="rankResults.my_position"
      [ngTemplateOutlet]="tableTrTpl"
      [ngTemplateOutletContext]="{
        item: rankResults.my_position,
        rank: rank,
        showTr: show === 'myPosition'
      }"></ng-container>

    <!-- RESULTS -->
    <ng-container
      *ngFor="let item of rankResults.results"
      [ngTemplateOutlet]="tableTrTpl"
      [ngTemplateOutletContext]="{
        item: item,
        rank: rank,
        showTr: show === 'results'
      }"></ng-container>
  </tbody>
</table>

<!-- TABLE TR TEMPLATE -->
<ng-template #tableTrTpl let-item="item" let-rank="rank" let-showTr="showTr">
  <tr [ngClass]="{ 'hide-element': !showTr }">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN -->
      <ng-container *ngIf="!column.columnFromApi">
        <ng-container *ngIf="column.custom; else defaultTdTpl">
          <!-- PRIZES COLUMN -->
          <td *ngIf="column.template === 'prizes'" [style.text-align]="column.textAlign">
            <div class="prizes">
              <!-- CURRENCY PRIZES -->
              <m-ui-currency
                *ngFor="let currency of item.currency_prizes"
                [item]="currency"
                [lack]="false"
                [stockView]="STOCK_VIEW.H"></m-ui-currency>

              <!-- PRODUCT PRIZES -->
              <m-ui-product
                *ngFor="let product of item.product_prizes"
                [hideValue]="true"
                [item]="product"
                [lack]="false"
                [stockView]="STOCK_VIEW.H"
                [lockOpenStorage]="true"></m-ui-product>
            </div>
          </td>

          <!-- AVATAR COLUMN -->
          <td *ngIf="column.template === 'avatar' && GAME_CONFIG.FLAGS.RANKINGS.IS_VISIBLE_AVATARS" [style.text-align]="column.textAlign">
            <div
              [ngStyle]="{
                'background-image': 'url(' + (item['user_avatar_url'] || (item['user_avatar_number'] + '.png' | asset: 'avatar')) + ')'
              }"
              class="avatar"></div>
          </td>

          <!-- FULLNAME COLUMN -->
          <td *ngIf="column.template === 'fullName'" [style.text-align]="column.textAlign" class="fullname">
            {{ item.first_name }} {{ item.last_name }}
          </td>

          <!-- PLAYER RANK COLUMN -->
          <!--          <td-->
          <!--            *ngIf="column.template === 'playerRank'"-->
          <!--            [style.text-align]="column.textAlign"-->
          <!--            class="player-rank"-->
          <!--          >-->
          <!--            <img-->
          <!--              class="rank-image"-->
          <!--              [matTooltip]="item.player_rank_details.name"-->
          <!--              [src]="(item.player_rank_details.icon + '.png') | asset:'vehicle'"-->
          <!--            />-->
          <!--          </td>-->

          <!-- TROPHY -->
          <td *ngIf="column.template === 'trophy'" [style.text-align]="column.textAlign" class="player-trophy">
            <ng-container *ngFor="let trophy of TROPHIES">
              <ng-container *ngIf="trophy.positions.includes(item.position)">
                <img class="rank-image" [src]="trophy.name + '.png' | asset: 'ui'" />
              </ng-container>
            </ng-container>
          </td>

          <!-- PRIZE -->
          <td *ngIf="column.template === 'prize'" [style.text-align]="column.textAlign" class="prize">
            <div [innerHTML]="item.prize | resourceFromString" class="prize"></div>
          </td>
        </ng-container>

        <!-- DEFAULT COLUMN -->
        <ng-template #defaultTdTpl>
          <td [style.text-align]="column.textAlign">
            {{ item[column.value] }}
          </td>
        </ng-template>
      </ng-container>

      <!-- BE COLUMN -->
      <ng-container *ngIf="column.columnFromApi">
        <td class="column-from-api" *ngIf="item['value' + column.columnFromApi]" container="body" placement="top" [style.text-align]="column.textAlign">
          {{ item["value" + column.columnFromApi] +" pkt"}}
        </td>
      </ng-container>
    </ng-container>
  </tr>
</ng-template>
