import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiMissionGroupService } from '../../../../api/core/services/api-mission-group.service';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';
import { Mission } from '../../../../interfaces/core';
import { EVENT_DIALOGS_NAMES_MISSION } from '../../../../consts/core/event-dialogs/event-names';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../store/state';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { EVENT_DIALOGS_NAMES_CHEST } from '../../../../../chest/consts/core/event-dialogs/event-names';

@Component({
  selector: 'app-mission-group-details',
  templateUrl: './mission-group-details.component.html',

})
export class MissionGroupDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MissionGroupDetailsComponent>;
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(MAT_DIALOG_DATA) data: {
    missionGroups: any[],
    missionGroupId: number,
    index: number,
  };
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  STOCK_VIEW = STOCK_VIEW;
  activeMissionGroupId = this['data'].missionGroupId;
  activeMissionGroupIndex: number;

  missionGroups: {
    mission_group_id: number;
    icon: string;
    name: string;
    reward_product_id: number;
    total_missions: number;
    completed_missions: number;
    is_reward_collected: boolean;
  }[];

  missionGroup: {
    mission_group_id: number;
    icon: string;
    name: string;
    reward_product_id: number;
    player_chest_id: number;
    total_missions: number;
    completed_missions: number;
    is_reward_collected: boolean;
    missions: {
      exp_points: number;
      icon: string;
      is_collected: boolean;
      player_mission_id: number;
      title: string;
    }[];
  };

  ngOnInit() {
    this.setMissionGroups();
  }

  setMissionGroups() {
    this.missionGroups = this.data.missionGroups;

    if (!this.missionGroups) {
      this.apiMissionGroupService.getMissionGroups().subscribe((resp) => {
        this.missionGroups = resp;
        this.setMissionGroup();
      });
    } else {
      this.setMissionGroup();
    }
  }

  setMissionGroup() {
    this.activeMissionGroupIndex = this.missionGroups.findIndex((missionGroup) => {
      return missionGroup.mission_group_id === this.activeMissionGroupId;
    });

    if (this.activeMissionGroupIndex !== undefined) {
      this.getMissionGroupsDetails();
    }
  }

  getMissionGroupsDetails() {
    this.missionGroup = null;

    this.apiMissionGroupService.getMissionGroupsDetails(this.activeMissionGroupId)
      .subscribe((resp) => {
        this.missionGroup = resp;
      });
  }

  getMissionGroupReward() {
    this.apiMissionGroupService.getMissionGroupReward(this.activeMissionGroupId)
      .subscribe((resp: {
        player_chest_id: number | null;
        product_id: number
      }) => {
        if (!resp.player_chest_id) {
          this.dialogService.openAlert({
            description: 'Nagroda została odebrana'
          });
        } else {
          this.openChestDetails(resp.player_chest_id);
        }

        this.getMissionGroupsDetails();
      });
  }

  backToList() {
    this.matDialogRef.close();
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_GROUP_LIST,
        config: {
          data: {
            index: this.data.index,
          }
        }
      });
    });
  }

  openMissionDetails(mission: Mission) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
      config: {
        data: {
          mission,
        },
      },
      callback: (data) => {
        if (data && data.playerMissionsReload) {
          this.getMissionGroupsDetails();
        }
      }
    });
  }

  changePage(changeToIndex: number) {
    this.activeMissionGroupId = this.missionGroups[changeToIndex].mission_group_id;
    this.setMissionGroup();
  }

  openChestDetails(playerChestId: number) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CHEST.CHEST,
      config: {
        data: {
          playerChestId,
        }
      },
    });
  }
}
