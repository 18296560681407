import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { GetQaListRequest } from '../interfaces/get-qa-list-request.interface';
import { PostStartQaRequest } from '../interfaces/post-start-qa-request.interface';
import { PostSubmitQaAnswerRequest } from '../interfaces/post-submit-qa-answer-request.interface';
import { PostFinishQaRequest } from '../interfaces/post-finish-qa-request.interface';
import { QA_TYPE } from '../../../consts/core/qa-type.const';
import { GetQaEachRequest } from '../interfaces/get-qa-each-request.interface';
import { EVENT_DIALOGS_NAMES_QA } from '../../../consts/core/event-dialogs/event-names.const';


@Injectable({
  providedIn: 'root'
})
export class ApiQaService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  getQaList(getQaListRequest: GetQaListRequest) {
    const options: ApiOptions = {
      params: {
        ...getQaListRequest
      }
    };

    return this.apiService.get(
      `/qa/list`,
      options,
      EVENT_DIALOGS_NAMES_QA.QA_LIST
    );
  }

  startQa({qa_id, type}: PostStartQaRequest) {
    let typeApi: string;

    switch (type) {
      case QA_TYPE.QUIZ:
        typeApi = 'quizzes';
        break;
      case QA_TYPE.SURVEY:
        typeApi = 'surveys';
        break;
    }
    return this.apiService.post(`/qa/${typeApi}/${qa_id}/start`);
  }

  submitQaAnswer({qa_id, qa_question_id, qa_answer_id, qa_text_answer, type}: PostSubmitQaAnswerRequest) {
    let typeApi: string;
    const options: ApiOptions = {
      body: {
        qa_answer_id,
        qa_text_answer
      },
    };

    switch (type) {
      case QA_TYPE.QUIZ:
        typeApi = 'quizzes';
        break;
      case QA_TYPE.SURVEY:
        typeApi = 'surveys';
        break;
    }

    return this.apiService.post(`/qa/${typeApi}/${qa_id}/question/${qa_question_id}`, options);

  }

  finishQa({qa_id, type}: PostFinishQaRequest) {
    if (type === 'quiz') {
      return this.apiService.request('POST', `/qa/quizzes/${qa_id}/finish`);
    } else {
      return this.apiService.request('POST', `/qa/surveys/${qa_id}/finish`);
    }
  }

  getQaEach({qa_id}: GetQaEachRequest) {
    return this.apiService.get(`/qa/${qa_id}`);
  }
}
