import { AUTH_ROUTING_PATH } from './routing-auth.const';
import { CUSTOM_AUTH_ROUTING_ABSOLUTE_PATH } from '../custom/routing-auth-absolute.const';

export const AUTH_ROUTING_ABSOLUTE_PATH = {
  CHANGE_PASSWORD: `/${AUTH_ROUTING_PATH.CHANGE_PASSWORD}`,
  FORGOT_PASSWORD: `/${AUTH_ROUTING_PATH.FORGOT_PASSWORD}`,
  PASSWORD_RESET: `/${AUTH_ROUTING_PATH.PASSWORD_RESET}`,
  REGISTRATION: `/${AUTH_ROUTING_PATH.REGISTRATION}`,
  AUTHORIZE: `/${AUTH_ROUTING_PATH.AUTHORIZE}`,
  LOGIN: `/${AUTH_ROUTING_PATH.LOGIN}`,
  REAUTHORIZE: `/${AUTH_ROUTING_PATH.REAUTHORIZE}`,
  SOCKET_ERROR_CONNECTION: `/${AUTH_ROUTING_PATH.SOCKET_ERROR_CONNECTION}`,
  ...CUSTOM_AUTH_ROUTING_ABSOLUTE_PATH,
};
