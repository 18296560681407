import { Directive, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProductDetailsBalance } from '../../../../../player/interfaces/product.interface';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { PlayerSelectors } from '../../../../../../store/player';
import { AppState } from '../../../../../../store/state';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ApiStorageService } from '../../api/core/services/api-storage.service';
import { StorageWithCategories } from '../../interfaces/core/storage-with-categories.interface';
import { StorageBase } from '../../interfaces/core/storage.interface';
import { UtilityActions, UtilitySelectors } from '../../../../../../store/utility';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { WarehouseDetailsData } from '../../interfaces/core/dialogs/warehouse-details-data.interface';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from '../../consts/core/event-dialogs/event-names.const';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';

@Directive()
@Injectable()
export abstract class AbstractWarehouseComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractWarehouseComponent>;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ApiStorageService) apiStorageService: ApiStorageService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: {
    storageId: number,
  };

  @ViewChild('sliderProductsRef') sliderProductsRef;
  productsSlider = {
    config: {
      itemPerPage: 10,
    },
    pages: [],
    items: [],
  };
  sliderIndex = 0;
  productDetailsView: boolean;

  activeCategory: StorageTab;
  items: ProductDetailsBalance[];
  subs = {
    player: null,
    newProductsInStorage: null,
  };
  itemCapacity: number;
  storages: StorageWithCategories[];
  activeStorage: StorageWithCategories;

  ngOnInit() {
    this.getStorages();
    this.subscribePlayer();
  }

  getStorages() {
    this.storages = [];

    this.apiStorageService.storage()
      .subscribe((resp: StorageBase[]) => {
        this.storages = <StorageWithCategories[]>resp
          .filter((storage) => {
            if (this.data.storageId) {
              return storage.storage_id === this.data.storageId;
            } else {
              return true;
            }
          })
          .map((storage) => {
            return {...storage, categories: []};
          });

        this.getAllCategories();
        this.changeActiveStorage(this.storages[0]);
      });
  }

  getAllCategories() {
    const categoriesRequest = [];

    this.storages.forEach((storage) => {
      categoriesRequest.push(this.getCategories(storage));
    });

    forkJoin(
      categoriesRequest
    ).subscribe((categories: any) => {
      const category = categories.flat()[0];
      this.changeCategory(category);
      this.subscribeNewProductsInStorage();
    });
  }

  getCategories(storage: StorageWithCategories) {
    return this.productPlayerService.productsCategories({storage_id: storage.storage_id})
      .pipe(
        tap((resp) => {
          storage.categories = resp;
        })
      );
  }

  calculateItemsCapacity() {
    this.itemCapacity = 0;
    for (const item of this.productsSlider.items) {
      this.itemCapacity += item['balance'];
    }
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.parseProducts(this.productsSlider.items);
        });
      });
  }

  subscribeNewProductsInStorage() {
    this.subs.newProductsInStorage = this.store
      .pipe(
        select(UtilitySelectors.selectNewProductInStorage),
        take(1),
      )
      .subscribe((products) => {
        this.setNewCategories(products);
      });
  }

  getProductsByCategory(category_id) {
    this.productPlayerService.productsPlayer({category: category_id})
      .subscribe((resp) => {
        this.parseProducts(resp);
      });
  }

  parseProducts(products) {
    this.productsSlider.pages = [];
    this.productsSlider.items = this.setProducts(products);
    this.productsSlider = generateEachPages(this.productsSlider);
    this.calculateItemsCapacity();
  }

  changeActiveStorage(storage: Partial<StorageWithCategories>) {
    this.activeStorage = this.storages.find((s) => s.storage_id === storage.storage_id);
  }

  changeCategory(tab: StorageTab) {
    this.changeActiveStorage({storage_id: tab.storage_id});

    this.productsSlider.items = [];
    this.activeCategory = tab;

    this.getProductsByCategory(this.activeCategory.id);
  }

  openTradeDialog(item) {
    const warehouseDetailsData: WarehouseDetailsData = {
      product_id: item.product_id,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS,
      config: {
        data: warehouseDetailsData
      }
    });
  }

  setNewCategories(newProductsInStorage) {
    this.storages.forEach((storage) => {
      storage.categories.forEach((category) => {
        category.hasNewProducts = newProductsInStorage.some(item => item.product_category_id === category.id);
      });
    });

    this.dispatchClearNewProducts();
  }

  dispatchClearNewProducts() {
    this.store
      .dispatch(new UtilityActions.ClearNewProductsInStorage());
  }

  close() {
    this.matDialogRef.close();
  }

  prevSlide() {
    this.sliderProductsRef.directiveRef.prevSlide();
    this.calculateItemsCapacity();
  }

  nextSlide() {
    this.sliderProductsRef.directiveRef.nextSlide();
    this.calculateItemsCapacity();
  }

  setProducts(products) {
    products = this.productPlayerService.getProducts(products);
    const itemPerPage = this.productsSlider.config.itemPerPage;

    let lengthEmptyProducts = itemPerPage - (products.length % itemPerPage);

    if (lengthEmptyProducts === itemPerPage && products.length >= 1) {
      lengthEmptyProducts = 0;
    }

    const emptyProducts = new Array(lengthEmptyProducts).fill({});

    products = [...products, ...emptyProducts];
    return products;
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}

export interface StorageTab {
  id: number;
  name: string;
  storage_id: number;
}
