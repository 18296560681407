<div class="global-dialog">
  <div
    *ngIf="currentTime !== null"
    class="timer-container"
  >
    <div class="icon"></div>
    {{ currentTime | owDuration2:'mm:ss' }}
  </div>

  <div class="image-container">
    <ng-container *ngFor="let questionInfoSliderImage of data.questionInfoSliderImages; let index = index">
      <img
        class="active"
        *ngIf="currentIndex === index"
        [src]="questionInfoSliderImage.url"
      />
    </ng-container>
  </div>

  <div
    *ngIf="!data.manualClose"
    class="number-slide"
  >
    {{ currentIndex + 1 }}/{{ data.questionInfoSliderImages.length }}
  </div>

  <button
    *ngIf="data.manualClose"
    (click)="close()"
    class="short primary bottom-button"
  >
    Dalej
  </button>
</div>
